<template>
  <div>
    <div class="md-layout spacing appv-memo">
      <div class="md-layout-item md-size-100">
        <div class="md-theme-default">
          <md-card-content>
            <b-container fluid>
              <!-- Filter Row -->
              <b-row class="mb-3">
                <b-col cols="12" md="6" class="mb-2 mb-md-0">
                  <b-form-group
                    label="Filter"
                    label-for="filter-input"
                    class="mb-0"
                  >
                    <b-input-group>
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          :disabled="!filter"
                          @click="filter = ''"
                          variant="danger"
                        >
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="text-md-right">
                  <!-- Additional buttons or elements can go here -->
                </b-col>
              </b-row>

              <!-- Action Buttons Row -->
              <b-row class="mb-3">
                <b-col cols="12" md="6" class="mb-2 mb-md-0">
                  <b-button
                    variant="primary"
                    @click="selectAllRows"
                    class="mr-2"
                  >
                    เลือกทั้งหมด
                  </b-button>
                  <b-button variant="danger" @click="clearSelected">
                    ล้าง
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" class="text-md-right">
                  <b-button variant="danger" @click="disapprove" class="mr-2">
                    Reject
                  </b-button>
                  <b-button variant="warning" @click="recheck" class="mr-2">
                    Recheck
                  </b-button>
                  <b-button variant="success" @click="handleApprove">
                    Approve
                  </b-button>
                </b-col>
              </b-row>

              <!-- Table -->
              <b-row>
                <b-col cols="12">
                  <div class="approve-all-tbl text-nowrap">
                    <b-table
                      id="my-table"
                      :items="items"
                      :fields="fields"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :select-mode="selectMode"
                      :busy="isBusy"
                      ref="selectableTable"
                      selectable
                      show-empty
                      small
                      responsive
                      hover
                      class="custom-table"
                      @row-selected="onRowSelected"
                    >
                      <template #empty>
                        <center>
                          <h4>No Data Available</h4>
                        </center>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>

                      <template #cell(memo_name)="row">
                        <p>{{ row.item.memo_name }}</p>
                        <p
                          class="text-danger"
                          v-if="row.item.status_approve >= 4"
                        >
                          **{{ row.item.reason }}
                        </p>
                      </template>
                      <template #cell(emp_name)="row">
                        <p>
                          {{
                            `${row.item.empcreate_firstname_en} ${row.item.empcreate_lastname_en}`
                          }}
                        </p>
                      </template>
                      <template #cell(status_approve)="row">
                        <!--                        <div>-->
                        <span
                          v-if="row.item.status_approve == 0"
                          class="waitapprove"
                        >
                          <md-icon class="mr-1">pending</md-icon>
                          <span>WAIT APPROVE</span>
                        </span>
                        <span
                          v-if="row.item.status_approve == -1"
                          class="draft"
                        >
                          <md-icon class="mr-1">drafts</md-icon>
                          <span>DRAFT</span>
                        </span>
                        <span
                          v-if="row.item.status_approve == 2"
                          class="recheck"
                        >
                          <md-icon class="mr-1">autorenew</md-icon>
                          <span>RECHECK</span>
                        </span>
                        <span
                          v-if="row.item.status_approve == 3"
                          class="approved"
                        >
                          <md-icon class="mr-1">check_circle</md-icon>
                          <span>APPROVED</span>
                        </span>
                        <span
                          v-if="row.item.status_approve == 4"
                          class="request-recheck"
                        >
                          <md-icon class="mr-1">history</md-icon>
                          <span>REQUEST RECHECK</span>
                        </span>
                        <span
                          v-if="row.item.status_approve == 5"
                          class="request-reject"
                        >
                          <md-icon class="mr-1">cancel</md-icon>
                          <span>REQUEST REJECT</span>
                        </span>
                      </template>
                      <template #cell(detail)="row">
                        <div class="d-flex flex-column">
                          <div class="">
                            <div>
                              <span class="mx-1">
                                <b>จาก:</b> {{ row.item?.from_text }}
                              </span>
                              <span class="mx-1">
                                <b>ถึง:</b> {{ row.item?.to_text }}
                              </span>
                            </div>
                            <div><b>CC:</b> {{ row.item?.cc }}</div>
                            <div>
                              <b>วันเริ่มใช้งาน:</b>
                              {{ moment(row.item?.date).format('DD/MM/YYYY') }}
                            </div>
                          </div>

                          <div class="mt-2 text-wrap">
                            <b-button
                              variant="outline-danger"
                              v-if="row.item.status_approve >= 4"
                              class="mr-2"
                              @click="requestDisapprove(row.item.id)"
                            >
                              <i class="fas fa-times-circle"></i> Reject
                            </b-button>
                            <b-button
                              variant="outline-success"
                              v-if="row.item.status_approve >= 4"
                              class="mr-2"
                              @click="requestApprove(row.item.id)"
                            >
                              <i class="fas fa-check-circle"></i> Approve
                            </b-button>
                            <b-button
                              variant="outline-success"
                              v-if="row.item.status_approve == 0"
                              @click="
                                handleSigned(row.item?.id);
                                selected = [row.item];
                              "
                              class="mr-2"
                            >
                              <i class="fas fa-pen-nib"></i> Signed
                            </b-button>
                            <b-button
                              variant="outline-danger"
                              @click="downloadPdf(row.item?.id)"
                            >
                              <i class="fas fa-file-pdf"></i> DOWNLOAD
                            </b-button>
                          </div>
                        </div>
                      </template>

                      <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <span>&check;</span>
                          <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                          <span class="sr-only">Not selected</span>
                        </template>
                      </template>
                    </b-table>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </md-card-content>
        </div>
      </div>
    </div>

    <pdfme-form
      ref="pdfmeEditor"
      class="pdfmeForm"
      :template="pdfme_template"
      :hidden="showPdfmeForm"
    >
      <template slot="form-header">
        <div class="p-2">
          <span
            class="pointer mx-3"
            @click="
              showPdfmeForm = false;
              pdfme_template = undefined;
            "
          >
            ❌
          </span>

          <span class="mr-2">
            <i class="fas fa-file-pdf fa-lg text-danger"></i>
          </span>

          <span class="ml-1 text-white">{{ selected[0]?.memo_name }}</span>
        </div>
      </template>

      <template slot="form-body">
        <div class="form-body d-flex justify-content-end mr-4">
          <div class="mt-2">
            <md-button
              class="md-round mx-2"
              @click="
                showPdfmeForm = false;
                pdfme_template = undefined;
              "
            >
              CLOSE
            </md-button>
            <md-button class="md-success md-round mx-2" @click="approve()">
              APPROVE
            </md-button>
          </div>
        </div>
      </template>
    </pdfme-form>

    <signature-pad-modal
      :force-signature-before-close="true"
      :is-open="showSignaturePadModal"
      :button-text="'APPROVE'"
      @onClose="showSignaturePadModal = false"
      @onRequireSignature="
        $store.dispatch('alerts/error', 'Please sign before approve!')
      "
      @onSubmit="handleSignatureSubmit"
    ></signature-pad-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { loading_close, loading_start } from '@/utils/loading.js';
import MemoMixin from '@/components/Pdfme/BaseTemplate/MemoMixin';
import moment from 'moment';
import SignaturePadModal from '@/components/SignaturePadModal.vue';
import PdfmeForm from '@/components/Pdfme/views/PdfmeForm.vue';
import pdfmeHelperMixin from '@/components/Pdfme/plugins/PdfmeHelperMixin';
import Vuex from 'vuex';

export default {
  computed: {
    moment() {
      return moment;
    },
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  mixins: [MemoMixin, pdfmeHelperMixin],
  components: {
    PdfmeForm,
    SignaturePadModal,
  },
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL,
      isBusy: false,
      selected: [],
      selectMode: 'multi',
      approve_st: 'approve',
      fields: [
        {
          key: 'selected',
          label: 'เลือก',
          tdClass: 'td-selected-approve',
          class: 'text-center',
        },
        {
          key: 'memo_name',
          label: 'ชื่อ',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'emp_name',
          label: 'จัดทำโดย',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'status_approve',
          label: 'สถานะ',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: true,
          class: 'text-center',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      remark: '',
      pdfme_template: undefined,
      showPdfmeForm: false,
      branchLogo: null,
      memorandam: {},
      showSignaturePadModal: false,
      memoTemplates: {},
    };
  },

  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },

  methods: {
    handleApprove() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }

      this.showSignaturePadModal = true;
    },
    async handleSignatureSubmit(signature) {
      this.showSignaturePadModal = false;
      this.selected.map((s) => {
        this.signed(s.id, signature);
        return s.id;
      });
      await this.approve();
    },
    async handleSigned(memoId) {
      this.pdfme_template = await this.signed(memoId);
      this.showPdfmeForm = true;
    },
    getMemoDetail(memoId) {
      return new Promise(async (resolve, reject) => {
        try {
          loading_start();

          const response = await this.$store.dispatch('memo/getMemoDetail', {
            id: memoId,
          });

          this.memorandam = {
            toText: response.data.memoDetail.to_text,
            fromText: response.data.memoDetail.from_text,
            dateMemo: response.data.memoDetail.date,
            pageText: response.data.memoDetail.page,
            subjectText: response.data.memoDetail.subject,
            cc: response.data.memoDetail.cc,
          };

          const response_logo = await this.$store.dispatch(
            'pdfs/getBranchLogo',
            {
              bch_id: response.data.memoDetail.bch_id,
            },
          );
          this.branchLogo = response_logo[0]?.image;
          const title = `MEMORANDAM - ${response.data.memoDetail.subject}`;
          const settings = JSON.parse(response.data.memoDetail.settings);

          resolve({
            memorandam: this.memorandam,
            branchLogo: this.branchLogo,
            template: response.data.memoDetail.memo_data,
            approvers: response.data.approvers ?? [],
            settings,
            title,
          });
        } catch (error) {
          reject(`Error generating preview: ${error}`);
        } finally {
          loading_close();
        }
      });
    },
    async applyAutoGenerate(template, branchLogo, memorandam) {
      const baseTemplate = this.getBaseTemplate({
        branchLogo,
        memorandam,
      });

      return this.mergeTemplates({
        template,
        pendingChanges: [await this.updateBaseTemplate(baseTemplate, template)],
      });
    },
    async signed(memoId, signature) {
      const memo = await this.getMemoDetail(memoId);
      const user = this.me || this.$store.getters['profile/me'];

      const approversMap = new Map(
        memo.approvers.map((a) => [`${a.emp_id}-${a.type}`, a]),
      );

      const approver = approversMap.get(`${user.employee_id}-signer`);
      if (!approver) {
        memo.settings.autoGenerate = JSON.parse(memo.settings.autoGenerate);
        if (memo.settings.autoGenerate) {
          memo.template.schemas.forEach((schemas) => {
            schemas.forEach((schema) => (schema.readOnly = true));
          });

          this.memoTemplates[memoId] = await this.applyAutoGenerate(
            memo.template,
            this.branchLogo,
            this.memorandam,
          );
          return this.memoTemplates[memoId];
        }
      }

      const key = `${approver.priority}${approver.order_person}`;

      memo.template.schemas.forEach((schemas) => {
        schemas.forEach((schema) => {
          schema.readOnly = true;

          const readOnly = !(
            schema.name?.startsWith('signature-') && schema.name?.endsWith(key)
          );
          const spReadOnly = !(
            schema.name?.startsWith('signaturePad-') &&
            schema.name?.endsWith(key)
          );

          if (!readOnly) {
            if (!schemas.some((s) => s.name === `signaturePad-${key}`)) {
              schemas.push({
                name: `signaturePad-${key}`,
                type: 'signature',
                content: signature ?? '',
                position: {
                  x: schema.position.x,
                  y: schema.position.y - schema.height + 7,
                },
                width: schema.width,
                height: schema.height,
                readOnly: readOnly,
                required: false,
              });
            }
          } else if (!spReadOnly) {
            if (signature) schema.content = signature;

            schema.position.y = schema.position.y - schema.height + 18;
            schema.readOnly = false;
          }
        });
      });

      memo.settings.autoGenerate = JSON.parse(memo.settings.autoGenerate);
      if (memo.settings.autoGenerate) {
        this.memoTemplates[memoId] = await this.applyAutoGenerate(
          memo.template,
          this.branchLogo,
          this.memorandam,
        );
        return this.memoTemplates[memoId];
      }
      memo.template = this.mergeTemplates({
        template: memo.template,
      });
      return (this.memoTemplates[memoId] = memo.template);
    },
    async downloadPdf(memoId) {
      try {
        loading_start();
        const memo = await this.getMemoDetail(memoId);

        if (memo.settings?.autoGenerate) {
          memo.template = this.mergeTemplates({
            template: memo.template,
            pendingChanges: [
              await this.updateBaseTemplate(
                this.getBaseTemplate({
                  branchLogo: this.branchLogo,
                  memorandam: this.memorandam,
                }),
                memo.template,
              ),
            ],
          });
        }

        const blob = await this.generatePDF({
          template: memo.template,
          title: memo.title,
          preview: false,
        });
        const a = Object.assign(document.createElement('a'), {
          href: URL.createObjectURL(blob),
          download: `${memo.title || 'document'}.pdf`,
        });
        document.body.append(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(a.href);
      } catch (error) {
        throw new Error(`Error generating preview: ${error}`);
      } finally {
        loading_close();
      }
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    approve() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to approve the changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        confirmButtonText: 'Approve',
      }).then(async (result) => {
        if (result.value) {
          this.approve_st = 'approve';
          await loading_start();
          let res = await this.save_approve('approve');
          await loading_close();
          if (res.st === 'success') {
            this.showPdfmeForm = false;
            this.pdfme_template = undefined;
            this.memoTemplates = {};

            await Swal.fire('Saved!', '', 'success');
            this.$emit('get_data');
          } else if (res.st === 'error') {
            await Swal.fire({
              icon: 'error',
              title: res.title ?? 'Error',
              text: res.message ?? 'Something went wrong',
            });
          }
        } else {
          return false;
        }
      });
    },
    recheck() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to recheck the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'recheck',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          this.approve_st = 'recheck';
          await loading_start();
          let res = await this.save_approve('recheck', result.value);
          await loading_close();
          this.$emit('get_data');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    disapprove() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to reject the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Reject',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          this.approve_st = 'reject';
          await loading_start();
          let res = await this.save_approve(
            'reject',
            result.value,
          ).finally(() => loading_close());
          await loading_close();
          this.$emit('get_data');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    requestApprove(memoId) {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to approve the changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        confirmButtonText: 'Approve',
      }).then(async (result) => {
        if (result.value) {
          await loading_start();
          let res = await this.save_request(memoId, 'requestRecheck');
          await loading_close();
          if (res.st === 'success') {
            this.showPdfmeForm = false;
            this.pdfme_template = undefined;
            this.memoTemplates = {};

            await Swal.fire('Saved!', '', 'success');
            this.$emit('get_data');
          } else if (res.st === 'error') {
            await Swal.fire({
              icon: 'error',
              title: res.title ?? 'Error',
              text: res.message ?? 'Something went wrong',
            });
          }
        } else {
          return false;
        }
      });
    },
    requestDisapprove(memoId) {
      Swal.fire({
        title: 'Do you want to reject the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Reject',
      }).then(async (result) => {
        if (result.value) {
          await loading_start();
          let res = await this.save_request(
            memoId,
            'requestDelete',
            result.value,
          );
          await loading_close();
          this.$emit('get_data');
          if (res?.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
          } else if (res?.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    async save_approve(status, remark = '') {
      try {
        const memoIds = this.selected.map((item) => item.id);

        let memoTemplates = {};

        if (this.$refs.pdfmeEditor.formInstance && this.pdfme_template) {
          const memoInputs = this.$refs.pdfmeEditor.formInstance.getInputs();
          const template = this.$refs.pdfmeEditor.formInstance.getTemplate();
          memoInputs.forEach((item, index) => {
            if (Object.keys(item)?.length <= 0) return false;

            const [key, value] = Object.entries(item)[0];
            template.schemas[index] = template.schemas[index].map((schema) => {
              if (schema.name === key) {
                if (!value) throw new Error('VAL001');
                schema.content = value;
              }
              return schema;
            });
          });
          memoTemplates[memoIds[0]] = template;
        } else {
          memoTemplates = Object.fromEntries(
            Object.entries(this.memoTemplates).filter(([key]) =>
              memoIds.includes(key),
            ),
          );
        }

        const obj = {
          memoIds,
          memoTemplates,
          status,
          remark,
        };

        return await this.$store.dispatch('approvers/saveMemoApprovals', obj);
      } catch (error) {
        if (error.message === 'VAL001') {
          await this.$store.dispatch(
            'alerts/error',
            'Please sign before saving.',
          );

          return {
            st: 'error',
            message: 'Please sign before saving.',
            title: 'Validation Error',
          };
        } else if (error.message === 'ERR001') {
          await this.$store.dispatch('alerts/error', '');
        } else {
          await this.$store.dispatch('alerts/error', error.message);
          throw error;
        }
      }
    },
    async save_request(memoId, status, remark = '') {
      try {
        const obj = {
          memoId,
          status,
          reason: remark,
        };

        return await this.$store.dispatch('approvers/saveMemoRequest', obj);
      } catch (error) {
        if (error.message === 'VAL001') {
          return {
            st: 'error',
            message: 'Please sign before saving.',
            title: 'Validation Error',
          };
        } else {
          throw error;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.pdfmeForm {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 59;
  width: 100%;

  &.pdfme-form {
    background: rgb(74, 74, 74);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .editor {
      height: 89vh;
    }

    .form-body {
      height: 12vh;
    }
  }
}
</style>
<style lang="scss" scoped>
.td-selected-approve {
  width: 12% !important;
  vertical-align: middle !important;
  text-align: center;
  font-size: 2rem;
}

.show-file {
  width: 100%;

  height: 100%;
}

.footer-btn {
  width: 100%;
  text-align: right;
  margin: 10px 0;
  padding: 0px 50px;
}

@media (max-width: 768px) {
  .btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .b-form-group {
    margin-bottom: 10px;
  }

  .b-table {
    font-size: 14px;
  }

  .custom-table {
    overflow-x: auto;
  }
}
</style>
