import service from '@/store/services/messenger-service';

const state = {};

const mutations = {};

const actions = {
  async send({ commit, dispatch }, params) {
    return await service.messengerSend(params);
  },
  async read({ commit, dispatch }, params) {
    return await service.messengerRead(params);
  },
  async messengers({ commit, dispatch }, params) {
    return await service.messengers(params);
  },
};

const getters = {};

const memo = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default memo;
