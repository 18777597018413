<template>
  <div>
    <!-- Progress bar -->
    <vue-progress-bar></vue-progress-bar>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title"></h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-5 md-medium-size-10 md-small-size-10 md-xsmall-size-100"
            style="text-align: left"
          >
            <label style="margin-top: 1.3rem; text-align: right">
              ค้นหา:
            </label>
          </div>
          <!-- <div
              class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
              align="center"
            >
              <md-field class="searchbranchs">
                <label>เลือก สำนักงาน</label>
                <md-select v-model="branches" name="branch_id">
                  <md-option
                    v-for="item in available_branches"
                    :key="item.branch_id"
                    :value="item.branch_id"
                  >
                    {{ item.br_nameth }}
                  </md-option>
                </md-select>
              </md-field>
            </div> -->
          <div
            class="md-layout-item md-size-30 md-medium-size-50 md-small-size-50 md-xsmall-size-80"
            style="text-align: left"
          >
            <md-field>
              <label>Search</label>
              <md-input
                class="md-size-50 searchID"
                v-model="keySearch"
                @keyup.enter="getAppeal()"
              />
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-10 md-medium-size-10 md-small-size-10 md-xsmall-size-20"
            style="text-align: left"
          >
            <md-button
              class="md-info md-just-icon md-round md-sm"
              @click="getAppeal()"
            >
              <md-icon>search</md-icon>
            </md-button>
          </div>
          <div
            class="md-layout-item md-size-10 md-medium-size-20 md-small-size-30 md-xsmall-size-100"
            style="text-align: right"
          >
            <md-button class="md-info md-round" @click="requestAppeal()">
              <i class="fa fa-comment-medical"></i>
              Request
            </md-button>
          </div>
        </div>
        <div deck id="my-data">
          <b-card-group
            style="margin-top: 1rem"
            v-for="data in show_data_form_search"
            :value="data.sos_name"
            :key="data.id"
          >
            <b-card
              border-variant="Warning"
              class="text-left card1"
              style="box-shadow: 3px 3px 2px #ebebeb"
              @click="goToShow(data.id)"
            >
              <template #header>
                <h5 class="mb-0">หัวข้อ: {{ data.appeal_name }}</h5>
              </template>
              <b-card-text>
                <p class="detail">{{ data.appeal_detail }}</p>
              </b-card-text>
              <div class="card-actions">
                <md-button
                  class="md-success md-round"
                  @click.stop="openChat(data)"
                >
                  <md-icon>chat</md-icon>
                  แชท
                </md-button>
                <md-button
                  class="md-info md-round"
                  @click.stop="goToShow(data.id)"
                >
                  <md-icon>visibility</md-icon>
                  ดูรายละเอียด
                </md-button>
              </div>
            </b-card>
          </b-card-group>
        </div>
        <b-pagination
          v-if="data_form_search.length > 0"
          style="margin-top: 15px"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import $ from 'jquery';
import Vuex from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      keySearch: '',
      tmpkeySearch: '',
      perPage: 4,
      currentPage: 1,
      show_data_form_search: [],
      data_form_search: [],
    };
  },
  async created() {
    this.keySearch = this.$route.params.keySearch || '';
    await this.getAppeal();
    this.currentPage = this.$route.params.currentPage || 1;
    // this.getBranches();
  },
  computed: {
    rows() {
      var data_rows = this.data_form_search.length;
      return data_rows;
    },
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var pms = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },
  watch: {
    async currentPage() {
      await this.filterPage();
    },
  },
  methods: {
    filterPage() {
      this.show_data_form_search = [];
      var a = this.currentPage > 1 ? this.currentPage * this.perPage - 4 : 0;
      var b = this.perPage + (this.currentPage * this.perPage - 4);
      var data = [];
      data = this.data_form_search.slice(a, b);

      this.show_data_form_search = data;
    },

    async getAppeal() {
      let obj = {
        com_id: this.me.employees.com_id,
        ...{
          emp_id: this.permissions.includes('manageAppeal') ? {} : this.emp_id,
        },
        // branches_id: this.me.employees.branch_id,
        key_search: this.keySearch,
      };
      let resp = await this.$store.dispatch('appeal/getAppeal', obj);
      if (resp == 0) {
        this.keySearch = this.tmpkeySearch;
        await this.$store.dispatch('alerts/error', 'ไม่มีข้อมูลในระบบ!');
        return false;
      } else {
        this.tmpkeySearch = this.keySearch;
        this.currentPage = 1;
        this.data_form_search = Object.values(resp);
        this.data_form_search.forEach((d) => {
          d.format_updated_at = moment(d.updated_at).format(
            'DD/MM/YYYY HH:mm:ss',
          );
        });
        this.filterPage();
      }
    },

    async requestAppeal() {
      let currentPage = this.currentPage;
      let keySearch = this.keySearch;
      let backPage = 'list';

      await this.$router.push({
        path: 'addAppeal',
        name: 'เพิ่ม ข้อเสนอแนะ / ข้อร้องเรียน',
        params: {
          keySearch,
          currentPage,
          backPage,
        },
      });
    },

    async openChat(data) {
      const { id, appeal_name, emp_id } = data;
      let currentPage = this.currentPage;
      let keySearch = this.keySearch;

      await this.$router.push({
        path: 'messages',
        name: 'Messages',
        params: {
          m_detail: {
            ref_id: id,
            ref_name: 'App\\Models\\AppealTopic',
            ref_column: 'appeal_name',
            emp_id: emp_id,
            mg_code: 'APPEAL-0001',
            name: appeal_name,
            type: 'APPEAL',
          },
          route: {
            path: 'listAppeal',
            name: 'รายงานข้อเสนอแนะ / ข้อร้องเรียน',
          },
          params: {
            keySearch,
            currentPage,
          },
        },
      });
    },

    goToShow(id, backPage = 'list') {
      let getdata = this.show_data_form_search;
      var data = [];

      $(getdata).each(async function(i, o) {
        if (id == o.id) {
          data = [o];
        }
      });

      let currentPage = this.currentPage;
      let keySearch = this.keySearch;

      this.$router.push({
        path: 'viewAppeal',
        name: 'รายการข้อเสนอแนะ / ข้อร้องเรียน',
        params: {
          id,
          data,
          keySearch,
          currentPage,
          backPage,
        },
      });
    },
  },
};
</script>
<style scoped>
.card1 {
  box-shadow: 3px 3px 2px #ebebeb;
}

.card1:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  border: 1px solid green;
  background-color: white;
}

.card1 p.detail {
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mg-left-badge:not(:first-child) {
  margin-left: 5px;
}

.card1 {
  box-shadow: 3px 3px 2px #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* จัดเนื้อหาและปุ่มให้อยู่ห่างกัน */
  height: 100%; /* ทำให้ card มีความสูงเต็มที่ */
}

.card1:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  border: 1px solid green;
  background-color: white;
}

.card1 p.detail {
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-actions {
  display: flex;
  justify-content: flex-end; /* จัดปุ่มไปทางขวา */
  gap: 10px; /* ระยะห่างระหว่างปุ่ม */
  margin-top: 10px; /* ระยะห่างจากเนื้อหาด้านบน */
}

.md-success {
  background-color: #4caf50 !important; /* สีเขียว */
  color: white !important;
}

.md-success:hover {
  background-color: #45a049 !important; /* สีเขียวเข้มเมื่อ hover */
}

.md-info {
  background-color: #2196f3 !important; /* สีน้ำเงิน */
  color: white !important;
}

.md-info:hover {
  background-color: #1e88e5 !important; /* สีน้ำเงินเข้มเมื่อ hover */
}
</style>
