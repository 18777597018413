<template>
  <div>
    <md-dialog :md-active.sync="showEdit" style="min-width: 60%;" md-fullscreen>
      <md-dialog-title style="font-size: 1.5rem">
        เพิ่ม - แก้ไข การอนุมัติ
      </md-dialog-title>
      <md-dialog-content style="font-size: 1.2rem">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
          >
            <ValidationProvider
              name="ot_id"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="ot">เลือกประเภท</label>
                <md-select v-model="ot_id">
                  <md-option
                    v-for="item in available_ots"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>
          </div>
          <div
            class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
          >
            <ValidationProvider
              name="branchs"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="branchs">เลือก สำนักงาน</label>
                <md-select v-model="branchs">
                  <md-option
                    v-for="item in available_branches"
                    :key="item.bch_id"
                    :value="item.bch_id"
                  >
                    {{ item.brname_th }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <hr />
          </div>
          <div
            class="md-layout-item md-size-35 md-medium-size-35 md-small-size-60 md-xsmall-size-100"
          >
            <md-field>
              <label for="priority">ลำดับการแสดง</label>
              <md-select v-model="uniqueapprover.number">
                <md-option value="1">ลำดับ 1</md-option>
                <md-option value="2">ลำดับ 2</md-option>
                <md-option value="3">ลำดับ 3</md-option>
                <md-option value="4">ลำดับ 4</md-option>
                <md-option value="5">ลำดับ 5</md-option>
              </md-select>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-35 md-medium-size-35 md-small-size-60 md-xsmall-size-100"
          >
            <md-field>
              <label for="priority">ลำดับการอนุมัติ</label>
              <md-select v-model="uniqueapprover.priority">
                <md-option value="1">ลำดับ 1</md-option>
                <md-option value="2">ลำดับ 2</md-option>
                <md-option value="3">ลำดับ 3</md-option>
                <md-option value="4">ลำดับ 4</md-option>
                <md-option value="5">ลำดับ 5</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <filter-employee
              v-model="filter"
              :headLabel="'ผู้อนุมัติ'"
              :permissionsList="permissions"
              @childStaffs="setStaffs_approver"
            >
            </filter-employee>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-field>
              <label>คำอธิบาย</label>
              <md-textarea
                v-model="uniqueapprover.description"
                md-autogrow
              ></md-textarea>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-button class="md-primary" @click="list_data()">ADD</md-button>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-table
              :value="listData"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
              v-if="change == true"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell class="backwhite" md-label="ลำดับการแสดง">
                  <span class="upfont">
                    {{ item.number }}
                  </span>
                </md-table-cell>
                <md-table-cell class="backwhite" md-label="ลำดับการอนุมัติ">
                  <span class="upfont">
                    {{ item.priority }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="name">
                  <span class="upfont">
                    {{ item.emp_approver_tname }} {{ item.emp_approver_fname }}
                    {{ item.emp_approver_lname }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="คำอธิบาย">
                  <span class="upfont">
                    {{ item.description }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="Action">
                  <md-button
                    class="md-rose md-just-icon md-round"
                    style="margin: 0.2rem"
                    @click="goto_delete(item)"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <!-- <div class="md-layout-item md-size-100 md-small-size-100">
            <hr />
          </div> -->
          <div
            class="md-layout-item md-size-70 md-medium-size-60 md-small-size-40 md-alignment-right"
          ></div>
          <div
            class="md-layout-item md-size-30 md-medium-size-40 md-small-size-60 md-alignment-right"
          >
            <md-switch v-model="uniqueapprover.status" value="active">
              <div v-if="uniqueapprover.status == 'active'">
                เปิดการใช้งาน
              </div>
              <div v-if="uniqueapprover.status == 'inactive'">
                ปิดการใช้งาน
              </div>
            </md-switch>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-success" @click="insert_data()">
          บันทึก
        </md-button>
        <md-button class="md-danger" @click="showEdit = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card-content>
          <div class="md-layout">
            <div
              class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
              align="center"
            >
              <md-field class="searchbranchs">
                <label>เลือก สำนักงาน</label>
                <md-select v-model="branches" name="branch_id">
                  <md-option
                    v-for="item in available_branches"
                    :key="item.bch_id"
                    :value="item.bch_id"
                  >
                    {{ item.brname_th }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div
              class="md-layout-item md-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="ot_id"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ot">เลือกประเภท</label>
                  <md-select v-model="s_ot_id">
                    <md-option
                      v-for="item in available_ots"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              class="md-layout-item md-size-100 mt-4 md-small-size-100 text-right"
            >
              <md-button @click="goToAdd" class="md-primary md-dense">
                เพิ่มการอนุมัติ
              </md-button>
            </div>
          </div>
          <md-table
            :value="tableData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="สาขา">
                <span class="upfont">
                  {{ item.br_nameen }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="ประเภท">
                <span class="upfont">
                  {{ item.ot_name }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Approver">
                <span class="upfont">
                  {{ item.emp_code }} :
                  {{ item.firstname_en }}
                  {{ item.lastname_en }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Description">
                {{ item.position_detail }}
              </md-table-cell>
              <md-table-cell md-label="Priority">
                ลำดับที่ : {{ item.priority }}
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <md-switch
                  v-model="item.status"
                  value="active"
                  @change="switch_active(item.id, item.status)"
                >
                  <div v-if="item.status == 'active'">
                    เปิดการใช้งาน
                  </div>
                  <div v-if="item.status == null || item.status == 'inactive'">
                    ปิดการใช้งาน
                  </div>
                </md-switch>
                <!-- <md-button
                  class="md-icon-button md-raised md-round md-info"
                  @click="goToEdit(item.id)"
                  style="margin: 0.2rem"
                >
                  <md-icon>edit</md-icon>
                </md-button> -->
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';
import Vuex from 'vuex';
import $ from 'jquery';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  data() {
    return {
      profile: null,
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      uniqueapprover: {
        type: 'uniqueapprover',
        emp_approver_id: null,
        priority: 0,
        status: 'active',
        description: '',
      },

      unique: [],
      emp_id: [],
      permissions: [],
      staffs: null,
      employee_id: null,
      showEdit: false,
      priority: '',
      ot_id: null,
      s_ot_id: null,
      branchs: null,
      branches: '',
      selected_employee: null,
      com_id: null,
      listData: [],
      change: false,
      filter: [],
      // courses: [
      //   {
      //     courseName: '',
      //   }
      // ],
    };
  },
  async created() {
    await this.setPermission(this.me);
    loading_start();
    let params = { profile: true };
    let employee = await this.$store.dispatch('staffs/searchStaff', params);
    // await this.getOTType(employee[0].com_id);
    this.com_id = employee[0].com_id;
    loading_close();
  },
  watch: {
    uniqueapprover: {
      handler: function(val) {
        if (val.status != 'active') {
          val.status = 'inactive';
        }
      },
      deep: true,
    },
    branches: function(v) {
      this.getList();
    },
    s_ot_id: function(val) {
      this.getList();
    },
  },
  props: {
    available_branches: {
      type: Array,
      default: () => {},
    },
    available_ots: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  methods: {
    async getList() {
      loading_start();
      let params = { profile: true };
      let employee = await this.$store.dispatch('staffs/searchStaff', params);
      params = {
        com_id: employee[0].com_id,
        ...(this.branches ? { bch_id: this.branches } : {}),
        ...(this.s_ot_id ? { ot_id: this.s_ot_id } : {}),
      };
      this.tableData = await this.$store.dispatch(
        'uniqueapprover/listreportflatreat',
        params,
      );
      loading_close();
    },

    async list_data() {
      if (!this.uniqueapprover.number) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกลำดับการแสดง');
        return false;
      }
      if (!this.uniqueapprover.priority) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกลำดับการอนุมัติ');
        return false;
      }
      if (!this.uniqueapprover.emp_approver_id) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกพนักงาน');
        return false;
      }
      if (!this.uniqueapprover.description) {
        this.$store.dispatch('alerts/error', 'กรุณาระบุคำอธิบาย');
        return false;
      }
      let emp_chk = this.uniqueapprover.emp_approver_id;
      let chk = false;
      $.each(this.listData, function(i, v) {
        if (v.emp_approver_id == emp_chk) {
          chk = true;
        }
      });
      if (chk == true) {
        this.$store.dispatch(
          'alerts/error',
          'เลือกพนักงานซ้ำ กรุณเลือกใหม่อีกครั้ง',
        );
        return false;
      }
      this.change = true;
      this.listData.push({
        emp_approver_id: this.uniqueapprover.emp_approver_id,
        emp_approver_tname: this.uniqueapprover.emp_approver_tname,
        emp_approver_fname: this.uniqueapprover.emp_approver_fname,
        emp_approver_lname: this.uniqueapprover.emp_approver_lname,
        priority: this.uniqueapprover.priority,
        number: Number(this.uniqueapprover.number),
        status: this.uniqueapprover.status,
        description: this.uniqueapprover.description,
      });
      this.uniqueapprover = {
        type: 'uniqueapprover',
        id: null,
        emp_approver_id: null,
        priority: null,
        number: null,
        status: 'active',
        description: '',
      };
      this.filter = [];
    },

    async insert_data() {
      if (!this.ot_id) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกประเภท');
        return false;
      }
      if (!this.branchs) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
        return false;
      }
      if (!this.listData) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกผู้อนุมัติ');
        return false;
      }
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'md-button md-success',
          cancelButton: 'md-button md-danger',
        },
      });
      if (confirmation.value === true) {
        let params = {
          items: this.listData,
          ot_id: this.ot_id,
          branchs: this.branchs,
        };
        await this.$store
          .dispatch('uniqueapprover/addReportflatreat', params)
          .then((response) => {
            if (response.erorr == 304) {
              this.$store.dispatch(
                'alerts/error',
                'มีการตั้งค่าการอนุมัตินี้ไว้แล้ว',
              );
              return false;
            }
            this.$store.dispatch('alerts/success', 'Successfully.');
            this.uniqueapprover = {
              type: 'uniqueapprover',
              id: null,
              emp_approver_id: null,
              priority: null,
              number: null,
              status: 'active',
              description: '',
            };
            this.branchs = '';
            this.ot_id = '';
            this.listData = [];
            this.showEdit = false;
          });
      }
    },

    goto_delete(items) {
      const result = this.listData.filter((val, key) => {
        return val['emp_approver_id'] != items['emp_approver_id'];
      });
      this.listData = result;
    },

    goToAdd() {
      this.showEdit = true;
    },

    async switch_active(id, status) {
      var new_status = '';
      if (status == null) {
        new_status = 'inactive';
      } else {
        new_status = 'active';
      }
      let params = {
        type: 'uniqueapprover',
        id: id,
        edit_id: id,
        status: new_status,
      };
      await this.$store.dispatch('uniqueapprover/editreportflatrate', params);
      await this.$store.dispatch('alerts/success', 'Successfully.');
    },

    // set employee id value from child component
    async setStaffs(v) {
      this.staffs = v;
      if (v) {
        this.employee_id = v.id;
        await this.getList();
      } else {
        this.employee_id = null;
      }
    },

    async setStaffs_approver(v) {
      this.uniqueapprover.emp_approver_id = v.id;
      this.uniqueapprover.emp_approver_tname = v.title_nameen;
      this.uniqueapprover.emp_approver_fname = v.first_nameen;
      this.uniqueapprover.emp_approver_lname = v.last_nameen;
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
  },
};
</script>
<style scoped>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
</style>
