<template>
  <!-- http://livepocket.local:8081/admin/approve-setup -->
  <!-- pages\Dashboard\Pages\Admin\ListUniqueApprover.vue -->
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card-content>
          <div class="" style="text-align: right">
            <md-button @click="goBack()" class="md-primary md-dense">
              Back to appraisal
            </md-button>
          </div>
          <div class="md-layout">
            <div
              class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
            >
              <filter-employee
                @childBranches="setBranchs"
                @childStaffs="setStaffs"
              >
              </filter-employee>
            </div>
            <div
              class="md-layout-item md-size-100 mt-4 md-small-size-100 text-right"
            >
              <!-- <md-button @click="goToAdd" class="md-primary md-dense">
                เพิ่มการอนุมัติ
              </md-button> -->
            </div>
          </div>
          <md-table
            :value="tableData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Approver">
                <span class="upfont">
                  {{ item.emp_code }} :
                  {{ item.firstname_en }}
                  {{ item.lastname_en }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Staff Request">
                <span class="upfont" v-if="item.empreq_data">
                  {{ item.empreq_data.emp_code }} :
                  {{ item.empreq_data.firstname_en }}
                  {{ item.empreq_data.lastname_en }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Description">
                {{ item.description }}
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <!-- <md-button
                    class="md-icon-button md-raised md-round md-info"
                    @click="goToEdit(item.id)"
                    style="margin: 0.2rem"
                  >
                    <md-icon>edit</md-icon>
                  </md-button> -->
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
import employeeMixin from '@/mixins/employee-mixin';

export default {
  mixins: [employeeMixin],
  components: {
    'filter-employee': FilterEmployee,
  },
  data() {
    return {
      //   editData: {},
      tableData: [],
      staffs: null,
      employee_id: null,
      showEdit: false,
    };
  },
  async created() {
    // if (!this.me?.employee_id) {
    //   console.log('if');
    //   await this.getProfileData();
    // }
    // if (this.me?.employee_id) {
    //   this.profile = await this.getProfileData(this.me.employee_id);
    // } else {
    //   this.profile = await this.getProfileData();
    // }
  },
  watch: {},
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  methods: {
    async getList() {
      loading_start();
      let params = {};
      params = {
        ...(this.branchs ? { bch_id: this.branchs } : {}),
      };
      this.tableData = await this.$store.dispatch(
        'appraisal/get_list_report',
        params,
      );
      loading_close();
    },

    async goBack() {
      let params = {};

      this.$router.push({
        path: 'appraisal',
        name: 'การประเมิน',
        params: params,
      });
    },

    goToAdd() {
      this.showEdit = true;
    },

    // set branch value from child component
    async setBranchs(v) {
      if (v) {
        this.branchs = v;
        await this.getList();
      }
    },

    // set employee id value from child component
    async setStaffs(v) {
      this.staffs = v;
      if (v) {
        this.employee_id = v.id;
        await this.getList();
      } else {
        this.employee_id = null;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
  },
};
</script>
<style scoped>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
</style>
