<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>

          <md-card-content>
            <!-- Progress bar -->
            <vue-progress-bar></vue-progress-bar>
            <!-- Private code -->
            <div class="md-layout spacing">
              <!-- <div class="md-layout-item md-size-40 mt-2 md-small-size-40">
                <md-button
                  class="md-primary md-round md-theme-default"
                  @click="inputprivatecode"
                  :disabled="disabled_pvcode"
                >
                  <md-icon>lock</md-icon>
                  Private code
                </md-button>
              </div> -->
              <div>
                <md-dialog-prompt
                  :md-active.sync="active"
                  v-model="privatecodeIn"
                  md-title="Admin Private code"
                  md-input-maxlength="30"
                  md-input-placeholder="Type your private code..."
                  md-confirm-text="Send"
                  class="privatecode"
                />
              </div>
              <div class="md-layout-item md-size-10">
                <md-progress-spinner
                  :md-diameter="50"
                  :md-stroke="3"
                  md-mode="indeterminate"
                  :md-value="100"
                  v-show="showProgressBar"
                >
                </md-progress-spinner>
              </div>
            </div>

            <!-- รายวัน รายเดือน -->
            <div class="md-layout spacing md-alignment-center">
              <div
                class="md-layout-item mt-2 md-size-20 md-medium-size-25 md-small-size-30 md-xsmall-size-100"
              >
                เลือก รายเดือน - รายวัน
              </div>
              <div
                class="md-layout-item mt-2 md-size-25 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
              >
                <md-field>
                  <label>เลือก รายเดือน - รายวัน</label>
                  <md-select v-model="select_stafftype">
                    <md-option value="all">
                      ทั้งหมด
                    </md-option>
                    <md-option value="month">
                      รายเดือน
                    </md-option>
                    <md-option value="day">
                      รายวัน
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
              >
                <md-field class="searchbranchs">
                  <label>เลือก สำนักงาน</label>
                  <md-select v-model="branches" name="branch_id">
                    <md-option
                      v-for="item in available_branches"
                      :key="item.bch_id"
                      :value="item.bch_id"
                    >
                      {{ item.brname_th }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <!-- Date -->
            <div class="md-layout md-alignment-center">
              <div
                class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
              >
                <md-datepicker v-model="fromdate" md-immediately>
                  <label>วันที่เริ่ม</label>
                </md-datepicker>
              </div>
              <div
                class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
              >
                <md-datepicker
                  v-model="todate"
                  md-immediately
                  :md-disabled-dates="disabledToDates"
                >
                  <label>วันที่สิ้นสุด</label>
                </md-datepicker>
              </div>
            </div>
            <!-- Button -->
            <div class="md-layout spacing md-alignment-center">
              <div
                class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
              >
                <b-button
                  lg="4"
                  size="lg"
                  variant="info"
                  @click="searchFlatreat()"
                >
                  Search
                </b-button>
              </div>
              <div
                class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
              >
                <b-button
                  lg="4"
                  size="lg"
                  variant="success"
                  :disabled="disabled_export"
                  @click="exportPDF()"
                >
                  Export
                </b-button>
              </div>
              <div
                class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
              >
                <b-button
                  lg="4"
                  size="lg"
                  style="color:white"
                  variant="warning"
                  :disabled="disabled_process"
                  @click="send_report()"
                >
                  ส่งรีพอต
                </b-button>
              </div>
            </div>
            <!-- table -->
            <div id="tbExport" class="tbExport">
              <b-table-simple
                small
                caption-top
                responsive
                v-for="(d, index) in dataTable"
                :key="index"
              >
                <b-thead>
                  <b-tr variant="success">
                    <b-th colspan="10" class="text-center"
                      >{{ d.title_nameen }} {{ d.first_nameen }}
                      {{ d.last_nameen }}</b-th
                    >
                  </b-tr>
                  <b-tr variant="success">
                    <b-th colspan="10" class="text-center"
                      >Employee No. {{ d.emp_id }}</b-th
                    >
                  </b-tr>
                  <b-tr variant="success">
                    <b-th>DATE</b-th>
                    <b-th>TYPE</b-th>
                    <b-th>BILL NO.</b-th>
                    <b-th>ROOM NO.</b-th>
                    <b-th>TREATMENT BOOKED</b-th>
                    <b-th>TIME</b-th>
                    <b-th>DURATION</b-th>
                    <b-th>INCENTIVE</b-th>
                    <b-th>TOTAL INCENTIVE</b-th>
                    <b-th>REMARK</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-for="(data, e) in d.data" :key="'data-' + e">
                  <b-tr v-for="(items, i) in data" :key="'data-' + i">
                    <b-td :rowspan="data.length" v-if="items.checkrow == 0">
                      {{ items.formdate }}
                    </b-td>
                    <b-td>{{ items.otType_name }}</b-td>
                    <b-td>{{ items.bill_no }}</b-td>
                    <b-td>{{ items.room_no }}</b-td>
                    <b-td>{{ items.treatment_name }}</b-td>
                    <b-td>
                      {{ items.time_from_date }} -
                      {{ items.time_to_date }}
                    </b-td>
                    <b-td>{{ items.duration }}</b-td>
                    <b-td>{{ items.price }}</b-td>
                    <b-td>{{ items.total_price }}</b-td>
                    <b-td>{{ items.notes }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot foot-variant="light">
                  <b-tr>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th sticky-column></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th>รวม</b-th>
                    <b-th>{{ d.sum.duration }}</b-th>
                    <b-th></b-th>
                    <b-th>{{ d.sum.total_price }}</b-th>
                    <b-th></b-th>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import $ from 'jquery';
import Vuex from 'vuex';
import md5 from 'js-md5';
import moment from 'moment';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import jsPDF from 'jspdf';
import { NavTabsCard } from '@/components';
import Swal from 'sweetalert2';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {},
  mixins: [employeeMixin],
  data() {
    return {
      available_branches: null,
      branches: '',
      moment: moment,
      headLabel: '',
      employee_id: null,
      departments_id: null,
      permissions: [],
      // disabled_pvcode: false,
      active: false,
      privatecodeIn: null,
      showProgressBar: false,
      // invalid_privatecode: true,
      radio_select: 'bydepart',
      tableData: [],
      departments: null,
      months: null,
      branchs: null,
      company_id: null,
      fromdate: null,
      todate: null,
      private_code: null,
      addService: false,
      service: null,
      income: [],
      cost: [],
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      select_stafftype: 'all',
      disabled_export: true,
      disabled_process: true,
      datalistprocess: [],
      dataTable: [],
      available_ots: [],
      ot_id: null,
      permissionsList: {},
      sum_all: null,
      data_report: [],
      report: [],
    };
  },
  props: {
    employeeID: null,
    branchID: Object,
  },

  async created() {
    await this.setPermission(this.me);
    let params = { profile: true };
    this.profile = await this.$store.dispatch('staffs/searchStaff', params);
    // if (localStorage.getItem('privatecode')) {
    //   this.disabled_pvcode = true;
    //   this.invalid_privatecode = false;
    // }
    this.getBranches();
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),

    income_net() {
      return this.tableData[0]['incomenet'];
    },
    summary_income() {
      return this.tableData[0]['summaryincome'];
    },
    summary_cost() {
      return this.tableData[0]['summarycost'];
    },
    summary_incomenet() {
      return this.tableData[0]['summaryincomenet'];
    },
    disabled_submit() {
      if (
        this.radio_select === 'bydepart' &&
        this.departments &&
        this.fromdate &&
        this.todate
      ) {
        return false;
      } else if (
        this.radio_select === 'bystaff' &&
        this.employee_id &&
        this.fromdate &&
        this.todate
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  watch: {
    // privatecodeIn: function() {
    //   this.securePrivatecode(this.privatecodeIn);
    // },
    tableData: {
      handler: function(val) {
        if (val && val.length > 0) {
          this.income = val[0]['income'];
          this.cost = val[0]['cost'];
        }
      },
      immediate: true,
      deep: true,
    },
    fromdate: function() {
      if (this.todate) {
        var todate = moment(this.todate, 'DD/MM/YYYY');
        if (moment(todate).diff(moment(this.fromdate), 'days') < 0) {
          this.todate = this.fromdate;
        }
      }
    },
    async me(val) {
      await this.setPermission(val);
    },
  },

  methods: {
    headdropdown(event) {
      var con = $(event.target).closest('.md-layout-item');
      var content = $('.contentleave', con);
      content.slideToggle();
      content.css('display', 'grid');
    },

    async getBranches() {
      let params = {
        com_id: this.profile[0].com_id,
        bch_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_branches = data;
      });
      if (!this.permissions.includes('process salary')) {
        var arr = [];
        this.available_branches.map((bch) => {
          if (this.permissions.includes(bch.brname_en)) {
            arr.push(bch);
          }
        });
        this.available_branches = arr;
      }
    },

    async searchFlatreat() {
      if (!this.branches) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
        return false;
      } else if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      }
      let params = {
        ...(this.branches ? { branches: this.branches } : {}),
        ...(this.fromdate
          ? { fromdate: moment(this.fromdate).format('YYYY-MM-DD') }
          : {}),
        ...(this.todate
          ? { todate: moment(this.todate).format('YYYY-MM-DD') }
          : {}),
        ...{ ot_id: 5 },
        ...(this.select_stafftype
          ? { stafftype: this.select_stafftype }
          : { stafftype: 'all' }),
      };
      const resp = await this.$store.dispatch(
        'otsflatrate/listProcess',
        params,
      );
      if (resp.length == 0) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'ไม่มีข้อมูลในระบบ!',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.disabled_export = false;
        this.disabled_process = false;
        var data = [];
        let no = 0;
        for (const [key, value] of Object.entries(resp)) {
          data[no] = value;
          no++;
        }
        this.exportTable(resp);
        this.data_report = data;
      }
    },

    async exportTable(resp) {
      const nf = new Intl.NumberFormat('en-US');
      const m = Math;
      let data = Object.keys(resp).map((arr) => {
        return resp[arr];
      });
      this.dataTable = data;
      var notformat = [
        'approve_status',
        'approver_require',
        'attach_filepath',
        'bill_no',
        'checkrow',
        'created_at',
        'emp_id',
        'employee_id',
        'first_nameen',
        'first_nameth',
        'formdate',
        'from_date',
        'id',
        'last_nameen',
        'last_nameth',
        'modification_id',
        'name_th',
        'nick_name',
        'notes',
        'otType_name',
        'ot_active',
        'ot_id',
        'ot_name',
        'ot_treatment_id',
        'price',
        'requestdate',
        'room_no',
        'time_flatreat',
        'time_from_date',
        'time_to_date',
        'title_nameen',
        'title_nameth',
        'to_date',
        'treatment_name',
        'type_ot',
        'unit_type_ot',
        'updated_at',
      ];
      data.forEach(async (rp, index) => {
        var temp = {
          duration: 0,
          total_price: 0,
        };
        let listOfObjects = Object.keys(rp.data).map((key) => {
          return rp.data[key];
        });
        listOfObjects.forEach((rp_data) => {
          for (const [key, value] of Object.entries(rp_data)) {
            for (const [k, v] of Object.entries(value)) {
              if (!notformat.includes(k)) {
                temp[k] += m.round(v);
                rp_data[k] = nf.format(m.round(v));
              }
            }
          }
        });
        this.dataTable[index]['sum'] = temp;
      });
    },

    async exportPDF() {
      if (!this.branches) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return false;
      } else if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      }
      var notformat = ['duration', 'total_price'];
      var headername = {};
      headername.name = 'ประจำงวดจ่าย';
      var pdfname = 'Spa Therapist Insentive Report';
      var font = await this.$store.dispatch('exports/fontthsarabunnew');
      headername.head = 'SPA THERAPIST INCENTIVE';
      headername.name_fromdate_th = moment(this.fromdate).format('LL');
      headername.name_todate_th = moment(this.todate).format('LL');
      var doc = new jsPDF('landscape');
      doc.addFileToVFS('THSarabunNew.ttf', font);
      doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
      doc.setFont('THSarabunNew');
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text(
        headername.head +
          '\n' +
          headername.name_fromdate_th +
          ' - ' +
          headername.name_todate_th +
          ' ',
        150,
        10,
        'center',
      );

      var arr = [];
      $.each(this.data_report, function(i, v) {
        var rows = [];
        $.each(v.data, function(key, val) {
          delete val.duration;
          delete val.total_price;
          $.each(val, function(k, v) {
            rows.push(v);
          });
        });
        var header_emp = {};
        header_emp.title = v.title_nameen;
        header_emp.first_nameen = v.first_nameen;
        header_emp.last_nameen = v.last_nameen;
        header_emp.emp = 'Employee No.';
        header_emp.emp_id = v.emp_id;

        var hname =
          header_emp.title +
          ' ' +
          header_emp.first_nameen +
          ' ' +
          header_emp.last_nameen;
        var emp = header_emp.emp + ' ' + header_emp.emp_id;
        let total = {
          time_to_date: 'รวม',
          duration: v.sum.duration,
          total_price: v.sum.total_price,
        };
        rows.push(total);
        var d = {
          hname: hname,
          emp: emp,
          rows: rows,
        };
        arr.push(d);
      });
      var first = true;
      arr.forEach((val, key) => {
        var head = [
          [
            {
              content: val.hname,
              colSpan: 11,
              styles: {
                halign: 'center',
                fillColor: [19, 120, 100],
                fontStyle: 'bold',
              },
            },
          ],
          [
            {
              content: val.emp,
              colSpan: 11,
              styles: { halign: 'center', fillColor: [22, 160, 133] },
              fontStyle: 'bold',
            },
          ],
          [
            'DATE',
            'TYPE',
            'BILL NO.',
            'ROOM NO.',
            'TREATMENT BOOKED',
            'FROM TIME',
            'TO TIME',
            'DURATION',
            'INCENTIVE',
            'TOTAL INCENTIVE',
            'REMARK',
          ],
        ];
        var column = [
          { header: 'DATE', dataKey: 'formdate' },
          { header: 'TYPE', dataKey: 'otType_name' },
          { header: 'BILL NO.', dataKey: 'bill_no' },
          { header: 'ROOM NO.', dataKey: 'room_no' },
          { header: 'TREATMENT BOOKED', dataKey: 'treatment_name' },
          { header: 'FROM TIME', dataKey: 'time_from_date' },
          { header: 'TO TIME', dataKey: 'time_to_date' },
          { header: 'DURATION', dataKey: 'duration' },
          { header: 'INCENTIVE', dataKey: 'price' },
          { header: 'TOTAL INCENTIVE', dataKey: 'total_price' },
          { header: 'REMARK', dataKey: 'notes' },
        ];
        var config = {
          head: head,
          columns: column,
          theme: 'grid',
          body: val.rows,
          styles: {
            fontSize: 12,
            font: 'THSarabunNew',
          },
        };
        if (first == true) {
          config.startY = 22;
          config.startX = 2;
        }
        doc.autoTable(config);
        first = false;
      });
      doc.setProperties({
        title: pdfname,
      });
      window.open(doc.output('bloburl'), '_blank');
      // doc.save(pdfname + '.pdf'); // download
    },

    async send_report() {
      if (this.select_stafftype == null) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือก รายเดือน - รายวัน');
        return;
      }
      if (this.branches == null) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return;
      }

      if (this.fromdate == null) {
        this.$store.dispatch('alerts/error', 'วันที่เริ่ม');
        return;
      }

      if (this.todate == null) {
        this.$store.dispatch('alerts/error', 'วันที่สิ้นสุด');
        return;
      }

      var params = {
        id: null,
        type: 'sendtospa',
        filter: {
          ...(this.branches ? { branches: this.branches } : {}),
          ...(this.fromdate
            ? { fromdate: moment(this.fromdate).format('YYYY-MM-DD') }
            : {}),
          ...(this.todate
            ? { todate: moment(this.todate).format('YYYY-MM-DD') }
            : {}),
          ...{ ot_id: 5 },
          ...(this.select_stafftype
            ? { stafftype: this.select_stafftype }
            : { stafftype: 'all' }),
          ...(this.branches ? { branches: this.branches } : {}),
          ...(this.private_code ? { private_code: this.private_code } : {}),
          sendto: 1,
        },
      };

      let resp = await this.$store.dispatch(
        'otsflatrate/createReportSpa',
        params,
      );

      if (resp == 1) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'ส่งรีพอตสำเร็จ',
          showConfirmButton: false,
          timer: 1500,
        });
        this.dataTable = null;
        this.branches = null;
        this.todate = null;
        this.fromdate = null;
      } else if (resp == 2) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'มีรายงานนี้ในระบบแล้ว!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.dataTable = null;
        this.branches = null;
        this.todate = null;
        this.fromdate = null;
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'มีบางอย่างผิดพลาด!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    setMonths(v) {
      this.months = v;
    },

    decodeBase64(data) {
      if (data.length <= 0) {
        return false;
      }
      let decode = this.Base64_decode(data);
      return decode;
    },

    Base64_encode(input) {
      // private property
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

      // public method for encoding
      var output = '';
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      input = this.Base64_utf8_encode(input);

      while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output =
          output +
          _keyStr.charAt(enc1) +
          _keyStr.charAt(enc2) +
          _keyStr.charAt(enc3) +
          _keyStr.charAt(enc4);
      }

      return output;
    },

    Base64_decode(input) {
      var _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
      var output = '';
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;

      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

      while (i < input.length) {
        enc1 = _keyStr.indexOf(input.charAt(i++));
        enc2 = _keyStr.indexOf(input.charAt(i++));
        enc3 = _keyStr.indexOf(input.charAt(i++));
        enc4 = _keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
        }
      }

      output = this.Base64_utf8_decode(output);

      return output;
    },

    Base64_utf8_encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    },

    Base64_utf8_decode(utftext) {
      var string = '';
      var i = 0;
      var c2 = 0;
      var c1 = c2;
      var c = c1;

      while (i < utftext.length) {
        c = utftext.charCodeAt(i);

        if (c < 128) {
          string += String.fromCharCode(c);
          i++;
        } else if (c > 191 && c < 224) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(
            ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63),
          );
          i += 3;
        }
      }

      return string;
    },

    // set permission
    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },

    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-tabs-content table thead {
  display: contents;
}
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.total-price {
  font-weight: 500;
}
.group-total-price {
  font-family: math;
  font-style: italic;
  font-weight: 500;
}
// .table-shopping .md-table-head:nth-child(5),
// .table-shopping .md-table-head:nth-child(7),
// .table-shopping .md-table-head:nth-child(6) {
//   text-align: right;
// }

@media print {
  .tbExport {
    font-family: TH Sarabun;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
  }
  .text-center {
    text-align: center;
  }
}
</style>
