<template>
  <div class="pdfme-viewer">
    <slot name="viewer-header"></slot>

    <div ref="editor" class="editor"></div>

    <slot name="viewer-body"></slot>
  </div>
</template>

<script>
import { Viewer } from '@pdfme/ui';
import PdfmeHelperMixin from '@/components/Pdfme/plugins/PdfmeHelperMixin';
import { getInputFromTemplate } from '@pdfme/common';

export default {
  mixins: [PdfmeHelperMixin],
  props: {
    template: {
      type: Object,
      default: () => ({
        basePdf: {
          width: 210,
          height: 297,
          padding: [10, 10, 10, 10],
        },
        schemas: [[]],
      }),
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewerInstance: null,
    };
  },
  async mounted() {
    await this.init();
  },
  watch: {
    async template(val) {
      await this.init(val);
    },
  },
  methods: {
    async init(template = this.template) {
      const container = this.$refs.editor;
      if (container) {
        const font = await this.getFontsData();

        this.viewerInstance = new Viewer({
          domContainer: container,
          template,
          inputs: getInputFromTemplate(this.template),
          options: {
            font,
            theme: {
              token: { colorPrimary: '#25c2a0' },
            },
            maxZoom: 250,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.editor {
  width: 100%;
  height: fit-content;

  background-color: #ffffff;
  border-radius: 8px;
}
</style>
