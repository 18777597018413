import service from '@/store/services/warning-service';

const state = {};

const mutations = {};

const actions = {
  async getListWarning({ commit, dispatch }, params) {
    return await service.getListWarning(params);
  },
  async getListWarningType({ commit, dispatch }, params) {
    return await service.getListWarningType(params);
  },
  async createWarning({ commit, dispatch }, params) {
    return await service.createWarning(params);
  },
};

const getters = {};

const warning = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default warning;
