<template>
  <div class="modern-chat-container">
    <div class="text-right" v-if="$route.params?.route">
      <md-button @click="goBack" class="md-primary md-raised">
        Back to Previous Page
      </md-button>
    </div>
    <md-card class="modern-card">
      <md-app md-waterfall md-mode="fixed" class="modern-app">
        <md-app-toolbar class="md-primary toolbar">
          <span class="md-title">{{ messageDetail?.name ?? '' }}</span>
        </md-app-toolbar>

        <md-app-drawer md-permanent="full" class="modern-drawer">
          <md-toolbar class="md-transparent modern-drawer-header">
            <h2>Chats</h2>
            <md-chip class="md-accent">Unread Messages 5</md-chip>
          </md-toolbar>

          <div class="modern-search-box">
            <md-field>
              <md-icon>search</md-icon>
              <md-input
                v-model="search"
                placeholder="Search..."
                @input="handleSearch"
              ></md-input>
            </md-field>
          </div>

          <messages-lists
            :lists="messenger.lists"
            @list-selected="handleListSelected"
          ></messages-lists>
        </md-app-drawer>

        <md-app-content class="modern-content">
          <messages-details
            :messages="messenger.messages"
            :detail="messageDetail"
            :firstMessage="true"
          ></messages-details>
        </md-app-content>
      </md-app>
    </md-card>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MessagesLists from '@/components/Messages/MessagesLists.vue';
import MessagesDetails from '@/components/Messages/MessagesDetails.vue';
import { loading_close, loading_start } from '@/utils/loading';

export default defineComponent({
  components: { MessagesLists, MessagesDetails },
  data() {
    return {
      messenger: { messages: [], lists: [] },
      messageDetail: {},
      search: '',
      searchTimeout: null,
    };
  },
  async mounted() {
    this.messageDetail = this.$route.params.m_detail;
    await this.fetchMessages(this.messageDetail);
  },
  methods: {
    async fetchMessages(detail) {
      try {
        loading_start();
        const resp = await this.$store.dispatch('messenger/messengers', {
          ...detail,
          search: this.search ?? undefined,
        });
        this.messenger.messages = resp.data.messages;
        this.messenger.lists = !Array.isArray(resp.data.lists)
          ? [{ ...resp.data.lists }]
          : resp.data.lists;
      } catch (error) {
      } finally {
        loading_close();
      }
    },
    async fetchMessagesDetails(detail) {
      try {
        loading_start();
        const resp = await this.$store.dispatch('messenger/read', detail);
        this.messenger.messages = resp.data;
      } catch (error) {
      } finally {
        loading_close();
      }
    },
    async handleListSelected(list) {
      this.messageDetail = list;
      await this.fetchMessagesDetails(list);
    },
    handleSearch() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.fetchMessages(this.messageDetail);
      }, 300);
    },
    goBack() {
      const routeParams = this.$route.params;
      this.$router.push({
        path: routeParams.route.path,
        name: routeParams.route.name,
        params: routeParams.params,
      });
    },
  },
});
</script>

<style scoped>
.modern-chat-container {
  height: 85vh;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.modern-card {
  flex: 1;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modern-app {
  height: 100%;
  background: white;
}

.toolbar {
  background: linear-gradient(135deg, #2196f3, #21cbf3);
  color: white;
  border-radius: 0 16px 0 0;
}

.modern-drawer {
  background: white;
  border-right: 1px solid #e0e0e0;
}

.modern-drawer-header {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modern-drawer-header h2 {
  margin: 0;
}

.modern-search-box {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.modern-content {
  background: #f5f7fa;
  padding: 16px;
}
</style>
