<template>
  <div class="memoList">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>list</md-icon>
        </div>
        <h4 class="title">Memo List</h4>
        <div class="text-right">
          <md-button @click="gotoCreate()" class="md-success md-dense">
            <md-icon>add</md-icon>
            Create Memo
          </md-button>
          <md-button @click="goBack()" class="md-primary md-dense">
            <md-icon>arrow_back</md-icon>
            Back to SanthiyaSOS
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="search-container">
          <md-field>
            <label>Search</label>
            <md-input
              v-model="keySearchMemo"
              @keyup.enter="setMemoList()"
            ></md-input>
            <md-icon>search</md-icon>
          </md-field>
        </div>
        <tabs
          :tab-name="['Pending Approval', 'Approved', 'Rejected']"
          :tab-icon="['pending', 'check_circle', 'cancel']"
          :tab-active="0"
          nav-pills-icons
          plain
          color-button="warning"
        >
          <template slot="tab-pane-1">
            <md-card>
              <b-table-simple responsive>
                <b-thead>
                  <b-tr>
                    <b-th>No.</b-th>
                    <b-th sticky-column>Memo Name</b-th>
                    <b-th>Memo Date</b-th>
                    <b-th>Reason</b-th>
                    <b-th>Status</b-th>
                    <b-th>Edited Date</b-th>
                    <b-th>Edited By</b-th>
                    <b-th>Action</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(memo, index) in memoList.filter(
                      (ml) => ml.status_approve != 1,
                    )"
                    :key="index + 'memoList'"
                  >
                    <b-td>{{ index + 1 + (currentPageMemo - 1) * limit }}</b-td>
                    <b-th sticky-column>{{ memo.memo_name }}</b-th>
                    <b-td>{{ memo.date }}</b-td>
                    <b-td>{{ memo.reason }}</b-td>
                    <b-td class="nowarp">
                      <span v-if="memo.status_approve == 0" class="waitapprove">
                        <md-icon class="mr-1">pending</md-icon>
                        <span>WAIT APPROVE</span>
                      </span>
                      <span v-if="memo.status_approve == -1" class="draft">
                        <md-icon class="mr-1">drafts</md-icon>
                        <span>DRAFT</span>
                      </span>
                      <span v-if="memo.status_approve == 2" class="recheck">
                        <md-icon class="mr-1">autorenew</md-icon>
                        <span>RECHECK</span>
                      </span>
                      <span v-if="memo.status_approve == 3" class="approved">
                        <md-icon class="mr-1">check_circle</md-icon>
                        <span>APPROVED</span>
                      </span>
                      <span
                        v-if="memo.status_approve == 4"
                        class="request-recheck"
                      >
                        <md-icon class="mr-1">history</md-icon>
                        <span>REQUEST RECHECK</span>
                      </span>
                      <span
                        v-if="memo.status_approve == 1"
                        class="request-reject"
                      >
                        <md-icon class="mr-1">cancel</md-icon>
                        <span>REQUEST REJECT</span>
                      </span>
                    </b-td>

                    <b-td>{{ memo.updated_at }}</b-td>
                    <b-td class="nowarp">
                      <span>
                        {{ memo.empupdate_emp_id }}
                        {{
                          `${memo.empupdate_titlename_en} ${memo.empupdate_firstname_en} ${memo.empupdate_lastname_en}`
                        }}
                      </span>
                    </b-td>
                    <b-td class="nowarp">
                      <b-button
                        variant="primary"
                        class="mr-2"
                        @click="preview(memo?.id)"
                      >
                        <md-icon class="mr-1 text-white">visibility</md-icon>
                        <span>Preview</span>
                      </b-button>
                      <b-button
                        variant="info"
                        class="mr-2"
                        @click="viewApprover(memo.id)"
                        v-if="
                          memo.status_approve !== -1 &&
                            memo.status_approve !== 2
                        "
                      >
                        <md-icon class="mr-1 text-white">people</md-icon>
                        <span>Approvers</span>
                      </b-button>
                      <b-button
                        variant="warning"
                        class="mr-2 text-white"
                        @click="gotoEdit(memo.id)"
                        v-if="memo.status_approve === -1"
                      >
                        <md-icon class="mr-1 text-white">edit</md-icon>
                        <span>Edit</span>
                      </b-button>
                      <b-button
                        variant="warning"
                        class="mr-2"
                        @click="gotoEdit(memo.id)"
                        v-if="memo.status_approve === 2"
                      >
                        <md-icon class="mr-1 text-white">autorenew</md-icon>
                        <span>Recheck</span>
                      </b-button>
                      <b-button
                        variant="danger"
                        class="mr-2"
                        @click="gotoDelete(memo.id)"
                        v-if="memo.status_approve == -1"
                      >
                        <md-icon class="mr-1 text-white">delete</md-icon>
                        <span>Delete</span>
                      </b-button>
                      <!--<b-dropdown
                        variant="warning"
                        class="mr-2"
                        v-if="memo.status_approve == 0"
                        text="Request"
                        right
                      >
                        <b-dropdown-item @click="requestRecheck(memo.id)">
                          <div>
                            <md-icon class="mr-2">edit</md-icon>
                            <span>Request Edit</span>
                          </div>
                        </b-dropdown-item>
                        <b-dropdown-item @click="requestDelete(memo.id)">
                          <div>
                            <md-icon class="mr-2">delete</md-icon>
                            <span>Request Delete</span>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>-->
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </md-card>
          </template>
          <template slot="tab-pane-2">
            <md-card>
              <b-table-simple responsive>
                <b-thead>
                  <b-tr>
                    <b-th>No.</b-th>
                    <b-th sticky-column>Memo Name</b-th>
                    <b-th>Memo Date</b-th>
                    <b-th>Status</b-th>
                    <b-th>Edited Date</b-th>
                    <b-th>Edited By</b-th>
                    <b-th>Action</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(memo, index) in approvedMemos"
                    :key="index + 'memoList'"
                  >
                    <b-td>{{ index + 1 + (currentPageMemo - 1) * limit }}</b-td>
                    <b-th sticky-column>{{ memo.memo_name }}</b-th>
                    <b-td>{{ memo.date }}</b-td>
                    <b-td class="nowarp">
                      <span v-if="memo.status_approve == 3" class="approved">
                        <md-icon class="mr-1">check_circle</md-icon>
                        <span>APPROVED</span>
                      </span>
                    </b-td>
                    <b-td>{{ memo.updated_at }}</b-td>
                    <b-td class="nowarp">
                      <span>
                        {{ memo.empupdate_emp_id }}
                        {{
                          `${memo.empupdate_titlename_en} ${memo.empupdate_firstname_en} ${memo.empupdate_lastname_en}`
                        }}
                      </span>
                    </b-td>
                    <b-td>
                      <b-button
                        variant="primary"
                        class="mr-2"
                        @click="preview(memo?.id)"
                      >
                        <md-icon class="text-white">visibility</md-icon>
                        Preview
                      </b-button>
                      <b-button
                        variant="success"
                        class="ml-2"
                        @click="gotoPublish(memo.id)"
                      >
                        <md-icon class="text-white">publish</md-icon>
                        Set Publish
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </md-card>
          </template>
          <template slot="tab-pane-3">
            <md-card>
              <b-table-simple responsive>
                <b-thead>
                  <b-tr>
                    <b-th>No.</b-th>
                    <b-th sticky-column>Memo Name</b-th>
                    <b-th>Memo Date</b-th>
                    <b-th>Reason</b-th>
                    <b-th>Status</b-th>
                    <b-th>Edited Date</b-th>
                    <b-th>Edited By</b-th>
                    <b-th>Action</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(memo, index) in memoList.filter(
                      (ml) => ml.status_approve == 1,
                    )"
                    :key="index + 'rejectedMemos'"
                  >
                    <b-td>{{ index + 1 + (currentPageMemo - 1) * limit }}</b-td>
                    <b-th sticky-column>{{ memo.memo_name }}</b-th>
                    <b-td>{{ memo.date }}</b-td>
                    <b-td>{{ memo.reason }}</b-td>
                    <b-td class="nowarp">
                      <span v-if="memo.status_approve == 1" class="rejected">
                        <md-icon class="mr-1">cancel</md-icon>
                        <span>REJECTED</span>
                      </span>
                    </b-td>
                    <b-td>{{ memo.updated_at }}</b-td>
                    <b-td class="nowarp">
                      <span>
                        {{ memo.empupdate_emp_id }}
                        {{
                          `${memo.empupdate_titlename_en} ${memo.empupdate_firstname_en} ${memo.empupdate_lastname_en}`
                        }}
                      </span>
                    </b-td>
                    <b-td class="nowarp">
                      <b-button
                        variant="primary"
                        class="mr-2"
                        @click="preview(memo?.id)"
                      >
                        <md-icon class="mr-1 text-white">visibility</md-icon>
                        <span>Preview</span>
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </md-card>
          </template>
        </tabs>
      </md-card-content>
    </md-card>
    <md-dialog class="memoList" :md-active.sync="showApprover">
      <!-- <md-dialog-title>Approver</md-dialog-title> -->
      <md-dialog-content>
        <p style="font-size:20px" v-if="approverList?.approver?.length > 0">
          ผู้อนุมัติ
        </p>
        <md-list v-if="approverList?.approver?.length > 0">
          <md-list-item
            v-for="(app, index) in approverList['approver']"
            :key="index + 'approverList'"
          >
            <p>
              {{ app.emp_first_name + ' ' + app.emp_last_name }}
              <span>
                <span v-if="app.status_approve == 3">อนุมัติ</span>
                <span v-else-if="app.status_approve == 0">รออนุมัติ</span>
                <md-icon class="app approve" v-if="app.status_approve == 3">
                  check
                </md-icon>
                <md-icon class="app wait" v-else-if="app.status_approve == 0">
                  pending
                </md-icon>
              </span>
            </p>
          </md-list-item>
        </md-list>
        <p style="font-size:20px" v-if="approverList?.acknowledge?.length > 0">
          ผู้รับทราบ
        </p>
        <md-list v-if="approverList?.acknowledge?.length > 0">
          <md-list-item
            v-for="(app, index) in approverList['acknowledge']"
            :key="index + 'approverList'"
          >
            <p>
              {{ app.emp_first_name + ' ' + app.emp_last_name }}
              <span>
                <span v-if="app.status_approve == 3">อนุมัติ</span>
                <span v-else-if="app.status_approve == 0">รออนุมัติ</span>
                <md-icon class="app approve" v-if="app.status_approve == 3">
                  check
                </md-icon>
                <md-icon class="app wait" v-else-if="app.status_approve == 0">
                  pending
                </md-icon></span
              >
            </p>
          </md-list-item>
        </md-list>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showApprover = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import { loading_close, loading_start } from '@/utils/loading.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import Vuex from 'vuex';
import MemoMixin from '@/components/Pdfme/BaseTemplate/MemoMixin';
import PdfmeHelperMixin from '@/components/Pdfme/plugins/PdfmeHelperMixin';

export default {
  mixins: [MemoMixin, PdfmeHelperMixin],
  components: {
    Tabs,
  },
  async created() {
    this.keySearchMemo = this.$route.params.keySearchMemo || '';
    this.currentPageMemo = this.$route.params.currentPageMemo || 1;
    await this.setMemoList();
  },

  data() {
    return {
      memorandam: {},
      pdfme_template: undefined,
      currentPageMemo: 1,
      limitList: [15, 30, 50, 100],
      limit: 9999,
      totalrows: 0,
      keySearchMemo: '',
      memoList: [],
      mamoData: [],
      approvedMemos: [],
      approverList: [],
      showApprover: false,
    };
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
      lang: (state) => state.profile.lang,
    }),
  },
  watch: {
    async currentPageMemo() {
      await this.setMemoList();
    },
    async limit() {
      await this.setMemoList();
    },
  },
  methods: {
    goBack() {
      let keySearch = this.$route.params.keySearch;
      let currentPage = this.$route.params.currentPage;

      this.$router.push({
        path: 'santhiayaSOS-management',
        name: 'จัดการสันธิญา SOS',
        params: {
          keySearch,
          currentPage,
        },
      });
    },
    gotoCreate() {
      this.$router.push({
        path: 'createMemo',
        name: 'สร้าง Memo',
        params: {
          keySearchMemo: this.keySearchMemo,
          currentPageMemo: this.currentPageMemo,
        },
      });
    },
    gotoEdit(id) {
      let params = {
        id: id,
        keySearchMemo: this.keySearchMemo,
        currentPageMemo: this.currentPageMemo,
      };

      this.$router.push({
        path: 'createMemo',
        name: 'สร้าง Memo',
        params: params,
      });
    },
    async viewApprover(id) {
      this.approverList = await this.getApproverList(id);

      if (!Object.values(this.approverList)?.length) {
        await this.$store.dispatch('alerts/error', 'ไม่พบรายการผู้อนุมัติ');
        return false;
      }

      this.showApprover = true;
    },
    async getApproverList(id) {
      let params = {
        memo_id: id,
      };

      return await this.$store.dispatch('memo/getApproverList', params);
    },
    async getMemoList() {
      let params = {
        page: this.currentPageMemo,
        limit: this.limit,
        memo_search: this.keySearchMemo,
      };

      loading_start();
      if (this.mamoData?.length > 0) {
        return this.mamoData;
      } else {
        this.mamoData = await this.$store.dispatch('memo/getMemoList', params);
      }
      loading_close();

      return this.mamoData;
    },
    getMemoDetail(memoId) {
      return new Promise(async (resolve, reject) => {
        try {
          loading_start();

          const response = await this.$store.dispatch('memo/getMemoDetail', {
            id: memoId,
          });

          this.memorandam = {
            toText: response.data.memoDetail.to_text,
            fromText: response.data.memoDetail.from_text,
            dateMemo: response.data.memoDetail.date,
            pageText: response.data.memoDetail.page,
            subjectText: response.data.memoDetail.subject,
            cc: response.data.memoDetail.cc,
          };

          const response_logo = await this.$store.dispatch(
            'pdfs/getBranchLogo',
            {
              bch_id: response.data.memoDetail.bch_id,
            },
          );
          this.branchLogo = response_logo[0]?.image;
          const title = `MEMORANDAM - ${response.data.memoDetail.subject}`;
          const settings = JSON.parse(response.data.memoDetail.settings);

          resolve({
            memorandam: this.memorandam,
            branchLogo: this.branchLogo,
            template: response.data.memoDetail.memo_data,
            approvers: response.data.approvers ?? [],
            settings,
            title,
          });
        } catch (error) {
          reject(`Error generating preview: ${error}`);
        } finally {
          loading_close();
        }
      });
    },
    async handlePageChange(newPage) {
      await this.setMemoList();
      this.currentPageMemo = newPage;
    },
    async setMemoList() {
      let { pendingMemos, approvedMemos } = await this.getMemoList();

      this.memoList = pendingMemos?.['data']?.map((memo) => {
        memo.date = moment(memo.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        memo.created_at = moment(memo.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        memo.updated_at = moment(memo.updated_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        return memo;
      });

      this.totalrows = pendingMemos?.['rows'];

      this.approvedMemos = approvedMemos?.['data']?.map((memo) => {
        memo.date = moment(memo.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        memo.created_at = moment(memo.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        memo.updated_at = moment(memo.updated_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        return memo;
      });

      this.totalrows = approvedMemos?.['rows'];
    },
    async gotoDelete(memoId) {
      try {
        const confirmResult = await Swal.fire({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this memo!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel',
          reverseButtons: true,
        });

        if (confirmResult.isConfirmed) {
          const response = await this.$store.dispatch('memo/delete', {
            id: memoId,
          });

          if (response.success) {
            await Swal.fire(
              'Deleted!',
              'Your memo has been deleted.',
              'success',
            );
            await this.setMemoList();
          } else {
            throw new Error(response.message || 'Failed to delete memo.');
          }
        }
      } catch (error) {
        await Swal.fire(
          'Error!',
          error.message || 'Something went wrong while deleting the memo.',
          'error',
        );
      }
    },
    gotoPublish(memo_id) {
      this.$router.push({
        path: 'memoSetPublish',
        name: 'ตั้งค่า การเผยแพร่ Memo',
        params: {
          memo_id,
        },
      });
    },
    async preview(memoId) {
      try {
        loading_start();

        const memo = await this.getMemoDetail(memoId);

        if (memo.settings?.autoGenerate) {
          const baseTemplate = this.getBaseTemplate({
            branchLogo: this.branchLogo || '',
            memorandam: this.memorandam,
          });

          memo.template = this.mergeTemplates({
            template: memo.template,
            pendingChanges: [
              await this.updateBaseTemplate(baseTemplate, memo.template),
            ],
          });
        }
        await this.generatePDF({
          template: memo.template,
          title: memo.title,
        });
      } catch (error) {
        throw new Error(`Error generating preview: ${error}`);
      } finally {
        loading_close();
      }
    },

    requestRecheck(memo_id) {
      Swal.fire({
        title: 'Would you like to request an edit for the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter a remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Recheck',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          await loading_start();
          let res = await this.save_request(
            memo_id,
            'requestRecheck',
            result.value,
          );
          await loading_close();

          if (res.status) {
            await Swal.fire('Saved!', '', 'success');
            await this.setMemoList();
          } else {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    requestDelete(memo_id) {
      Swal.fire({
        title: 'Are you sure you want to delete this item?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter a remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          await loading_start();
          let res = await this.save_request(
            memo_id,
            'requestDelete',
            result.value,
          );
          await loading_close();

          if (res.status) {
            await Swal.fire('Deleted!', '', 'success');
            await this.setMemoList();
          } else {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    async save_request(memo_id, status, reason) {
      try {
        const obj = {
          memo_id,
          status,
          reason,
        };

        return await this.$store.dispatch('memo/saveRequest', obj);
      } catch (error) {
        if (error.message === 'VAL001') {
          return {
            st: 'error',
            message: 'Please sign before saving.',
            title: 'Validation Error',
          };
        } else {
          throw error;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.memoList {
  .pointer {
    cursor: pointer;
  }

  .search-container {
    margin-bottom: 20px;

    .md-field {
      max-width: 300px;
    }
  }

  .status-icon {
    margin-right: 5px;
  }

  .waitapprove {
    color: #26a69a;
  }

  .draft {
    color: #6c757d;
  }

  .recheck {
    color: #ff5722;
  }

  .approved {
    color: #28a745;
  }

  .request-recheck {
    color: #ff9800;
  }

  .request-reject {
    color: #dc3545;
  }

  .rejected {
    color: #dc3545;
  }

  .nowarp {
    white-space: nowrap;
  }

  .md-icon {
    &.wait {
      color: #007bff;
    }

    &.approve {
      color: #28a745;
    }

    &.reject {
      color: #dc3545;
    }

    &.recheck {
      color: #ff5722;
    }
  }
}
</style>
