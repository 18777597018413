import service from '@/store/services/memo-service';

const state = {};

const mutations = {};

const actions = {
  async getMemoList({ commit, dispatch }, params) {
    return await service.getMemoList(params);
  },
  async getApproverList({ commit, dispatch }, params) {
    return await service.getApproverList(params);
  },
  async getMemoDetail({ commit, dispatch }, params) {
    return await service.getMemoDetail(params);
  },
  async getMemoApprovalsList({ commit, dispatch }) {
    return service.getMemoApprovalsList();
  },
  async getMemoPublished({ commit, dispatch }, params) {
    return service.getMemoPublished(params);
  },
  async getFile({ commit, dispatch }, params) {
    return service.getMemoFile(params);
  },
  async delete({ commit, dispatch }, params) {
    return await service.deleteMemo(params);
  },
  async saveMemo({ commit, dispatch }, params) {
    return await service.saveMemo(params);
  },
  async saveMemoPublish({ commit, dispatch }, params) {
    return await service.saveMemoPublish(params);
  },
};

const getters = {};

const memo = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default memo;
