import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import appeal from './modules/appeal-module';
import alerts from './modules/alerts-module';
import approvers from './modules/approvers-module';
import exports from './modules/exports-module';
import profile from './modules/profile-module';
import info from './modules/info-module';
import users from './modules/users-module';
import stafftimeinout from './modules/timeinout-module';
import branches from './modules/branches-module';
import departments from './modules/departments-module';
import positions from './modules/positions-module';
import staffs from './modules/staffs-module';
import staffhasworks from './modules/staffhasworks-module';
import workingtimes from './modules/workingtime-module';
import weekends from './modules/weekend-module';
import provinces from './modules/province-module';
import salarys from './modules/salarys-module';
import roles from './modules/roles-module';
import permissions from './modules/permissions-module';
import stafftransaction from './modules/stafftransaction';
import leaves from './modules/leaves-module';
import staffleaves from './modules/staffleaves-module';
import ots from './modules/ots-module';
import taxs from './modules/taxs-module';
import registers from './modules/registers-module';
import holidays from './modules/holidays-module';
import keepdays from './modules/keepdays-module';
import uniqueapprover from './modules/uniqueapprover-module';
import salaryperiod from './modules/salaryperiod-module';
import limittaxs from './modules/limittaxs-module';
import staffweekends from './modules/staffweekends-module';
import staffsso from './modules/staffsso-module';
import contract from './modules/contract-module';
import santhiyaSOS from './modules/santhiyaSOS-module';
import otsflatrate from './modules/otsflatrate-module';
import adjunct from './modules/adjunct-module';
import pdfs from './modules/pdfs-module';
import memo from './modules/memo-module';
import messenger from './modules/messenger-module';
import applicant from './modules/Hiring/applicant-module';
import appraisal from './modules/appraisal-module';
import canteen from './modules/canteen-module';
import warning from './modules/warning-module';
import system from './modules/system-module';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
    appeal,
    alerts,
    approvers,
    exports,
    profile,
    info,
    users,
    stafftimeinout,
    branches,
    departments,
    positions,
    staffs,
    staffhasworks,
    workingtimes,
    weekends,
    provinces,
    salarys,
    roles,
    permissions,
    stafftransaction,
    leaves,
    staffleaves,
    ots,
    taxs,
    registers,
    holidays,
    keepdays,
    uniqueapprover,
    salaryperiod,
    limittaxs,
    staffweekends,
    staffsso,
    contract,
    santhiyaSOS,
    otsflatrate,
    adjunct,
    pdfs,
    memo,
    messenger,
    applicant,
    appraisal,
    canteen,
    warning,
    system,
  },
});
