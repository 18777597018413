<template>
  <div>
    <!-- <md-dialog :md-active.sync="showAdd" style="min-width: 60%;" md-fullscreen>
      <md-dialog-title style="font-size: 1.5rem">
        เพิ่มการตั้งค่า Limit Tax
      </md-dialog-title>
      <md-dialog-content style="font-size: 1.2rem">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <filter-employee
              :headLabel="'พนักงาน'"
              :permissionsList="permissions"
              @childBranches="setBranchs"
              @childDepartment="setDepartments"
              @childStaffs="setStaffs"
            >
            </filter-employee>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <hr />
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-field>
              <label>ชื่อของรอบเงินเดือน</label>
              <md-input v-model="salarymonthly.monthly_name"></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-70 md-medium-size-60 md-small-size-40 md-alignment-right"
          ></div>
          <div
            class="md-layout-item md-size-30 md-medium-size-40 md-small-size-60 md-alignment-right"
          ></div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-success" @click="insert_data()">
          บันทึก
        </md-button>
        <md-button class="md-danger" @click="showAdd = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog> -->
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                manage_accounts
              </md-icon>
            </div>
            <h5 class="title">ลิมิตค่าภาษีของแต่ละเดือน</h5>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-70 mt-2 md-small-size-100 md-xsmall-size-100 md-alignment-left"
                  >
                    <filter-employee
                      :permissionsList="permissions"
                      @childBranches="setBranchs"
                      @childDepartment="setDepartments"
                      @childStaffs="setStaffs"
                    >
                    </filter-employee>
                  </div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  >
                    <md-field>
                      <label for="">ปี</label>
                      <md-select v-model="selectyear" :disabled="disabled_year">
                        <md-option
                          :value="itemyear"
                          v-for="itemyear in listyear"
                          :key="itemyear"
                        >
                          {{ itemyear }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  >
                    <md-field>
                      <label for="">เดือน</label>
                      <md-select
                        v-model="selectmonth"
                        :disabled="disabled_month"
                      >
                        <md-option
                          :value="itemmonth"
                          v-for="itemmonth in listmonth"
                          :key="itemmonth"
                        >
                          {{ itemmonth }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
              <!-- <div
                class="md-layout-item md-size-100 mt-4 md-small-size-100 text-right"
              >
                <md-button @click="goToAdd" class="md-primary md-dense">
                  เพิ่มการตั้งค่า Limit Tax
                </md-button>
              </div> -->
            </div>
            <md-table
              :value="tableData"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="รหัสพนักงาน">
                  <span class="upfont">
                    {{ item.emp_id }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  <span class="upfont">
                    {{ item.first_nameth }}
                    {{ item.last_nameth }}
                  </span>
                </md-table-cell>
                <md-table-cell
                  class="backwhite"
                  md-label="จ่ายภาษีสูงสุดของเดือน"
                >
                  <md-field>
                    <label for="">ภาษีสูงสุดของเดือน</label>
                    <md-input
                      v-model="item.limit_tax"
                      :value="item.limit_tax"
                    ></md-input>
                  </md-field>
                </md-table-cell>
                <!-- <md-table-cell md-label="เดือน">
                  {{ item.months }}
                </md-table-cell>
                <md-table-cell md-label="ปี">
                  {{ item.years }}
                </md-table-cell> -->
                <md-table-cell md-label="Action">
                  <b-button
                    variant="success"
                    @click="insert_data(item.limit_tax, item.id)"
                  >
                    อัพเดท
                  </b-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
import moment from 'moment';

export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  data() {
    return {
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      permissions: [],
      staffs: null,
      listyear: null,
      listmonth: null,
      listmonthly: null,
      selectyear: null,
      disabled_year: true,
      selectmonth: null,
      disabled_month: true,
      employee_id: null,
      departments: null,
      branchs: null,
      employee: null,
      showEdit: false,
      showAdd: false,
    };
  },
  async created() {
    await this.setPermission(this.me);
    await this.getListYear();
    await this.getListMonth();
  },
  watch: {
    selectyear: function(val) {
      if (val != null) {
        this.selectmonth = null;
        this.disabled_month = false;
      }
    },
    selectmonth: function(val) {
      if (val != null) {
        this.getListEmp();
      }
    },
    departments: function(val) {
      if (val != null) {
        if (this.selectmonth != null) {
          this.getListEmp();
        }
      }
    },
    employee_id: function(val) {
      if (this.selectmonth != null) {
        this.getListEmp();
      }
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  methods: {
    async getListYear() {
      loading_start();
      let params = { profile: true };
      this.employee = await this.$store.dispatch('staffs/searchStaff', params);
      // params = {
      //   com_id: this.employee[0].com_id,
      // };
      // this.listyear = await this.$store.dispatch('limittaxs/listYear', params);
      let listyear = [];
      const currentYear = Number(moment().format('Y'));
      for (let index = 2020; index <= currentYear + 3; index++) {
        listyear.push(index);
      }
      this.listyear = listyear;
      loading_close();
    },

    async getListMonth() {
      loading_start();
      // let params = {
      //   com_id: this.employee[0].com_id,
      //   ...(this.selectyear ? { years: this.selectyear } : {}),
      // };
      // this.listmonth = await this.$store.dispatch(
      //   'limittaxs/listMonth',
      //   params,
      // );
      let listmonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      // for (let index = 1; index <= 12; index++) {
      //   listmonth.push(index);
      // }
      this.listmonth = listmonth;
      loading_close();
    },

    async getListEmp() {
      loading_start();
      let params = {
        ...(this.employee[0].com_id ? { com_id: this.employee[0].com_id } : {}),
        ...(this.branchs ? { bch_id: this.branchs } : {}),
        ...(this.departments ? { dep_id: this.departments } : {}),
        ...(this.employee_id ? { emp_id: this.employee_id } : {}),
        ...(this.selectyear ? { years: this.selectyear } : {}),
        ...(this.selectmonth ? { months: this.selectmonth } : {}),
      };
      this.tableData = await this.$store.dispatch('limittaxs/listEmp', params);
      loading_close();
    },

    async insert_data(limittax, emp_id) {
      if (emp_id == null || limittax == null) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        let params = {
          emp_id: emp_id,
          limit_tax: limittax,
          months: this.selectmonth,
          years: this.selectyear,
        };
        await this.$store.dispatch('limittaxs/add', params).then((response) => {
          this.$store.dispatch('alerts/success', 'ตั้งค่าเรียบร้อยแล้ว.');
        });
      }
    },

    // set branch value from child component
    async setBranchs(v) {
      if (v && v != null) {
        this.branchs = v;
      }
    },
    // set departments value from child component
    async setDepartments(v) {
      if (v && v != []) {
        this.departments = v;
        this.disabled_year = false;
      }
    },
    // set staffs value from child component
    async setStaffs(v) {
      if (v && v != []) {
        this.employee_id = v;
        this.disabled_year = false;
      } else {
        this.employee_id = null;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
  },
};
</script>
<style scoped>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
.backwhite {
  background-color: white;
}
/* .searchstaffs {
  display: none;
} */
</style>
