import axios from 'axios';

const url = process.env.VUE_APP_API_SERVICE_URL;
async function getListWarning(params) {
  const response = await axios.get(`${url}/warning/getListWarning`, { params });
  return response.data;
}
async function getListWarningType(params) {
  const response = await axios.get(`${url}/warning/getListWarningType`, {
    params,
  });
  return response.data;
}

async function createWarning(params) {
  const response = await axios.post(`${url}/warning/insertWarning`, params);
  return response.data;
}

export default {
  getListWarning,
  getListWarningType,
  createWarning,
};
