import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function gettagsos(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/get_tagsos`, options);
  return response.data;
}

async function getFileAppeal(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/getfileappeal`, params, options);
  return response.data;
}

async function createAppeal(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/createappeal`, params, options);
  return response.data;
}

async function getAppeal(params) {
  const response = await axios.get(`${url}/v1/getappeal`, { params });
  return response.data;
}

async function updateSos(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/update_sos`, params, options);
  return response.data;
}

async function deleteSOS(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  const response = await axios.post(`${url}/v1/deleteSOS`, params, options);
  return response.data;
}

export default {
  gettagsos,
  createAppeal,
  getAppeal,
  getFileAppeal,
  updateSos,
  deleteSOS,
};
