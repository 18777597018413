import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(
      `${url}/v1/employees?include=users.permissions,users.roles.permissions`,
      options,
    )
    .then((response) => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function getStaffRole(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .get(`${url}/v1/staffrole/${params.id}/${params.branch}`, options)
    .then((response) => {
      let user = jsona.deserialize(response.data);
      //delete user.links;
      return user;
    });
}

function assignUserRole(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/assignstaff-role`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function searchStaff(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/searchstaff`, options).then((response) => {
    return response.data;
    // let user = jsona.deserialize(response.data);
    //delete user.links;
  });
}

function searchImgStaff(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/searchImgStaff`, options).then((response) => {
    return response.data;
    // let user = jsona.deserialize(response.data);
    //delete user.links;
  });
}

function searchStaffAll(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/searchstaff`, options).then((response) => {
    return response.data;
    // let user = jsona.deserialize(response.data);
    //delete user.links;
  });
}

function getdepartment(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_department`, options).then((response) => {
    return response.data;
  });
}
function getposition(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_position`, options).then((response) => {
    return response.data;
  });
}
function getpos_dep(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/v1/get_pos_dep`, options).then((response) => {
    return response.data;
  });
}

function getEmployeeToEdit(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios.get(`${url}/v1/getstaffedit`, options).then((response) => {
    return response.data;
  });
}
async function getlistEmp(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  let response = await axios.get(`${url}/v1/getlistemp`, options);
  return response.data;
}

export default {
  list,
  getStaffRole,
  assignUserRole,
  searchStaff,
  getEmployeeToEdit,
  getdepartment,
  getposition,
  getpos_dep,
  searchImgStaff,
  getlistEmp,
};
