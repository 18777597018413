<template>
  <div class="md-layout">
    <md-card-header></md-card-header>
    <md-card-content>
      <div class="md-layout spacing md-alignment-center">
        <div
          class="md-layout-item md-size-25 md-medium-size-30 md-small-size-40 md-xsmall-size-100"
        >
          <!-- <div>สำนักงาน</div> -->
          <md-field class="searchbranchs">
            <label for="branchs">สำนักงาน</label>
            <md-select v-model="branches" name="branch_id">
              <md-option
                v-for="item in available_branches"
                :key="item.bch_id"
                :value="item.bch_id"
              >
                {{ item.brname_th }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-25 md-medium-size-30 md-small-size-40 md-xsmall-size-100"
        >
          <!-- <div>สำนักงาน</div> -->
          <md-field class="searchdepartments">
            <label for="department">เลือก แผนก</label>
            <md-select v-model="departments">
              <md-option
                v-for="item in available_departments"
                :key="item.dep_id"
                :value="item.dep_id"
                :disabled="disabled_dep"
              >
                {{ item.depname_th }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-25 md-medium-size-30 md-small-size-40 md-xsmall-size-100"
        >
          <md-field>
            <label for="branchs">ประเภทพนักงาน</label>
            <md-select v-model="stafftype" name="stafftype">
              <md-option value="all">ทั้งหมด</md-option>
              <md-option value="month">รายเดือน</md-option>
              <md-option value="day">รายวัน</md-option>
            </md-select>
          </md-field>
        </div>
        <!-- Date -->
        <div
          class="md-layout md-layout-item md-size-100 md-alignment-center-center"
        >
          <div
            class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-35 md-xsmall-size-100 md-alignment-center-center"
          >
            <md-datepicker v-model="fromdate" md-immediately>
              <label for="fromdate">วันที่เริ่ม</label>
            </md-datepicker>
          </div>
          <div
            class="md-layout md-layout-item md-size-25 md-medium-size-30 md-small-size-35 md-xsmall-size-100 md-alignment-center-center"
          >
            <md-datepicker
              v-model="todate"
              md-immediately
              :md-disabled-dates="disabledToDates"
            >
              <label for="todate">วันที่สิ้นสุด</label>
            </md-datepicker>
          </div>
        </div>
        <div class="md-layout-item md-size-10">
          <md-progress-spinner
            :md-diameter="50"
            :md-stroke="3"
            md-mode="indeterminate"
            :md-value="100"
            v-show="false"
          >
          </md-progress-spinner>
        </div>
        <div
          class="md-layout spacing md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 md-alignment-center-center"
        >
          <md-button class="md-info" @click="getDataExport">Export</md-button>
        </div>
      </div>
    </md-card-content>
  </div>
</template>
<script>
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import { loading_start, loading_close } from '@/utils/loading.js';
import * as XLSX from 'xlsx';

export default {
  mixins: [employeeMixin],
  components: {},

  data() {
    return {
      branches: null,
      available_years: [],
      available_months: [],
      fromdate: null,
      todate: null,
      years: moment().year(),
      months: null,
      exporttype: 'time',
      stafftype: 'all',
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      permissionsList: [],
      employee_id: null,
      branchs: {},
      isParams: false,
      profile: null,
      query: null,
      available_departments: null,
      disabled_dep: true,
      departments: null,
    };
  },
  created() {
    this.getProfileData().then(async (res) => {
      this.profile = res;
      loading_start();
      // await this.getReportsalaryList();
      loading_close();
    });
  },
  props: {
    available_branches: {
      type: Array,
      default: () => {},
    },
  },
  watch: {
    branches: async function() {
      if (this.branches) {
        this.getDepartments();
      }
    },
  },
  methods: {
    async getReportsalaryList() {
      this.available_list = await this.$store.dispatch(
        'exports/reportsalarylist',
      );
    },
    async getDepartments() {
      this.departments = null;
      let params = {
        com_id: '1',
        ...{ bch_id: this.branches ? this.branches : {} },
        dep_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_departments = data;
        if (this.available_departments.length > 0) {
          this.disabled_dep = false;
        }
      });
    },
    async getDataExport() {
      if (!this.branches) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
        return false;
      } else if (!this.stafftype) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก ประเภทพนักงาน');
        return false;
      } else if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      }
      var leavelisttype = {
        sick: { name: 'sick', nameth: 'ลาป่วย' },
        personal: { name: 'personal', nameth: 'ลากิจ' },
        annual: { name: 'annual', nameth: 'ลาพักร้อน' },
        wop: { name: 'wop', nameth: 'ลาไม่รับค่าจ้าง' },
        ch: { name: 'ch', nameth: 'เปลี่ยนวันหยุด/วันเก็บ' },
        maternity: { name: 'maternity', nameth: 'ลาคลอด' },
        military: { name: 'military', nameth: 'ลารับราชการทหาร' },
        training: { name: 'training', nameth: 'ลาฝึกอมรม' },
        sterilization: { name: 'sterilization', nameth: 'ลาทำหมัน' },
        ph: { name: 'ph', nameth: 'ลาวันหยุดประเพณี' },
      };

      let params = {
        ...{ bch_id: this.branches ? this.branches : {} },
        ...{ dep_id: this.departments ? this.departments : {} },
        ...(this.fromdate
          ? { fromdate: moment(this.fromdate).format('YYYY-MM-DD') }
          : {}),
        ...(this.todate
          ? { todate: moment(this.todate).format('YYYY-MM-DD') }
          : {}),
        ...(this.stafftype
          ? { stafftype: this.stafftype }
          : { stafftype: 'all' }),
      };

      loading_start();
      let list_data = await this.$store.dispatch(
        'stafftimeinout/get_timeinout',
        params,
      );

      if (list_data.length > 0) {
        var items = [];

        list_data.forEach((data) => {
          data.forEach((d) => {
            d.date = moment(d.date, ['YYYY-MM-DD', 'DD/MM/YYYY']).format(
              'DD/MM/YYYY',
            );
            d.date = d.date != 'Invalid date' ? d.date : '';
            if (d.timein) {
              d.timein.replace('ก่อนเที่ยง', 'AM');
              d.timein.replace('หลังเที่ยง', 'PM');
              d.timein = this.formatDateTo24(d.timein);
              d.timein = moment(d.timein, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss',
              );
              d.timein = d.timein != 'Invalid date' ? d.timein : '-';
            }
            d.timebreak = moment(d.timebreak, 'YYYY-MM-DD HH:mm:ss A').format(
              'DD/MM/YYYY HH:mm:ss',
            );
            d.timebreak = d.timebreak != 'Invalid date' ? d.timebreak : '-';
            d.timefinishbreak = moment(
              d.timefinishbreak,
              'YYYY-MM-DD HH:mm:ss A',
            ).format('DD/MM/YYYY HH:mm:ss');
            d.timefinishbreak =
              d.timefinishbreak != 'Invalid date' ? d.timefinishbreak : '-';
            if (d.timeout) {
              d.timeout.replace('ก่อนเที่ยง', 'AM');
              d.timeout.replace('หลังเที่ยง', 'PM');
              d.timeout = this.formatDateTo24(d.timeout);
              d.timeout = moment(d.timeout, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss',
              );
              d.timeout = d.timeout != 'Invalid date' ? d.timeout : '-';
            }
            d.cw = 0;
            d.newworkstatus = '';
            try {
              if (d.workstatus.split('-').length > 1) {
                d.workstatus.split('-').forEach((work) => {
                  let worksplit = work.split('_');
                  d.cw += 1;
                  if (d.newworkstatus != '') {
                    d.newworkstatus += ' - ';
                  }
                  d.newworkstatus += leavelisttype[worksplit[0]].nameth;
                  if (worksplit[1] == 'f') {
                    d.newworkstatus += ' (เต็มวัน)';
                  } else if (worksplit[1] == 'hm') {
                    d.newworkstatus += ' (ครึ่งเช้า)';
                  } else if (worksplit[1] == 'hn') {
                    d.newworkstatus += ' (ครึ่งบ่าย)';
                  }
                });
              }
            } catch (error) {
              this.$store.dispatch(
                'alerts/error',
                'Something went wrong!!! ' + d?.leave?.employee_id,
              );
              loading_close();
              throw error;
            }

            items.push({
              employee_id: d.condition.emp_id,
              name:
                d.condition.title_nameth +
                d.condition.first_nameth +
                ' ' +
                d.condition.last_nameth,
              date: d.date,
              in: d.timein,
              out: d.timeout,
              break: d.timebreak,
              finishbreak: d.timefinishbreak,
              status: d.newworkstatus ? d.newworkstatus : d.workstatus,
              minutetolate: d.timetolatein,
              minuteoff: d.timetolateout,
              workingtime:
                moment(
                  d.condition.start_date + ' ' + d.condition.start_time,
                ).format('HH:mm') +
                ' - ' +
                moment(
                  d.condition.start_date + ' ' + d.condition.finish_time,
                ).format('HH:mm'),
            });
          });
        });

        const dataWS = XLSX.utils.json_to_sheet(items);
        const wb = XLSX.utils.book_new();
        const file_name = 'timeinout_export' + '.xlsx';
        XLSX.utils.book_append_sheet(wb, dataWS);
        XLSX.writeFile(wb, file_name);
      } else {
        this.$store.dispatch('alerts/error', 'No data!');
      }
      loading_close();
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
    },

    /**
     * set employee id value from child component
     */
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employeeID = v.id;
      } else {
        this.staffs = [];
        this.employeeID = null;
      }
    },
  },
};
</script>
<style lang="scss">
.tab-pane {
  width: 100%;
}
</style>
