<template>
  <div>
    <md-dialog
      class="edit-data"
      :md-active.sync="showEdit_income_cost"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.5rem">
        <!-- กรอกรายได้และค่าใช้จ่าย -->
        กรอก - แก้ไขข้อมูล
      </md-dialog-title>
      <md-dialog-content v-if="edit_data" style="font-size: 1.1rem">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            รหัสพนักงาน : {{ edit_data.employee_id }} <br />
            ชื่อพนักงาน : {{ edit_data.employee_name }} <br />
            วันที่เริ่ม - สิ้นสุด :
            {{ moment(fromdate).format('DD/MM/YYYY') }} -
            {{ moment(todate).format('DD/MM/YYYY') }}
          </div>
        </div>
        <!-- income cost can create component -->
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header
                  class="md-card-header-icon md-card-header-warning"
                >
                  <div class="card-icon">
                    <md-icon>request_quote</md-icon>
                  </div>
                  <h4 class="title">กรอกรายได้-ค่าใช้จ่าย</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-size-100 md-small-size-100">
                    รหัสพนักงาน : {{ edit_data.employee_id }} <br />
                    ชื่อพนักงาน : {{ edit_data.employee_name }} <br />
                    วันที่เริ่ม - สิ้นสุด :
                    {{ moment(fromdate).format('DD/MM/YYYY') }} -
                    {{ moment(todate).format('DD/MM/YYYY') }}
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">เงินเดือน</label>
                      <md-input
                        min="0"
                        v-model="edit_data.salary_bath"
                        type="number"
                      >
                        {{ edit_data.salary_bath }}
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">โอทีเหมา</label>
                      <md-input
                        min="0"
                        v-model="edit_data.ot_bath_insert"
                        type="number"
                      >
                        {{ edit_data.ot_bath_insert }}
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">โอที 1 เท่า</label>
                      <md-input
                        min="0"
                        v-model="edit_data.ot1_bath_insert"
                        type="number"
                      >
                        {{ edit_data.ot1_bath_insert }}
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">โอที 1.5 เท่า</label>
                      <md-input
                        min="0"
                        v-model="edit_data.ot1_5_bath_insert"
                        type="number"
                      >
                        {{ edit_data.ot1_5_bath_insert }}
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">เซอร์วิสชาร์ท</label>
                      <md-input
                        min="0"
                        v-model="edit_data.service_charge_income"
                        type="number"
                      >
                        {{ edit_data.service_charge_income }}
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">เซอร์วิสชาร์ทไม่คิดปกส.</label>
                      <md-input
                        min="0"
                        v-model="edit_data.service_charge_income_nosso"
                        type="number"
                      >
                        {{ edit_data.service_charge_income_nosso }}
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">เบี้ยเลี้ยงค่าอาหาร</label>
                      <md-input
                        min="0"
                        v-model="edit_data.food_service"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">ค่านวดสปา</label>
                      <md-input
                        min="0"
                        v-model="edit_data.massage_income_insert"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">ค่าเช่าบ้าน</label>
                      <md-input
                        min="0"
                        v-model="edit_data.home_rent_income"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">ค่าจ้างค้างรับ</label>
                      <md-input
                        min="0"
                        v-model="edit_data.accrued_income"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">คืนค่าบริหารจัดการ</label>
                      <md-input
                        min="0"
                        v-model="edit_data.manage_income"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">เงินได้จากหน้าที่</label>
                      <md-input
                        min="0"
                        v-model="edit_data.allowance"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">ค่าทิป</label>
                      <md-input min="0" v-model="edit_data.tip" type="number" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">รายได้อื่นๆ</label>
                      <md-input
                        min="0"
                        v-model="edit_data.other_income"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">คืนวันหยุด/บาท</label>
                      <md-input
                        min="0"
                        v-model="edit_data.keepdays_baht_insert"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: green">add</md-icon>
                      <label for="">โบนัส (เงินพิเศษ)</label>
                      <md-input
                        min="0"
                        v-model="edit_data.bonus"
                        type="number"
                      />
                    </md-field>
                  </div>
                </div>
                <br />
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักมาสาย - กลับก่อน</label>
                      <md-input
                        min="0"
                        v-model="edit_data.late_bath_insert"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หัก WOP/ขาดงาน</label>
                      <md-input
                        min="0"
                        v-model="edit_data.wopandnotwork_bath"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักเงินกู้ กยศ./หักนำส่งบังคับคดี</label>
                      <md-input
                        min="0"
                        v-model="edit_data.student_loan_fund"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักค่าเช่าบ้าน</label>
                      <md-input
                        min="0"
                        v-model="edit_data.home_rent_cost"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักค่าไฟฟ้า</label>
                      <md-input
                        min="0"
                        v-model="edit_data.electricity_cost"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักค่าน้ำ</label>
                      <md-input
                        min="0"
                        v-model="edit_data.water_bill"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักค่าอาหาร</label>
                      <md-input
                        min="0"
                        v-model="edit_data.food_cost"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักค่าส่วนกลาง</label>
                      <md-input
                        min="0"
                        v-model="edit_data.general_affairs_fee"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักเซอร์วิสชาร์จ</label>
                      <md-input
                        min="0"
                        v-model="edit_data.service_charge_cost"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักเซอร์วิสชาร์ทไม่คิดปกส.</label>
                      <md-input
                        min="0"
                        v-model="edit_data.service_charge_cost_nosso"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon style="color: red">remove</md-icon>
                      <label for="">หักอื่นๆ</label>
                      <md-input
                        min="0"
                        v-model="edit_data.other_bath_cost"
                        type="number"
                      />
                    </md-field>
                  </div>
                  <div
                    class="md-layout md-layout-item md-size-100 md-small-size-100"
                  >
                    <div
                      class="md-layout-item md-size-100 md-small-size-100 md-alignment-right"
                    >
                      <md-button
                        class="md-success"
                        @click="edit_data_insert(edit_data)"
                      >
                        บันทึก
                      </md-button>
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header
                  class="md-card-header-icon md-card-header-danger"
                >
                  <div class="card-icon">
                    <md-icon>schedule</md-icon>
                  </div>
                  <h4 class="title">กะการทำงาน</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <working-time-edit
                  :permissions="permissions"
                  :employeeID="selected_employee[0].emp_id"
                />
              </md-card-content>
            </md-card>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header
                  class="md-card-header-icon md-card-header-green"
                >
                  <div class="card-icon">
                    <md-icon>assignment</md-icon>
                  </div>
                  <h4 class="title">บันทึกการลา</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <leave-edit :persons="selected_employee[0].emp_id" />
              </md-card-content>
            </md-card>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header class="md-card-header-icon md-card-header-blue">
                  <div class="card-icon">
                    <md-icon>update</md-icon>
                  </div>
                  <h4 class="title">ทำงานล่วงเวลา (OT)</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <otrequestpage
                  :persons="selected_employee[0].emp_id"
                  :typecreate="1"
                />
              </md-card-content>
            </md-card>
          </div>
        </div>
        <!-- ot เหมา -->
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-card>
              <div class="header" @click="headdropdown($event)">
                <md-card-header class="md-card-header-icon md-card-header-blue">
                  <div class="card-icon">
                    <md-icon>update</md-icon>
                  </div>
                  <h4 class="title">เที่ยวเรือ และค่านวดสปา</h4>
                </md-card-header>
              </div>
              <md-card-content class="contentleave">
                <otrequestcharter
                  :persons="selected_employee[0].emp_id"
                  :typecreate="1"
                />
              </md-card-content>
            </md-card>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showEdit_income_cost = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="md-layout">
      <!-- Progress bar -->
      <vue-progress-bar></vue-progress-bar>

      <!-- Staffwork -->
      <!-- <div
        class="md-layout-item md-size-100 md-medium-size-100 md-xsmall-size-100"
      >
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <salary-calculate :permissions="permissions" />
          </md-card-content>
        </md-card>
      </div> -->
      <!-- Staffwork -->

      <div
        class="md-layout-item md-size-100 md-medium-size-100 md-xsmall-size-100"
      >
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>

          <md-card-content>
            <!-- Private code -->
            <div class="md-layout spacing">
              <div class="md-layout-item md-size-40 mt-2 md-small-size-40">
                <md-button
                  class="md-primary md-round md-theme-default"
                  @click="inputprivatecode"
                  :disabled="disabled_pvcode"
                >
                  <md-icon>lock</md-icon>
                  Private code
                </md-button>
              </div>
              <div>
                <md-dialog-prompt
                  :md-active.sync="active"
                  v-model="privatecodeIn"
                  md-title="Admin Private code"
                  md-input-maxlength="30"
                  md-input-placeholder="Type your private code..."
                  md-confirm-text="Send"
                  class="privatecode"
                />
              </div>
              <div class="md-layout-item md-size-10">
                <md-progress-spinner
                  :md-diameter="50"
                  :md-stroke="3"
                  md-mode="indeterminate"
                  :md-value="100"
                  v-show="showProgressBar"
                >
                </md-progress-spinner>
              </div>
            </div>

            <!-- Department -->
            <div class="md-layout">
              <div
                class="md-layout-item md-size-15 mt-2 md-small-size-30 md-small-size-100"
              >
                <md-radio v-model="radio_select" value="bydepart">
                  แผนก
                </md-radio>
              </div>
              <div
                class="md-layout-item md-size-25 mt-2 md-medium-size-30 md-small-size-100 md-alignment-center"
              >
                <md-field v-if="radio_select == 'bydepart'">
                  <label for="branchs">เลือก สำนักงาน</label>
                  <md-select v-model="branchs">
                    <md-option
                      v-for="item in available_branchs"
                      :key="item.bch_id"
                      :value="item.bch_id"
                    >
                      {{ item.brname_th }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-20 mt-2 md-medium-size-30 md-small-size-100 md-alignment-center"
              >
                <md-field v-if="radio_select == 'bydepart'">
                  <label for="department">เลือก แผนก</label>
                  <md-select v-model="departments">
                    <md-option
                      v-for="item in available_departments"
                      :key="item.dep_id"
                      :value="item.dep_id"
                    >
                      {{ item.depname_th }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <!-- Filter employee -->
            <div class="md-layout spacing md-alignment-left">
              <div class="md-layout-item md-size-10 mt-2 md-small-size-100">
                <md-radio v-model="radio_select" value="bystaff">
                  พนักงาน
                </md-radio>
              </div>
              <div class="md-layout-item md-size-90 mt-2 md-small-size-100">
                <filter-employee
                  v-if="radio_select == 'bystaff'"
                  :headLabel="headLabel"
                  :permissionsList="permissions"
                  @childBranches="setBranchs"
                  @childDepartment="setDepartment"
                  @childStaffs="setStaffs"
                >
                </filter-employee>
              </div>
            </div>

            <!-- Date -->
            <div class="md-layout md-alignment-center">
              <!-- <div
                class="md-layout-item md-size-30 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
              >
                <select-months @selectmonth="setMonths"></select-months>
              </div>
              <div
                class="md-layout-item md-size-50 md-medium-size-50 md-small-size-30 md-xsmall-size-100"
              >
                <div style="font-size: 16px" v-if="months">
                  วันที่เริ่ม : {{ months.detail.start_date }} <br />
                  วันที่สิ้นสุด : {{ months.detail.end_date }}
                </div>
              </div> -->
              <div
                class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
              >
                <md-datepicker v-model="fromdate" md-immediately>
                  <label>วันที่เริ่ม</label>
                </md-datepicker>
              </div>
              <div
                class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
              >
                <md-datepicker
                  v-model="todate"
                  md-immediately
                  :md-disabled-dates="disabledToDates"
                >
                  <label>วันที่สิ้นสุด</label>
                </md-datepicker>
              </div>
              <div
                class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
              >
                <!-- <salary-period
                  :radio_select="radio_select"
                  :departments="departments"
                  :branchs="branchs"
                  :fromdate="fromdate"
                  :todate="todate"
                >
                </salary-period> -->
              </div>
            </div>
            <div class="md-layout spacing md-alignment-center">
              <div
                class="md-layout-item mt-2 md-size-20 md-medium-size-25 md-small-size-30 md-xsmall-size-100"
              >
                เลือก รายเดือน - รายวัน
              </div>
              <div
                class="md-layout-item mt-2 md-size-25 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
              >
                <md-field>
                  <label>เลือก รายเดือน - รายวัน</label>
                  <md-select v-model="select_stafftype">
                    <md-option value="all">
                      ทั้งหมด
                    </md-option>
                    <md-option value="month">
                      รายเดือน
                    </md-option>
                    <md-option value="day">
                      รายวัน
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <!-- <div class="md-layout spacing md-alignment-center">
              <div
                class="md-layout-item md-size-15 mt-2 md-medium-size-20 md-small-size-20 md-xsmall-size-100"
              >
                เลือกรายการ OT
              </div>
              <div
                class="md-layout-item md-size-20 mt-2 md-medium-size-25 md-small-size-30 md-xsmall-size-100"
              >
                <md-field>
                  <label>เลือกรายการ OT</label>
                  <md-select v-model="select_ot">
                    <md-option
                      v-for="item in available_ot"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div> -->
            <!-- Button -->
            <div class="md-layout spacing md-alignment-center">
              <div
                class="md-layout-item md-size-20 md-medium-size-20 md-small-size-50 md-alignment-center"
              >
                <md-button
                  @click="calculateSalary"
                  class="md-info"
                  :disabled="disabled_submit"
                  v-if="invalid_privatecode == false"
                >
                  คำนวณ
                </md-button>
              </div>
              <!-- <div class="md-layout-item md-size-20 md-alignment-center">
                <md-button @click="exportSalary" class="md-warning" :disabled="disabled_submit" 
                  v-if ="invalid_privatecode == false">Export </md-button>
              </div> -->
            </div>
            <!-- <div
              class="md-layout spacing"
              v-if="
                radio_select === 'bystaff' && tableData && tableData.length > 0
              "
            >
              <div class="md-layout-item md-size-20 mt-2 md-small-size-20">
                <md-checkbox v-model="addService" @change="addServiceClick"
                  >เพิ่มรายการ</md-checkbox
                >
              </div>
              <div
                class="md-layout-item md-size-20 mt-2 md-small-size-20"
                v-if="addService"
              >
                <md-field>
                  <label>เลือก รายการ</label>
                  <md-select v-model="service">
                    <md-option
                      v-for="item in available_ot"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-20 mt-2 md-small-size-20"
                v-if="addService"
              >
                <md-field>
                  <label>จำนวน</label>
                  <md-input v-model="amount" />
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-20 mt-2 md-small-size-20"
                v-if="addService"
              >
                <div class="md-layout-item md-size-20 md-alignment-center">
                  <md-button @click="addServiceData" class="md-success"
                    >เพิ่ม
                  </md-button>
                </div>
              </div>
            </div> -->
            <!-- Salary process -->
            <!-- <salary-process 
              :employeeID ="employee_id" 
              :fromdate="fromdate" 
              :todate ="todate"
              v-if="(employee_id || departments) && is_calculate">
            </salary-process> -->
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100"
              v-if="tableData && tableData.length > 0"
            >
              <nav-tabs-card>
                <template slot="content">
                  <span class="md-nav-tabs-title"></span>
                  <md-tabs
                    class="md-success"
                    md-alignment="left"
                    v-if="tableData && tableData.length > 0"
                  >
                    <md-tab
                      id="tab-home"
                      md-label="รายได้"
                      md-icon="monetization_on"
                    >
                      <md-table
                        v-model="income"
                        table-header-color="blue"
                        :value="income"
                        class="paginated-table table-striped table-hover"
                      >
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="No">
                            {{ item.no }}.
                          </md-table-cell>
                          <md-table-cell md-label="Action">
                            <b-button
                              variant="info"
                              size="sm"
                              @click="edit_income_change(item.employee_id)"
                            >
                              กรอกข้อมูล
                            </b-button>
                          </md-table-cell>
                          <md-table-cell md-label="รหัสพนักงาน">
                            {{ item.employee_id }}
                          </md-table-cell>
                          <md-table-cell md-label="พนักงาน">
                            {{ item.employee_name }}
                          </md-table-cell>
                          <md-table-cell md-label="เงินเดือน (Baht)">
                            {{ item.salary }}
                          </md-table-cell>
                          <md-table-cell md-label="วันทำงาน (Day)">
                            {{ item.daywork }}
                          </md-table-cell>
                          <md-table-cell md-label="รวมเงินเดือน (Baht)">
                            {{ item.total_salary }}
                          </md-table-cell>
                          <md-table-cell md-label="OT (hour/minute)">
                            <tr
                              v-if="
                                Number(
                                  item.ot1_bath.replace(/[^0-9.-]+/g, ''),
                                ) > 0
                              "
                            >
                              1 เท่า:
                              {{
                                item.ot1_hr
                              }}
                            </tr>
                            <tr
                              v-if="
                                Number(
                                  item.ot1_5_bath.replace(/[^0-9.-]+/g, ''),
                                ) > 0
                              "
                            >
                              1.5 เท่า:
                              {{
                                item.ot1_5_hr
                              }}
                            </tr>
                          </md-table-cell>

                          <!-- <md-table-cell md-label="OT 1 (Baht)">
                            {{ item.ot1_bath }}
                          </md-table-cell> -->
                          <md-table-cell md-label="OT 1.5 (Baht)">
                            {{ item.ot1_5_bath }}
                          </md-table-cell>
                          <md-table-cell md-label="OT 2.0 (Baht)">
                            {{ item.ot2_bath }}
                          </md-table-cell>
                          <md-table-cell md-label="OT เหมา (Baht)">
                            {{ item.ot_bath }}
                          </md-table-cell>
                          <md-table-cell md-label="เซอร์วิสชาร์ท (Baht)">
                            {{ item.total_svc }} (
                            {{
                              (Number(item.service_charge_income) != null
                                ? Number(item.service_charge_income)
                                : 0) +
                                (Number(item.service_charge_income_nosso) !=
                                null
                                  ? Number(item.service_charge_income_nosso)
                                  : 0)
                            }}
                            )
                          </md-table-cell>
                          <md-table-cell md-label="เบี้ยเลี้ยงค่าอาหาร (Baht)">
                            {{ item.food_service }}
                          </md-table-cell>
                          <md-table-cell md-label="ค่านวดสปา (Baht)">
                            {{ item.massage_income }}
                          </md-table-cell>
                          <md-table-cell md-label="ค่าเช่าบ้าน (Baht)">
                            {{ item.home_rent_income }}
                          </md-table-cell>
                          <md-table-cell md-label="ค่าจ้างค้างรับ (Baht)">
                            {{ item.accrued_income }}
                          </md-table-cell>
                          <md-table-cell md-label="คืนค่าบริหารจัดการ (Baht)">
                            {{ item.manage_income }}
                          </md-table-cell>
                          <md-table-cell md-label="เงินได้จากหน้าที่ (Baht)">
                            {{ item.allowance }}
                          </md-table-cell>
                          <md-table-cell md-label="ค่าทิป (Baht)">
                            {{ item.tip }}
                          </md-table-cell>
                          <md-table-cell md-label="รายได้อื่นๆ (Baht)">
                            {{
                              (
                                parseInt(
                                  item.other_income.replace(/[^0-9.-]+/g, ''),
                                ) + parseInt(item.ot1_bath)
                              ).toLocaleString('en-US')
                            }}
                          </md-table-cell>
                          <md-table-cell md-label="คืนวันหยุด (Day)">
                            {{ item.keepdays }}
                          </md-table-cell>
                          <md-table-cell md-label="คืนวันหยุด (Baht)">
                            {{ item.keepdays_baht }}
                          </md-table-cell>
                          <md-table-cell md-label="โบนัส(เงินพิเศษ) (Baht)">
                            {{ item.bonus }}
                          </md-table-cell>
                          <!-- <md-table-cell md-label="OT 1(Hrs)">{{ item.ot1_hr }}</md-table-cell>
                          
                          <md-table-cell md-label="OT 1.5(Hrs)">{{ item.ot1_5_hr }}</md-table-cell>
                          
                          <md-table-cell md-label="รวม OT (Baht)">{{ item.ot_total }}</md-table-cell> -->
                          <md-table-cell
                            md-label="รวมเงินได้ก่อนหัก คชจ."
                            class="total-price"
                          >
                            {{ item.income_total }}
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                      <div class="table table-stats table-striped">
                        <div class="td-price">
                          <div class="td-total">รวมเงินได้ก่อนหัก คชจ.</div>
                          <span class="group-total-price">
                            {{ summary_income }}
                          </span>
                        </div>
                      </div>
                    </md-tab>

                    <md-tab id="tab-pages" md-label="รายการหัก" md-icon="code">
                      <md-table
                        v-model="cost"
                        table-header-color="orange"
                        :value="cost"
                        class="paginated-table table-striped table-hover"
                      >
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="No">
                            {{ item.no }}.
                          </md-table-cell>
                          <md-table-cell md-label="รหัสพนักงาน">
                            {{ item.employee_id }}
                          </md-table-cell>
                          <md-table-cell md-label="พนักงาน">
                            {{ item.employee_name }}
                          </md-table-cell>
                          <!-- <md-table-cell md-label="หักมาสาย/กลับก่อน(Mins)">{{ item.late_mins }}</md-table-cell> -->
                          <md-table-cell md-label="หักมาสาย/กลับก่อน (Baht)">
                            {{ item.late_bath }}
                          </md-table-cell>
                          <!-- <md-table-cell md-label="หักขาดงาน(Day)">{{ item.notwork_day  }}</md-table-cell>
                          <md-table-cell md-label="หักขาดงาน(Baht)">{{ item.notwork_bath }}</md-table-cell> -->
                          <!-- <md-table-cell md-label="หักลาไม่รับค่าจ้าง(Day)">{{ item.leave_wop_day }}</md-table-cell> -->
                          <md-table-cell md-label="หักลาไม่รับค่าจ้าง (Baht)">
                            {{ item.leave_wop_bath }}
                          </md-table-cell>
                          <md-table-cell md-label="ขาดงาน (Baht)">
                            {{ item.notwork_bath }}
                          </md-table-cell>
                          <md-table-cell md-label="หักค่าเซอร์วิสชาร์จ (Baht)">
                            {{ item.totalcost_svc }}
                          </md-table-cell>
                          <md-table-cell md-label="หักประกันสังคม (Baht)">
                            {{ item.sso_bath }}
                          </md-table-cell>
                          <md-table-cell md-label="หักภาษี (Baht)">
                            {{ item.tax_bath }}
                          </md-table-cell>
                          <md-table-cell
                            md-label="หักเงินกู้ กยศ./หักนำส่งบังคับคดี (Baht)"
                          >
                            {{ item.student_loan_fund }}
                          </md-table-cell>
                          <md-table-cell md-label="หักค่าเช่าบ้าน (Baht)">
                            {{ item.home_rent_cost }}
                          </md-table-cell>
                          <md-table-cell md-label="หักค่าไฟฟ้า (Baht)">
                            {{ item.electricity_cost }}
                          </md-table-cell>
                          <md-table-cell md-label="หักค่าน้ำ (Baht)">
                            {{ item.water_bill }}
                          </md-table-cell>
                          <md-table-cell md-label="หักค่าส่วนกลาง (Baht)">
                            {{ item.general_affairs_fee }}
                          </md-table-cell>
                          <md-table-cell md-label="หักค่าอาหาร (Baht)">
                            {{ item.food_cost }}
                          </md-table-cell>
                          <md-table-cell md-label="หักอื่นๆ (Baht)">
                            {{ item.other_bath_cost }}
                          </md-table-cell>
                          <!-- <md-table-cell md-label="หักเซอร์วิสชาร์จ">{{ item.tax_bath }}</md-table-cell> -->
                          <!-- <md-table-cell md-label="หักอื่น ๆ">{{ item.other_bath }}</md-table-cell> -->
                          <md-table-cell
                            md-label="รวมรายการหัก"
                            class="total-price"
                          >
                            {{ item.cost_total }}
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                      <div class="table table-stats table-striped">
                        <div class="td-price">
                          <div class="td-total">รวมรายการหัก</div>
                          <span class="group-total-price">
                            {{ summary_cost }}
                          </span>
                        </div>
                      </div>
                    </md-tab>

                    <md-tab
                      id="tab-posts"
                      md-label="เงินได้สุทธิ"
                      md-icon="attach_money"
                    >
                      <md-table
                        v-model="income_net"
                        table-header-color="green"
                        :value="income_net"
                        class="paginated-table table-striped table-hover"
                      >
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="No">
                            {{ item.no }}.
                          </md-table-cell>
                          <md-table-cell md-label="รหัสพนักงาน">
                            {{ item.employee_id }}
                          </md-table-cell>
                          <md-table-cell md-label="พนักงาน">
                            {{ item.employee_name }}
                          </md-table-cell>
                          <md-table-cell md-label="เงินเดือน">
                            {{ item.salary }}
                          </md-table-cell>
                          <md-table-cell md-label="เงินได้สะสมต่อปี">
                            {{ item.saving_per_year }}
                          </md-table-cell>
                          <md-table-cell md-label="ภาษีสะสมต่อปี">
                            {{ item.tax_per_year }}
                          </md-table-cell>
                          <md-table-cell md-label="เงินประกันสังคมต่อปี">
                            {{ item.sso_per_year }}
                          </md-table-cell>
                          <md-table-cell md-label="ค่าลดหย่อนอื่นๆ">
                            {{ item.ssf }}
                          </md-table-cell>
                          <md-table-cell md-label="รายได้รวม">
                            {{ item.income_total }}
                          </md-table-cell>
                          <md-table-cell md-label="รายการหักรวม">
                            {{ item.cost_net }}
                          </md-table-cell>
                          <md-table-cell
                            md-label="รวมเงินได้สุทธิ"
                            class="total-price"
                          >
                            {{ item.income_net }}
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                      <div class="table table-stats table-striped" variat>
                        <div class="td-price">
                          <div class="td-total">รวมเงินได้สุทธิ</div>
                          <span class="group-total-price">
                            {{ summary_incomenet }}
                          </span>
                        </div>
                      </div>
                    </md-tab>
                  </md-tabs>
                  <b-button
                    v-if="is_calculate"
                    @click="send_salary_sum"
                    variant="success"
                  >
                    ส่งรีพอต <md-icon style="color: white;">send</md-icon>
                  </b-button>
                </template>
              </nav-tabs-card>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import $ from 'jquery';
import Vuex from 'vuex';
import md5 from 'js-md5';
import moment from 'moment';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import { NavTabsCard } from '@/components';
import OTRequestPage from '@/pages/Dashboard/Pages/Staff/Transactions/OT/OTRequestPage.vue';
import OTRequestCharter from '@/pages/Dashboard/Pages/Staff/Transactions/OTFlatRateSpa/OTRequestCharter.vue';
import WorkingTimeEdit from '@/pages/Dashboard/Pages/Staff/Transactions/Working/WorkingTimeEdit.vue';
import LeaveEdit from '@/pages/Dashboard/Pages/Staff/Leave/LeaveEdit.vue';
import Swal from 'sweetalert2';

// import SalaryCalculate from '@/pages/Dashboard/Pages/Staff/Transactions/SalaryCalculate/SalaryCalculate.vue';
// import SalaryPeriod from '@/components/SalaryPeriod';
// import SelectMonths from '@/components/SelectMonths';
// import SalaryProcess from "./SalaryProcessPage.vue";

import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {
    NavTabsCard,
    'leave-edit': LeaveEdit,
    'filter-employee': FilterEmployee,
    'otrequestpage': OTRequestPage,
    'otrequestcharter': OTRequestCharter,
    'working-time-edit': WorkingTimeEdit,
    // 'salary-calculate': SalaryCalculate,
    // 'salary-period': SalaryPeriod,
    // 'select-months': SelectMonths,
  },
  mixins: [employeeMixin],
  data() {
    return {
      moment: moment,
      headLabel: '',
      showEdit_income_cost: false,
      edit_data: null,
      employee_id: null,
      departments_id: null,
      // permissions: [],
      is_calculate: false,
      disabled_pvcode: false,
      active: false,
      privatecodeIn: null,
      showProgressBar: false,
      invalid_privatecode: true,
      radio_select: 'bydepart',
      tableData: [],
      departments: null,
      months: null,
      branchs: null,
      available_branchs: [],
      available_departments: null,
      available_ot: [],
      select_ot: null,
      company_id: null,
      fromdate: null,
      todate: null,
      private_code: null,
      addService: false,
      service: null,
      income: [],
      cost: [],
      amount: 0,
      selected_employee: null,
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      select_stafftype: 'all',
    };
  },
  props: {
    employeeID: null,
    branchID: Object,
  },

  async created() {
    if (localStorage.getItem('privatecode')) {
      this.disabled_pvcode = true;
      this.invalid_privatecode = false;
    }
    await this.getBranchs();
    // await this.setPermission(this.me);
    // await this.getOT();
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          per.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
    income_net() {
      return this.tableData[0]['incomenet'];
    },
    summary_income() {
      return this.tableData[0]['summaryincome'];
    },
    summary_cost() {
      return this.tableData[0]['summarycost'];
    },
    summary_incomenet() {
      return this.tableData[0]['summaryincomenet'];
    },
    disabled_submit() {
      if (
        this.radio_select === 'bydepart' &&
        this.departments &&
        this.fromdate &&
        this.todate
      ) {
        return false;
      } else if (
        this.radio_select === 'bystaff' &&
        this.employee_id &&
        this.fromdate &&
        this.todate
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  watch: {
    branchs: function() {
      if (this.branchs) {
        this.getDepartments();
      }
    },
    privatecodeIn: function() {
      this.securePrivatecode(this.privatecodeIn);
    },
    tableData: {
      handler: function(val) {
        if (val && val.length > 0) {
          this.income = val[0]['income'];
          this.cost = val[0]['cost'];
        }
      },
      immediate: true,
      deep: true,
    },
    fromdate: function() {
      if (this.todate) {
        var todate = moment(this.todate, 'DD/MM/YYYY');
        if (moment(todate).diff(moment(this.fromdate), 'days') < 0) {
          this.todate = this.fromdate;
        }
      }
    },
  },

  methods: {
    headdropdown(event) {
      var con = $(event.target).closest('.md-layout-item');
      var content = $('.contentleave', con);
      content.slideToggle();
      content.css('display', 'grid');
    },
    async inputprivatecode() {
      const globalthis = this;
      function setactive() {
        globalthis.active = true;
      }
      await setactive();
      $('.md-dialog.privatecode input').prop('type', 'password');
    },
    async getBranchs() {
      let params = {
        com_id: '1',
        bch_only: '1',
      };
      this.available_branchs = await this.$store.dispatch(
        'staffs/searchStaff',
        params,
      );
    },

    async getDepartments() {
      this.departments = null;

      let params = {
        com_id: '1',
        ...{ bch_id: this.branchs ? this.branchs : {} },
        dep_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_departments = data;
        if (this.available_departments.length > 0) {
          this.disabled_dep = false;
        }
      });
    },

    async edit_income_change(emp_id) {
      let tableData = this.tableData.filter((tabledata) => {
        return tabledata.id == emp_id;
      });
      this.edit_data = { ...tableData[0] };
      let params = {
        emp_code: this.edit_data.id,
      };
      this.selected_employee = await this.$store.dispatch(
        'staffs/searchStaff',
        params,
      );
      this.showEdit_income_cost = true;
    },

    async edit_data_insert(data) {
      let params = {
        type: 'incomecost',
        ...(this.select_ot
          ? { select_ot: this.select_ot }
          : { select_ot: null }),
        emp_id: data.employee_id,
        emp_id_edit: this.$store.getters['profile/me'].employee_id,
        from_date: moment(this.fromdate).format('YYYY-MM-DD'),
        to_date: moment(this.todate).format('YYYY-MM-DD'),
        salary_bath: data.salary_bath,
        ot_bath: data.ot_bath_insert,
        ot1_bath: data.ot1_bath_insert,
        ot1_5_bath: data.ot1_5_bath_insert,
        service_charge_income: data.service_charge_income,
        service_charge_income_nosso: data.service_charge_income_nosso,
        service_charge_cost: data.service_charge_cost,
        service_charge_cost_nosso: data.service_charge_cost_nosso,
        food_service: data.food_service,
        massage_income: data.massage_income_insert,
        home_rent_income: data.home_rent_income,
        accrued_income: data.accrued_income,
        manage_income: data.manage_income,
        allowance: data.allowance,
        tip: data.tip,
        other_income: data.other_income,
        keepdays_baht: data.keepdays_baht_insert,
        bonus: data.bonus,
        student_loan_fund: data.student_loan_fund,
        home_rent_cost: data.home_rent_cost,
        electricity_cost: data.electricity_cost,
        water_bill: data.water_bill,
        general_affairs_fee: data.general_affairs_fee,
        food_cost: data.food_cost,
        other_bath_cost: data.other_bath_cost,
        late_bath: data.late_bath_insert,
        wopandnotwork_bath: data.wopandnotwork_bath,
      };

      try {
        this.start();
        await this.$store.dispatch('salarys/addIncomeCost', params).then(
          (data) => {
            this.calculateSalary();
            this.finish();
            this.$store.dispatch('alerts/success', 'Save Complete.');
            // this.showEdit_income_cost = false;
          },
          () => {
            this.$Progress.fail();
            this.$store.dispatch('alerts/error', 'Oops, something went wrong!');
          },
        );
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },
    async calculateSalary() {
      if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สุดท้าย');
        return false;
      } else if (this.radio_select === 'bydepart') {
        if (!this.branchs) {
          await this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
          return false;
        }
        if (!this.departments) {
          await this.$store.dispatch('alerts/error', 'กรุณาเลือก แผนก');
          return false;
        }
      } else if (this.radio_select === 'bystaff' && !this.employee_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      }

      this.tableData = [];
      this.private_code = localStorage.getItem('privatecode');
      let params = {
        id: null,
        type: 'salarycal',
        filter: {
          ...(this.fromdate
            ? {
                fromdate: moment(this.fromdate, [
                  'DD/MM/YYYY',
                  'YYYY-MM-DD',
                ]).format('YYYY-MM-DD'),
              }
            : {}),
          ...(this.todate
            ? {
                todate: moment(this.todate, [
                  'DD/MM/YYYY',
                  'YYYY-MM-DD',
                ]).format('YYYY-MM-DD'),
              }
            : {}),
          ...(this.select_ot
            ? { select_ot: this.select_ot }
            : { select_ot: null }),
          ...(this.select_stafftype
            ? { select_stafftype: this.select_stafftype }
            : { select_stafftype: 'all' }),
          ...(this.radio_select === 'bystaff'
            ? { emp_id: this.employee_id }
            : { depart_id: this.departments }),
          ...(this.radio_select === 'bystaff'
            ? {}
            : this.branchs
            ? { branch_id: this.branchs }
            : {}),
          ...(this.private_code ? { private_code: this.private_code } : {}),
        },
      };
      try {
        this.start();
        loading_start();
        await this.$store.dispatch('salarys/salaryCalculate', params).then(
          (data) => {
            // this.tableData = this.$store.getters['salarys/salarycalculatelist'];
            if (data.comment) {
              this.$store.dispatch('alerts/error', data.comment);
              this.finish();
              loading_close();
              return false;
            }

            this.tableData = data;
            this.is_calculate = true;
            this.finish();
            loading_close();
          },
          () => {
            this.$Progress.fail();
            this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
            loading_close();
          },
        );
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        loading_close();
      }
    },

    async send_salary_sum() {
      this.private_code = localStorage.getItem('privatecode');
      const confirmation = await Swal.fire({
        title: 'ยืนยันการส่งรีพอต ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Send Report!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        preConfirm: () => {
          return [true, this.private_code];
        },
      });
      if (confirmation.dismiss) {
      } else {
        if (confirmation.value[0] === true) {
          if (await this.securePrivatecode(confirmation.value[1])) {
            if (!this.fromdate) {
              await this.$store.dispatch(
                'alerts/error',
                'กรุณาเลือก วันที่เริ่ม',
              );
              return false;
            } else if (!this.todate) {
              await this.$store.dispatch(
                'alerts/error',
                'กรุณาเลือก วันที่สุดท้าย',
              );
              return false;
            } else if (this.radio_select === 'bydepart') {
              if (!this.branchs) {
                await this.$store.dispatch(
                  'alerts/error',
                  'กรุณาเลือก สำนักงาน',
                );
                return false;
              }
              if (!this.departments) {
                await this.$store.dispatch('alerts/error', 'กรุณาเลือก แผนก');
                return false;
              }
            } else if (this.radio_select === 'bystaff' && !this.employee_id) {
              await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
              return false;
            }
            let params = {
              id: null,
              type: 'sendtosalary',
              filter: {
                ...(this.fromdate
                  ? {
                      fromdate: moment(this.fromdate, [
                        'DD/MM/YYYY',
                        'YYYY-MM-DD',
                      ]).format('YYYY-MM-DD'),
                    }
                  : {}),
                ...(this.todate
                  ? {
                      todate: moment(this.todate, [
                        'DD/MM/YYYY',
                        'YYYY-MM-DD',
                      ]).format('YYYY-MM-DD'),
                    }
                  : {}),
                ...(this.select_stafftype
                  ? { select_stafftype: this.select_stafftype }
                  : { select_stafftype: 'all' }),
                ...(this.select_ot
                  ? { select_ot: this.select_ot }
                  : { select_ot: null }),
                ...(this.radio_select === 'bystaff'
                  ? { emp_id: this.employee_id, depart_id: this.departments_id }
                  : { depart_id: this.departments }),
                ...(this.branchs ? { branch_id: this.branchs } : {}),
                // ...(this.company_id ? { com_id: this.company_id } : {}),
                ...(this.private_code
                  ? { private_code: this.private_code }
                  : {}),
                sendto: 1,
              },
            };

            try {
              this.start();
              loading_start();
              await this.$store
                .dispatch('salarys/salaryCalculate', params)
                .then(
                  (data) => {
                    if (data == 302) {
                      this.$store.dispatch(
                        'alerts/error',
                        'ช่วงเวลาที่เลือกทับกับข้อมูลเดิม',
                      );
                      this.fail();
                      loading_close();
                      return false;
                    } else if (data == 500) {
                      this.$store.dispatch(
                        'alerts/error',
                        'มีบางอย่างผิดพลาด!',
                      );
                      this.fail();
                      loading_close();
                      return false;
                    }
                    // this.tableData = this.$store.getters['salarys/salarycalculatelist'];
                    this.$store.dispatch('alerts/success', 'Save Complete.');
                    this.finish();
                    loading_close();
                  },
                  () => {
                    this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
                    loading_close();
                  },
                );
            } catch (e) {
              await this.$store.dispatch(
                'alerts/error',
                'Oops, something went wrong!',
              );
              loading_close();
            }
          } else {
            Swal.fire('มีบางอย่างผิดพลาด', '', 'warning');
          }
        }
      }
    },

    exportSalary() {},

    async securePrivatecode(private_code) {
      let employee = JSON.parse(localStorage.getItem('employees'));
      if (private_code && employee.privatecode) {
        if (employee.privatecode === md5(private_code)) {
          localStorage.setItem('privatecode', private_code);
          this.disabled_pvcode = true;
          this.invalid_privatecode = false;
          return true;
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Invalid private code !!!',
          );
        }
      }
      return false;
    },

    addServiceClick() {},

    addServiceData() {
      if (Object.keys(this.tableData[0]['income'][0]).includes(this.service)) {
        this.income = [];
        this.tableData[0]['income'][0][this.service] = this.amount;
        this.income = this.tableData[0]['income'];
      }

      if (Object.keys(this.tableData[0]['cost'][0]).includes(this.service)) {
        this.cost = [];
        this.tableData[0]['cost'][0][this.service] = this.amount;
        this.cost = this.tableData[0]['cost'];
      }
    },

    setMonths(v) {
      this.months = v;
    },

    // set branch value from child component
    setBranchs(v) {
      this.branchs = v;
    },

    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    // set employee id value from child component
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employee_id = v.id;
      }
    },

    // set permission
    // setPermission(val) {
    //   val.permissions.map((permis) => {
    //     this.permissions.push(permis.name);
    //   });
    //   val.roles[0].permissions.map((permission) => {
    //     this.permissions.push(permission.name);
    //   });
    // },

    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-tabs-content table thead {
  display: contents;
}
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.total-price {
  font-weight: 500;
}
.group-total-price {
  font-family: math;
  font-style: italic;
  font-weight: 500;
}
// .table-shopping .md-table-head:nth-child(5),
// .table-shopping .md-table-head:nth-child(7),
// .table-shopping .md-table-head:nth-child(6) {
//   text-align: right;
// }
</style>
