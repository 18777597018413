import {
  DEFAULT_FONT_NAME,
  getDefaultFont,
  getInputFromTemplate,
  ZOOM,
} from '@pdfme/common';
import { generate } from '@pdfme/generator';
import {
  multiVariableText,
  text,
  barcodes,
  image,
  svg,
  line,
  table,
  rectangle,
  ellipse,
  dateTime,
  date,
  time,
  select,
  checkbox,
  radioGroup,
} from '@pdfme/schemas';
import SignaturePad from 'signature_pad';

export default {
  name: 'PdfmeHelper',
  data() {
    return {
      fontObjList: [
        {
          fallback: true,
          label: 'Angsana New',
          url:
            process.env.VUE_APP_IMAGE_BASE_URL +
            '/storage/font/angsananew/angsana-new-normal.ttf',
        },
        {
          fallback: false,
          label: 'Angsana New Bold',
          url:
            process.env.VUE_APP_IMAGE_BASE_URL +
            '/storage/font/angsananew/angsana-new-bold.ttf',
        },
        {
          fallback: false,
          label: DEFAULT_FONT_NAME,
          data: getDefaultFont()[DEFAULT_FONT_NAME].data,
        },
      ],
      fonts: null,
      templateDefault: {
        schemas: [[]],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      },
    };
  },
  methods: {
    async getFontsData() {
      if (this.fonts) return this.fonts;

      const fontDataList = await Promise.all(
        this.fontObjList.map(async (font) => ({
          ...font,
          data:
            font.data ||
            (await fetch(font.url || '').then((res) => res.arrayBuffer())),
        })),
      );

      return fontDataList.reduce(
        (acc, font) => ({ ...acc, [font.label]: font }),
        {},
      );
    },
    getPlugins() {
      return {
        Text: text,
        multiVariableText: multiVariableText,
        Table: table,
        Line: line,
        Rectangle: rectangle,
        Ellipse: ellipse,
        Image: image,
        SVG: svg,
        Signature: this.signature(),
        QR: barcodes.qrcode,
        DateTime: dateTime,
        Date: date,
        Time: time,
        Select: select,
        Checkbox: checkbox,
        RadioGroup: radioGroup,
      };
    },
    async generatePDF({
      template,
      title = 'Preview PDF',
      preview = !this.previewOnly,
    } = {}) {
      const currentTemplate = template || this.designer?.getTemplate();
      let inputs =
        template?.inputs || this.designer?.getInputs?.() || undefined;
      inputs =
        inputs?.length > 0 ? [{}] : getInputFromTemplate(currentTemplate);
      const font = await this.getFontsData();

      if (!currentTemplate) {
        throw new Error('ไม่มี template สำหรับการสร้าง PDF');
      }

      try {
        const pdf = await generate({
          template: currentTemplate,
          inputs,
          options: {
            font,
            title,
          },
          plugins: this.getPlugins(),
        });

        const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
        if (preview) window.open(URL.createObjectURL(blob));
        return blob;
      } catch (e) {
        throw new Error(`Error generating PDF: ${e}`);
      }
    },
    getEffectiveScale(element) {
      let scale = 1;
      while (element && element !== document.body) {
        const style = window.getComputedStyle(element);
        const transform = style.transform;
        if (transform && transform !== 'none') {
          const localScale = parseFloat(
            transform.match(/matrix\((.+)\)/)?.[1].split(', ')[3] || '1',
          );
          scale *= localScale;
        }
        element = element.parentElement;
      }
      return scale;
    },
    signature() {
      return {
        ui: async (arg) => {
          const { schema, value, onChange, rootElement, mode, i18n } = arg;
          const canvas = document.createElement('canvas');
          canvas.width = schema.width * ZOOM;
          canvas.height = schema.height * ZOOM;
          const resetScale = 1 / this.getEffectiveScale(rootElement);
          canvas.getContext('2d').scale(resetScale, resetScale);

          const signaturePad = new SignaturePad(canvas, {
            minWidth: 0.5,
            maxWidth: 1.5,
            throttle: 16,
          });
          try {
            value
              ? await signaturePad.fromDataURL(value, { ratio: resetScale })
              : signaturePad.clear();
          } catch (e) {}

          if (mode === 'viewer' || (mode === 'form' && schema.readOnly)) {
            signaturePad.off();
          } else {
            signaturePad.on();
            const clearButton = document.createElement('button');
            clearButton.style.position = 'absolute';
            clearButton.style.zIndex = '1';
            clearButton.textContent = i18n('signature.clear') || 'x';
            clearButton.addEventListener('click', () => {
              onChange && onChange({ key: 'content', value: '' });
            });
            rootElement.appendChild(clearButton);
            signaturePad.addEventListener('endStroke', () => {
              const data = signaturePad.toDataURL('image/png');
              onChange && data && onChange({ key: 'content', value: data });
            });
          }
          rootElement.appendChild(canvas);
        },
        pdf: image.pdf,
        propPanel: {
          schema: {},
          defaultSchema: {
            name: '',
            type: 'signature',
            content: '',
            position: { x: 0, y: 0 },
            width: 62.5,
            height: 37.5,
          },
        },
      };
    },
    async updateBaseTemplate(baseTemplate, template) {
      try {
        const schemas = template?.schemas;

        if (!schemas || schemas.length === 0) {
          throw new Error('No schemas available in the template.');
        }

        schemas.forEach((page, pageIndex) => {
          const basePageTemplate =
            baseTemplate.schemas[pageIndex] || baseTemplate.schemas.at(-1);
          if (!basePageTemplate) {
            throw new Error(
              `Base template not found for page index ${pageIndex}`,
            );
          }

          const objBaseTemplate = basePageTemplate.reduce((acc, bt) => {
            acc[bt.name] = bt;
            return acc;
          }, {});

          page.forEach((item) => {
            if (objBaseTemplate[item.name]) {
              item = { ...objBaseTemplate[item.name] };
              delete objBaseTemplate[item.name];
            }
          });

          page.push(...Object.values(objBaseTemplate));
        });

        return this.mergeTemplates({ template });
      } catch (error) {
        throw new Error(`Error in updateBaseTemplate: ${error.message}`);
      }
    },
    mergeTemplates({
      template,
      pdfmeInstance = undefined,
      pendingChanges = [],
      type = 'merge-template',
    } = {}) {
      const mergedTemplate = pdfmeInstance?.getTemplate() ?? template;

      if (pendingChanges.length <= 0) {
        pendingChanges.push({
          schemas: [[]],
          basePdf: {
            width: 210,
            height: 297,
            padding: [15, 15, 15, 15],
          },
          pdfmeVersion: '5.0.0',
        });
      }

      const schemasMap = {};
      mergedTemplate.schemas.forEach((schemas, index) => {
        schemasMap[index] = new Map();
        schemas.forEach((schema) => {
          schemasMap[index].set(schema.name, schema);
        });
        mergedTemplate.schemas[index] = [...schemasMap[index].values()];
      });

      for (const change of pendingChanges) {
        if (type === 'merge-template') {
          change.schemas.forEach((schemas, index) => {
            if (!schemasMap[index]) schemasMap[index] = new Map();

            for (const schema of schemas) {
              if (!schema?.content) schema.content = '';
              if (schema?.required) schema.required = false;

              schemasMap[index].set(schema.name, schema);
            }

            mergedTemplate.schemas[index] = [...schemasMap[index].values()];
          });
        } else if (type === 'merge-content') {
          const { fieldName, content, key, page = 0 } = change;

          let field = mergedTemplate.schemas[page]?.find(
            (f) => f.name === fieldName,
          );

          if (field) {
            field[key] = content;
          }
        }
      }

      return mergedTemplate;
    },
  },
};
