<template>
  <div class="priority-settings p-4">
    <div class="mb-4">
      <h4 v-if="isGroupEnabled" class="text-lg font-bold mb-4">
        กลุ่มลำดับ{{ title }}
      </h4>
      <b-button
        v-if="isGroupEnabled"
        class="mb-4"
        variant="info"
        @click="addPriorityGroup"
      >
        เพิ่มกลุ่มลำดับ{{ title }}
      </b-button>

      <div>
        <draggable
          v-model="localPriorityGroups"
          tag="div"
          class="group-container"
        >
          <div
            class="group bg-light p-4 rounded shadow-sm mb-6"
            v-for="(group, groupIndex) in localPriorityGroups"
            :key="'g-' + (group.priority = groupIndex)"
          >
            <div
              class="d-flex align-items-center mb-4"
              :class="[
                { 'justify-content-between': isGroupEnabled },
                { 'justify-content-end': !isGroupEnabled },
              ]"
            >
              <h5 v-if="isGroupEnabled" class="h5">
                กลุ่มลำดับ{{ title }}ลำดับที่ {{ groupIndex + 1 }}
              </h5>
              <div v-if="radioOptions.length > 0" class="d-flex">
                <div
                  v-for="(radio, radioIndex) in radioOptions"
                  :key="'radio-' + radioIndex"
                >
                  <b-form-radio-group class="mb-2" v-model="group.priorityType">
                    <b-form-radio :value="radio.value">
                      {{ radio.name }}
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
              <div class="btn-group">
                <b-button
                  size="sm"
                  variant="info"
                  @click="addPriorityMember(group)"
                >
                  เพิ่มสมาชิกลำดับ{{ title }}
                </b-button>
                <b-button
                  v-if="isGroupEnabled"
                  size="sm"
                  variant="danger"
                  @click="deletePriorityGroup(groupIndex)"
                >
                  ลบกลุ่ม
                </b-button>
              </div>
            </div>

            <table class="table table-bordered">
              <thead class="thead-light">
                <tr class="d-flex">
                  <th class="custom-width column-center">ลำดับ</th>
                  <th
                    v-for="schemas in inputSchemas"
                    :key="schemas.key"
                    class="col column-center"
                  >
                    {{ schemas.label || '' }}
                  </th>
                  <th class="custom-width column-center">Action</th>
                </tr>
              </thead>
              <draggable v-model="group.member" tag="tbody">
                <tr
                  class="d-flex"
                  v-for="(member, memberIndex) in group.member"
                  :key="
                    'member-' + (member.memberInfo.order_person = memberIndex)
                  "
                >
                  <td class="custom-width column-center">
                    {{ memberIndex + 1 }}
                  </td>
                  <td
                    class="col column-center"
                    v-for="schemas in inputSchemas"
                    :key="schemas.key"
                  >
                    <component
                      :is="getComponentType(schemas)"
                      v-bind="getComponentProps(group, member, schemas)"
                      v-model="member.memberInfo[schemas.model]"
                      v-on="getEventHandlers(group, schemas, member)"
                    >
                      <template
                        v-if="schemas.type == 'autocomplete'"
                        slot="md-autocomplete-item"
                        slot-scope="{ item }"
                      >
                        {{ item.firstname_en + ' ' + item.lastname_en }}
                      </template>
                    </component>
                  </td>

                  <td class="custom-width column-center">
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="deleteMember(group, memberIndex)"
                    >
                      ลบ
                    </b-button>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import employeeMixin from '@/mixins/employee-mixin';
import Swal from 'sweetalert2';
import { loading_close, loading_start } from '@/utils/loading.js';
import draggable from 'vuedraggable';
import Vuex from 'vuex';

export default {
  mixins: [employeeMixin],
  components: {
    draggable,
  },
  name: 'PriorityManagement',
  props: {
    priorityGroups: {
      type: Array,
      default: () => [
        {
          member: [
            {
              options: {
                departments: [],
                employees: [],
                positions: [],
              },
              memberInfo: {
                bch_id: '',
                order_person: 0,
                posname_en: '',
                depname_en: '',
                emp_full_name: '',
                dep_id: '',
                position: '',
              },
            },
          ],
          priorityType: '',
          priority: 0,
        },
      ],
    },
    inputSchemas: {
      type: Array,
      default: () => [
        {
          key: 'branch',
          label: 'สำนักงาน',
          type: 'select',
          model: 'bch_id',
          placeholder: 'เลือกสำนักงาน',
          changeEvent: { name: 'handleBranchChange', args: ['member'] },
        },
        {
          key: 'department',
          label: 'แผนก',
          type: 'select',
          model: 'dep_id',
          placeholder: 'เลือกแผนก',
          options: 'options.departments',
          changeEvent: { name: 'handleDepartments', args: ['member'] },
          disabledCondition: 'bch_id',
        },
        {
          key: 'member',
          label: 'สมาชิก',
          type: 'autocomplete',
          model: 'emp_full_name',
          placeholder: 'เลือกพนักงาน',
          options: 'options.employees',
          mdChangedEvent: {
            name: 'searchStaffs',
            args: ['priority', 'member', 'schemas'],
          },
          mdSelectedEvent: {
            name: 'selectStaff',
            args: ['event', 'priority', 'priorityType', 'member'],
          },
          disabledCondition: 'bch_id',
        },
        {
          key: 'position',
          label: 'ตำแหน่ง',
          type: 'input',
          model: 'posname_en',
          placeholder: 'Enter Position',
          changeEvent: {
            name: 'handlePositionInputChange',
            args: ['event', 'priority', 'priorityType', 'member'],
          },
          disabledCondition: 'emp_full_name',
        },
      ],
    },
    isGroupEnabled: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'ความสำคัญ',
    },
    priorityType: { type: String, default: 'priority' },
    staffRecordFields: {
      type: Array,
      default: () => [
        'bch_id',
        'emp_id',
        'dep_id',
        'posname_en:position',
        'firstname_en',
        'lastname_en',
        'firstname_th:emp_first_name',
        'lastname_th:emp_last_name',
        'order_person',
        'priority',
        'priorityType:type',
      ],
    },
    radioOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...Vuex.mapState({
      branchData: (state) => state.branches.list,
    }),
  },
  data() {
    return {
      localPriorityGroups: [],
      availableBranches: [],
      employees: {},
      staffRecords: {},
      staffLastKey: '',
      debounceTimeout: null,
    };
  },
  async created() {
    let params = { profile: true };
    this.profile = await this.$store.dispatch('staffs/searchStaff', params);
    await this.getBranches();
  },
  async mounted() {
    await this.loadDataFromBchId();
    this.$emit('onMountedPrioritySettings');
  },
  watch: {
    async priorityGroups(newVal) {
      this.localPriorityGroups = newVal;
      for (const group of this.localPriorityGroups) {
        for (const member of group.member) {
          await this.updateStaffData(member);
        }
      }
    },
  },
  methods: {
    addPriorityGroup() {
      this.localPriorityGroups.push({
        member: [
          {
            options: {
              departments: [],
              employees: [],
              positions: [],
            },
            memberInfo: {
              bch_id: '',
              order_person: 0,
              posname_en: '',
              depname_en: '',
              emp_full_name: '',
              dep_id: '',
              position: '',
            },
          },
        ],
        priorityType: this.priorityType ?? '',
        priority: 0,
      });
    },
    addPriorityMember(group) {
      return group.member.push({
        options: {
          departments: [],
          employees: [],
          positions: [],
        },
        memberInfo: {
          bch_id: '',
          order_person: 0,
          posname_en: '',
          depname_en: '',
          emp_full_name: '',
          dep_id: '',
          position: '',
        },
      });
    },
    addStaffMember(priority, priorityType, member) {
      if (!member.memberInfo?.emp_id) return;

      const key = `${priority}${member.memberInfo.order_person}`;
      this.staffLastKey = key;
      this.staffRecords[key] = {};

      for (const field of this.staffRecordFields) {
        const [originalField, newField] = field.split(':');
        const value = member.memberInfo[originalField];

        if (value || value >= 0) {
          this.staffRecords[key][newField || originalField] = value;
        }
      }

      const [priorityField, priorityAlias] = (
        this.staffRecordFields.find((f) => f.startsWith('priority')) || ''
      ).split(':');

      if (priorityField) {
        this.staffRecords[key][priorityAlias || priorityField] = priority;
      }

      const [priorityTypeField, priorityTypeAlias] = (
        this.staffRecordFields.find((f) => f.startsWith('priorityType')) || ''
      ).split(':');

      if (priorityTypeField) {
        this.staffRecords[key][priorityTypeAlias || priorityTypeField] =
          priorityType || this.priorityType;
      }

      return this.staffRecords;
    },
    clearDisabledFields({ fields, member }) {
      for (const schema of this.inputSchemas) {
        const { disabledCondition, model } = schema;

        if (disabledCondition && fields.includes(disabledCondition)) {
          member.memberInfo[model] = '';
        }
      }
    },
    clearFiltersValues({ fields, member }) {
      for (const field of fields) {
        member.memberInfo[field] = '';
      }
    },
    deleteMember(group, memberIndex) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this member?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          group.member.splice(memberIndex, 1);

          this.$emit('staffs', await this.getAll());
        }
      });
    },
    deletePriorityGroup(index) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this priority group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.localPriorityGroups.splice(index, 1);
          Swal.fire('Deleted!', 'Priority group has been deleted.', 'success');
        }
      });
    },
    formatPriorityGroups(data) {
      const priorityGroups = [];

      for (const item of data) {
        let priorityGroup = priorityGroups.find(
          (group) => group.priority == item.priority,
        );

        if (!priorityGroup) {
          priorityGroup = {
            priority: item.priority,
            priorityType: item.type || item.priorityType || this.priorityType,
            member: [],
          };
          priorityGroups.push(priorityGroup);
        }

        const memberInfo = {
          emp_id: item.emp_id,
          bch_id: item.bch_id,
          order_person: item.order_person,
          dep_id: item.dep_id,
          depname_en: item.depname_en,
          pos_id: item.pos_id,
          posname_en: item.posname_en,
          firstname_en: item.firstname_en,
          lastname_en: item.lastname_en,
          firstname_th: item.firstname_th,
          lastname_th: item.lastname_th,
          emp_full_name: `${item.firstname_en} ${item.lastname_en}`,
          position: item.position,
        };

        priorityGroup.member.push({
          options: {
            departments: [],
            employees: [],
            positions: [],
          },
          memberInfo,
        });
      }
      for (const group of priorityGroups) {
        for (const member of group.member) {
          this.updateStaffData(member);
        }
      }

      if (priorityGroups.length <= 0) {
        return this.$options.props.priorityGroups.default();
      }
      return priorityGroups;
    },
    async getAll(data) {
      if (!data) data = this.localPriorityGroups;

      this.staffRecords = {};
      for (const group of data) {
        for (const member of group.member) {
          this.addStaffMember(group.priority, group.priorityType, member);
        }
      }

      return this.staffRecords;
    },
    getAllInput(data) {
      if (!data) data = this.localPriorityGroups;
      const priority = [];
      for (const group of data) {
        for (const member of group.member) {
          const m_info = member.memberInfo;
          priority.push({
            bch_id: m_info.bch_id,
            dep_id: m_info.dep_id,
            pos_id: m_info.position,
          });
        }
      }
      return priority;
    },
    evaluateField(disableField, group, member) {
      switch (disableField) {
        case 'priority':
          return Boolean(group.priority);
        case 'priorityType':
          return Boolean(group.priorityType);
        default:
          return Boolean(member.memberInfo[disableField]);
      }
    },
    getComponentProps(group, member, schemas) {
      const props = {
        placeholder: schemas.placeholder || '',
        size: 'sm',
        disabled: schemas.disabledCondition
          ? !this.evaluateField(schemas.disabledCondition, group, member)
          : false,
        style: {},
      };

      const text = schemas?.options ? String(schemas.options) : '';
      let options = [];

      if (text) {
        const result = text.split('.');
        options =
          result.reduce((acc, key) => {
            return acc && acc[key] !== undefined ? acc[key] : undefined;
          }, member) || [];
      }

      switch (schemas.type) {
        case 'select':
          const clearOption = { value: '', text: schemas?.placeholder };
          props.options = [clearOption].concat(
            schemas.key == 'branch' ? this.availableBranches : options,
          );
          if (schemas.key === 'branch') {
            props.style.textAlign = 'left';
            props.style.textAlignLast = 'center';
          }
          break;
        case 'autocomplete':
          props.mdOptions = options;
          props.mdInputPlaceholder = schemas.placeholder;
          break;
        case 'input':
          props.value = member.memberInfo[schemas.model] || '';
          break;
      }

      return props;
    },
    getComponentType(schemas) {
      switch (schemas.type) {
        case 'input':
          return 'b-input';
        case 'select':
          return 'b-form-select';
        case 'autocomplete':
          return 'md-autocomplete';
        case 'button':
          return 'b-button';
        default:
          return 'div';
      }
    },
    getEventHandlers(group, schemas, member) {
      const eventMap = {
        selectEvent: 'select',
        changeEvent: 'change',
        mdChangedEvent: 'md-changed',
        mdSelectedEvent: 'md-selected',
      };

      return Object.keys(eventMap).reduce((handlers, eventKey) => {
        if (schemas[eventKey]) {
          handlers[eventMap[eventKey]] = (event) =>
            this.handleEvent(eventKey, event, group, schemas, member);
        }
        return handlers;
      }, {});
    },
    async getStaff(member) {
      const { bch_id } = member.memberInfo;
      const com_id = this.profile?.[0]?.com_id;

      if (this.employees?.[bch_id]) {
        await this.updateStaffData(member);
        return;
      }
      loading_start();
      try {
        const data = await this.$store.dispatch('staffs/searchStaff', {
          com_id,
          bch_id,
        });

        if (!this.employees) {
          this.employees = {};
        }
        if (!this.employees[bch_id]) {
          this.employees[bch_id] = {};
        }

        for (const employee of data) {
          this.organizeEmployeeData(bch_id, employee);
        }

        await this.updateStaffData(member);
      } catch (error) {
        throw new Error(`Error fetching staff: ${error}`);
      } finally {
        loading_close();
      }
    },
    async getBranches() {
      try {
        const branches = await this.getBranchData();

        if (Array.isArray(branches) && branches.length > 0) {
          this.availableBranches = branches.map(({ id, br_nameth }) => ({
            value: id,
            text: br_nameth,
          }));
        } else {
          this.availableBranches = [];
          throw new Error('No branches available');
        }
      } catch (error) {
        this.availableBranches = [];
        throw new Error(`Error fetching branches: ${error}`);
      }
    },
    handleBranchChange({ member }) {
      member.memberInfo.position = '';
      member.memberInfo.dep_id = '';
      member.memberInfo.posname_en = '';
      member.memberInfo.depname_en = '';
      member.memberInfo.emp_full_name = '';
      this.getStaff(member);
    },
    handleDepartments({ member }) {
      member.memberInfo.position = '';
      member.memberInfo.emp_full_name = '';

      this.setPositions(member);
      this.setEmployeeData(member);
    },
    handlePositions({ member }) {
      // member.memberInfo.emp_full_name = '';
      const branchId = member.memberInfo.bch_id;
      const targetPositionId = member.memberInfo.position;
      const departments = Object.values(this.employees[branchId]);

      const depId = departments.reduce((acc, dep) => {
        if (acc) return acc;

        const hasTargetPosition = Object.values(dep?.positions).some(
          (pos) => pos.pos_id == targetPositionId,
        );

        return hasTargetPosition ? dep.dep_id : null;
      }, null);

      if (depId) member.memberInfo.dep_id = depId;
      this.setPositions(member);
      this.setEmployeeData(member);
    },
    handlePositionInputChange({ event, priority, priorityType, member }) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        () =>
          this.$emit(
            'staffs',
            this.addStaffMember(priority, priorityType, member),
          ),
        500,
      );
    },
    async loadDataFromBchId() {
      const memberWithBchId = this.localPriorityGroups.flatMap((group) =>
        group.member.filter((member) => member.memberInfo?.bch_id),
      );

      if (memberWithBchId.length > 0) {
        memberWithBchId.map(async (member) => {
          await this.getStaff(member);
        });
      }
    },
    organizeEmployeeData(bch_id, employee) {
      const {
        com_id,
        dep_id,
        depname_en,
        emp_id,
        firstname_en,
        lastname_en,
        firstname_th,
        lastname_th,
        pos_id,
        posname_en,
      } = employee;

      const fullName = `${firstname_en} ${lastname_en}`;

      if (!this.employees[bch_id][dep_id]) {
        this.employees[bch_id][dep_id] = {
          dep_id,
          depname_en,
          positions: {},
        };
      }
      const department = this.employees[bch_id][dep_id];
      if (!department?.positions[pos_id]) {
        department.positions[pos_id] = { pos_id, posname_en, employees: {} };
      }

      department.positions[pos_id].employees[emp_id] = {
        com_id,
        dep_id,
        bch_id,
        depname_en,
        emp_id,
        firstname_en,
        lastname_en,
        firstname_th,
        lastname_th,
        pos_id,
        posname_en,
        emp_full_name: fullName,
      };
    },
    searchStaffs({ priority, member, schemas }) {
      if (member.memberInfo?.emp_full_name == '') {
        this.clearDisabledFields({ fields: [schemas.model], member });
        this.clearFiltersValues({ fields: [schemas.model, 'pos_id'], member });
        this.setEmployeeData(member);
        const key = `${priority}${member.memberInfo.order_person}`;
        delete this.staffRecords[key];
        this.$emit('staffs', this.staffRecords);
      }
    },
    selectStaff({ event, priority, priorityType, member }) {
      member.memberInfo = {
        ...event,
        position: event.position || event.pos_id,
        emp_full_name: event.emp_full_name,
        order_person: member.memberInfo.order_person,
      };

      this.$emit('staffs', this.addStaffMember(priority, priorityType, member));
    },
    setDepartments({ memberInfo, options }) {
      if (this.employees[memberInfo.bch_id]) {
        options.departments = Object.values(
          this.employees[memberInfo.bch_id],
        ).map((dep) => ({
          value: dep.dep_id,
          text: dep.depname_en,
        }));
      }
    },
    setEmployeeData({ options, memberInfo }) {
      const branch = this.employees?.[memberInfo.bch_id];

      if (!branch) return;

      if (memberInfo.dep_id) {
        const department = branch[memberInfo.dep_id];

        if (department) {
          if (memberInfo.pos_id) {
            const position = department?.positions[memberInfo?.pos_id];
            if (position) {
              options.employees = Object.values(position.employees);
            } else {
              options.employees = [];
            }
          } else {
            options.employees = Object.values(
              department?.positions,
            ).flatMap((pos) => Object.values(pos.employees));
          }
        } else {
          options.employees = [];
        }
      } else if (memberInfo?.pos_id) {
        options.employees = Object.values(branch).flatMap((dep) => {
          return Object.values(dep.positions).flatMap((pos) => {
            return pos.pos_id == memberInfo?.pos_id
              ? Object.values(pos.employees)
              : [];
          });
        });
      } else {
        options.employees = Object.values(branch).flatMap((dep) => {
          return Object.values(dep.positions).flatMap((pos) =>
            Object.values(pos.employees),
          );
        });
      }
    },
    setPositions({ memberInfo, options }) {
      if (this.employees[memberInfo.bch_id]) {
        const positions = {};
        const { dep_id } = memberInfo;

        if (dep_id && this.employees[memberInfo.bch_id][dep_id]) {
          const department = this.employees[memberInfo.bch_id][dep_id];

          for (const [pos_id, position] of Object.entries(
            department?.positions,
          )) {
            positions[pos_id] = position.posname_en;
          }
        } else {
          for (const dep of Object.values(this.employees[memberInfo.bch_id])) {
            for (const [pos_id, position] of Object.entries(dep?.positions)) {
              if (!positions[pos_id]) {
                positions[pos_id] = position.posname_en;
              }
            }
          }
        }

        options.positions = Object.entries(positions).map(
          ([pos_id, posname_en]) => ({
            value: pos_id,
            text: posname_en,
          }),
        );
      } else {
        options.positions = [];
      }
    },
    async updateStaffData(member) {
      if (
        !this.employees[member.memberInfo.bch_id] &&
        this.localPriorityGroups.length > 0
      ) {
        await this.getStaff(member);
      }
      this.setDepartments(member);
      this.setPositions(member);
      this.setEmployeeData(member);
    },
    handleEvent(eventType, event, { priority, priorityType }, schemas, member) {
      const eventConfig = schemas[eventType];

      if (!eventConfig || typeof this[eventConfig.name] !== 'function') {
        throw new Error(
          `Unknown event type or handler not found: ${eventType}`,
        );
      }

      const args = eventConfig.args.reduce((acc, arg) => {
        acc[arg] = eval(arg);

        return acc;
      }, {});

      this[eventConfig.name](args);
    },
  },
};
</script>
<style lang="scss">
.priority-settings {
  .md-input {
    background-color: white !important;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .md-input:disabled {
    color: #6c757d;
    background-color: #e9ecef !important;
    border-radius: 0.25rem;
  }

  .md-field .md-input-action {
    top: 5px;
    right: 8px;
  }

  .md-field .md-input-action {
    width: 25px;
    min-width: 25px;
    height: 25px;
  }

  .custom-select {
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    border: 1px solid #ced4da;
  }
}
</style>
<style lang="scss" scoped>
.priority-settings {
  .column-center {
    text-align: center;
  }

  .custom-width {
    width: 5%;
  }

  .group-container {
    margin-bottom: 1rem;
  }

  .group {
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 0.5rem;
  }

  .column-center {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
</style>
