<template>
  <div class="pdfme-form" v-show="hidden">
    <slot name="form-header"></slot>

    <div ref="editor" class="editor"></div>

    <slot name="form-body"></slot>
  </div>
</template>

<script>
import { Form } from '@pdfme/ui';
import PdfmeHelperMixin from '@/components/Pdfme/plugins/PdfmeHelperMixin';
import { getInputFromTemplate } from '@pdfme/common';

export default {
  mixins: [PdfmeHelperMixin],
  props: {
    template: {
      type: Object,
      default: () => ({
        basePdf: {
          width: 210,
          height: 297,
          padding: [10, 10, 10, 10],
        },
        schemas: [[]],
      }),
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formInstance: null,
    };
  },
  async mounted() {
    await this.init();
  },
  watch: {
    async template(val) {
      // this.formInstance.
      await this.init(val);
    },
  },
  methods: {
    async init(template = this.template) {
      const container = this.$refs.editor;
      if (container) {
        const font = await this.getFontsData();
        this.formInstance = new Form({
          domContainer: container,
          template,
          inputs: getInputFromTemplate(this.template),
          options: {
            font,
            labels: {
              'signature.clear': '🗑️',
            },
            theme: {
              token: { colorPrimary: '#25c2a0' },
            },
            icons: {
              text: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="4 7 4 4 20 4 20 7"></polyline>
                        <line x1="9" x2="15" y1="20" y2="20"></line>
                        <line x1="12" x2="12" y1="4" y2="20"></line>
                     </svg>`,
              multiVariableText: `<svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24">
                                    <path d="M6.643,13.072,17.414,2.3a1.027,1.027,0,0,1,1.452,0L20.7,4.134a1.027,1.027,0,0,1,0,1.452L9.928,16.357,5,18ZM21,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"/>
                                  </svg>`,
            },
            maxZoom: 250,
          },
          plugins: this.getPlugins(),
        });
      }
    },
  },
};
</script>

<style scoped>
.editor {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 8px;
}
</style>
