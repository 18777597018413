<template>
  <div class="md-layout">
    <!-- Progress bar -->
    <vue-progress-bar></vue-progress-bar>
    <div class="md-layout-item md-size-100">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <h4 class="title"></h4>
      </md-card-header>
      <md-card-content>
        <!-- Filter employee -->
        <filter-employee
          :headLabel="headLabel"
          :branchId="branches"
          :permissionsList="permissions"
          @childBranches="setBranches"
          @childDepartment="setDepartments"
          @childStaffs="setStaffs"
        >
        </filter-employee>

        <!-- Table data -->
        <b-container fluid class="bv-example-row mb-3">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="3"
              class="center"
              v-for="(data, index) in show_data_form_search"
              :key="index"
            >
              <div class="imgclass">
                <b-img
                  class="img_card"
                  thumbnail
                  fluid
                  :src="`${data.images}`"
                  alt="Image"
                />
              </div>
              <div>
                {{ data.employee_id }}<br />
                {{ data.title_nameth }}{{ data.first_nameth }}
                {{ data.last_nameth }}
              </div>
            </b-col>
          </b-row>
        </b-container>
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <pagination
          class="pagination-no-border pagination-success"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </md-card-actions>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Pagination from '@/components/Pagination';
import Swal from 'sweetalert2';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import Vuex from 'vuex';

export default {
  components: {
    'pagination': Pagination,
    'filter-employee': FilterEmployee,
  },
  mixins: [employeeMixin],

  data() {
    return {
      headLabel: 'เลือกพนักงาน',
      tableData: [],
      query: null,
      employee_id: null,
      show_data_form_search: [],
      // branch: null,
      sortation: {
        field: 'emp_id',
        order: 'asc',
      },

      pagination: {
        currentPage: 1,
        perPage: 12,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      branches: [],
      branchid: null,
      departments: null,
      avatar: process.env.VUE_APP_APP_BASE_URL + '/img/default-avatar.png',
      //permissions: [],
    };
  },

  props: {
    employeeID: null,
    branchID: Object,
  },

  computed: {
    sort() {
      if (this.sortation.order === 'desc') {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      let pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }

      return pms;
    },
  },
  created() {
    this.branches = this.$route.params.branchid;
    if (this.$route.params.page) {
      this.pagination.currentPage = this.$route.params.page.currentPage;
      this.pagination.perPage = this.$route.params.page.size;
    }
  },

  watch: {
    query: {
      handler: 'getListDebounced',
      immediate: true,
    },
    sortation: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },
    pagination: {
      handler: 'filterPage',
      immediate: false,
      deep: true,
    },
    branches: {
      handler: 'getList',
      immediate: false,
      deep: true,
    },
    departments: {
      handler: 'getList',
      immediate: false,
      deep: true,
    },
    employee_id: {
      handler: 'getList',
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function() {
      this.getList();
    }, 100),

    async getList() {
      this.start();
      if (this.branches) {
        let data = {
          ...{ bch_id: this.branches ? this.branches : {} },
          ...{ dep_id: this.departments ? this.departments : {} },
          ...{ emp_id: this.employee_id ? this.employee_id : {} },
        };
        var t_Data = await this.$store.dispatch('staffs/searchImgStaff', data);
        t_Data.forEach((rp_data, ind) => {
          if (rp_data.profile_image !== '') {
            rp_data['images'] =
              process.env.VUE_APP_IMAGE_BASE_URL + rp_data.profile_image;
          } else {
            rp_data['images'] = this.avatar;
          }
        });
        this.tableData = t_Data;
        this.total = this.tableData.length;
        this.filterPage();
      } else {
        this.finish();
      }
    },

    filterPage() {
      this.show_data_form_search = [];
      var a =
        this.pagination.currentPage > 1
          ? this.pagination.currentPage * this.pagination.perPage -
            this.pagination.perPage
          : 0;
      var b =
        this.pagination.perPage +
        (this.pagination.currentPage * this.pagination.perPage -
          this.pagination.perPage);
      var data = [];
      $.each(this.tableData, function(index, el) {
        if (index >= a && index < b) {
          data.push(el);
        }
      });
      this.show_data_form_search = data;
    },

    async destroy(id) {
      const confirmation = await Swal.fire({
        title: 'Delete this user?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        // await this.$store.dispatch("users/destroy", id)
        // await this.$store.dispatch("alerts/success", "User deleted successfully.")
        await this.getList();
      }
    },

    goToEdit(id) {
      this.$router.push({
        path: 'edit-user/:id',
        name: 'Edit User',
        params: {
          id: id,
          branchid: this.branches ? this.branches : null,
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        },
      });
    },

    customSort() {
      return false;
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      if (v) {
        this.employee_id = null;
        this.branches = v;
      }
    },

    /**
     * set branch value from child component
     */
    setDepartments(v) {
      if (v) {
        this.employee_id = null;
        this.departments = v;
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employee_id = v.id;
      }
    },

    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },

  // beforeDestroy() {
  //   this.branches.destroy;
  //   this.tableData.destroy;
  // },
};
</script>
<style lang="scss" scoped>
.center {
  text-align: center;
  padding: 5px;
}
.imgclass {
  text-align: center;
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: inline-block;
}
.img_card {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
}
</style>
