<template>
  <div>
    <vue-progress-bar></vue-progress-bar>
    <md-dialog :md-active.sync="showImg" md-fullscreen>
      <md-dialog-title> ไฟล์รูป </md-dialog-title>
      <md-dialog-content v-if="tranfer64">
        <b-button
          v-if="tranfer64[1] == 'pdf'"
          variant="info"
          download="PDF-LP"
          target="_blank"
          :href="tranfer64[0]"
        >
          Download PDF
        </b-button>
        <img
          class="canzoom"
          v-else-if="tranfer64[1] == 'pic'"
          @click="zoomedImg($event.currentTarget)"
          :src="tranfer64[0]"
        />
        <video
          class="canzoom"
          controls
          v-else-if="tranfer64[1] == 'video'"
          @click="zoomedImg($event.currentTarget)"
          :src="tranfer64[0]"
        />
      </md-dialog-content>
      <md-dialog-content v-else>
        ไม่มีรูป
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showImg = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="showEditLeave" md-fullscreen>
      <md-dialog-title>แก้ไขการลา</md-dialog-title>
      <md-dialog-content>
        <span v-for="(item, index) in editLeaveData" :key="index">
          {{ item }}
        </span>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showEditLeave = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <div v-if="check_progress" class="md-layout">
      <div
        class="md-layout-item md-size-20 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
      >
        <md-field>
          <label for="year">ปี (Years)</label>
          <md-select v-model="years">
            <md-option v-for="item in listyear" :key="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>

      <div
        class="md-layout-item md-size-20 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
      >
        <md-button class="md-success" @click="refresh()">REFRESH</md-button>
      </div>

      <div
        class="md-layout-item md-size-100"
        v-if="
          (years >= 2021 || devtool) && !permissions.includes('cannotleave')
        "
      >
        <leavecomholiday
          @createleave="submit"
          @editLeave="editLeave"
          @deleteLeave="deleteLeave"
          :old_leave="false"
          :permissions="permissions"
          :emp_id="profileMe[0].id"
          :leaveholidays="leave_holidays"
          :years="years"
          :persons="persons"
        >
        </leavecomholiday>
      </div>

      <div
        class="md-layout-item md-size-100"
        v-if="
          (years >= 2021 || devtool) && !permissions.includes('cannotleave')
        "
      >
        <leavecomsick
          @alertimg="alert_img"
          @createleave="submit"
          @editLeave="editLeave"
          @deleteLeave="deleteLeave"
          :old_leave="old_leave"
          :permissions="permissions"
          :leavesicks="leave_sick"
          :totaldatesick="total_date_sick"
          :disableddate="disabled_date"
          :persons="persons"
        >
        </leavecomsick>
      </div>

      <div
        class="md-layout-item md-size-100"
        v-if="
          (years >= 2021 || devtool) && !permissions.includes('cannotleave')
        "
      >
        <leavecomannual
          v-if="total_date_annual != null"
          @alertimg="alert_img"
          @createleave="submit"
          @editLeave="editLeave"
          @deleteLeave="deleteLeave"
          :old_leave="old_leave"
          :permissions="permissions"
          :leaveannuals="leave_annual"
          :totaldateannual="total_date_annual"
          :persons="persons"
        >
        </leavecomannual>
      </div>

      <div
        class="md-layout-item md-size-100"
        v-if="
          ((years >= 2021 && profileMe[0].stafftype == 'month') || devtool) &&
            !permissions.includes('cannotleave')
        "
      >
        <leavecomcompassionate
          v-if="total_date_compassionate != null"
          @createleave="submit"
          @editLeave="editLeave"
          @deleteLeave="deleteLeave"
          :old_leave="old_leave"
          :permissions="permissions"
          :leavecompassionates="leave_compassionate"
          :totaldatecompassionate="total_date_compassionate"
          :persons="persons"
        >
        </leavecomcompassionate>
      </div>

      <div class="md-layout-item md-size-100">
        <leavecomchangeday
          ref="leavecomchangeday"
          @createleave="submit"
          @editLeave="editLeave"
          @deleteLeave="deleteLeave"
          @deleteKeepday="deleteKeepday"
          :old_leave="false"
          :permissions="permissions"
          :leavechangedayoff="leave_changedayoff"
          :totaldatechangedayoff="total_date_changedayoff"
          :persons="persons"
          :profileMe="profileMe[0]"
          :years="years"
        >
        </leavecomchangeday>
      </div>

      <div
        class="md-layout-item md-size-100"
        v-if="
          (years >= 2021 || devtool) && !permissions.includes('cannotleave')
        "
      >
        <leavecomother
          @alertimg="alert_img"
          @createleave="submit"
          :old_leave="old_leave"
          :permissions="permissions"
          :leaveothers="leave_other"
          :availableleaves="available_leaves"
          :totaldateother="total_date_other"
          :persons="persons"
        >
        </leavecomother>
      </div>

      <div class="md-layout-item md-size-100" v-if="years >= 2021 || devtool">
        <leavecomcomwithoutpay
          @createleave="submit"
          @editLeave="editLeave"
          @deleteLeave="deleteLeave"
          :old_leave="old_leave"
          :permissions="permissions"
          :leavewithoutpay="leave_withoutpay"
          :totaldatewithoutpay="total_date_withoutpay"
          :persons="persons"
        >
        </leavecomcomwithoutpay>
      </div>
      <div class="md-layout-item md-size-100" v-if="years >= 2021 || devtool">
        <leavecomoutthepremises
          ref="leavecomoutthepremises"
          :permissions="permissions"
          :profileMe="profileMe[0]"
          :years="years"
        >
        </leavecomoutthepremises>
      </div>
    </div>
    <div v-else-if="check_progress == false">
      <b-container fluid>
        <h3 style="color:#FF8000">{{ error_show }}</h3>
      </b-container>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import Vuex from 'vuex';
import employeeMixin from '@/mixins/employee-mixin';

// import { extend } from 'vee-validate';
// import { required } from 'vee-validate/dist/rules';
// extend('required', required);

import LeaveComHoliday from './Components/LeaveComHoliday.vue';
import LeaveComSick from './Components/LeaveComSick.vue';
import LeaveComCompassionate from './Components/LeaveComCompassionate.vue';
import LeaveComWithoutpay from './Components/LeaveComWithoutpay.vue';
import LeaveComChangeday from './Components/LeaveComChangeday.vue';
import LeaveComOther from './Components/LeaveComOther.vue';
import LeaveComAnnual from './Components/LeaveComAnnual.vue';
import LeaveComOutThePremises from './Components/LeaveComOutThePremises.vue';
import Swal from 'sweetalert2';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {
    leavecomholiday: LeaveComHoliday,
    leavecomsick: LeaveComSick,
    leavecomchangeday: LeaveComChangeday,
    leavecomother: LeaveComOther,
    leavecomcompassionate: LeaveComCompassionate,
    leavecomannual: LeaveComAnnual,
    leavecomcomwithoutpay: LeaveComWithoutpay,
    leavecomoutthepremises: LeaveComOutThePremises,
  },
  mixins: [employeeMixin],
  data() {
    return {
      listyear: [],
      devtool: false, //เอาไว้ debug ก่อนอัพให้ set เป็น false
      once_hide: false,
      profile: [],
      leave_list: [],
      available_leaves: [],
      total_leave_all: [],
      leave_name: null,
      can_leave: false,
      date_amount_leave: 0,
      disabled_leave: false,
      disabled_leavedata: true,

      showDialog: false,
      showImg: false,
      showEditLeave: false,
      editLeaveData: null,

      is_samedate: false,
      years: moment().year(),
      is_leavestart_half: false,
      is_leaveend_half: false,
      file_size: 0,
      attach_filepath: null,
      tranfer64: null,
      notes: '',
      alertimgbase64: null,
      employee_leave: {
        type: 'employeeleaves',
        modification_id: null,
        employee_id: null,
        leave_id: null,
        from_date: null,
        from_date_type: 'f',
        to_date: null,
        to_date_type: 'f',
        attach_filepath: null,
        approve_status: 'leaverequest',
        approver_require: 1,
        requestdate: null,
        notes: '',
      },

      select_Daychange: null,
      dayoff_Data: [],

      // sort_leave
      leave_sick: [],
      leave_compassionate: [],
      leave_annual: [],
      leave_withoutpay: [],
      leave_changedayoff: [],
      leave_holidays: [],
      leave_other: [],

      // total_date
      total_date_sick: null,
      total_date_compassionate: null,
      total_date_annual: null,
      total_date_withoutpay: null,
      total_date_changedayoff: null,
      total_date_other: null,

      remain_leave: [],
      old_leave: false,
      check_progress: false,
      error_show: '',
      reset_form: false,
      disabled_date: [],
      profileMe: null,
    };
  },
  props: {
    persons: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    async years(val) {
      this.refresh(val);
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      let pms = [];
      if (this.me) {
        this.me.permissions.map((permis) => {
          pms.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          pms.push(permission.name);
        });
      }
      return pms;
    },
  },
  async created() {
    await this.getLeave();
    this.createlistyear();
  },

  methods: {
    zoomedImg(element) {
      element.classList.toggle('zoomed');
    },

    async refresh(val) {
      let year = this.years;
      if (val) {
        year = val;
      }
      await this.getLeave();
      await this.$refs.leavecomchangeday.getKeepJoinLeave();
      await this.$refs.leavecomoutthepremises.getHistory();
      if (moment().year() > year) {
        this.old_leave = true;
      } else {
        this.old_leave = false;
      }
    },

    createlistyear() {
      var arr = [];
      for (
        let index = moment(this.profileMe[0].start_date).year();
        index <=
        moment()
          .add(1, 'M')
          .year();
        index++
      ) {
        arr.push(index);
      }
      this.listyear = arr;
    },

    async getLeave() {
      //start progress bar
      this.start();
      loading_start();
      if (this.persons) {
        this.profileMe = await this.getProfileData(this.persons);
      } else {
        this.profileMe = await this.getProfileData();
        // this.profileMe = this.me;
      }
      if (
        this.profileMe[0].start_date != '0000-00-00' &&
        this.profileMe[0].start_date
      ) {
        var r = await this.calMonthWorkingAge(this.profileMe[0].start_date);
      } else {
        this.error_show =
          'ระบบตรวจไม่พบวันที่เริ่มงานจึงไม่สามารถคำนวณอายุงานได้ กรุณาแจ้ง ADMIN เพื่อแก้ไข';
        this.fail();
        return;
      }
      this.remain_leave = [];
      // if (this.years == moment().year()) {
      this.remain_leave = await this.getLeaveRemain(this.profileMe[0].emp_id);
      // }
      await this.getLeaveData(r, this.profileMe[0].pos_id);
      await this.getStaffLeaveList(this.profileMe[0]);
      await this.get_sort_leave();
      await this.getTotalDateLeave();
      loading_close();
      this.finish();
    },

    async editLeave(leave, type = '') {
      this.showEditLeave = true;
      this.editLeaveData = { ...leave };
      this.editLeaveData['type'] = type;
      return;
    },

    async deleteLeave(leave) {
      let l_type = this.available_leaves.find((el) => el.id == leave.leave_id);
      let text =
        l_type.leave_nameth + ': ' + leave.from_date + ' - ' + leave.to_date;
      const confirmation = await Swal.fire({
        title: 'Are you sure delete this?',
        text: text,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'md-button md-success',
          cancelButton: 'md-button md-danger',
        },
        preConfirm: () => {},
      });
      if (confirmation.value === true) {
        let params = {
          id: leave.id,
          modification_id: leave.modification_id,
          approve_status: leave.approve_status,
        };
        let res = await this.$store.dispatch('staffleaves/deleteLeave', params);
        if (res.status == '200') {
          loading_start();
          await this.getLeave();
          await this.$refs.leavecomchangeday.getKeepJoinLeave();
          loading_close();
          this.$store.dispatch('alerts/success', 'ลบรายการสำเร็จ!');
        } else {
          this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
        }
      }
    },

    async deleteKeepday(keep) {
      let text =
        'วันเก็บ' +
        ': ' +
        keep.from_date_format +
        ' ' +
        keep.from_date_time +
        ' - ' +
        keep.to_date_time +
        (keep.keep_type == 'f'
          ? ' เต็มวัน'
          : keep.keep_type == 'hm'
          ? ' ครึ่งเช้า'
          : ' ครึ่งบ่าย');
      const confirmation = await Swal.fire({
        title: 'Are you sure delete this?',
        text: text,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'md-button md-success',
          cancelButton: 'md-button md-danger',
        },
        preConfirm: () => {},
      });
      if (confirmation.value === true) {
        let params = {
          id: keep.id,
          modification_id: keep.modification_id,
          approve_status: keep.approve_status,
        };
        let res = await this.$store.dispatch('keepdays/deleteKeepday', params);
        if (res.status == '200') {
          loading_start();
          await this.$refs.leavecomchangeday.getKeepJoinLeave();
          loading_close();
          this.$store.dispatch('alerts/success', 'ลบรายการสำเร็จ!');
        } else {
          this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
        }
      }
    },

    async calMonthWorkingAge(startdate) {
      // const format = 'YYYY-MM-DD';
      let start_date = moment(startdate);
      var current_date;
      current_date = moment();
      return current_date.diff(start_date, 'months');
    },

    // get leave remain
    async getLeaveRemain(emp_id) {
      var previousyear = this.years - 1;
      // moment()
      // .subtract(1, 'years')
      // .year();
      let params = {
        ...{ years: previousyear },
        ...{ emp_id: emp_id },
      };
      let remain = await this.$store.dispatch(
        'staffleaves/staffleaveremain',
        params,
      );
      return remain;
    },

    // get leave data
    async getLeaveData(workingage, pos_id) {
      let params = {
        ...{ pos_id: pos_id },
        ...{ working_age: workingage },
      };
      this.available_leaves = await this.$store.dispatch('leaves/list', params);
      // this.available_leaves = await this.$store.getters['leaves/dropdown'];
    },
    // get staff leave list
    async getStaffLeaveList(employee) {
      var year = this.years; // current year

      if (employee) {
        var params = {
          filter: {
            ...{ year: year },
            ...{ company_id: employee.com_id },
            ...{ emp_id: employee.emp_id },
          },
        };
      } else {
        var params = {
          filter: {
            ...{ year: year },
            ...{ company_id: this.profileMe[0].com_id },
            ...{ emp_id: this.profileMe[0].emp_id },
          },
        };
      }

      this.leave_list = await this.$store.dispatch(
        'staffleaves/staffleavelist',
        params,
      );
      if (this.leave_list) {
        this.leave_list.map((leave) => {
          var fdate = moment(leave.from_date, ['DD/MM/YYYY', 'YYYY-MM-DD']);
          let tdate = moment(leave.to_date, ['DD/MM/YYYY', 'YYYY-MM-DD']).add(
            1,
            'days',
          );
          let datediff = tdate.diff(fdate, 'days');
          if (datediff < 2) {
            this.disabled_date[fdate.format('YYYY-MM-DD')] = fdate.format(
              'YYYY-MM-DD',
            );
            // this.disabled_date.push(fdate.format('YYYY-MM-DD'));
          } else {
            var result;
            for (let i = 0; i < datediff; i++) {
              if (i == 0) {
                result = fdate.format('YYYY-MM-DD');
              } else {
                result = fdate.add(1, 'days').format('YYYY-MM-DD');
              }
              this.disabled_date[result] = result;
              // this.disabled_date.push(result);
            }
          }
        });
      }
      this.disabled_leavedata = false;
    },

    async get_sort_leave() {
      this.leave_sick = [];
      this.leave_compassionate = [];
      this.leave_annual = [];
      this.leave_withoutpay = [];
      this.leave_changedayoff = [];
      this.leave_holidays = [];
      this.leave_other = [];
      if (this.leave_list) {
        await this.leave_list.map(async (d) => {
          await this.sort_leave(d);
        });
      }
    },

    sort_leave(data) {
      data.from_date_bfformat = data.from_date;
      data.to_date_bfformat = data.to_date;
      data.from_date = moment(data.from_date, [
        'YYYY-MM-DD',
        'DD/MM/YYYY',
      ]).format('DD/MM/YYYY');
      data.to_date = moment(data.to_date, ['YYYY-MM-DD', 'DD/MM/YYYY']).format(
        'DD/MM/YYYY',
      );
      data.requestdate = moment(data.requestdate, [
        'YYYY-MM-DD',
        'DD/MM/YYYY',
      ]).format('DD/MM/YYYY');
      data.created_at = moment(data.created_at, [
        'YYYY-MM-DD',
        'DD/MM/YYYY',
      ]).format('DD/MM/YYYY');
      if (data.leave_id == 1) {
        this.leave_sick.push(data);
      } else if (data.leave_id == 2) {
        this.leave_compassionate.push(data);
      } else if (
        data.leave_id == 3 ||
        data.leave_id == 4 ||
        data.leave_id == 5
      ) {
        this.leave_annual.push(data);
      } else if (data.leave_id == 6) {
        this.leave_withoutpay.push(data);
      } else if (data.leave_id == 7) {
        this.leave_changedayoff.push(data);
      } else if (data.leave_id == 12) {
        this.leave_holidays.push(data);
      } else {
        this.leave_other.push(data);
      }
    },

    async getTotalDateLeave() {
      if (this.available_leaves) {
        this.total_date_other = [];
        this.total_leave_all = [];
        const promises = this.available_leaves.map(async (leave) => {
          let l = {};
          let remain = this.remain_leave.find((rl) => {
            if (leave.id == 3 || leave.id == 4 || leave.id == 5) {
              return rl.leave_id;
            } else {
              return rl.leave_id == leave.id;
            }
          });
          l.leave_remain = 0;
          if (typeof remain !== 'undefined' && Object.keys(remain).length > 0) {
            l.leave_remain = parseFloat(remain.remain);
          }

          l.id = leave.id;
          l.name = leave.leave_nameth;
          l.leave_day = leave.leave_day;
          l.pay_day = leave.pay_day;
          l.working_age = leave.working_age;
          l.total_leave = await this.TotalDateLeave(leave);

          if (l.id == 1) {
            this.total_date_sick = l;
          } else if (l.id == 2) {
            this.total_date_compassionate = l;
          } else if (l.id == 3 || l.id == 4 || l.id == 5) {
            this.total_date_annual = l;
          } else if (l.id == 6) {
            this.total_date_withoutpay = l;
          } else if (l.id == 7) {
            this.total_date_changedayoff = l;
          } else if (l.id == 12) {
          } else {
            this.total_date_other.push(l);
          }
          this.total_leave_all.push(l);
        });
        await Promise.all(promises);
      }
    },

    async TotalDateLeave(leave) {
      if (this.leave_list) {
        const leaveResult = this.leave_list.filter((leave_list) => {
          if (leave.id == 3 || leave.id == 4 || leave.id == 5) {
            return (
              leave_list.leave_id == 3 ||
              leave_list.leave_id == 4 ||
              leave_list.leave_id == 5
            );
          }
          return leave_list.leave_id == leave.id;
        });
        if (leave.id == 7 || leave.id == 12) {
          if (leaveResult.length) {
            // no leave record in this year with return default leave day
            return leaveResult.length;
          } else {
            return leaveResult.length;
          }
        } else {
          if (!leaveResult) {
            // no leave record in this year with return default leave day
            return 0;
          } else {
            // return amount leave record - defaual leave day
            let leave_amount = leaveResult.reduce((sum, leave) => {
              let fdate = moment(leave.from_date, ['DD/MM/YYYY', 'YYYY-MM-DD']);
              let tdate = moment(leave.to_date, [
                'DD/MM/YYYY',
                'YYYY-MM-DD',
              ]).add(1, 'days');
              let datediff = tdate.diff(fdate, 'days');
              if (
                leave.from_date_type === 'f' &&
                leave.to_date_type === 'f' &&
                (leave.approve_status === 'approve' ||
                  leave.approve_status === 'leaverequest')
              ) {
                return sum + datediff;
              } else if (
                leave.from_date_type != 'f' &&
                leave.to_date_type != 'f' &&
                (leave.approve_status === 'approve' ||
                  leave.approve_status === 'leaverequest')
              ) {
                return sum + datediff - 0.5;
              } else if (leave.approve_status === 'disapprove') {
                return sum + 0;
              } else {
                return sum + datediff;
              }
            }, 0);
            return leave_amount;
          }

          // if (leave_con.leave_day - leave_amount > 0) {
          //   this.date_amount_leave = leave_con.leave_day - leave_amount;
          // } else {
          //   this.date_amount_leave = 0;
          // }
        }
      } else {
        return 0;
      }
    },

    async getDateAmountLeave(leave_id) {
      this.date_amount_leave = 0;

      let leave_con = this.available_leaves.find((leave) => {
        return leave.id == leave_id;
      });

      let remain = this.remain_leave.find((rl) => {
        if ([3, 4, 5].includes(leave_id)) {
          return [3, 4, 5].includes(rl.leave_id);
        } else {
          return rl.leave_id == leave_id;
        }
      });

      if (typeof remain !== 'undefined' && Object.keys(remain).length > 0) {
        this.date_amount_leave += parseFloat(remain.remain);
      }

      this.leave_name = leave_con.name;
      let leaveResult = [];
      if (this.leave_list) {
        leaveResult = this.leave_list.filter((l) => {
          return l.leave_id == leave_con.id;
        });
      }

      if (leave_id == 7) {
        return Promise.resolve((this.date_amount_leave += leaveResult.length));
      }
      if (!leaveResult) {
        // no leave record in this year with return default leave day
        return Promise.resolve((this.date_amount_leave += leave_con.leave_day));
      } else {
        // return amount leave record - defaual leave day
        let leave_amount = await leaveResult.reduce((sum, leave) => {
          let fdate = moment(leave.from_date, 'DD/MM/YYYY');

          let tdate = moment(leave.to_date, 'DD/MM/YYYY').add(1, 'days');
          let datediff = tdate.diff(fdate, 'days');

          if (
            leave.from_date_type === 'f' &&
            leave.to_date_type === 'f' &&
            (leave.approve_status === 'approve' ||
              leave.approve_status === 'leaverequest')
          ) {
            return sum + datediff;
          } else if (
            leave.from_date_type != 'f' &&
            leave.to_date_type != 'f' &&
            (leave.approve_status === 'approve' ||
              leave.approve_status === 'leaverequest')
          ) {
            return sum + datediff - 0.5;
          } else if (leave.approve_status === 'disapprove') {
            return sum + 0;
          } else {
            return sum + datediff;
          }
        }, 0);
        return Promise.resolve(
          (this.date_amount_leave +=
            leave_con.leave_day - leave_amount > 0
              ? leave_con.leave_day - leave_amount
              : 0),
        );
        // if (leave_con.leave_day - leave_amount > 0) {
        //   this.date_amount_leave = leave_con.leave_day - leave_amount;
        // } else {
        //   this.date_amount_leave = 0;
        // }
      }
    },

    async checkStaffCanLeave() {
      if (
        this.employee_leave.from_date &&
        this.employee_leave.to_date &&
        this.employee_leave.leave_id
      ) {
        let fdate = moment(this.employee_leave.from_date, 'DD/MM/YYYY');
        let tdate = moment(this.employee_leave.to_date, 'DD/MM/YYYY').add(
          1,
          'days',
        );

        let datediff = tdate.diff(fdate, 'days');

        if (this.employee_leave.from_date_type) {
          if (this.employee_leave.from_date_type === 'hm') {
            // this.employee_leave.from_date_type = 'hm';
            datediff = datediff - 0.5;
          } else if (this.employee_leave.from_date_type === 'hn') {
            // this.employee_leave.from_date_type = 'hn';
            datediff = datediff - 0.5;
          }
        } else {
          this.employee_leave.from_date_type = 'f';
        }

        if (fdate === tdate) {
          this.employee_leave.to_date_type = this.employee_leave.from_date_type;
        } else {
          if (this.employee_leave.to_date_type) {
            if (this.employee_leave.to_date_type === 'hm') {
              // this.employee_leave.to_date_type = 'hm';
              datediff = datediff - 0.5;
            } else if (this.employee_leave.to_date_type === 'hn') {
              // this.employee_leave.to_date_type = 'hn';
              datediff = datediff - 0.5;
            }
          } else {
            this.employee_leave.to_date_type = 'f';
          }
        }
        if (datediff > this.date_amount_leave) {
          this.can_leave = false;
        } else {
          // this.disabled_leave = false;
          this.can_leave = true;
        }
      }
    },

    async to_Base64(img) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(img);
      });
    },

    async alert_img(img) {
      if (img) {
        // this.file_size = e.files[0]['size'];
        if (typeof img == 'string') {
          this.tranfer64 = img;
        } else {
          this.tranfer64 = await this.to_Base64(img);
        }
        let split = this.tranfer64.split(';', 1);
        if (split == 'data:application/pdf') {
          this.tranfer64 = [this.tranfer64, 'pdf'];
        } else if (split == 'data:video/mp4') {
          this.tranfer64 = [this.tranfer64, 'video'];
        } else {
          this.tranfer64 = [this.tranfer64, 'pic'];
        }
        this.showImg = true;
      } else {
        return false;
      }
    },

    viewPDF(pdf) {
      window.open(pdf, '_blank');
    },

    async submit(leave) {
      if (leave == null) {
        this.$store.dispatch('alerts/error', 'ไม่มีสิทธิ์การลานี้.');
        return false;
      }

      var list_excep = [7, 12];
      if (leave.from_date == leave.to_date) {
        if (
          leave.from_date_type != 'f' &&
          leave.to_date_type != 'f' &&
          leave.from_date_type != leave.to_date_type
        ) {
          this.$store.dispatch(
            'alerts/error',
            'ไม่สามารถเลือกลาเช้าบ่ายวันเดียวกันได้',
          );
          return false;
        }
      } else if (leave.from_date != leave.to_date) {
        if (
          !list_excep.includes(leave.leave_id) &&
          (leave.from_date_type != 'f' || leave.to_date_type != 'f')
        ) {
          this.$store.dispatch(
            'alerts/error',
            'ไม่สามารถเลือกลาครึ่งวัน คนละวันกันได้',
          );
          return false;
        }
      }

      this.employee_leave = leave;
      const confirmation = await Swal.fire({
        title: 'ยืนยันการลา ?',
        showCancelButton: true,
        confirmButtonText: 'Yes, Submit!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'md-button md-success',
          cancelButton: 'md-button md-danger',
        },
        preConfirm: () => {},
      });
      if (confirmation.value === true) {
        await this.getDateAmountLeave(leave.leave_id);
        await this.checkStaffCanLeave();
        if (this.can_leave) {
          this.employee_leave.approver_require = 1;
          this.employee_leave.approve_status = 'leaverequest';
          this.employee_leave.type = 'employeeleaves';
          this.employee_leave.employee_id = this.profileMe[0].emp_id;
          var list_anyone = [7, 12];
          if (list_anyone.includes(leave.leave_id)) {
            this.employee_leave.condition = 'anyone';
          } else {
            this.employee_leave.condition = '';
          }
          this.employee_leave.from_date = moment(
            this.employee_leave.from_date,
            ['YYYY-MM-DD', 'DD/MM/YYYY'],
          ).format('YYYY-MM-DD');
          this.employee_leave.to_date = moment(this.employee_leave.to_date, [
            'YYYY-MM-DD',
            'DD/MM/YYYY',
          ]).format('YYYY-MM-DD');

          this.employee_leave.requestdate = moment().format('YYYY-MM-DD');
          if (leave.file) {
            this.employee_leave.attach_filepath = await this.to_Base64(
              leave.file,
            );
          }
          try {
            loading_start();
            let result = await this.$store.dispatch(
              'staffleaves/leavecreate',
              this.employee_leave,
            );

            if (result.status != 200) {
              if (result.status == 201) {
                this.$store.dispatch('alerts/error', result?.data?.message);
              } else if (result.status != 201) {
                this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
              }
              loading_close();
              return false;
            }
            await this.getStaffLeaveList(this.profileMe[0]);
            await this.get_sort_leave();
            await this.getTotalDateLeave();
            loading_close();
            this.$store.dispatch(
              'alerts/success',
              'บันทึกการลาเสร็จเรียบร้อยแล้ว.',
            );
            this.reset_form = true;
            this.formReset();
            this.showDialog = false;
            this.tranfer64 = null;
          } catch (e) {
            if (e.response.data.errors[0]) {
              this.$store.dispatch(
                'alerts/error',
                e.response.data.errors[0].title,
              );
            } else {
              this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
            }
          }
        } else {
          if (typeof this.date_amout_leave == 'undefined') {
            this.$store.dispatch(
              'alerts/error',
              'ไม่สามารถลาได้ จำนวนวันลาเหลือ ' +
                this.date_amount_leave +
                ' วัน',
            );
            return false;
          }
        }
      }
    },

    setResetForm(val) {
      if (val) {
        this.reset_form = val;
      }
    },

    formReset() {
      this.leave_id = null;
      this.employee_leave = {};
      this.attach_filepath = null;
      this.file_size = 0;
      this.alertimgbase64 = null;
      this.employee_leave = {
        type: 'employeeleaves',
        employee_id: null,
        modification_id: null,
        leave_id: null,
        from_date: null,
        from_date_type: 'f',
        to_date: null,
        to_date_type: 'f',
        attach_filepath: null,
        approve_status: 'leaverequest',
        approver_require: 1,
        requestdate: null,
        notes: '',
      };
    },

    add_Dayoff(v) {
      if (v) {
        this.dayoff_Data.push(v);
        const dataArrWithSet = new Set(this.dayoff_Data);
        this.dayoff_Data = [...dataArrWithSet];
        this.select_Daychange = null;
      }
    },

    /**
     * remove permission from list data
     */
    remove_Dayoff(index) {
      this.dayoff_Data.splice(index, 1);
    },

    // childCompleteRencer() {
    //   if (this.editProfile_created && this.userProfile_created) {
    //     this.finish();
    //   }
    // },

    startUpImg(v) {
      if (!v) {
        this.start();
      } else {
        this.finish();
      }
    },

    start() {
      this.$Progress.start(); //start progress bar
    },
    finish() {
      this.$Progress.finish(); //finish progress bar
      this.check_progress = true;
    },
    fail() {
      this.$Progress.fail(); //fail progress bar
      this.check_progress = false;
    },
    increase(num) {
      this.$Progress.increase(num); //percent progress bar
    },
  },
};
</script>

<style scoped>
::v-deep .b-form-datepicker .btn.btn-sm[aria-label='Previous year'] {
  display: none;
}
::v-deep .b-form-datepicker .btn.btn-sm[aria-label='Next year'] {
  display: none;
}

img.canzoom {
  width: 100%;
  height: auto;
  transition: transform 0.25s ease;
  cursor: zoom-in;
  transform-origin: top left;
}

img.canzoom.zoomed {
  transform: scale(2);
  cursor: zoom-out;
}
</style>
