<template>
  <div>
    <md-dialog class="info-add" :md-active.sync="showAdd">
      <md-dialog-title>Add File</md-dialog-title>
      <md-dialog-content class="info-add-content">
        <div class="md-layout">
          <div
            class="md-layout-item spacing md-xlarge-size-80 md-large-size-80 md-medium-size-80 md-small-size-100 md-xsmall-size-100"
          >
            <md-field>
              <label>ชื่อไฟล์</label>
              <md-input type="text" v-model="fileNameCreate"></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item spacing md-xlarge-size-80 md-large-size-80 md-medium-size-80 md-small-size-100 md-xsmall-size-100"
          >
            <b-form-file
              v-model="fileToCreate"
              accept=".pdf, .jpg, .png, image/jpeg, image/png"
              placeholder="Choose a file"
              drop-placeholder="Drop file here..."
            />
          </div>
          <div
            class="md-layout-item spacing md-xlarge-size-20 md-large-size-20 md-medium-size-20 md-small-size-100 md-xsmall-size-100"
          >
            <b-button variant="info" @click="setData.push('')">
              เพิ่มสิทธิ์
            </b-button>
          </div>
          <div
            v-for="(item, index) in setData"
            :key="index"
            class="md-layout md-layout-item md-alignment-center-left md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          >
            <div
              class="md-layout-item md-xlarge-size-80 md-large-size-70 md-medium-size-80 md-small-size-80 md-xsmall-size-100"
            >
              <filter-employee
                @childBranches="setBranchsAdd($event, index)"
                @childDepartment="setDepartmentsAdd($event, index)"
                :permissionsList="permissions"
                :filterPermissions="['department']"
                st="department"
                showClear="1"
              />
            </div>
            <div
              class="md-layout-item spacing md-xlarge-size-20 md-large-size-30 md-medium-size-20 md-small-size-20 md-xsmall-size-100"
            >
              <b-button variant="danger" @click="deletePermission(index)">
                ลบสิทธิ์
              </b-button>
            </div>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-success" @click="createFile()">Save</md-button>
        <md-button class="md-danger" @click="showAdd = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog class="info-file" :md-active.sync="showInfo">
      <md-dialog-title>File</md-dialog-title>
      <md-dialog-content class="info-file-content">
        <div class="md-layout">
          <div
            class="md-layout-item spacing md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          >
            <informationrules v-if="file_id != null" :file_id="file_id" />
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showInfo = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-card>
      <md-card-header>
        <h4 class="title"></h4>
        <p class="category"></p>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-alignment-center-left">
          <div
            v-if="permissions.includes('manageinfo')"
            class="md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-70 md-small-size-100 md-xsmall-size-100"
          >
            <filter-employee
              @childBranches="setBranchsSearch"
              @childDepartment="setDepartmentsSearch"
              :permissionsList="permissions"
              st="department"
              showClear="1"
            />
          </div>
          <div
            v-if="!permissions.includes('manageinfo')"
            class="md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-70 md-small-size-100 md-xsmall-size-100"
          >
            <filter-employee
              @childBranches="setBranchsSearch"
              @childDepartment="setDepartmentsSearch"
              :permissionsList="permissions"
              :filterPermissions="['department']"
              st="department"
              showClear="1"
            />
          </div>
          <div
            class="md-layout-item spacing md-xlarge-size-20 md-large-size-30 md-medium-size-30 md-small-size-100 md-xsmall-size-100"
          >
            <b-button
              variant="info"
              @click="searchFileInfo()"
              style="text-align: right; margin-right: 10px"
            >
              Search
            </b-button>
            <b-button variant="success" @click="showAdd = true">
              Add File
            </b-button>
          </div>
          <div
            class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          >
            <md-card v-if="files != null && Object.keys(files).length > 0">
              <md-card-content>
                <div
                  v-for="(file, index) in files"
                  :key="index + file.filename + file.info_id"
                  @click="set_file_id(file.info_id)"
                  class="list-info-file"
                >
                  <div style="margin: 15px; font-size: medium;">
                    <div>
                      <span style="float: right;">
                        {{ file.format_created_at }}
                      </span>
                      <h5>
                        {{ file.filename }}
                      </h5>
                    </div>
                    <div>
                      <b-table
                        style="min-width: 280px; max-width: 30%;"
                        small
                        bordered
                        :items="file.permis"
                        :fields="[
                          { key: 'bch_nameshort', label: 'สาขา' },
                          { key: 'dep_nameen', label: 'แผนก' },
                        ]"
                      />
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import $ from 'jquery';
import { loading_start, loading_close } from '@/utils/loading.js';
import Swal from 'sweetalert2';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import InformationRules from '@/pages/Dashboard/Pages/Staff/Transactions/Information/InformationRules.vue';
import moment from 'moment';
import employeeMixin from '@/mixins/employee-mixin';

export default {
  mixins: [employeeMixin],
  components: {
    'filter-employee': FilterEmployee,
    'informationrules': InformationRules,
  },
  async created() {
    // this.files = await this.getfile();
    this.profile = await this.getProfileData();
    this.uniqPermiss = await this.getUniqueApprove();
    this.permissions = await this.getPermissions();
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  data() {
    return {
      fileNameCreate: '',
      fileToCreate: null,
      showAdd: false,
      branchsAdd: [],
      departmentsAdd: [],
      branchsSearch: null,
      departmentsSearch: null,
      files: null,
      file_id: null,
      setData: [''],
      showInfo: false,
      profile: null,
      permissions: [],
      uniqPermiss: [],
    };
  },
  watch: {
    showInfo() {
      if (this.showInfo == false) {
        this.file_id = null;
      }
    },
    async branchsSearch(bch_id) {
      let arr_permis = await this.getPermissions();

      if (this.uniqPermiss[bch_id]) {
        Object.entries(this.uniqPermiss[bch_id]).forEach(([key, value]) => {
          if (key != 'info') {
            arr_permis.push(value.depname_en);
          }
        });
      }
      this.permissions = arr_permis;
    },
  },
  methods: {
    async getfile(params) {
      if (params.length > 0) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
      }

      loading_start();
      let resp = await this.$store.dispatch('info/getFileInfo', params);
      loading_close();

      return resp;
    },
    async getPermissions() {
      let arr_permis = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          arr_permis.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          arr_permis.push(permission.name);
        });
      }
      if (this.uniqPermiss) {
        Object.entries(this.uniqPermiss).forEach(([key, value]) => {
          arr_permis.push(value.info.bchname_en);
        });
      }
      return arr_permis;
    },
    async getUniqueApprove() {
      let params = {
        ...{
          com_id: this.profile?.[0].com_id ? this.profile?.[0]?.com_id : {},
        },
        ...{ approver_id: this.profile?.[0].id ? this.profile?.[0]?.id : {} },
        ...{ type: 'info' },
      };

      loading_start();
      let staffList = await this.$store.dispatch('uniqueapprover/list', params);
      loading_close();

      return staffList;
    },

    async searchFileInfo() {
      if (this.branchsSearch == null) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return;
      }

      let params = {
        bch_id: this.branchsSearch,
        dep_id: this.departmentsSearch,
        emp_id: null,
        edit: 1,
      };

      let temp = await this.getfile(params);

      this.files = Object.values(temp).map((el) => {
        el.format_created_at = moment(el.created_at).format(
          'DD/MM/YYYY HH:mm:ss',
        );
        el.permis.forEach((p) => {
          if (p.dep_nameen == null) {
            p.dep_nameen = 'All';
          }
        });
        return el;
      });

      this.file_id = null;

      if (this.files.length <= 0) {
        this.$store.dispatch('alerts/error', 'ไม่มีข้อมูล');
        return;
      }
    },

    async createFile() {
      if (this.fileNameCreate == '') {
        this.$store.dispatch('alerts/error', 'กรุณาใส่ชื่อไฟล์!');
        return;
      }

      if (this.fileToCreate == null) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกไฟล์');
        return;
      }

      const encodedString = await this.encodeFile(this.fileToCreate);

      let permission = [];

      this.setData.forEach((arr, index) => {
        let temp = {
          bch_id: this.branchsAdd[index] ? this.branchsAdd[index] : null,
          dep_id: this.departmentsAdd[index]
            ? this.departmentsAdd[index]
            : null,
        };
        if (
          this.branchsAdd[index] != null ||
          this.departmentsAdd[index] != null
        ) {
          permission.push(temp);
        }
      });

      let params = {
        filename: this.fileNameCreate,
        file: encodedString,
        permission,
      };

      try {
        loading_start();
        let resp = await this.$store.dispatch('info/createFileInfo', params);
        loading_close();
        this.showAdd = false;
        return resp;
      } catch (error) {
        loading_close();
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
      }
    },

    async encodeFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result;
          resolve(base64String);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },

    set_file_id(id) {
      if (this.file_id != id) {
        this.showInfo = true;
        this.file_id = id;
      }
    },

    async setBranchsSearch(v) {
      this.branchsSearch = v;
    },

    async setDepartmentsSearch(v) {
      this.departmentsSearch = v;
    },

    async setBranchsAdd(v, index) {
      this.branchsAdd[index] = v;
    },

    async setDepartmentsAdd(v, index) {
      this.departmentsAdd[index] = v;
    },

    deletePermission(index) {
      this.setData.splice(index, 1);
      this.departmentsAdd.splice(index, 1);
      this.branchsAdd.splice(index, 1);
    },
  },
};
</script>
<style>
.info-add {
  min-width: 60%;
}

.info-add-content {
  min-height: 400px;
}

.list-info-file {
  margin: 10px 0px 10px 0px;
  border: 2px solid grey;
  border-radius: 10px;
  cursor: pointer;
  transition: 250ms;
}

.list-info-file:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
