import Swal from 'sweetalert2';
import store from '@/store';

export const versionVerification = async () => {
  try {
    const serverVersion = await store.dispatch('system/version');
    const clientVersion = process.env.VUE_APP_VERSION;

    if (clientVersion !== serverVersion) {
      Swal.fire({
        title: '🔄 New Update!',
        text: 'The app will automatically refresh in 90 seconds.',
        icon: 'info',
        timer: 90000, // 90 seconds
        showConfirmButton: true,
        confirmButtonText: 'Refresh Now',
        cancelButtonText: 'Close',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });

      setTimeout(() => {
        window.location.reload();
      }, 90000);
    }
  } catch (error) {
    throw new Error(`❌ Unable to check version: ${error.message}`);
  }
};
