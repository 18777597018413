<template>
  <div class="appraisal">
    <md-dialog
      class="appraisal edit-data"
      :md-active.sync="showSetting"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.5rem">
        ประเมินพนักงาน
      </md-dialog-title>
      <md-dialog-content v-if="emp_setting" style="font-size: 1.1rem">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            รหัสพนักงาน : {{ emp_setting.emp_code }} <br />
            ชื่อพนักงาน :
            {{ emp_setting.firstname_th + ' ' + emp_setting.lastname_th }}
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <appraisalform
              ref="appraisalform"
              :level="level_emp"
              :emp_id="emp_setting.emp_id"
              :disabled_status="false"
            />
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-success" @click="saveAppraisal()">
          บันทึก
        </md-button>
        <md-button class="md-danger" @click="showSetting = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      class="appraisal edit-data"
      :md-active.sync="showHistory"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.5rem">
        ประเมินพนักงาน
      </md-dialog-title>
      <md-dialog-content v-if="emp_setting" style="font-size: 1.1rem">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            รหัสพนักงาน : {{ emp_setting.emp_code }} <br />
            ชื่อพนักงาน :
            {{ emp_setting.firstname_th + ' ' + emp_setting.lastname_th }}
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <template>
              <div class="accordion mt-3" role="tablist">
                <b-card
                  no-body
                  class="mb-1"
                  v-for="emp_setting in historys"
                  :key="emp_setting.score_id"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle="'accordion-' + emp_setting.appv_code"
                      variant="info"
                    >
                      ผู้ประเมินครั้งที่ {{ emp_setting.level }}
                      {{ emp_setting.appv_titlename_th
                      }}{{ emp_setting.appv_firstname_th }}
                      {{ emp_setting.appv_lastname_th }}
                      ({{ emp_setting.appv_nickname }})
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    visible
                    :id="'accordion-' + emp_setting.appv_code"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <appraisalform
                        :key="emp_setting.score_id"
                        ref="appraisalform"
                        :emp_id="emp_setting.emp_id"
                        :gfrom_date="emp_setting.from_date"
                        :gto_date="emp_setting.to_date"
                        :score_id="emp_setting.score_id"
                        :disabled_status="true"
                      />
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </template>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showHistory = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                manage_accounts
              </md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="" style="text-align: right">
              <md-button class="md-info md-dense" @click="goToHistory">
                ประวัติการประเมิน </md-button
              >&nbsp;
              <!-- <md-button class="md-primary md-dense" @click="goToReportDis">
                สรุปผลการประเมิน
              </md-button> -->
            </div>

            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
                  >
                    <filter-employee
                      v-if="profile && emp_list != null"
                      :branchId="branchId"
                      :available_branches="branchs_list"
                      :available_departments="departments_list"
                      :available_employee="emp_list"
                      :emp_id="profile[0].emp_id"
                      @changeBranch="changeBranch"
                      @changeDepartment="changeDepartment"
                      @changeEmployee="changeEmployee"
                    >
                    </filter-employee>
                  </div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                </div>
              </div>
            </div>
            <md-table
              :value="staffs"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-toolbar>
                <!-- Filter per page-->
                <md-field>
                  <label>Per page</label>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </md-table-toolbar>

              <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                <md-table-cell md-label="ลำดับ" class="no">
                  <span class="upfont">
                    {{
                      index +
                        1 +
                        (pagination.currentPage - 1) * pagination.perPage
                    }}
                  </span>
                </md-table-cell>

                <md-table-cell md-label="รหัสพนักงาน" class="emp_code">
                  <span class="upfont">
                    {{ item.emp_code }}
                  </span>
                </md-table-cell>

                <md-table-cell md-label="ชื่อพนักงาน" class="emp_name">
                  <span class="upfont">
                    {{ item.firstname_en }}
                    {{ item.lastname_en }}
                    ({{ item.firstname_th }} {{ item.lastname_th }})
                  </span>
                </md-table-cell>

                <md-table-cell md-label="สำนักงาน">
                  <span class="upfont">
                    {{ item.bchname_th }} ({{ item.br_nameth }})
                  </span>
                </md-table-cell>

                <md-table-cell md-label="ตำแหน่ง">
                  <span class="upfont">{{ item.pos_nameth }}</span>
                </md-table-cell>

                <md-table-cell md-label="ประเมินล่าสุด" class="last_appraisal">
                  <span class="upfont">
                    {{ convertToThaiDate(item.end_date) }}
                  </span>
                </md-table-cell>

                <md-table-cell md-label="Action">
                  <!--  -->
                  <b-button
                    v-if="item.chk_history > 0 && item.level != '1'"
                    variant="info"
                    @click="getHistory(item)"
                  >
                    ประวัติ
                  </b-button>
                  <span v-if="item.chk_action == 0">&ensp;</span>
                  <b-button
                    v-if="item.chk_action == 0"
                    variant="success"
                    @click="getdata(item, item.level)"
                  >
                    ประเมินครั้งที่ {{ parseInt(item.level) }}
                  </b-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Showing {{ from }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vuex from 'vuex';
import AppraisalEmpForm from './AppraisalEmpForm.vue';
import { loading_start, loading_close } from '@/utils/loading.js';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import FilterEmployeeAppraisal from './FilterEmployeeAppraisal.vue';
import Pagination from '@/components/Pagination';

export default {
  mixins: [employeeMixin],
  components: {
    'pagination': Pagination,
    'appraisalform': AppraisalEmpForm,
    'filter-employee': FilterEmployeeAppraisal,
  },
  data() {
    return {
      profile: null,
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      // permissions: [],
      staffs: [],
      showSetting: false,
      showHistory: false,
      emp_setting: null,
      staffList: [],
      staffSearchTerm: '',
      selected_emp: [],
      staffsSearchList: [],
      historys: [],
      level_emp: 1,
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [5, 10, 25, 50],
      },
      total: 0,
      // search level 2
      branchs_list: [],
      departments_list: [],
      emp_list: null,
      branchId: 0,
      depId: null,
      empCode: null,
      can_call_emp: true,
      can_call_dep: true,
      timeoutGetEmplist: null,
      first: true,
    };
  },
  async created() {
    if (this.me?.employee_id) {
      this.profile = await this.getProfileData(this.me.employee_id);
    } else {
      this.profile = await this.getProfileData();
    }

    if (this.profile[0]?.bch_id) {
      this.changeBranch(this.profile[0].bch_id);
    }
  },
  watch: {
    pagination: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },

    async branchId() {
      this.getListDebounced();
    },

    async depId() {
      if (this.can_call_dep == true) {
        this.getListDebounced();
      }
    },

    async empCode() {
      if (this.can_call_emp == true) {
        this.getListDebounced();
      }
    },

    async staffSearchTerm(val) {
      if (val.split(' ').length > 1) {
        val = val.split(' ')[0];
      }
      this.empCode = val;
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          per.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
    from() {
      var start = 0;
      if (this.staffs.length > 0) {
        start = this.pagination.perPage * (this.pagination.currentPage - 1) + 1;
      }
      return start;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  methods: {
    async getListDebounced() {
      if (this.timeoutGetEmplist != null) {
        clearTimeout(this.timeoutGetEmplist);
      }

      let time = 800;
      if (this.first) {
        time = 0;
        this.first = false;
      }

      this.timeoutGetEmplist = setTimeout(async () => {
        this.staffList = await this.getListEmp();
        // if (this.staffList.length > 0) {
        this.searchStaffs();
        // }
      }, time);
    },

    headdropdown(event) {
      var con = $(event.target).closest('.md-layout-item');
      var content = $('.contentleave', con);
      content.slideToggle();
      content.css('display', 'grid');
    },
    async getListEmp() {
      let params = {
        ...{ com_id: this.profile[0].com_id ? this.profile[0].com_id : {} },
        ...{ approver_id: this.profile[0].id ? this.profile[0].id : {} },
        ...{
          page: this.pagination.currentPage ? this.pagination.currentPage : {},
        },
        ...{ limit: this.pagination.perPage ? this.pagination.perPage : {} },
        ...{ bch_id: this.branchId ? this.branchId : {} },
        ...{ dep_id: this.depId ? this.depId : {} },
        ...{ emp_code: this.empCode ? this.empCode : {} },
      };

      loading_start();
      let staffListTmp = await this.$store.dispatch('appraisal/list', params);
      let staffList = staffListTmp.data; //เดิมใช้อันนี้
      // เปลี่ยน search
      var temp_arr = staffListTmp.employees?.[this.branchId]
        ? Object.values(staffListTmp.employees?.[this.branchId])
        : [];

      if (temp_arr.length > 0) {
        var staffsSearchList = temp_arr.flatMap((a) => {
          return Object.values(a).flat();
        });
        this.staffsSearchList = staffsSearchList;
      }

      var maxLevel = staffListTmp?.max_level ? staffListTmp.max_level : 1;
      this.total = parseInt(staffListTmp.page.total);
      this.level_emp = parseInt(maxLevel);
      this.branchs_list = staffListTmp.branchs;
      this.departments_list = staffListTmp.departments;
      this.emp_list = staffListTmp.employees;

      loading_close();

      return staffList;
    },

    async getdata(emp, level) {
      if (emp == null) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      this.emp_setting = emp;
      this.level_emp = level;
      this.showSetting = true;
    },

    async getHistory(emp) {
      if (emp == null) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      let staffList = await this.$store.dispatch(
        'appraisal/get_score_history_log',
        { emp_id: emp.emp_id },
      );
      this.historys = staffList;

      this.emp_setting = emp;
      this.showHistory = true;
    },

    async searchStaffs(searchTerm = '') {
      if (searchTerm == '') {
        if (typeof this.staffSearchTerm == 'object') {
          searchTerm = this.staffSearchTerm.emp_code;
        } else {
          searchTerm = this.staffSearchTerm;
        }
      } else if (typeof searchTerm == 'object') {
        searchTerm = searchTerm?.emp_code ? searchTerm.emp_code : '';
        this.staffSearchTerm = searchTerm;
      }

      this.staffs = await new Promise((resolve) => {
        window.setTimeout(() => {
          if (searchTerm == '') {
            resolve(this.staffList);
          } else {
            const term = searchTerm.toLowerCase().split(' ')[0];
            resolve(
              this.staffList.filter(
                ({
                  emp_code,
                  firstname_th,
                  lastname_th,
                  firstname_en,
                  lastname_en,
                }) =>
                  emp_code.toLowerCase().includes(term) ||
                  firstname_th.toLowerCase().includes(term) ||
                  lastname_th.toLowerCase().includes(term) ||
                  firstname_en.toLowerCase().includes(term) ||
                  lastname_en.toLowerCase().includes(term),
              ),
            );
          }
        }, 300);
      });
      if (searchTerm == '') {
        this.staffsSearchList = new Promise((resolve) => {
          var temp = this.staffsSearchList;
          resolve(temp);
        });
      }
    },

    selectStaff(c) {
      if (this.staffSearchTerm != '') {
        this.staffSearchTerm = c.emp_code;
      } else {
        this.staffSearchTerm = '';
      }
    },
    async saveAppraisal() {
      let res = await this.$refs.appraisalform.saveAppraisal();
      if (res.status) {
        this.showSetting = false;
        this.staffList = await this.getListEmp();
        if (this.staffList.length > 0) {
          this.searchStaffs();
        }
      }
    },

    convertToThaiDate(dateString) {
      if (dateString) {
        const [year, month, day] = dateString.split('-');
        const thaiYear = parseInt(year) + 543;
        return `${day}/${month}/${thaiYear}`;
      }
    },

    async goToHistory() {
      let params = {};

      this.$router.push({
        path: 'appraisal-history',
        name: 'ประวัติการประเมิน',
        params: params,
      });
    },

    async goToReportDis() {
      let params = {};

      this.$router.push({
        path: 'appraisal-report',
        name: 'ผลการประเมิน',
        params: params,
      });
    },

    changeBranch(val) {
      this.can_call_emp = false;
      this.can_call_dep = false;
      this.depId = '';
      this.empCode = '';
      this.branchId = val;
    },

    changeDepartment(val) {
      this.can_call_emp = false;
      this.empCode = '';
      this.can_call_dep = true;
      this.depId = val;
    },

    changeEmployee(val) {
      this.can_call_emp = true;
      this.empCode = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgb(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}

.appraisal {
  .md-table-cell.md-table-cell-selection {
    width: auto !important;
  }
  .paginated-table .md-table-head-label {
    padding-right: 0 !important;
  }
  #rating-lg-no-border {
    outline: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .form-control:focus {
    outline: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .btn.btn-info.btn-block {
    text-align: left;
  }
  .no {
    width: 5%;
    text-align: center;
  }
  .emp_code {
    width: 10%;
  }
  .emp_name {
    width: 20%;
  }
  .last_appraisal {
    width: 10%;
  }
}
</style>
