<template>
  <div>
    <!-- <md-dialog :md-active.sync="showEdit" style="min-width: 60%;" md-fullscreen>
      <md-dialog-title style="font-size: 1.5rem">
        เพิ่มการตั้งค่า
      </md-dialog-title>
      <md-dialog-content style="font-size: 1.2rem">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <hr />
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <filter-employee
              :headLabel="'ผู้ที่ได้รับการอนุมัติ'"
              :permissionsList="permissions"
              @childStaffs="setStaffs_staffrequest"
            >
            </filter-employee>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-field>
              <label>คำอธิบาย</label>
              <md-textarea
                v-model="uniqueapprover.description"
                md-autogrow
              ></md-textarea>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-70 md-medium-size-60 md-small-size-40 md-alignment-right"
          ></div>
          <div
            class="md-layout-item md-size-30 md-medium-size-40 md-small-size-60 md-alignment-right"
          >
            <md-switch v-model="uniqueapprover.status" value="active">
              <div v-if="uniqueapprover.status == 'active'">
                เปิดการใช้งาน
              </div>
              <div v-if="uniqueapprover.status == 'inactive'">
                ปิดการใช้งาน
              </div>
            </md-switch>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-success" @click="insert_data()">
          บันทึก
        </md-button>
        <md-button class="md-danger" @click="showEdit = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog> -->
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>money_off</md-icon>
            </div>
            <h4 class="title">ตั้งค่าประกันสังคม</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <filter-employee
                  :permissionsList="permissions"
                  @childBranches="setBranchs"
                  @childDepartment="setDepartments"
                  @childStaffs="setStaffs"
                >
                </filter-employee>
              </div>
              <div
                class="md-layout-item md-size-100 mt-4 md-small-size-100 text-right"
              >
                <!-- <md-button @click="goToAdd" class="md-primary md-dense">
                  เพิ่มการอนุมัติ
                </md-button> -->
              </div>
            </div>
            <md-table
              :value="tableData"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ชื่อพนักงาน">
                  <span class="upfont">
                    {{ item.emp_code }} :
                    {{ item.firstname_th }}
                    {{ item.lastname_th }}
                  </span>
                </md-table-cell>
                <!-- <md-table-cell md-label="Description">
                  {{ item.description }}
                </md-table-cell> -->
                <md-table-cell md-label="Actions">
                  <md-switch
                    v-model="item.status"
                    value="active"
                    @change="switch_active(item.emp_id, item.status)"
                  >
                    <div
                      v-if="item.status == null || item.status == 'inactive'"
                    >
                      ไม่คิดค่าประกันสังคม
                    </div>
                    <div v-if="item.status == 'active'">
                      คิดค่าประกันสังคม
                    </div>
                  </md-switch>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from 'sweetalert2';
import _ from 'lodash';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  data() {
    return {
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      staffsso: {
        type: 'staffsso',
        id: null,
        emp_id: null,
        status: 'active',
        description: '',
      },
      permissions: [],
      staffs: null,
      employee_id: null,
      branchs: null,
      departments: null,
      showEdit: false,
    };
  },
  async created() {
    await this.setPermission(this.me);
  },
  watch: {
    staffsso: {
      handler: function(val) {
        if (val.status != 'active') {
          val.status = 'inactive';
        }
      },
      deep: true,
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  methods: {
    async getList() {
      loading_start();
      let params = { profile: true };
      let employee = await this.$store.dispatch('staffs/searchStaff', params);
      params = {
        com_id: employee[0].com_id,
        ...(this.branchs ? { bch_id: this.branchs } : {}),
        ...(this.departments ? { dep_id: this.departments } : {}),
        ...(this.employee_id ? { emp_id: this.employee_id } : {}),
      };
      this.tableData = await this.$store.dispatch('staffsso/list', params);
      loading_close();
    },

    async switch_active(id, status) {
      var new_status = '';
      if (status == null) {
        new_status = 'inactive';
      } else {
        new_status = 'active';
      }
      let params = {
        type: 'staffsso',
        emp_id: id,
        status: new_status,
      };
      await this.$store.dispatch('staffsso/edit', params);
      await this.$store.dispatch('alerts/success', 'Successfully.');
    },

    // set branch value from child component
    async setBranchs(v) {
      if (v) {
        this.branchs = v;
        this.departments = null;
        await this.getList();
      }
    },
    // set department value from child component
    async setDepartments(v) {
      if (v) {
        this.departments = v;
        await this.getList();
      }
    },

    // set employee id value from child component
    async setStaffs(v) {
      this.staffs = v;
      if (v) {
        this.employee_id = v.id;
        await this.getList();
      } else {
        this.employee_id = null;
        await this.getList();
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
  },
};
</script>
<style scoped>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
</style>
