<!-- ModernWarningSystem.vue -->
<template>
  <div class="warning-system p-4">
    <!-- Header Section -->
    <b-card class="mb-4 border-0 shadow-sm">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h2 class="mb-0">
          <b-icon
            icon="exclamation-triangle-fill"
            variant="warning"
            class="mr-2"
          />
          ระบบการออกใบเตือนพนักงาน
        </h2>
        <b-button variant="danger" @click="showWarningModal = true">
          <b-icon icon="plus-circle-fill" class="mr-1"></b-icon>
          ออกใบเตือนใหม่
        </b-button>
      </div>

      <!-- Search and Filter -->
      <b-row>
        <b-col md="4">
          <b-input-group>
            <b-input-group-prepend>
              <b-icon icon="search" class="mx-2"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTerm"
              placeholder="ค้นหาใบเตือน..."
              class="border-left-0"
            />
          </b-input-group>
        </b-col>
        <b-col md="3">
          <b-form-select v-model="filterType" :options="warningTypeOptions" />
        </b-col>
        <b-col md="3">
          <b-button @click="getListWarning()">getListWarning</b-button>
          <b-button @click="getListWarningType()">getListWarningType</b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- Warning Statistics -->
    <b-row class="mb-4">
      <b-col md="3">
        <b-card bg-variant="danger" text-variant="white" class="border-0 hover">
          <h3 class="h6 mb-2">ใบเตือนทั้งหมด</h3>
          <h2 class="mb-0">{{ warnings.length }}</h2>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card
          bg-variant="warning"
          text-variant="white"
          class="border-0 hover"
        >
          <h3 class="h6 mb-2">เตือนในเดือนนี้</h3>
          <h2 class="mb-0">{{ currentMonthWarnings }}</h2>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card bg-variant="info" text-variant="white" class="border-0 hover">
          <h3 class="h6 mb-2">รอดำเนินการ</h3>
          <h2 class="mb-0">{{ pendingWarnings }}</h2>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card
          bg-variant="success"
          text-variant="white"
          class="border-0 hover"
        >
          <h3 class="h6 mb-2">ดำเนินการแล้ว</h3>
          <h2 class="mb-0">{{ completedWarnings }}</h2>
        </b-card>
      </b-col>
    </b-row>

    <!-- Warnings Table -->
    <b-card class="border-0 shadow-sm">
      <b-table
        :items="filteredWarnings"
        :fields="fields"
        responsive="sm"
        striped
        hover
      >
        <!-- Custom cell renderers -->
        <template #cell(severity)="data">
          <b-badge :variant="getSeverityVariant(data.item.severity)">
            {{ getSeverityText(data.item.severity) }}
          </b-badge>
        </template>

        <template #cell(employee)="data">
          <div class="d-flex align-items-center">
            <b-avatar
              :text="getInitials(data.item.employeeName)"
              class="mr-2"
            />
            <div>
              <div>{{ data.item.employeeName }}</div>
              <small class="text-muted">{{ data.item.department }}</small>
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-button-group size="sm">
            <b-button variant="outline-primary" @click="viewWarning(data.item)">
              <b-icon icon="eye-fill" />
            </b-button>
            <b-button variant="outline-success" @click="editWarning(data.item)">
              <b-icon icon="pencil-fill" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-card>

    <!-- Warning Form Modal -->
    <b-modal
      v-model="showWarningModal"
      title="ออกใบเตือนใหม่"
      size="lg"
      @hidden="resetForm"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      {{ newWarning }}

      <selectEmplist
        v-model="newWarning.giver_emp_id"
        :format="'emp_id'"
        :label="'ผู้ให้ใบเตือน'"
      />
      <selectEmplist
        v-model="newWarning.recipient_emp_id"
        :format="'emp_id'"
        :label="'พนักงานรับใบเตือน'"
      />
      <b-form-group label="ระดับความรุนแรง:">
        <b-form-select
          v-model="newWarning.severity"
          :options="severityOptions"
          required
        />
      </b-form-group>
      <b-form-group label="ประเภทความผิด:">
        <b-form-select
          v-model="newWarning.type"
          :options="warningTypeOptions"
          required
        />
      </b-form-group>
      <b-form-group label="รายละเอียด:">
        <b-form-textarea
          v-model="newWarning.details"
          placeholder="ระบุรายละเอียดการกระทำผิด..."
          rows="3"
          required
        />
      </b-form-group>
      <b-form-group label="แนวทางการแก้ไข:">
        <b-form-textarea
          v-model="newWarning.solution"
          placeholder="ระบุแนวทางการแก้ไขหรือปรับปรุง..."
          rows="3"
          required
        />
      </b-form-group>
      <b-form-group label="วันที่เตือน:">
        <b-form-datepicker
          v-model="newWarning.date"
          :max="new Date()"
          locale="th"
          required
        />
      </b-form-group>
      <template #modal-footer>
        <b-button variant="secondary" @click="showWarningModal = false">
          ยกเลิก
        </b-button>
        <b-button variant="danger" @click="createWarning">
          บันทึกใบเตือน
        </b-button>
      </template>
    </b-modal>

    <!-- Warning Details Modal -->
    <b-modal
      v-model="showDetailsModal"
      title="รายละเอียดใบเตือน"
      size="lg"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-card v-if="selectedWarning" class="border-0">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-weight-bold">พนักงาน:</span>
            <span>{{ selectedWarning.employeeName }}</span>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-weight-bold">แผนก:</span>
            <span>{{ selectedWarning.department }}</span>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-weight-bold">วันที่:</span>
            <span>{{ formatDate(selectedWarning.date) }}</span>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-weight-bold">ระดับความรุนแรง:</span>
            <b-badge :variant="getSeverityVariant(selectedWarning.severity)">
              {{ getSeverityText(selectedWarning.severity) }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>

        <h6 class="mt-4 mb-2">รายละเอียด:</h6>
        <p>{{ selectedWarning.details }}</p>

        <h6 class="mt-4 mb-2">แนวทางการแก้ไข:</h6>
        <p>{{ selectedWarning.solution }}</p>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import Vuex from 'vuex';
import employeeMixin from '@/mixins/employee-mixin';
import selectEmplist from './selectEmplist.vue';

// import FilterEmployee from './FilterEmployee.vue';
export default {
  name: 'WarningSystem',
  mixins: [employeeMixin],
  components: { selectEmplist: selectEmplist },
  data() {
    return {
      profile: null,
      showWarningModal: false,
      showDetailsModal: false,
      searchTerm: '',
      filterType: null,
      selectedWarning: null,
      fields: [
        { key: 'date', label: 'วันที่', sortable: true },
        { key: 'employee', label: 'พนักงาน' },
        { key: 'type', label: 'ประเภท', sortable: true },
        { key: 'severity', label: 'ระดับ', sortable: true },
        { key: 'details', label: 'รายละเอียด' },
        { key: 'actions', label: 'ดำเนินการ' },
      ],
      warnings: [
        {
          id: 1,
          employeeId: 1,
          employeeName: 'นายสมชาย ใจดี',
          department: 'ฝ่ายผลิต',
          date: '2024-02-20',
          type: 'attendance',
          severity: 'low',
          details: 'มาสาย 3 ครั้งในเดือนนี้',
          solution:
            'ต้องมาให้ตรงเวลา ถ้ามาสายอีกจะถูกตักเตือนเป็นลายลักษณ์อักษร',
          status: 'pending',
        },
      ],
      newWarning: {
        giver_emp_id: null,
        recipient_emp_id: null,
        severity: null,
        solution: null,
        type: null,
        details: '',
        date: null,
        status: 'pending',
      },
      warningTypeOptions: null,
    };
  },
  async created() {
    this.profile = await this.getProfileData();
    let listWarning = await this.getListWarning();
    let getListWarningType = await this.getListWarningType();
    let warningType = this.format_warning_type(getListWarningType);
    this.warningTypeOptions = warningType;
    // console.log(listWarning);
  },
  watch: {},
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    severityOptions() {
      return [
        { value: null, text: 'เลือกประเภทการเตือน' },
        { value: 'low', text: 'เตือนด้วยวาจา / Notice' },
        { value: 'medium', text: 'เตือนเป็นลายลักษณ์อักษร / Warning' },
        { value: 'high', text: 'เตือนครั้งสุดท้าย / Last Warning' },
      ];
    },
    // warningTypeOptions() {
    //   return [
    //     { value: null, text: 'ทั้งหมด' },
    //     { value: 'attendance', text: 'การขาด/ลา/มาสาย' },
    //     { value: 'performance', text: 'ผลการปฏิบัติงาน' },
    //     { value: 'behavior', text: 'พฤติกรรม/การแต่งกาย' },
    //     { value: 'rules', text: 'ละเมิดกฎระเบียบ' },
    //     { value: 'other', text: 'อื่นๆ' },
    //   ];
    // },
    filteredWarnings() {
      return this.warnings.filter((warning) => {
        const matchesSearch =
          warning.employeeName
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          warning.details.toLowerCase().includes(this.searchTerm.toLowerCase());
        const matchesType =
          !this.filterType || warning.type === this.filterType;
        return matchesSearch && matchesType;
      });
    },
    currentMonthWarnings() {
      const now = new Date();
      return this.warnings.filter((w) => {
        const warningDate = new Date(w.date);
        return (
          warningDate.getMonth() === now.getMonth() &&
          warningDate.getFullYear() === now.getFullYear()
        );
      }).length;
    },
    pendingWarnings() {
      return this.warnings.filter((w) => w.status === 'pending').length;
    },
    completedWarnings() {
      return this.warnings.filter((w) => w.status === 'completed').length;
    },
  },
  methods: {
    createWarning() {
      try {
        let result = this.$store.dispatch(
          'warning/createWarning',
          this.newWarning,
        );
        this.$bvToast.toast('บันทึกใบเตือนเรียบร้อยแล้ว', {
          title: 'สำเร็จ',
          variant: 'success',
          solid: true,
        });
        this.showWarningModal = false;
        this.resetForm();
      } catch (error) {
        this.$bvToast.toast('มีบางอย่างผิดพลาด!', {
          title: 'ไม่สำเร็จ',
          variant: 'error',
          solid: true,
        });
      }
    },
    async getListWarning() {
      let params = {
        type: this.filterType,
      };
      let result = await this.$store.dispatch('warning/getListWarning', params);
      // console.log(result);
    },
    async getListWarningType() {
      let params = {
        type: this.filterType,
      };
      let warningTypeOptions = await this.$store.dispatch(
        'warning/getListWarningType',
        params,
      );
      return warningTypeOptions;
    },
    format_warning_type(type) {
      let warningType = [{ value: null, text: 'ทั้งหมด' }];
      type.forEach((t) => {
        warningType.push({ value: t.id, text: `${t.type_name_th}` });
      });
      return warningType;
    },
    resetForm() {
      this.newWarning = {
        giver_emp_id: null,
        recipient_emp_id: null,
        severity: null,
        solution: null,
        type: null,
        details: '',
        date: null,
        status: 'pending',
      };
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    getSeverityVariant(severity) {
      const variants = {
        low: 'warning',
        medium: 'danger',
        high: 'dark',
      };
      return variants[severity] || 'primary';
    },
    getSeverityText(severity) {
      const texts = {
        low: 'เตือนด้วยวาจา',
        medium: 'ลายลักษณ์อักษร',
        high: 'เตือนครั้งสุดท้าย',
      };
      return texts[severity] || severity;
    },
    getInitials(name) {
      return name
        .split(' ')
        .map((word) => word[0])
        .join('')
        .toUpperCase();
    },
    viewWarning(warning) {
      this.selectedWarning = warning;
      this.showDetailsModal = true;
    },
    editWarning(warning) {
      this.newWarning = { ...warning };
      this.showWarningModal = true;
    },
  },
};
</script>

<style>
.warning-system {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.card {
  transition: all 0.3s ease;
}

.card.hover:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.table th {
  font-weight: 600;
  color: #495057;
}

.badge {
  padding: 0.5em 0.75em;
}

.modal-header {
  border-bottom: 0;
}

.list-group-item {
  border-left: 0;
  border-right: 0;
}

.list-group-item:first-child {
  border-top: 0;
}

.list-group-item:last-child {
  border-bottom: 0;
}

.b-avatar {
  background-color: #e9ecef;
}
</style>
