import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

async function messengerSend(params) {
  const response = await axios.post(`${url}/v1/messenger-send`, { params });
  return response.data;
}

async function messengerRead(params) {
  const response = await axios.get(`${url}/v1/messenger-read`, { params });
  return response.data;
}

async function messengers(params) {
  const response = await axios.get(`${url}/v1/messengers`, { params });
  return response.data;
}

export default {
  messengerSend,
  messengerRead,
  messengers,
};
