<template>
  <div class="md-layout spacing md-alignment-center">
    <div
      class="md-layout-item md-size-10 md-medium-size-20 md-small-size-20 md-xsmall-size-100"
      v-if="headLabel != '' && headLabel != null"
    >
      {{ headLabel }}
    </div>
    <div
      class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
    >
      <md-field class="searchbranchs">
        <label>เลือก สำนักงาน</label>
        <md-select v-model="gbranchId" name="branch_id" @input="changeBreanch">
          <md-option
            v-for="item in available_branches"
            :key="item.bch_id"
            :value="item.bch_id"
          >
            {{ item.br_nameth }}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div
      class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
    >
      <md-field class="searchdepartments">
        <label>เลือก แผนก</label>
        <md-select
          v-model="departmentsId"
          name="branch_id"
          @input="changeDepartment"
          :disabled="disabled_dep"
        >
          <md-option
            v-for="item in available_departments[gbranchId]"
            :key="item.dep_id"
            :value="item.dep_id"
          >
            {{ item.dep_nameth }}
          </md-option>
        </md-select>
        <b-button v-if="showClear" variant="danger" @click="clearDep()">
          X
        </b-button>
      </md-field>
    </div>
    <div
      class="md-layout-item md-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
    >
      <md-autocomplete
        v-model="rstaffSearchTerm"
        :md-options="staffs"
        :disabled="disabled_employee"
        @md-opened="searchStaffs"
        @md-changed="searchStaffs"
        @input="changeEmployee"
        class="searchstaffs"
      >
        <label>เลือก พนักงาน</label>
        <template slot="md-autocomplete-item" slot-scope="{ item }">
          {{ item.emp_code + ' ' + item.firstname_en + ' ' + item.lastname_en }}
        </template>
      </md-autocomplete>
    </div>
  </div>
</template>

<script>
import employeeMixin from '@/mixins/employee-mixin';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  mixins: [employeeMixin],
  data() {
    return {
      staffList: [],
      rstaffSearchTerm: '',
      staffs: [],
      disabled_employee: false,
      disabled_dep: false,
      showClear: false,
      gbranchId: 0,
      departmentsId: '',
    };
  },
  props: {
    headLabel: null,
    branchId: { default: 0 },
    emp_id: { default: 0 },
    available_branches: { default: [] },
    available_departments: { default: [] },
    available_employee: { default: null },
  },
  computed: {},
  async created() {
    this.gbranchId = this.branchId;
    if (this.available_employee != null) {
      await this.setEmployee();
    }
  },
  watch: {},
  methods: {
    async changeBreanch(val) {
      this.clearSelect();
      await this.$emit('changeBranch', val);
      await this.setEmployee();
    },

    async changeDepartment(val) {
      this.clearEmployee();
      await this.$emit('changeDepartment', val);
      await this.setEmployee();
      if (this.departmentsId) {
        this.showClear = true;
      } else {
        this.showClear = false;
      }
    },

    async changeEmployee(val) {
      if (typeof val == 'string') {
        await this.$emit('changeEmployee', val);
        await this.setEmployee();
      }
    },

    clearSelect() {
      this.rstaffSearchTerm = '';
      this.departmentsId = '';
    },

    async clearDep() {
      this.departmentsId = '';
      this.rstaffSearchTerm = '';
      await this.$emit('changeDepartment', '');
    },

    clearEmployee() {
      this.rstaffSearchTerm = '';
    },

    setEmployee() {
      var temp = [];
      var temp_arr = [];

      var departmentEmployees = [];
      if (this.available_employee[this.gbranchId]?.[this.departmentsId]) {
        departmentEmployees = Object.values(
          this.available_employee?.[this.gbranchId]?.[this.departmentsId],
        );
      } else if (this.available_employee?.[this.gbranchId]) {
        temp_arr = Object.values(this.available_employee?.[this.gbranchId]);
        departmentEmployees = temp_arr.flatMap((a) => {
          return Object.values(a).flat();
        });
      }

      temp = Object.values(departmentEmployees).map((emp) => ({
        emp_code: emp.emp_code,
        firstname_en: emp.firstname_en,
        lastname_en: emp.lastname_en,
        firstname_th: emp.firstname_th,
        lastname_th: emp.lastname_th,
      }));

      this.staffList = temp;
      if (this.staffList.length > 0) {
        this.searchStaffs();
      }
    },

    async searchStaffs(searchTerm = '') {
      if (searchTerm == '') {
        if (typeof this.rstaffSearchTerm == 'object') {
          searchTerm = this.rstaffSearchTerm.emp_code;
        } else {
          searchTerm = this.rstaffSearchTerm;
        }
      } else if (typeof searchTerm == 'object') {
        searchTerm = searchTerm?.emp_code ? searchTerm.emp_code : '';
        this.rstaffSearchTerm = searchTerm;
      }

      if (typeof searchTerm == 'string') {
        this.staffs = new Promise((resolve) => {
          if (searchTerm == '') {
            resolve(this.staffList);
          } else {
            const term = searchTerm.toLowerCase();
            resolve(
              this.staffList.filter(
                ({
                  emp_code,
                  firstname_th,
                  lastname_th,
                  firstname_en,
                  lastname_en,
                }) =>
                  emp_code.toLowerCase().includes(term) ||
                  firstname_th.toLowerCase().includes(term) ||
                  lastname_th.toLowerCase().includes(term) ||
                  firstname_en.toLowerCase().includes(term) ||
                  lastname_en.toLowerCase().includes(term),
              ),
            );
          }
        });
      }
    },
  },
};
</script>
<style lang="scss"></style>
