<template>
  <div>
    <!-- Filter employee -->
    <filter-employee
      :permissionsList="permissions"
      @childBranches="setBranchs"
      @childDepartment="setDepartment"
      @childStaffs="setStaffs"
    >
    </filter-employee>

    <!-- Select date -->
    <div class="md-layout spacing md-alignment-left">
      <div class="md-layout-item md-size-20"></div>
      <div class="md-layout-item md-size-25">
        <md-datepicker v-model="fromdate" md-immediately>
          <label>วันที่เริ่ม</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-size-25">
        <md-datepicker
          v-model="todate"
          md-immediately
          :md-disabled-dates="disabledToDates"
        >
          <label>วันที่สิ้นสุด</label>
        </md-datepicker>
      </div>
      <!-- <div class="md-layout-item md-size-10">
        <md-progress-spinner :md-diameter="50" :md-stroke="3" md-mode="indeterminate" :md-value="100" v-show="showProgressBar">  
        </md-progress-spinner>
      </div> -->
    </div>

    <!-- Button search -->
    <div class="md-layout spacing md-alignment-center">
      <div class="md-layout-item md-size-20">
        <md-button @click="getWorkDay()" class="md-warning">Search</md-button>
      </div>
    </div>

    <!-- Display data -->
    <div class="md-layout spacing">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <md-table
              v-model="tableData"
              table-header-color="green"
              :value="tableData"
              :md-sort.sync="sortation.field"
              :md-sort-order.sync="sortation.order"
              :md-sort-fn="customSort"
              class="paginated-table table-striped table-hover"
            >
              <md-table-toolbar>
                <!-- Filter per page-->
                <md-field>
                  <label>Per page</label>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </md-table-toolbar>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="วันที่">
                  {{ moment(item.work_date).format('DD/MM/YYYY') }}
                </md-table-cell>
                <md-table-cell md-label="ช่วงเวลาการจับไทม์ (ชั่วโมง)">
                  ก่อนเข้างาน:
                  {{
                    JSON.parse(item.comment)['condition']['beforestart_time']
                  }}
                  <br />
                  หลังเลิกงาน:
                  {{
                    JSON.parse(item.comment)['condition']['afterfinish_time']
                  }}
                </md-table-cell>
                <md-table-cell md-label="กะการทำงาน">
                  {{ JSON.parse(item.comment)['condition']['code'] }}
                </md-table-cell>
                <md-table-cell md-label="เข้างาน" v-if="item.scan_in != ''">
                  {{ item.scan_in }}
                  <!-- {{ moment(item.scan_in).format('DD/MM/YYYY HH:mm:ss') }} -->
                </md-table-cell>
                <md-table-cell md-label="เข้างาน" v-else></md-table-cell>
                <md-table-cell md-label="พัก" v-if="item.break != ''">
                  {{ item.break }}
                  <!-- {{ moment(item.break).format('DD/MM/YYYY HH:mm:ss') }} -->
                </md-table-cell>
                <md-table-cell md-label="พัก" v-else></md-table-cell>
                <md-table-cell
                  md-label="เข้างานบ่าย"
                  v-if="item.finish_break != ''"
                >
                  {{ item.finish_break }}
                  <!-- {{ moment(item.finish_break).format('DD/MM/YYYY HH:mm:ss') }} -->
                </md-table-cell>
                <md-table-cell md-label="เข้างานบ่าย" v-else></md-table-cell>
                <md-table-cell md-label="ออกงาน" v-if="item.scan_out != ''">
                  {{ item.scan_out }}
                  <!-- {{ moment(item.scan_out).format('DD/MM/YYYY HH:mm:ss') }} -->
                </md-table-cell>
                <md-table-cell md-label="ออกงาน" v-else></md-table-cell>
                <md-table-cell
                  class="stop-work"
                  md-label="สถานะ"
                  v-if="item.date_type === 'not'"
                >
                  ขาดงาน
                </md-table-cell>
                <md-table-cell
                  class="leave"
                  md-label="สถานะ"
                  v-else-if="
                    item.date_type === 'leave' && JSON.parse(item.comment).leave
                  "
                >
                  {{ JSON.parse(item.comment).leave.leave_nameth }}
                  <div
                    v-if="JSON.parse(item.comment).leave.from_date_type == 'f'"
                  >
                    (เต็มวัน)
                  </div>
                  <div
                    v-else-if="
                      JSON.parse(item.comment).leave.from_date_type == 'hm'
                    "
                  >
                    (ครึ่งเช้า)
                  </div>
                  <div
                    v-else-if="
                      JSON.parse(item.comment).leave.from_date_type == 'hn'
                    "
                  >
                    (ครึ่งบ่าย)
                  </div>
                </md-table-cell>
                <md-table-cell
                  class="weekend-work"
                  md-label="สถานะ"
                  v-else-if="item.date_type === 'stopday'"
                >
                  วันหยุด
                </md-table-cell>
                <md-table-cell
                  class="late"
                  md-label="สถานะ"
                  v-else-if="
                    item.date_type === 'work' &&
                      (item.late_minute > 0 || item.off_minute > 0)
                  "
                >
                  ทำงานสาย - กลับก่อน
                </md-table-cell>
                <md-table-cell
                  md-label="สถานะ"
                  v-else-if="item.date_type === 'work'"
                >
                  ทำงาน
                </md-table-cell>
                <md-table-cell
                  class="leave"
                  md-label="สถานะ"
                  v-else
                ></md-table-cell>
                <md-table-cell md-label="มาสาย" v-if="item.late_minute !== 0">
                  {{ item.late_minute }} นาที
                </md-table-cell>
                <md-table-cell md-label="มาสาย" v-else>-</md-table-cell>
                <md-table-cell md-label="กลับก่อน" v-if="item.off_minute !== 0">
                  {{ item.off_minute }} นาที
                </md-table-cell>
                <md-table-cell md-label="กลับก่อน" v-else>-</md-table-cell>
                <md-table-cell md-label="หมายเหตุ" v-if="item.remark">
                  {{ item.remark }}
                </md-table-cell>
                <md-table-cell md-label="หมายเหตุ" v-else>-</md-table-cell>
                <!-- <md-table-cell md-label="Actions">
                  <md-button
                    class="md-icon-button md-raised md-round md-danger"
                    @click="destroy(item.id)"
                    style="margin: .2rem;"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-table-cell> -->
              </md-table-row>
            </md-table>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from '@/components';
import Pagination from '@/components/Pagination';
import moment from 'moment';
import Swal from 'sweetalert2';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import Vuex from 'vuex';
//import SalaryDetail from "@/pages/Dashboard/Pages/Staff/Transactions/SalaryCalculate/SalaryDetail.vue";

export default {
  components: {
    'filter-employee': FilterEmployee,
    'pagination': Pagination,
    // "salary-detail": SalaryDetail
  },
  mixins: [employeeMixin],
  data() {
    return {
      headLabel: 'เวลาเข้า-ออกพนักงาน',
      tableData: {},
      fromdate: null,
      todate: null,
      //showProgressBar: false,
      branchs: null,
      branch_id: null,
      departments_id: null,
      employee_id: null,
      staffs: null,
      amount: 100,
      fromdate_fmt: null,
      todate_fmt: null,
      workday: 0,
      expect_salary: 0,
      moment: moment,
      sortation: {
        field: 'emp_id',
        order: 'asc',
      },
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [10, 20, 30, 50],
      },
      total: 0,
      disabledToDates: (date) => {
        let d_cur = date.getDate();
        let m_cur = date.getMonth() + 1;
        let y_cur = date.getFullYear();
        let passdate = [y_cur, m_cur, d_cur].join('-');

        let fdate = moment(passdate);
        let tdate = moment(this.fromdate);
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      //permissions: [],
    };
  },

  props: {
    permissions: Array,
    // employeeID: null,
  },

  created() {
    // this.employee_id = this.employeeID;
  },

  // computed: {
  //   ...Vuex.mapState({
  //     me: (state) => state.profile.me,
  //   }),
  // },

  watch: {
    fromdate: function() {
      this.fromdate_fmt = moment(this.fromdate).format('YYYY-MM-DD');
    },
    todate: function() {
      this.todate_fmt = moment(this.todate).format('YYYY-MM-DD');
    },

    // sortation: {
    //   handler: 'getWorkDay',
    //   immediate: false,
    //   deep: true,
    // },
    // pagination: {
    //   handler: 'getWorkDay',
    //   immediate: false,
    //   deep: true,
    // },

    // me: {
    //   handler: function(val) {
    //     val.permissions.map((permis) => {
    //       this.permissions.push(permis.name);
    //     });
    //     val.roles[0].permissions.map((permission) => {
    //       this.permissions.push(permission.name);
    //     });
    //   },
    //   deep: true,
    // },
  },

  computed: {
    sort() {
      if (this.sortation.order === 'desc') {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    // ...Vuex.mapState(
    //   {
    //     me: state => state.profile.me
    //   }
    // ),
  },

  methods: {
    // Get working day
    async getWorkDay() {
      if (!this.branchs) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
        return false;
      } else if (!this.employee_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      } else if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      }

      // let params = {
      //   ...(this.sort ? { sort: this.sort } : {}),
      //   filter: {
      //     ...(this.employee_id ? { emp_id: this.employee_id } : {}),
      //     ...(this.fromdate
      //       ? { fromdate: moment(this.fromdate).format('YYYY-MM-DD') }
      //       : {}),
      //     ...(this.todate
      //       ? { todate: moment(this.todate).format('YYYY-MM-DD') }
      //       : {}),
      //   },
      //   page: {
      //     number: this.pagination.currentPage,
      //     size: this.pagination.perPage,
      //   },
      // };
      //this.showProgressBar = true;
      // this.$store.watch(
      //   () => this.$store.getters['staffworks/list'],
      //   (staffwork) => {
      //     this.tableData = staffwork;
      //     this.total = this.$store.getters['staffworks/listTotal'];
      //     this.workday = staffwork.length;
      //   },
      // );
      this.start();
      let params = {
        ...(this.employee_id ? { emp_id: this.employee_id } : {}),
        ...(this.fromdate
          ? { from_date: moment(this.fromdate).format('YYYY-MM-DD') }
          : {}),
        ...(this.todate
          ? { to_date: moment(this.todate).format('YYYY-MM-DD') }
          : {}),
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      let datawork = await this.$store.dispatch('staffhasworks/list', params);
      let datatime_cal = await this.calculateLeave(datawork);
      // console.log(datatime_cal);
      this.tableData = datatime_cal;
      this.finish();
      //this.showProgressBar = false;
    },

    async calculateLeave(data) {
      var leavelisttype = {
        sick: { name: 'sick', nameth: 'ลาป่วย' },
        personal: { name: 'personal', nameth: 'ลากิจ' },
        annual: { name: 'annual', nameth: 'ลาพักร้อน' },
        wop: { name: 'wop', nameth: 'ลาไม่รับค่าจ้าง' },
        ch: { name: 'ch', nameth: 'เปลี่ยนวันหยุด/วันเก็บ' },
        maternity: { name: 'maternity', nameth: 'ลาคลอด' },
        military: { name: 'military', nameth: 'ลารับราชการทหาร' },
        training: { name: 'training', nameth: 'ลาฝึกอมรม' },
        sterilization: { name: 'sterilization', nameth: 'ลาทำหมัน' },
        ph: { name: 'ph', nameth: 'ลาวันหยุดประเพณี' },
      };
      data.forEach((d) => {
        d.date = moment(d.date, ['YYYY-MM-DD', 'DD/MM/YYYY']).format(
          'DD/MM/YYYY',
        );
        d.date = d.date != 'Invalid date' ? d.date : '';
        if (d.timein) {
          d.timein.replace('ก่อนเที่ยง', 'AM');
          d.timein.replace('หลังเที่ยง', 'PM');
          d.timein = this.formatDateTo24(d.timein);
          d.timein = moment(d.timein, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm:ss',
          );
          d.timein = d.timein != 'Invalid date' ? d.timein : '-';
        }
        d.timebreak = moment(d.timebreak, 'YYYY-MM-DD HH:mm:ss A').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        d.timebreak = d.timebreak != 'Invalid date' ? d.timebreak : '-';
        d.timefinishbreak = moment(
          d.timefinishbreak,
          'YYYY-MM-DD HH:mm:ss A',
        ).format('DD/MM/YYYY HH:mm:ss');
        d.timefinishbreak =
          d.timefinishbreak != 'Invalid date' ? d.timefinishbreak : '-';
        if (d.timeout) {
          d.timeout.replace('ก่อนเที่ยง', 'AM');
          d.timeout.replace('หลังเที่ยง', 'PM');
          d.timeout = this.formatDateTo24(d.timeout);
          d.timeout = moment(d.timeout, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm:ss',
          );
          d.timeout = d.timeout != 'Invalid date' ? d.timeout : '-';
        }
        d.cw = 0;
        d.newworkstatus = '';
        if (d.workstatus.split('-').length > 1) {
          d.workstatus.split('-').forEach((work) => {
            let worksplit = work.split('_');
            d.cw += 1;
            if (d.newworkstatus != '') {
              d.newworkstatus += ' - ';
            }
            d.newworkstatus += leavelisttype[worksplit[0]].nameth;
            if (worksplit[1] == 'f') {
              d.newworkstatus += ' (เต็มวัน)';
            } else if (worksplit[1] == 'hm') {
              d.newworkstatus += ' (ครึ่งเช้า)';
            } else if (worksplit[1] == 'hn') {
              d.newworkstatus += ' (ครึ่งบ่าย)';
            }
          });
        }
      });
      return data;
    },

    // remove working date
    async destroy(id) {
      const confirmation = await Swal.fire({
        title: 'Delete this record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        await this.$store.dispatch('staffworks/destroy', id);
        await this.$store.dispatch(
          'alerts/success',
          'Deleted task successfully.',
        );
        await this.getWorkDay();
      }
    },

    // set branch value from child component
    setBranchs(v) {
      this.branchs = v;
    },

    setDepartment(v) {
      if (v) {
        this.departments_id = v;
      }
    },

    // set employee id value from child component
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.employee_id = v.id;
      }
    },

    customSort() {
      return false;
    },
    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>

<style scoped>
.mt-0 {
  margin-top: 0 !important;
}
.table-transparent {
  background-color: transparent !important;
}
.other {
  color: #508dff;
}
.stop-work {
  color: red;
}
.weekend-work {
  color: #ffd000;
}
.change-day {
  color: #ff932e;
}
.leave {
  color: #508dff;
}
.notscan {
  color: #d400ff;
}
.late {
  color: #c4284a;
}
.keep-day {
  color: #00dd30;
}
</style>
