<template>
  <div>
    <md-card>
      <div class="header" @click="headdropdown()">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>money_off</md-icon>
          </div>

          <h4 class="title">
            ลาไม่รับค่าจ้าง (Without Pay Leave)
            <span v-if="old_leave && leavewithoutpay.length <= 0">
              ไม่มีการลา
            </span>
          </h4>
        </md-card-header>
      </div>

      <md-card-content class="contentleave">
        <md-table
          v-model="leavewithoutpay"
          md-sort="from_date_bfformat"
          md-sort-order="desc"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="From/ จาก">
              {{ item.from_date }}
              <div v-if="item.from_date_type === 'f'">เต็มวัน</div>
              <div v-else-if="item.from_date_type === 'hm'">ช่วงเช้า</div>
              <div v-else-if="item.from_date_type === 'hn'">ช่วงบ่าย</div>
            </md-table-cell>
            <md-table-cell md-label="To / ถึง">
              {{ item.to_date }}
              <div v-if="item.to_date_type === 'f'">เต็มวัน</div>
              <div v-else-if="item.to_date_type === 'hm'">ช่วงเช้า</div>
              <div v-else-if="item.to_date_type === 'hn'">ช่วงบ่าย</div>
            </md-table-cell>
            <md-table-cell md-label="วันที่ขออนุมัติ">
              {{ item.requestdate }}
            </md-table-cell>
            <md-table-cell
              v-if="item.approve_status == 'leaverequest'"
              md-label="สถานะการอนุมัติ"
            >
              <span style="color: ">รอการอนุมัติ</span>
            </md-table-cell>
            <md-table-cell
              v-if="item.approve_status == 'approve'"
              md-label="สถานะการอนุมัติ"
            >
              <span style="color: green">อนุมัติแล้ว</span>
            </md-table-cell>
            <md-table-cell
              v-else-if="item.approve_status == 'pending'"
              md-label="สถานะการอนุมัติ"
            >
              <span style="color: blue">รับทราบและรอการอนุมัติ</span>
            </md-table-cell>
            <md-table-cell
              v-else-if="item.approve_status == 'disapprove'"
              md-label="สถานะการอนุมัติ"
            >
              <span style="color: red">ไม่อนุมัติวันลา</span>
            </md-table-cell>
            <md-table-cell
              v-else-if="item.approve_status == 'reject'"
              md-label="สถานะการอนุมัติ"
            >
              <!-- <button @click="editleave(item)" class="md-warning">แก้ไข</button> -->
            </md-table-cell>
            <md-table-cell md-label="ผู้อนุมัติ">
              <div v-if="item.approver_firstname">
                {{ item.approver_firstname }} {{ item.approver_lastname }}
              </div>
              <div v-else>รอผู้อนุมัติ</div>
            </md-table-cell>
            <md-table-cell md-label="วันที่อนุมัติ">
              {{ item.created_at }}
            </md-table-cell>
            <md-table-cell md-label="หมายเหตุ">{{ item.notes }}</md-table-cell>
            <md-table-cell md-label="Action">
              <b-button
                v-if="permissions.includes('process salary') && persons != 0"
                @click="editLeave(item, 'oneday')"
                size="md"
                variant="warning"
              >
                แก้ไข
              </b-button>
              <b-button
                v-if="
                  item.approve_status == 'leaverequest' ||
                    (permissions.includes('process salary') && persons != 0)
                "
                @click="deleteLeave(item)"
                size="md"
                variant="danger"
              >
                ลบ
              </b-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-table v-if="totaldatewithoutpay">
          <md-table-row>
            <md-table-head> ผลรวมวันลาไม่รับค่าจ้าง</md-table-head>
            <md-table-head>
              {{ totaldatewithoutpay.total_leave }}
            </md-table-head>
            <!-- <md-table-head> เหลือวันลา </md-table-head> -->
            <!-- <md-table-head>
              {{
                totaldatewithoutpay.leave_day +
                  totaldatewithoutpay.leave_remain -
                  totaldatewithoutpay.total_leave
              }}
              (
              {{ totaldatewithoutpay.leave_day }}
              <span v-if="totaldatewithoutpay.leave_remain > 0">+</span>
              <span v-if="totaldatewithoutpay.leave_remain > 0">
                &nbsp;{{ totaldatewithoutpay.leave_remain }}
              </span>
              )
            </md-table-head> -->
          </md-table-row>
        </md-table>
        <br />
        <md-table v-if="!old_leave">
          <md-table-row>
            <md-table-head>From/ จาก</md-table-head>
            <md-table-head>To / ถึง</md-table-head>
            <!-- <md-table-head>PDF หรือ ไฟล์รูป</md-table-head> -->
            <md-table-head>หมายเหตุ</md-table-head>
            <md-table-head>Action</md-table-head>
          </md-table-row>
          <md-table-row>
            <md-table-cell md-label="From/ จาก">
              <b-datepicker
                variant="primary"
                placeholder="วันที่เริ่ม"
                locale="th"
                v-model="selectdate.from_date"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :date-disabled-fn="disabledFromDates"
              ></b-datepicker>
              <md-checkbox class="md-info" v-model="is_leavestart_half">
                ลาครึ่งวัน
              </md-checkbox>
              <md-field v-show="is_leavestart_half">
                <label>เลือกช่วงการลา</label>
                <md-select v-model="from_date_type">
                  <md-option value="hm">ช่วงเช้า </md-option>
                  <md-option value="hn">ช่วงบ่าย </md-option>
                </md-select>
              </md-field>
            </md-table-cell>
            <md-table-cell md-label="To / ถึง">
              <b-datepicker
                variant="primary"
                placeholder="วันที่สิ้นสุด"
                locale="th"
                v-model="selectdate.to_date"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :date-disabled-fn="disabledToDates"
              ></b-datepicker>
              <div v-show="is_samedate">
                <md-checkbox v-model="is_leaveend_half">
                  ลาครึ่งวัน
                </md-checkbox>
                <md-field v-show="is_leaveend_half">
                  <label>เลือกช่วงการลา</label>
                  <md-select v-model="to_date_type">
                    <md-option value="hm">ช่วงเช้า </md-option>
                    <md-option value="hn">ช่วงบ่าย </md-option>
                  </md-select>
                </md-field>
              </div>
            </md-table-cell>
            <md-table-cell md-label="หมายเหตุ">
              <md-field>
                <label>หมายเหตุ</label>
                <md-textarea v-model="notes"></md-textarea>
              </md-field>
            </md-table-cell>
            <md-table-cell md-label="Action">
              <b-button @click="createleave" pill variant="success">
                ลาไม่รับค่าจ้าง
              </b-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
// import { Tabs } from '@/components';
// import moment from 'moment';
// import { ValidationError } from '@/components';
// import Vuex from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
export default {
  components: {},
  mixins: [employeeMixin],
  data() {
    return {
      profile: {},
      available_leaves: null,

      selectdate: {
        from_date: null,
        to_date: null,
      },
      is_leavestart_half: false,
      is_leaveend_half: false,
      is_samedate: false,
      from_date_type: null,
      to_date_type: null,
      notes: '',
    };
  },

  props: {
    leavewithoutpay: {
      type: Array,
      default: () => ({}),
    },
    totaldatewithoutpay: {
      type: Object,
      default: null,
    },
    permissions: {
      type: Array,
      default: () => ({}),
    },
    old_leave: {
      type: Boolean,
      default: false,
    },
    persons: {
      type: Number,
      default: 0,
    },
  },

  async created() {
    // console.log(this.leavewithoutpay);
    // await this.getProfileData();
    // this.get();
  },

  computed: {
    // employees: function () {
    //   return JSON.parse(localStorage.employees);
    // },
    // ...Vuex.mapState({
    //   me: (state) => state.profile.me,
    // }),
    // ...Vuex.mapState({
    //   staffleaves: (state) => state.staffleaves.staffleave,
    // }),
  },
  watch: {
    is_leavestart_half: function(status) {
      if (status == false) {
        this.from_date_type = '';
      }
    },
    is_leaveend_half: function(status) {
      if (status == false) {
        this.to_date_type = '';
      }
    },
    selectdate: {
      handler() {
        this.check_date();
      },
      deep: true,
    },
    resetform: function() {
      if (this.resetform == true) {
        this.ResetForm();
        this.$emit('resetform', false);
      }
    },
  },
  methods: {
    disabledFromDates(passdate) {
      let fdate = moment(passdate);
      let tdate = moment();
      let datediff = fdate.diff(tdate, 'days');
      return datediff < -120;
    },

    disabledToDates(passdate) {
      let fdate = moment(passdate);
      let tdate = moment(this.selectdate.from_date);
      let datediff = fdate.diff(tdate, 'days');
      return datediff < 0;
    },
    check_date() {
      if (this.selectdate.from_date && this.selectdate.to_date) {
        let from_date = moment(this.selectdate.from_date).format('YYYY-MM-DD');
        let to_date = moment(this.selectdate.to_date).format('YYYY-MM-DD');
        if (from_date > to_date) {
          this.$store.dispatch('alerts/error', 'วันที่กรอกให้ถูกต้อง');
          this.selectdate.to_date = this.selectdate.from_date;
        }
        if (from_date == to_date) {
          this.to_date_type = null;

          this.is_samedate = false;
        } else {
          this.is_samedate = true;
        }
      }
    },

    editLeave(leave) {
      this.$emit('editLeave', leave);
    },

    deleteLeave(leave) {
      this.$emit('deleteLeave', leave);
    },
    async createleave() {
      var item = {};
      item.leave_id = 6;
      if (this.selectdate.from_date != null) {
        let fromdate = this.selectdate.from_date;
        item.from_date = fromdate;
      } else {
        this.$store.dispatch('alerts/error', 'กรุณากรอกวันที่ให้ครบ');
        return false;
      }

      if (this.selectdate.to_date != null) {
        let todate = this.selectdate.to_date;
        item.to_date = todate;
      } else {
        this.$store.dispatch('alerts/error', 'กรุณากรอกวันที่ให้ครบ');
        return false;
      }

      if (this.is_samedate == false) {
        if (this.from_date_type == 'hm' || this.from_date_type == 'hn') {
          item.from_date_type = this.from_date_type;
          item.to_date_type = this.from_date_type;
        } else {
          item.from_date_type = 'f';
          item.to_date_type = 'f';
        }
      } else {
        if (this.from_date_type == 'hm' || this.from_date_type == 'hn') {
          item.from_date_type = this.from_date_type;
        } else {
          item.from_date_type = 'f';
        }

        if (this.to_date_type == 'hm' || this.to_date_type == 'hn') {
          item.to_date_type = this.to_date_type;
        } else {
          item.to_date_type = 'f';
        }
      }
      item.notes = this.notes;
      this.$emit('createleave', item);
    },
  },
};
</script>
<style></style>
