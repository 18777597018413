<template>
  <div class="chat-room">
    <div v-if="detail?.name" class="messages">
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="message-bubble"
        :class="{
          right: message.emp === 'sender',
          left: message.emp !== 'sender',
        }"
      >
        <div v-if="message.emp !== 'sender'" class="message-sender-name">
          {{ message.emp }}
        </div>
        <div class="message-box">
          <p class="message-text">{{ message.message }}</p>
          <span class="message-time">{{ message.timestamp }}</span>
        </div>
      </div>
    </div>
    <div v-else class="no-chat-selected">
      <p>ยังไม่ได้เลือกแชท</p>
    </div>

    <div class="message-input" v-if="detail?.name">
      <input
        v-model="message"
        placeholder="Type something here..."
        @keyup.enter="sendMessage"
      />
      <button @click="sendMessage"><i class="fas fa-paper-plane"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstMessage: { type: Boolean, required: false },
    messages: {
      type: Array,
      default: () => [],
    },
    detail: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      message: '',
    };
  },
  mounted() {
    this.scrollToBottom();
  },
  watch: {
    messages() {
      this.scrollToBottom();
    },
  },
  methods: {
    async sendMessage() {
      if (this.message.trim() === '') return;
      const messenger = this.detail;
      messenger.message = this.message;

      let resp = await this.$store.dispatch('messenger/send', messenger);

      if (resp.status) {
        const timestamp = new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        this.messages.push({
          emp: 'sender',
          message: this.message,
          timestamp: timestamp,
        });
        this.message = '';
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const messagesContainer = this.$el.querySelector('.messages');
        if (messagesContainer) {
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      });
    },
  },
};
</script>

<style scoped>
.chat-room {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f5f7fa;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  box-sizing: border-box;
  background: #fafafa;
}

.no-chat-selected {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #666;
}

.message-bubble {
  width: fit-content;
  max-width: 70%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.message-box {
  margin: 8px;
  padding: 12px;
  border-radius: 18px;
  background: #e0e0e0;
  transition: all 0.3s ease;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.message-time {
  color: #666;
  font-size: 0.75rem;
  opacity: 0.8;
  position: absolute;
  bottom: -18px;
  right: 8px;
}

.message-text {
  margin: 0;
  padding: 0 8px;
  font-size: 0.95rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.message-input {
  padding: 16px;
  background: white;
  border-top: 1px solid #e0e0e0;
  display: flex;
  gap: 8px;
  border-radius: 0 0 16px 16px;
}

.message-input input {
  flex: 1;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  background: #f5f7fa;
  font-size: 1rem;
}

.message-input button {
  background: linear-gradient(135deg, #2196f3, #21cbf3);
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.message-input button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* ข้อความของผู้ส่ง (sender) - อยู่ทางขวา */
.right {
  margin-left: auto;
}

.right .message-box {
  background: linear-gradient(135deg, #2196f3, #21cbf3);
  color: white;
}

.right .message-time {
  right: 8px;
  left: auto;
}

/* ข้อความของผู้อื่น - อยู่ทางซ้าย */
.left {
  margin-right: auto;
}

.left .message-box {
  background: #e0e0e0;
  color: #333;
}

.left .message-time {
  left: 8px;
  right: auto;
}

/* ชื่อผู้ส่ง (emp) สำหรับฝั่งซ้าย */
.message-sender-name {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 4px;
  margin-left: 8px;
}
</style>
