import service from '@/store/services/staffs-service';

const state = {
  list: {},
  staff: {},
  meta: {},
  staffrole: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, staff) => {
    state.staff = staff;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_STAFFROLE: (state, staffrole) => {
    state.staffrole = staffrole;
  },
};

const actions = {
  searchStaff({ commit, dispatch }, params) {
    return service.searchStaff(params).then((list) => {
      return list;
      // commit('SET_LIST', list);
      // commit('SET_META', meta);
    });
  },

  searchImgStaff({ commit, dispatch }, params) {
    return service.searchImgStaff(params).then((list) => {
      return list;
      // commit('SET_LIST', list);
      // commit('SET_META', meta);
    });
  },

  getdepartment({ commit, dispatch }, params) {
    return service.getdepartment(params).then((list) => {
      return list;
    });
  },
  getposition({ commit, dispatch }, params) {
    return service.getposition(params).then((list) => {
      return list;
    });
  },
  getpos_dep({ commit, dispatch }, params) {
    return service.getpos_dep(params).then((list) => {
      return list;
    });
  },

  getEmployeeToEdit({ commit, dispatch }, params) {
    return service.getEmployeeToEdit(params).then((data) => {
      return data;
    });
  },

  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
      commit('SET_META', meta);
      return list;
    });
  },

  // get({ commit, dispatch }, params) {
  //   return service.get(params).then((staff) => {
  //     commit('SET_RESOURCE', staff);
  //   });
  // },

  // add({ commit, dispatch }, params) {
  //   return service.add(params).then((staff) => {
  //     commit('SET_RESOURCE', staff);
  //   });
  // },

  // update({ commit, dispatch }, params) {
  //   return service.update(params).then((staff) => {
  //     commit('SET_RESOURCE', staff);
  //   });
  // },

  // destroy({ commit, dispatch }, params) {
  //   return service.destroy(params);
  // },

  getStaffRole({ commit, dispatch }, params) {
    return service.getStaffRole(params).then((staffrole) => {
      commit('SET_STAFFROLE', staffrole);
    });
  },

  assignUserRole({ commit, dispatch }, params) {
    return service.assignUserRole(params).then((staffrole) => {
      commit('SET_STAFFROLE', staffrole);
    });
  },

  async getlistEmp({ commit, dispatch }, params) {
    return await service.getlistEmp(params);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  staff: (state) => state.staff,
  staffrole: (state) => state.staffrole,
};

const staffs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default staffs;
