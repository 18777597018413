import service from '@/store/services/system-service';

const state = {
  clientVersion: process.env.VUE_APP_VERSION,
  serverVersion: null,
};

const mutations = {};

const actions = {
  async version({ commit, dispatch }, params) {
    try {
      const response = await service.getVersion(params);
      return response.data.version;
    } catch (e) {}
  },
};

const getters = {};

const memo = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default memo;
