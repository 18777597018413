export default {
  name: 'MemoTemplate',
  methods: {
    getBaseTemplate({ branchLogo, memorandam }) {
      return {
        schemas: [
          [
            this.getBranchLogoTemplate({ branchLogo }),
            this.getMemorandumTemplate(),
            this.getMemorandumTableTemplate({ memorandam }),
          ],
          [this.getBranchLogoTemplate({ branchLogo })],
        ],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      };
    },
    getBranchLogoTemplate({ branchLogo }) {
      return {
        name: 'branch_logo',
        type: 'image',
        content: branchLogo,
        position: { x: 66.48, y: 0.79 },
        width: 77.02,
        height: 15.64,
        rotate: 0,
        opacity: 1,
        readOnly: true,
        required: false,
      };
    },
    getMemorandumTemplate() {
      return {
        name: 'memorandum',
        type: 'text',
        content: 'MEMORANDUM',
        position: { x: 65.96, y: 18.99 },
        width: 78.06,
        height: 6.02,
        rotate: 0,
        alignment: 'center',
        verticalAlignment: 'top',
        fontSize: 16,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: '#000000',
        backgroundColor: '',
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: true,
        fontName: 'Angsana New Bold',
      };
    },
    getMemorandumTableTemplate({ memorandam }) {
      return {
        name: 'memorandam_table',
        type: 'table',
        position: { x: 20.5, y: 31 },
        width: 169,
        height: 24,
        content: `[["FROM: ${memorandam?.fromText}","PAGE: ${memorandam?.pageText}"],["SUBJECT: ${memorandam?.subjectText}","CC: ${memorandam?.cc}"]]`,
        showHead: true,
        head: [`TO: ${memorandam?.toText}`, `DATE: ${memorandam?.dateMemo}`],
        headWidthPercentages: [50, 50],
        tableStyles: { borderWidth: 0.03, borderColor: '#000000' },
        headStyles: {
          fontName: 'Angsana New Bold',
          fontSize: 16,
          characterSpacing: 0,
          alignment: 'left',
          verticalAlignment: 'middle',
          lineHeight: 1,
          fontColor: '#000000',
          borderColor: '#000000',
          backgroundColor: '#ffffff',
          borderWidth: {
            top: 0.03,
            right: 0.03,
            bottom: 0.03,
            left: 0.03,
          },
          padding: { top: 1, right: 4, bottom: 1, left: 4 },
        },
        bodyStyles: {
          fontName: 'Angsana New Bold',
          fontSize: 16,
          characterSpacing: 0,
          alignment: 'left',
          verticalAlignment: 'middle',
          lineHeight: 1,
          fontColor: '#000000',
          borderColor: '#000000',
          backgroundColor: '#ffffff',
          alternateBackgroundColor: '#ffffff',
          borderWidth: {
            top: 0.03,
            right: 0.03,
            bottom: 0.03,
            left: 0.03,
          },
          padding: { top: 1, right: 4, bottom: 1, left: 4 },
        },
        columnStyles: {},
        required: false,
        readOnly: false,
      };
    },
    getTemplateSignatureTable(signature, positions) {
      return {
        name: signature.name ?? 'signature_table',
        type: 'table',
        position: {
          x: positions.x ?? 15,
          y: positions.y ?? 15,
        },
        width: 50,
        height: 20,
        content: `[["....................................."],["${signature.empName}"],["${signature.empPosition}"]]`,
        showHead: false,
        head: ['SIGNATURE'],
        headWidthPercentages: [100],
        tableStyles: {
          borderWidth: 0,
          borderColor: '',
        },
        headStyles: {
          fontName: 'Angsana New',
          fontSize: 16,
          characterSpacing: 0,
          alignment: 'center',
          verticalAlignment: 'top',
          lineHeight: 0.75,
          fontColor: '#ffffff',
          borderColor: '',
          backgroundColor: '#2980ba',
          borderWidth: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          padding: {
            top: 1,
            right: 2,
            bottom: 1,
            left: 2,
          },
        },
        bodyStyles: {
          fontName: 'Angsana New',
          fontSize: 16,
          characterSpacing: 0,
          alignment: 'center',
          verticalAlignment: 'middle',
          lineHeight: 1,
          fontColor: '#000000',
          borderColor: '',
          backgroundColor: '',
          alternateBackgroundColor: '',
          borderWidth: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          padding: {
            top: 0,
            right: 2,
            bottom: 2,
            left: 2,
          },
        },
        columnStyles: {
          alignment: {
            '0': 'center',
          },
        },
        required: false,
        readOnly: true,
      };
    },
  },
};
