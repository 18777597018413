<template>
  <div>
    <h1>อัปโหลดกล้อง</h1>

    <input
      type="file"
      accept="image/*,video/*"
      capture="environment"
      @change="handleFileChange"
    />

    <p>{{ output }}</p>

    <img
      v-if="previewType === 'image'"
      :src="previewUrl"
      alt="พรีวิวภาพ"
      style="max-width: 300px;"
    />
    <video
      v-if="previewType === 'video'"
      :src="previewUrl"
      controls
      style="max-width: 300px;"
    ></video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewUrl: '',
      previewType: '',
      output: '',
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        const fileURL = URL.createObjectURL(file);

        if (file.type.startsWith('image/')) {
          this.previewUrl = fileURL;
          this.previewType = 'image';
          this.output = `ภาพที่จับได้: ${file.name}`;
        } else if (file.type.startsWith('video/')) {
          this.previewUrl = fileURL;
          this.previewType = 'video';
          this.output = `วิดีโอที่จับได้: ${file.name}`;
        } else {
          this.previewType = '';
          this.output = 'ประเภทไฟล์ไม่รองรับ';
        }
      }
    },
  },
};
</script>

<style scoped></style>
