<template>
  <div>
    <!-- Staffwork -->
    <div
      class="md-layout-item md-size-100 md-medium-size-100 md-xsmall-size-100"
    >
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title"></h4>
        </md-card-header>
        <md-card-content>
          <salary-calculate :permissions="permissions" />
        </md-card-content>
      </md-card>
    </div>
    <!-- Staffwork -->
  </div>
</template>
<script>
import Vuex from 'vuex';
import moment from 'moment';
import employeeMixin from '@/mixins/employee-mixin';

import SalaryCalculate from '@/pages/Dashboard/Pages/Staff/Transactions/SalaryCalculate/SalaryCalculate.vue';
// import SalaryPeriod from '@/components/SalaryPeriod';
// import SelectMonths from '@/components/SelectMonths';
// import SalaryProcess from "./SalaryProcessPage.vue";

import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {
    'salary-calculate': SalaryCalculate,
    // 'salary-period': SalaryPeriod,
    // 'select-months': SelectMonths,
  },
  mixins: [employeeMixin],
  data() {
    return {
      moment: moment,
    };
  },

  async created() {
    if (localStorage.getItem('privatecode')) {
      this.disabled_pvcode = true;
      this.invalid_privatecode = false;
    }
    await this.getBranchs();
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    permissions() {
      var per = [];
      if (this.me != null) {
        this.me.permissions.map((permis) => {
          per.push(permis.name);
        });
        this.me.roles[0].permissions.map((permission) => {
          per.push(permission.name);
        });
      }
      return per;
    },
  },

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.md-tabs-content table thead {
  display: contents;
}
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.total-price {
  font-weight: 500;
}
.group-total-price {
  font-family: math;
  font-style: italic;
  font-weight: 500;
}
// .table-shopping .md-table-head:nth-child(5),
// .table-shopping .md-table-head:nth-child(7),
// .table-shopping .md-table-head:nth-child(6) {
//   text-align: right;
// }
</style>
