<template>
  <div>
    <!-- Modal with fade transition -->
    <transition name="fade">
      <div
        v-if="isOpen"
        class="modal fade"
        :class="{ 'show': isOpen, 'd-block': isOpen }"
        @mousedown.self="handleClose"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content shadow-lg rounded-3">
            <div class="modal-header border-0 pb-2">
              <h5 class="modal-title">Signature Pad</h5>
              <button type="button" class="close" @click="handleClose">
                <i class="fas fa-times"></i>
              </button>
            </div>

            <div class="modal-body">
              <canvas ref="signatureCanvas" class="signature-canvas"></canvas>

              <div class="d-flex justify-content-between mt-3">
                <button
                  class="btn btn-outline-secondary"
                  @click="clearSignature"
                >
                  <i class="fas fa-eraser"></i> Clear
                </button>
              </div>

              <div class="form-check mt-3">
                <input
                  type="checkbox"
                  id="rememberSignature"
                  v-model="rememberSignature"
                  class="form-check-input"
                />
                <label for="rememberSignature" class="form-check-label">
                  Remember Signature
                </label>
              </div>
            </div>

            <div class="modal-footer border-0 pt-2">
              <button
                type="button"
                class="btn btn-success w-100 py-2"
                @click="saveSignature"
                :disabled="!canSubmit"
              >
                <i class="fas fa-save"></i> {{ buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="isOpen" class="modal-backdrop fade show"></div>
    </transition>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    forceSignatureBeforeClose: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'SUBMIT_SIGNATURE',
    },
  },
  data() {
    return {
      signaturePad: null,
      signature: '',
      rememberSignature: false,
    };
  },
  computed: {
    canSubmit() {
      return !this.forceSignatureBeforeClose || this.getSignature() !== '';
    },
  },
  mounted() {
    if (this.isOpen) {
      this.openModal();
    }
    this.loadSignature();
  },
  watch: {
    isOpen(val) {
      if (val) this.openModal();
    },
  },
  methods: {
    openModal() {
      this.$nextTick(() => {
        const canvas = this.$refs.signatureCanvas;
        if (canvas) {
          this.signaturePad = new SignaturePad(canvas, { penColor: '#000' });
          this.resizeCanvas();
          this.loadSignature(); // Load the signature after initializing the pad
        }
      });
    },
    handleClose() {
      this.$emit('onClose');
    },
    resizeCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d').scale(ratio, ratio);
      this.signaturePad.clear();
    },
    getSignature() {
      return this.signaturePad?.isEmpty() ? '' : this.signaturePad?.toDataURL();
    },
    clearSignature() {
      this.signaturePad.clear();
    },
    saveSignature() {
      if (this.canSubmit) {
        this.signature = this.getSignature();
        if (this.rememberSignature) {
          localStorage.setItem('signature', this.signature);
        }
        this.$emit('onSubmit', this.signature);
      } else {
        this.$emit('onRequireSignature');
      }
    },
    loadSignature() {
      const storedSignature = localStorage.getItem('signature');
      if (storedSignature && this.signaturePad) {
        this.signature = storedSignature;
        this.signaturePad.fromDataURL(storedSignature);
        this.rememberSignature = true;

        this.$store.dispatch(
          'alerts/success',
          'Signature loaded successfully!',
        );
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal {
  display: none;
}

.modal.show {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 500px;
  border-radius: 10px;
}

.signature-canvas {
  width: 100%;
  height: 200px;
  border: 2px solid #007bff;
  border-radius: 10px;
  background-color: white;
}

.btn {
  font-size: 1rem;
  text-transform: uppercase;
}

.btn-outline-secondary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-secondary:hover {
  background-color: #007bff;
  color: white;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.modal-header {
  padding-bottom: 0.5rem;
  font-weight: 600;
}

.modal-footer {
  padding-top: 0.5rem;
}

.d-flex {
  justify-content: space-between;
}

.form-check {
  margin-top: 1rem;
}

.modal-body {
  padding-bottom: 20px;
}

.modal-footer {
  padding-top: 0.75rem;
}
</style>
