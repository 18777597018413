<template>
  <div class="chat-list">
    <div
      class="chat-item"
      v-for="list in lists"
      :key="list.id"
      @click="selectList(list)"
    >
      <div v-if="list.avatar" class="avatar-container">
        <img :src="list.avatar" alt="User Avatar" class="avatar" />
      </div>
      <div v-else class="avatar-logo">
        {{ list.type }}
      </div>
      <div class="chat-info">
        <div class="chat-header">
          <span class="username">{{ list.name }}</span>
          <span class="timestamp">{{ list.detail.lastMessageTime }}</span>
        </div>
        <p class="last-message">{{ list.detail.lastMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lists: {
      type: Array,
    },
  },
  methods: {
    selectList(list) {
      this.$emit('list-selected', list);
    },
  },
};
</script>

<style scoped>
.chat-list {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-item:hover {
  background-color: #f9f9f9;
}

.avatar-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-logo {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 35px; /* ลดขนาดลงจาก 40px */
  height: 35px; /* ลดขนาดลงจาก 40px */
  background-color: #ccc;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 14px; /* ลดขนาดตัวอักษรให้พอดี */
  text-overflow: ellipsis; /* เมื่อข้อความยาวเกินจะตัดข้อความ */
  white-space: nowrap; /* ไม่ให้ข้อความตัดบรรทัด */
  overflow: hidden; /* ซ่อนข้อความที่ยาวเกิน */
}

.chat-info {
  flex: 1;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.username {
  font-weight: bold;
  font-size: 16px;
}

.timestamp {
  font-size: 12px;
  color: #888;
}

.last-message {
  font-size: 14px;
  color: #666;
  margin: 0;
}
</style>
