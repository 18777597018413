<template>
  <div>
    <md-card>
      <div class="header" @click="headdropdown()">
        <md-card-header class="md-card-header-icon md-card-header-warning">
          <div class="card-icon">
            <md-icon>transfer_within_a_station</md-icon>
          </div>

          <h4 class="title">
            ขออนุญาตออกนอกสถานที่ (Out / In Resort During Duty)
          </h4>
        </md-card-header>
      </div>

      <md-card-content class="contentleave">
        <div v-if="this.years == moment().year()">
          <ValidationObserver>
            <form @submit.prevent="submit">
              <div class="md-layout spacing md-alignment-center md-gutter">
                <div class="md-layout-item md-size-100">
                  <h5>ขออนุญาตออกนอกสถานที่</h5>
                </div>
                <div class="md-layout-item md-size-100">
                  <div class="md-layout spacing md-gutter">
                    <div class="md-layout-item md-size-5"></div>
                    <div
                      class="md-layout-item md-size-75 md-medium-size-70 md-small-size-70 md-xsmall-size-100"
                    >
                      <!-- <ValidationProvider
                      name="leave_premises_type"
                      rules="required"
                      v-slot="{ errors }"
                    > -->
                      <div>
                        <!-- <span v-if="errors.length">{{ errors[0] }}</span> -->
                        <md-radio
                          v-model="leave_premises_type"
                          value="according_boss_orders"
                          >ไปปฎิบัติงานนอกรีสอร์ท ตามคำสั่งหัวหน้า
                          <!-- <small>(Default)</small> -->
                        </md-radio>
                        <md-radio
                          v-model="leave_premises_type"
                          value="personal_business"
                          class="md-primary"
                          >ไปทำธุระส่วนตัว</md-radio
                        >
                        <md-radio
                          v-model="leave_premises_type"
                          value="work_different_branches"
                          class="md-primary"
                          >ปฎิบัติงานต่างสาขา</md-radio
                        >
                      </div>
                      <!-- </ValidationProvider> -->
                    </div>
                  </div>
                  <div
                    class="md-layout spacing md-gutter"
                    style="margin-top: -25px;"
                    v-show="
                      leave_premises_type == 'according_boss_orders' ||
                        leave_premises_type == 'personal_business'
                    "
                  >
                    <div
                      class="md-layout-item md-size-5 md-medium-size-5 md-small-size-5 md-xsmall-size-100"
                    ></div>
                    <div
                      class="md-layout-item spacing md-size-25 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-field style="margin-bottom: 10px;">
                        <label for="go_to_location">สถานที่</label>
                        <md-input
                          name="go_to_location"
                          v-model="go_to_location"
                        />
                      </md-field>
                    </div>
                  </div>
                  <div
                    class="md-layout spacing md-gutter"
                    style="margin-top: -25px;"
                    v-show="leave_premises_type == 'work_different_branches'"
                  >
                    <div
                      class="md-layout-item md-size-5 md-medium-size-5 md-small-size-5 md-xsmall-size-100"
                    ></div>
                    <div
                      class="md-layout-item spacing md-size-25 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-field class="branchs">
                        <label>เลือก สำนักงาน</label>
                        <md-select v-model="branches" name="branch_id">
                          <md-option
                            v-for="item in available_branches"
                            :key="item.bch_id"
                            :value="item.bch_id"
                          >
                            {{ item.brname_th }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                  <div
                    class="md-layout spacing md-gutter mtm-15"
                    v-show="leave_premises_type != null"
                  >
                    <div
                      class="md-layout-item md-size-5 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    ></div>
                    <div
                      class="md-layout-item spacing md-size-25 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                    >
                      <ValidationProvider
                        name="fromdate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <b-datepicker
                          class="space-bt"
                          variant="primary"
                          placeholder="วันที่เริ่ม"
                          locale="th"
                          v-model="fromdate"
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                          :date-disabled-fn="disabledDateBefore"
                        >
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">
                              done
                            </md-icon>
                          </slide-y-down-transition>
                        </b-datepicker>
                      </ValidationProvider>
                      <ValidationProvider
                        name="time_fromdate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <VueCtkDateTimePicker
                          id="time_fromdate"
                          input-size="lg"
                          v-model="time_fromdate"
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                          :only-time="true"
                          :noLabel="true"
                          :minute-interval="15"
                          :disable-date="false"
                          :format="'HH:mm'"
                          :formatted="'HH:mm'"
                          :label="'เวลาที่เริ่ม'"
                        />
                      </ValidationProvider>
                    </div>
                    <div
                      class="md-layout-item spacing md-size-25 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                    >
                      <ValidationProvider
                        name="todate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <b-datepicker
                          class="space-bt"
                          variant="primary"
                          placeholder="วันที่สิ้นสุด"
                          locale="th"
                          v-model="todate"
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                          :date-disabled-fn="disabledDateBefore"
                        >
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">
                              done
                            </md-icon>
                          </slide-y-down-transition>
                        </b-datepicker>
                      </ValidationProvider>
                      <ValidationProvider
                        name="time_todate"
                        rules="required"
                        v-slot="{ passed, failed }"
                      >
                        <VueCtkDateTimePicker
                          id="time_todate"
                          input-size="lg"
                          v-model="time_todate"
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed },
                          ]"
                          :only-time="true"
                          :noLabel="true"
                          :minute-interval="15"
                          :disable-date="true"
                          :format="'HH:mm'"
                          :formatted="'HH:mm'"
                          :label="'เวลาที่สิ้นสุด'"
                          :overlay="false"
                        />
                      </ValidationProvider>
                    </div>
                  </div>

                  <div
                    class="md-layout spacing md-gutter mtm-15"
                    v-show="leave_premises_type != null"
                  >
                    <div
                      class="md-layout-item md-size-5 md-medium-size-5 md-small-size-5 md-xsmall-size-100"
                    ></div>
                    <div
                      class="md-layout-item spacing md-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
                    >
                      <md-field>
                        <label>หมายเหตุ</label>
                        <md-textarea v-model="remark"></md-textarea>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout spacing md-gutter">
                    <div
                      class="md-layout-item md-size-5 md-medium-size-5 md-small-size-5 md-xsmall-size-100"
                    ></div>
                    <div
                      class="md-layout-item md-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-100  "
                    >
                      <b-button
                        type="submit"
                        pill
                        variant="success"
                        :disabled="btn_send_approve_dis"
                      >
                        ยื่นขออนุญาต
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-if="history.length > 0">
          <!-- <md-table class="minheight" v-model="history"> -->
          <md-table class="" v-model="history">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="No.">
                {{ history.indexOf(item) + 1 }}
              </md-table-cell>

              <md-table-cell md-label="ช่วงเวลา">
                {{
                  `${convertToThaiDateTime(
                    item.from_datetime,
                  )} ถึง ${convertToThaiDateTime(item.to_datetime)}`
                }}
              </md-table-cell>

              <md-table-cell md-label="คำขอ">
                {{
                  `${convertTypeToText(item.leave_premises_type)} ที่ ${
                    item.go_to_location
                  }`
                }}
              </md-table-cell>

              <md-table-cell md-label="หมายเหตุ">
                {{ `${item.emp_remark}` }}
              </md-table-cell>

              <md-table-cell md-label="สถานะ">
                <div v-if="item.status_approve == 3">
                  <span style="color: green">อนุมัติแล้ว</span>
                </div>
                <div v-else-if="item.status_approve == 1">
                  <span style="color: red">ไม่อนุมัติ</span>
                  เหตุผล : {{ item.reason }}
                </div>
                <div v-else-if="item.status_approve == 0">
                  <span>รออนุมัติ</span>
                </div>
              </md-table-cell>
              <md-table-cell md-label="ผู้อนุมัติ">
                {{ `${item.appv_name}` }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import moment from 'moment';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import Swal from 'sweetalert2';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueCtkDateTimePicker,
  },
  mixins: [employeeMixin],
  data() {
    return {
      moment: moment,
      available_branches: null,
      branches: '',
      leave_premises_type: null,
      fromdate: null,
      todate: null,
      time_fromdate: null,
      time_todate: null,
      go_to_location: '',
      btn_send_approve_dis: false,
      remark: '',
      history: [],
    };
  },

  props: {
    profileMe: {},
    years: {
      type: Number,
      default: moment().year(),
    },
  },

  async created() {
    this.getBranches();
    if (this.years) {
      this.getHistory();
    }
  },

  computed: {},
  watch: {
    branches(newBranches) {
      const result = this.available_branches.find(
        (branch) => newBranches === branch.bch_id,
      );
      if (result != undefined) {
        this.go_to_location = result.brname_en;
      }
    },

    leave_premises_type(newType) {
      if (newType == 'work_different_branches') {
        this.go_to_location = '';
        this.branches = '';
      } else {
        this.branches = '';
      }
    },
  },
  methods: {
    convertTypeToText(text) {
      var txt = ``;
      switch (text) {
        case 'according_boss_orders':
          txt = `ไปปฎิบัติงานนอกรีสอร์ท ตามคำสั่งหัวหน้า`;
          break;
        case 'personal_business':
          txt = `ไปทำธุระส่วนตัว`;
          break;
        case 'work_different_branches':
          txt = `ปฎิบัติงานต่างสาขา`;
          break;
      }
      return txt;
    },

    convertToThaiDateTime(dateString) {
      const [date, time] = dateString.split(' ');
      const [year, month, day] = date.split('-');
      const [H, i, s] = time.split(':');
      const thaiYear = parseInt(year);
      return `${day}/${month}/${thaiYear} ${H}:${i}`;
    },

    async getBranches() {
      let params = {
        com_id: this.profileMe.com_id,
        bch_only: '1',
      };
      await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
        this.available_branches = data;
      });
    },

    async getHistory() {
      let params = {
        year: this.years,
      };
      await this.$store
        .dispatch('staffleaves/out_premises_history', params)
        .then((data) => {
          this.history = data;
        });
    },

    disabledDateBefore(passdate) {
      let date = moment(passdate);
      let now = moment();
      let datediff = now.diff(date, 'days');

      // return datediff > -3;
      return datediff > 0;
    },

    clearForm() {
      this.leave_premises_type = null;
      this.fromdate = null;
      this.todate = null;
      this.time_fromdate = null;
      this.time_todate = null;
      this.go_to_location = '';
      this.btn_send_approve_dis = false;
      this.branches = '';
      this.remark = '';
    },

    async submit() {
      if (
        !this.leave_premises_type ||
        this.leave_premises_type == null ||
        this.leave_premises_type == undefined
      ) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกประเภทคำขอ');
        this.btn_send_approve_dis = false;
        return false;
      }

      if (
        this.go_to_location.trim() == '' &&
        this.leave_premises_type != 'work_different_branches'
      ) {
        await this.$store.dispatch('alerts/error', 'กรุณาใส่สถานที่');
        this.btn_send_approve_dis = false;
        return false;
      }

      if (moment(this.fromdate) != null) {
        var fromdate = moment(this.fromdate).format('YYYY-MM-DD');
        var todate = moment(this.todate).format('YYYY-MM-DD');
      } else {
        this.btn_send_approve_dis = false;
        return false;
      }

      let fromdate_time = moment(fromdate + ' ' + this.time_fromdate).format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let todate_time = moment(todate + ' ' + this.time_todate).format(
        'YYYY-MM-DD HH:mm:ss',
      );

      if (!moment(fromdate_time).isBefore(todate_time)) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกเวลาให้ถูกต้อง');
        this.btn_send_approve_dis = false;
        return false;
      }

      const confirmation = await Swal.fire({
        title: 'ยืนยันการขอ ?',
        text: 'หากยืนยันแล้วจะไม่สามารถแก้ไขได้',
        showCancelButton: true,
        confirmButtonText: 'Yes, Submit!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'md-button md-success',
          cancelButton: 'md-button md-danger',
        },
        preConfirm: () => {},
      });

      if (confirmation.value === true) {
        this.btn_send_approve_dis = true;
        if (this.profileMe.emp_id) {
          let data_set = {
            emp_id: this.profileMe.emp_id,
            emp_code: this.profileMe.emp_code,
            emp_bch_id: this.profileMe.bch_id,
            leave_premises_type: this.leave_premises_type,
            go_to_location: this.go_to_location,
            emp_remark: this.remark,
            from_date: fromdate,
            from_time: this.time_fromdate,
            from_datetime: fromdate_time,
            to_date: todate,
            to_time: this.time_todate,
            to_datetime: todate_time,
            branches: this.branches,
          };

          try {
            let res = await this.$store.dispatch(
              'staffleaves/out_premises_add',
              data_set,
            );
            this.btn_send_approve_dis = false;
            this.clearForm();
            Swal.fire({
              title: '',
              text: 'Success',
              icon: 'success',
            });
            this.getHistory();
            return res;
          } catch (error) {
            Swal.fire({
              title: '',
              text: error?.response?.data?.error ?? 'เกิดข้อผิดพลาด',
              icon: 'warning',
            });
            this.btn_send_approve_dis = false;
          }
        } else {
          await this.$store.dispatch('alerts/error', 'ไม่พบ Employee ID');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-radio {
  display: flex;
}
.mtm-15 {
  margin-top: -15px;
}
</style>
