<template>
  <div>
    <md-dialog :md-active.sync="showAdd" style="min-width: 60%;" md-fullscreen>
      <md-dialog-title style="font-size: 1.5rem">
        เพิ่มรอบเงินเดือน
      </md-dialog-title>
      <md-dialog-content style="font-size: 1.2rem">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <filter-employee
              :headLabel="'ผู้อนุมัติ'"
              :permissionsList="permissions"
              @childBranches="setBranchs"
              @childDepartment="setDepartments"
            >
            </filter-employee>
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <hr />
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <md-field>
              <label>ชื่อของรอบเงินเดือน</label>
              <md-input v-model="salarymonthly.monthly_name"></md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-70 md-medium-size-60 md-small-size-40 md-alignment-right"
          ></div>
          <div
            class="md-layout-item md-size-30 md-medium-size-40 md-small-size-60 md-alignment-right"
          ></div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-success" @click="insert_data()">
          บันทึก
        </md-button>
        <md-button class="md-danger" @click="showAdd = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                history
              </md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-30 mt-2 md-small-size-50 md-xsmall-size-100 md-alignment-left"
                  >
                    <md-field>
                      <label for="">ปี</label>
                      <md-select v-model="selectyear">
                        <md-option
                          :value="itemyear.years"
                          v-for="itemyear in listyear"
                          :key="itemyear.years"
                        >
                          {{ itemyear.years }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div
                    class="md-layout-item md-size-30 mt-2 md-small-size-50 md-xsmall-size-100 md-alignment-left"
                  >
                    <md-field>
                      <label for="">เดือน</label>
                      <md-select v-model="selectmonth">
                        <md-option
                          :value="itemmonth.months"
                          v-for="itemmonth in listmonth"
                          :key="itemmonth.months"
                        >
                          {{ itemmonth.months }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 mt-4 md-small-size-100 text-right"
              >
                <md-button @click="goToAdd" class="md-primary md-dense">
                  เพิ่มรอบเงินเดือน
                </md-button>
              </div>
            </div>
            <md-table
              :value="tableData"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ชื่อรอบเงินเดือน">
                  <span class="upfont">
                    {{ item.monthly_name }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="วันที่เริ่ม - สิ้นสุด">
                  <span class="upfont">
                    {{ item.from_date }} -
                    {{ item.to_date }}
                  </span>
                </md-table-cell>

                <md-table-cell md-label="เดือน">
                  {{ item.months }}
                </md-table-cell>
                <md-table-cell md-label="ปี">
                  {{ item.years }}
                </md-table-cell>
                <md-table-cell md-label="Action">
                  <b-button variant="warning" @click="goToEdit">
                    แก้ไข
                  </b-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import _ from 'lodash';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  data() {
    return {
      editData: {},
      tableData: [],
      sortation: {
        field: 'created_at',
        order: 'asc',
      },
      boolean: true,
      status: 'active',
      salarymonthly: {
        type: 'salarymonthly',
        id: null,
        monthly_name: null,
        from_date: null,
        to_date: null,
      },
      permissions: [],
      staffs: null,
      listyear: null,
      listmonth: null,
      listmonthly: null,
      selectyear: null,
      selectmonth: null,
      employee_id: null,
      departments: null,
      branchs: null,
      employee: null,
      showEdit: false,
      showAdd: false,
    };
  },
  async created() {
    await this.getListYear();
  },
  watch: {
    selectyear: function(val) {
      if (val != null) {
        this.selectmonth = null;
        this.getListMonth();
        this.getListMonthly();
      }
    },
    selectmonth: function(val) {
      if (val != null) {
        this.getListMonthly();
      }
    },
    uniqueapprover: {
      handler: function(val) {
        if (val.status != 'active') {
          val.status = 'inactive';
        }
      },
      deep: true,
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  methods: {
    async getListYear() {
      loading_start();
      let params = { profile: true };
      this.employee = await this.$store.dispatch('staffs/searchStaff', params);
      params = {
        com_id: this.employee[0].com_id,
      };
      this.listyear = await this.$store.dispatch(
        'salaryperiod/listYear',
        params,
      );
      loading_close();
    },

    async getListMonth() {
      loading_start();
      let params = {
        ...(this.selectyear ? { years: this.selectyear } : {}),
      };
      this.listmonth = await this.$store.dispatch(
        'salaryperiod/listMonth',
        params,
      );
      loading_close();
    },

    async getListMonthly() {
      loading_start();
      let params = {
        ...(this.employee[0].com_id ? { com_id: this.employee[0].com_id } : {}),
        ...(this.selectyear ? { years: this.selectyear } : {}),
        ...(this.selectmonth ? { months: this.selectmonth } : {}),
      };
      this.tableData = await this.$store.dispatch(
        'salaryperiod/listMonthly',
        params,
      );
      loading_close();
    },

    async insert_data() {
      if (
        this.uniqueapprover.emp_approver_id == null ||
        this.uniqueapprover.emp_request_id == null
      ) {
        this.$store.dispatch(
          'alerts/error',
          'กรุณาเลือกผู้อนุมัติและผู้ถูกอนุมัติ',
        );
        return false;
      }
      if (
        this.uniqueapprover.emp_approver_id ==
        this.uniqueapprover.emp_request_id
      ) {
        this.$store.dispatch('alerts/error', 'ไม่สามารถเลือกเป็นคนเดียวกันได้');
        return false;
      }
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        let params = { ...this.uniqueapprover };
        await this.$store
          .dispatch('uniqueapprover/add', params)
          .then((response) => {
            if (response == 304) {
              this.$store.dispatch(
                'alerts/error',
                'มีการตั้งค่าการอนุมัตินี้ไว้แล้ว',
              );
              return false;
            }
            this.$store.dispatch('alerts/success', 'Successfully.');
            this.uniqueapprover = {
              type: 'uniqueapprover',
              id: null,
              emp_approver_id: null,
              emp_request_id: null,
              status: 'active',
              description: '',
            };
            this.showEdit = false;
          });
      }
    },

    goToAdd() {
      this.showAdd = true;
    },

    goToEdit() {
      this.showEdit = true;
    },

    async switch_active(id, status) {
      var new_status = '';
      if (status == null) {
        new_status = 'inactive';
      } else {
        new_status = 'active';
      }
      let params = {
        type: 'uniqueapprover',
        id: id,
        edit_id: id,
        status: new_status,
      };
      await this.$store.dispatch('uniqueapprover/edit', params);
      await this.$store.dispatch('alerts/success', 'Successfully.');
    },

    // set branch value from child component
    async setBranchs(v) {
      if (v && v != null) {
        this.branchs = v;
      }
    },
    // set departments value from child component
    async setDepartments(v) {
      if (v && v != []) {
        this.departments = v;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },
  },
};
</script>
<style scoped>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
/* .searchstaffs {
  display: none;
} */
</style>
