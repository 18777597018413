<template>
  <div class="pdfme-designer" v-show="!hidden">
    <div class="pdfme-tools toolbar">
      <md-menu>
        <md-button class="md-raised md-primary button" @click="addNewPage">
          <md-icon>add</md-icon>
          New Page
        </md-button>
      </md-menu>

      <md-menu md-size="medium" md-align-trigger>
        <md-button class="md-raised md-secondary button" md-menu-trigger>
          <md-icon>settings</md-icon>
          Header Actions
        </md-button>

        <md-menu-content>
          <md-list-item @click="resetHeader">
            <div>
              <md-icon>refresh</md-icon>
              <span>Reset Header</span>
            </div>
          </md-list-item>
          <md-list-item @click="clearHeader">
            <div>
              <md-icon>clear</md-icon>
              <span>Clear Header</span>
            </div>
          </md-list-item>
        </md-menu-content>
      </md-menu>

      <md-menu md-size="medium" md-align-trigger>
        <md-button class="md-raised md-warn button" md-menu-trigger>
          <md-icon>picture_as_pdf</md-icon>
          PDF Actions
        </md-button>

        <md-menu-content>
          <md-list-item @click="usePdf">
            <div>
              <md-icon>picture_as_pdf</md-icon>
              <span>Use PDF</span>
            </div>
          </md-list-item>
          <md-list-item @click="unUsePdf">
            <div>
              <md-icon>delete</md-icon>
              <span>Un Use PDF</span>
            </div>
          </md-list-item>
        </md-menu-content>
      </md-menu>

      <md-menu>
        <md-button class="md-raised md-accent button" @click="preview">
          <md-icon>visibility</md-icon>
          Preview
        </md-button>
      </md-menu>
    </div>
    <div ref="editor" class="editor"></div>
  </div>
</template>
<script>
import { cloneDeep } from '@pdfme/common';
import { Designer } from '@pdfme/ui';
import { generate } from '@pdfme/generator';
import PdfmeHelperMixin from '@/components/Pdfme/plugins/PdfmeHelperMixin';

export default {
  mixins: [PdfmeHelperMixin],
  name: 'PdfmeEditor',
  props: {
    baseTemplate: {
      type: Object,
      default: () => ({
        schemas: [[]],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      }),
    },
    contentTemplate: {
      type: Object,
      default: () => ({
        schemas: [[]],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      }),
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    previewOnly: {
      type: Boolean,
      default: false,
    },
    uiType: {
      type: String,
      default: 'Form',
    },
  },
  data() {
    return {
      designer: null,
      viewer: null,
      updateInterval: null,
      lastChangeTime: null,
      isUpdatePending: false,
      pendingChanges: [],
    };
  },
  async mounted() {
    if (!this.previewOnly) {
      await this.setDesigner();

      let counter = 0;
      const interval = setInterval(() => {
        if (++counter > 5) return clearInterval(interval);

        const editor = this.$refs.editor;
        if (editor?.children?.length > 0) {
          clearInterval(interval);
          this.$emit('onMountedPdfme');
        }
      }, 500);
    }
    this.startUpdateInterval();
  },
  methods: {
    addNewPage() {
      const currentTemplate = this.designer.getTemplate();
      currentTemplate.schemas.push([]);
      // this.designer.updateTemplate(currentTemplate);

      this.checkForChanges();
      this.pendingChanges.push(currentTemplate);
      this.$emit('addPage', currentTemplate.schemas.length);
    },
    resetHeader() {
      this.$emit('resetHeader');
    },
    clearHeader() {
      this.clearTemplate(['branch_logo', 'memorandum', 'memorandam_table']);
    },
    usePdf() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/pdf';
      input.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const dataURL = await this.convertFileToDataURL(file);
          this.handlePDFDataURL(dataURL);
        }
      };
      input.click();

      this.$emit('usePdf', false);
      this.clearTemplate(['branch_logo', 'memorandum', 'memorandam_table']);
    },
    unUsePdf() {
      let template = this.designer.getTemplate();

      if (typeof template.basePdf == 'object') return false;

      template.basePdf = {
        width: 210,
        height: 297,
        padding: [0, 0, 0, 0],
      };
      this.checkForChanges();
      this.pendingChanges.push(template);

      this.$emit('unUsePdf', true);
      this.$emit('resetHeader');
    },
    preview() {
      this.generatePDF({ preview: true });
    },
    convertFileToDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    },
    handlePDFDataURL(dataURL) {
      this.onChangeBasePDF(dataURL);
    },
    startUpdateInterval() {
      this.updateInterval = setInterval(() => {
        if (this.isUpdatePending) {
          this.performUpdate();
          this.isUpdatePending = false;
          this.pendingChanges = [];
        }
      }, 400);
    },
    resetUpdateInterval() {
      clearInterval(this.updateInterval);
      this.startUpdateInterval();
    },
    checkForChanges() {
      this.isUpdatePending = true;
      this.resetUpdateInterval();
    },
    async performUpdate() {
      const mergedTemplate = this.mergeTemplates({
        pdfmeInstance: this.designer,
        pendingChanges: this.pendingChanges,
      });

      await this.designer.updateTemplate(mergedTemplate);
    },
    /**
     * Updates the content of a specific field in the designer template.
     *
     * @param {string} fieldName - The name of the field to update in the template.
     * @param {string} content - The new content to set for the specified field.
     * @param {number} [page=0] - The page number where the field is located (defaults to 0).
     *
     * @param key
     * @throws {Error} If the designer instance is not available.
     * @throws {Error} If the page does not exist in the template schemas.
     * @throws {Error} If an error occurs during the content update.
     *
     * @returns {void}
     */
    updateContent(fieldName, content, key = 'content', page = 0) {
      try {
        if (!this.designer) throw new Error('Designer instance not available');

        this.mergeTemplates({
          pdfmeInstance: this.designer,
          pendingChanges: [{ fieldName, content, key, page }],
          type: 'merge-content',
        });
        this.checkForChanges();
      } catch (error) {
        throw new Error(`Error updating content: ${error.message}`);
      }
    },
    async updateBaseTemplate(
      baseTemplate = this.baseTemplate,
      template = this.designer.getTemplate(),
    ) {
      try {
        const schemas = template?.schemas;

        if (!schemas || schemas.length === 0) {
          throw new Error('No schemas available in the template.');
        }

        schemas.forEach((page, pageIndex) => {
          const basePageTemplate =
            baseTemplate.schemas[pageIndex] || baseTemplate.schemas.at(-1);
          if (!basePageTemplate) {
            throw new Error(
              `Base template not found for page index ${pageIndex}`,
            );
          }

          const objBaseTemplate = basePageTemplate.reduce((acc, bt) => {
            acc[bt.name] = bt;
            return acc;
          }, {});

          page.forEach((item) => {
            if (objBaseTemplate[item.name]) {
              item = { ...objBaseTemplate[item.name] };
              delete objBaseTemplate[item.name];
            }
          });

          page.push(...Object.values(objBaseTemplate));
        });

        this.pendingChanges.push(template);
        this.checkForChanges();
      } catch (error) {
        throw new Error(`Error in updateBaseTemplate: ${error.message}`);
      }
    },
    async getTemplatePosition(template, newLine = false, pageIndex) {
      const existingSchemas =
        template.schemas[pageIndex ?? template.schemas.length - 1];
      const maxYValue = Math.max(
        ...existingSchemas.map((field) => field.position.y),
      );

      const maxYFields = existingSchemas.filter(
        (field) => field.position.y === maxYValue,
      );

      if (!maxYFields || maxYFields.length === 0) {
        return { x: 11.5, y: 11.5 };
      }

      const maxXField = maxYFields?.reduce((prev, curr) => {
        return curr.position.x + curr.width > prev.position.x + prev.width
          ? curr
          : prev;
      });

      const maxX = maxXField.position.x;
      const maxY = maxXField.position.y;
      const maxWidth = maxXField.width;
      const maxHeight = maxXField.height;

      const effectiveWidth = 210 - 50;
      const effectiveHeight = 297 - 50;

      let newY = maxY - 15;
      let newX = maxX + maxWidth + 1;
      if (newX > effectiveWidth || newLine) {
        newX = 11.5 + 5.5;
        newY = maxY + maxHeight + 7;
      }
      if (newY > effectiveHeight) {
        newY = effectiveHeight - maxHeight;
      }

      return { x: newX, y: newY };
    },
    base64ToBlob(base64, type = 'application/pdf') {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return new Blob([bytes], { type: type });
    },
    async setDesigner() {
      const font = await this.getFontsData();
      if (this.$refs.editor) {
        this.designer = new Designer({
          domContainer: this.$refs.editor,
          template: this.contentTemplate,
          options: {
            font,
            labels: {
              'signature.clear': '🗑️',
            },
            theme: {
              token: { colorPrimary: '#25c2a0' },
            },
            icons: {
              text: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="4 7 4 4 20 4 20 7"></polyline>
                        <line x1="9" x2="15" y1="20" y2="20"></line>
                        <line x1="12" x2="12" y1="4" y2="20"></line>
                     </svg>`,
              multiVariableText: `<svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24">
                                    <path d="M6.643,13.072,17.414,2.3a1.027,1.027,0,0,1,1.452,0L20.7,4.134a1.027,1.027,0,0,1,0,1.452L9.928,16.357,5,18ZM21,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"/>
                                  </svg>`,
            },
            maxZoom: 250,
          },
          plugins: this.getPlugins(),
        });
      }
    },
    clearTemplate(names = []) {
      if (names.length > 0) {
        const currentTemplate = this.designer.getTemplate();

        currentTemplate.schemas = currentTemplate.schemas.map((page) => {
          return page.filter((field) => !names.includes(field.name));
        });

        this.checkForChanges();
        this.pendingChanges.push(currentTemplate);
      } else {
        this.checkForChanges();
        this.pendingChanges.push({
          schemas: [[]],
          basePdf: {
            width: 210,
            height: 297,
            padding: [0, 0, 0, 0],
          },
          pdfmeVersion: '5.0.0',
        });
      }
    },
    onChangeBasePDF(basePdf) {
      if (this.designer) {
        this.designer.updateTemplate(
          Object.assign(cloneDeep(this.designer.getTemplate()), {
            basePdf,
          }),
        );
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
};
</script>

<style scoped>
.pdfme-designer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
}

.toolbar {
  display: flex;
  gap: 10px;
  padding: 15px;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
  animation: fadeIn 0.3s ease-out;
}

.editor {
  flex: 1;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 15px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.md-button.md-secondary {
  background: linear-gradient(145deg, #6fa3ef, #3d75c6); /* น้ำเงินอ่อน */
  color: white;
}

.md-button.md-warn {
  background: linear-gradient(145deg, #f8bbd0, #f48fb1); /* ชมพูอ่อน */
  color: white;
}

.md-button.md-accent {
  background: linear-gradient(145deg, #81c784, #4caf50); /* เขียวมิ้นต์ */
  color: white;
}

.button {
  border-radius: 8px;
  text-transform: none;
  font-weight: bold;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
