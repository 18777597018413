import axios from 'axios';
async function getVersion() {
  const response = await axios.get(
    `/version.json?t=` + new Date().toISOString(),
  );
  return response;
}

export default {
  getVersion,
};
