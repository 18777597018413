<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-size-33 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
    >
      <md-card>
        <md-card-content>
          <md-datepicker v-model="fromdate" md-immediately>
            <label>วันที่เริ่ม</label>
          </md-datepicker>
          <md-datepicker v-model="todate" md-immediately>
            <label>วันที่สิ้นสุด</label>
          </md-datepicker>
          <md-button class="md-info" @click="setstaffTab">Search</md-button>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100" v-if="isParams">
      <tabs
        :tab-name="tabList.name"
        :tab-icon="tabList.icon"
        :tab-active="tabactive"
        nav-pills-icons
        plain
        color-button="warning"
      >
        <md-card
          v-for="(item, index) in tabList.tablename"
          :slot="'tab-pane-' + (1 + index)"
          :key="index + 'mdcard'"
        >
          <md-card-header>
            <h3>{{ tabList.name[index] }}</h3>
            <p class="category"></p>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-alignment-center-left">
              <div
                class="md-layout-item md-size-30 md-large-size-30 md-medium-size-50 md-small-size-60 md-xsmall-size-100"
              >
                <md-field>
                  <label>Filter</label>
                  <md-input v-model="filter"></md-input>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-15 md-large-size-15 md-medium-size-25 md-small-size-20 md-xsmall-size-50"
              >
                <md-button
                  class="md-danger md-sm"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear Filter
                </md-button>
              </div>
              <div
                class="md-layout-item md-size-15 md-large-size-15 md-medium-size-25 md-small-size-20 md-xsmall-size-50"
              >
                <b-form-checkbox size="lg" v-model="stacked">
                  Stack
                </b-form-checkbox>
              </div>
              <div class="md-layout-item md-size-100">
                <b-table
                  :id="'table-' + item"
                  :fields="tabList.fields[item]"
                  :items="tabList.data[item]"
                  :filter="filter"
                  :filter-ignored-fields="filterignored"
                  :tbody-tr-class="rowClass"
                  :current-page="currentPage[item]"
                  per-page="10"
                  head-variant="light"
                  responsive
                  hover
                  show-empty
                  :stacked="stacked"
                >
                  <template #empty>
                    <center><h4>ไม่มีข้อมูล</h4></center>
                  </template>
                </b-table>
                <div class="overflow-auto">
                  <b-pagination
                    v-if="tabList.data[item]"
                    v-model="currentPage[item]"
                    align="right"
                    :aria-controls="'table-' + item"
                    :total-rows="tabList.data[item].length"
                    per-page="10"
                    size="lg"
                    limit="7"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import _ from 'lodash';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  mixins: [employeeMixin],
  components: {
    Tabs,
  },

  data() {
    return {
      stacked: false,
      filter: '',
      filterignored: [],
      currentPage: {
        employeeleave: 1,
        employeeot: 1,
        employeehasworkingtime: 1,
        keepdays_off: 1,
      },
      tabactive: 0,
      tabList: {
        name: [],
        icon: [],
        tablename: [],
        fields: [],
      },
      fromdate: null,
      todate: null,
      permissionsList: [],
      employee_id: null,
      branchs: {},
      isParams: false,
      profile: null,
      query: null,
    };
  },
  created() {
    this.getProfileData().then(async (res) => {
      loading_start();
      this.profile = await res;
      this.fromdate = moment()
        .subtract(1, 'M')
        .format('DD/MM/YYYY');
      this.todate = moment().format('DD/MM/YYYY');
      await this.setstaffTab().then(() => {
        this.isParams = true;
      });
      loading_close();
    });
    if (this.$route.params.tabactive) {
      this.tabactive = this.$route.params.tabactive;
    }
  },

  methods: {
    setTabActive() {
      if (this.$route.params.tabactive) {
        this.tabactive = this.$route.params.tabactive;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissionsList.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissionsList.push(permission.name);
      });
    },

    async setEmployeeID(d) {
      this.branchs = d.employees.branchs;
      this.employee_id = d.employee_id;
    },

    async setstaffTab() {
      loading_start();
      this.tabList.name = [
        'การลา',
        'ทำงานล่วงเวลา (OT)',
        'กะเวลาทำงาน',
        'ทำงานวันหยุด',
        'Out/In Resort',
      ];
      this.tabList.icon = [
        'feed',
        'work_history',
        'schedule',
        'event',
        'transfer_within_a_station',
      ];
      this.tabList.tablename = [
        'employeeleave',
        'employeeot',
        'employeehasworkingtime',
        'keepdays_off',
        'out_premises',
      ];

      await this.setFields();
      let params = {
        ...{ emp_id: this.profile[0].emp_id },
        // ...{ emp_id: 9 },
        ...{
          fromdate: moment(this.fromdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
        ...{ todate: moment(this.todate, 'DD/MM/YYYY').format('YYYY-MM-DD') },
      };
      this.tabList.data = await this.$store.dispatch('approvers/list', params);
      await this.sortData();
      loading_close();
    },
    setFields() {
      this.filterignored = ['created_at', 'approve_status'];
      let fieldprefix = [
        { key: 'emp_code', label: 'รหัสพนักงาน', sortable: true },
        { key: 'fullname_th', label: 'ชื่อ - นามสกุล' },
        { key: 'fullname_en', label: 'Full Name' },
        { key: 'br_nameen', label: 'สาขา' },
        { key: 'pos_nameth', label: 'ตำแหน่ง' },
        { key: 'requestdate', label: 'วันที่ขอ', sortable: true },
        { key: 'created_at', label: 'วันที่อนุมัติ', sortable: true },
        { key: 'approve_status', label: 'สถานะการอนุมัติ' },
      ];
      let fieldsuffix = [{ key: 'notes', label: 'หมายเหตุ' }];
      this.tabList.fields.employeeleave = fieldprefix
        .concat([
          { key: 'l_th', label: 'ประเภทการลา' },
          {
            key: 'from_date',
            label: 'วันที่เริ่ม (จากวันที่)',
            sortable: true,
          },
          {
            key: 'to_date',
            label: 'วันที่สิ้นสุด (เปลี่ยนวันที่)',
            sortable: true,
          },
        ])
        .concat(fieldsuffix);

      this.tabList.fields.employeeot = fieldprefix
        .concat([
          { key: 'otname', label: 'ประเภท OT', sortable: true },
          { key: 'ottime', label: 'เวลาที่ทำ OT (ชั่วโมง)', sortable: true },
          {
            key: 'amount',
            label: 'จำนวนเที่ยวเรือ/ชั่วโมงนวดสปา',
            sortable: true,
          },
          { key: 'from_date', label: 'เวลาที่เริ่ม OT', sortable: true },
          { key: 'to_date', label: 'เวลาที่สิ้นสุด OT', sortable: true },
        ])
        .concat(fieldsuffix);

      this.tabList.fields.employeehasworkingtime = fieldprefix
        .concat([
          { key: 'work_code', label: 'รหัสกะ' },
          { key: 'work_name', label: 'กะการทำงาน' },
          { key: 'start_date', label: 'วันที่เริ่ม', sortable: true },
          { key: 'finish_date', label: 'วันที่สิ้นสุด', sortable: true },
        ])
        .concat(fieldsuffix);

      this.tabList.fields.keepdays_off = fieldprefix
        .concat([
          { key: 'from_date', label: 'วันที่เริ่ม', sortable: true },
          { key: 'to_date', label: 'วันที่สิ้นสุด', sortable: true },
        ])
        .concat(fieldsuffix);

      this.tabList.fields.out_premises = [
        { key: 'emp_code', label: 'รหัสพนักงาน', sortable: true },
        { key: 'fullname_th', label: 'ชื่อ - นามสกุล' },
        { key: 'fullname_en', label: 'Full Name' },
        { key: 'br_nameen', label: 'สาขา' },
        { key: 'pos_nameth', label: 'ตำแหน่ง' },
        { key: 'created_at', label: 'วันที่ขอ', sortable: true },
        { key: 'updated_at', label: 'วันที่อนุมัติ', sortable: true },
        { key: 'approve_status', label: 'สถานะการอนุมัติ' },
        { key: 'topic', label: 'คำขอ' },
        { key: 'emp_remark', label: 'หมายเหตุ' },
        { key: 'out_premises_wait_status', label: 'สถานะปัจจุบัน' },
      ];
    },
    async sortData() {
      this.tabList.tablename.forEach((tbname) => {
        if (this.tabList.data[tbname]) {
          switch (tbname) {
            case 'employeeleave':
              this.tabList.data[tbname].forEach((data) => {
                switch (data['approve_status']) {
                  case 'approve':
                    data['approve_status'] = 'อนุมัติ';
                    break;
                  default:
                    data['approve_status'] = 'ไม่อนุมัติ';
                    break;
                }
                data['requestdate'] = moment(data['requestdate']).format(
                  'DD/MM/YYYY',
                );
                data['created_at'] = moment(data['created_at']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );
                data['from_date'] = moment(data['from_date']).format(
                  'DD/MM/YYYY',
                );
                data['to_date'] = moment(data['to_date']).format('DD/MM/YYYY');
              });
              break;
            case 'employeehasworkingtime':
              this.tabList.data[tbname].forEach((data) => {
                switch (data['approve_status']) {
                  case 'approve':
                    data['approve_status'] = 'อนุมัติ';
                    break;
                  default:
                    data['approve_status'] = 'ไม่อนุมัติ';
                    break;
                }
                data['requestdate'] = moment(data['requestdate']).format(
                  'DD/MM/YYYY',
                );
                data['created_at'] = moment(data['created_at']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );
                data['start_date'] = moment(data['start_date']).format(
                  'DD/MM/YYYY',
                );
                data['finish_date'] = moment(data['finish_date']).format(
                  'DD/MM/YYYY',
                );
              });
              break;
            case 'out_premises':
              this.tabList.data[tbname].forEach((data) => {
                switch (data['approve_status']) {
                  case 0:
                    data['approve_status'] = 'รออนุมัติ';
                    break;
                  case 1:
                    data['approve_status'] = 'ไม่อนุมัติ';
                    break;
                  case 3:
                    data['approve_status'] = 'อนุมัติ';
                    break;
                  default:
                    data['approve_status'] = 'ไม่อนุมัติ';
                    break;
                }
                if (data['leave_premises_type']) {
                  if (data['leave_premises_type'] == 'according_boss_orders') {
                    data['topic'] =
                      'ไปปฎิบัติงานนอกรีสอร์ท ตามคำสั่งหัวหน้าที่ ';
                  }
                  if (data['leave_premises_type'] == 'personal_business') {
                    data['topic'] = 'ไปทำธุระส่วนตัวที่ ';
                  }
                  if (
                    data['leave_premises_type'] == 'work_different_branches'
                  ) {
                    data['topic'] = 'ปฎิบัติงานต่างสาขาที่ ';
                  }
                  data['topic'] +=
                    data['go_to_location'] +
                    ' ช่วง ' +
                    data['cv_from_datetime'] +
                    ' - ' +
                    data['cv_to_datetime'];
                }

                data['requestdate'] = moment(data['requestdate']).format(
                  'DD/MM/YYYY',
                );
                if (data['updated_at'] != data['created_at']) {
                  data['updated_at'] = moment(data['updated_at']).format(
                    'DD/MM/YYYY HH:mm:ss',
                  );
                } else {
                  data['updated_at'] = '';
                }
                data['created_at'] = moment(data['created_at']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );

                data['from_date'] = moment(data['from_date']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );
                data['to_date'] = moment(data['to_date']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );
              });
              break;
            default:
              this.tabList.data[tbname].forEach((data) => {
                switch (data['approve_status']) {
                  case 'approve':
                    data['approve_status'] = 'อนุมัติ';
                    break;
                  default:
                    data['approve_status'] = 'ไม่อนุมัติ';
                    break;
                }
                data['requestdate'] = moment(data['requestdate']).format(
                  'DD/MM/YYYY',
                );
                data['created_at'] = moment(data['created_at']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );
                data['from_date'] = moment(data['from_date']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );
                data['to_date'] = moment(data['to_date']).format(
                  'DD/MM/YYYY HH:mm:ss',
                );
              });
              break;
          }
        }
      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      switch (item.approve_status) {
        case 'อนุมัติ':
          return 'table-success';
        case 'ไม่อนุมัติ':
          return 'table-danger';
        default:
          return '';
      }
      // if (item.approve_status === 'อนุมัติ') return 'table-success';
    },
  },
};
</script>
<style lang="scss">
.tab-pane {
  width: 100%;
}
</style>
