<template>
  <div class="MemoSetPublish">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>publish</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-size-100 d-flex md-alignment-left">
          <div class="md-layout spacing md-alignment-left d-flex memoName">
            <div
              class="md-layout-item md-size-100 d-flex md-alignment-left text-nowrap"
            >
              <label class="md-alignment-left mt-4">
                Memo Name:
              </label>
              <md-field>
                <md-input
                  class="md-size-50 ml-2"
                  v-model="memoPublish.memo_name"
                ></md-input>
              </md-field>
            </div>
          </div>
        </div>

        <div class="md-layout md-alignment-left detail d-flex">
          <div class="md-layout-item md-size-100 md-alignment-left">
            <label class="mt-4 md-alignment-left">
              Detail:
            </label>
          </div>
          <div class="md-layout-item  md-size-100">
            <md-field>
              <md-textarea v-model="memoPublish.memo_detail"></md-textarea>
            </md-field>
          </div>
        </div>

        <div class="text-center my-3">
          <div v-if="memoPublish.pdf_file">
            <pdfme-viewer
              :template="{
                schemas: [[]],
                basePdf: `data:application/pdf;base64,${memoPublish.pdf_file}`,
                pdfmeVersion: '5.3.5',
              }"
            ></pdfme-viewer>
          </div>
          <div v-else class="alert-info text-center rounded-lg m-4 p-1">
            <md-icon class="text-warning">warning</md-icon>
            <p class="pdf-not-found-text">
              No PDF file or visibility not set.
            </p>
          </div>
        </div>

        <PrioritySettings
          ref="prioritySettings"
          :is-group-enabled="false"
          :title="'แสดง'"
          :input-schemas="inputSchemas"
          :staff-record-fields="staffRecordFields"
          :priority-groups="staffPriorityGroups"
          @staffs="getStaffs"
        ></PrioritySettings>

        <div class="md-layout spacing md-alignment-left addtag p-4">
          <b-form-tags v-model="tags" no-outer-focus class="md-layout-item p-4">
            <template v-slot="{ tags, disabled, removeTag, inputAttrs }">
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-2"
              >
                <li
                  v-for="(tag, index) in tags"
                  :key="index + ' addtag'"
                  class="scrol md-scrollbar list-inline-item"
                >
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag.tag_sos_name"
                    :disabled="disabled"
                    variant="info"
                    v-if="typeof tag == 'object'"
                  >
                    {{ tag.tag_sos_name }}
                  </b-form-tag>
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag.tag_sos_name"
                    :disabled="disabled"
                    variant="info"
                    v-else-if="typeof tag == 'string'"
                  >
                    {{ JSON.parse(tag).tag_sos_name }}
                  </b-form-tag>
                </li>
              </ul>
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="search"
                  v-bind="inputAttrs"
                  @keyup.enter="onOptionClick({ id: 0, tag_sos_name: search })"
                  id="tag-search-input"
                  type="search"
                  autocomplete="off"
                  placeholder="New tag - Press enter to add"
                  class="form-control"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="onOptionClick({ id: 0, tag_sos_name: search })"
                    variant="primary"
                  >
                    Add
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-dropdown
                size="sm"
                variant="outline-secondary"
                block
                menu-class="w-100"
                id="dropdown_tag"
              >
                <template #button-content>
                  <b-icon icon="tag-fill"></b-icon>
                  Choose tags
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group
                    label="Search tags"
                    label-for="tag-search-input"
                    label-cols-md="auto"
                    class="mb-0"
                    label-size="sm"
                    :description="searchDesc"
                    :disabled="disabled"
                  >
                    <b-form-input
                      v-model="search_tag"
                      id="tag-search-input"
                      type="search"
                      size="sm"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <div
                  style="min-height: 300px; max-height: 500px; overflow-x: auto"
                >
                  <b-dropdown-item-button
                    v-for="option in availableOptions"
                    :key="option.id + ' option'"
                    :value="option"
                    @click="onOptionClick(option)"
                  >
                    {{ option.tag_sos_name }}
                  </b-dropdown-item-button>
                </div>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  There are no tags available to select
                </b-dropdown-text>
              </b-dropdown>
            </template>
          </b-form-tags>
        </div>
        <hr />
        <md-button class="md-success md-dense" @click="save_publish()">
          Save
        </md-button>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import PrioritySettings from '@/components/PrioritySettings.vue';
import Vuex from 'vuex';
import Swal from 'sweetalert2';
import { loading_close, loading_start } from '@/utils/loading';
import MemoMixin from '@/components/Pdfme/BaseTemplate/MemoMixin';
import PdfmeHelperMixin from '@/components/Pdfme/plugins/PdfmeHelperMixin';
import PdfmeViewer from '@/components/Pdfme/views/PdfmeViewer.vue';

export default {
  mixins: [MemoMixin, PdfmeHelperMixin],
  components: {
    PdfmeViewer,
    PrioritySettings: PrioritySettings,
  },
  async created() {
    await this.get_tag();
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    criteria() {
      return this.search_tag.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      const options = this.options.filter(
        (opt) => this.tags.indexOf(opt) === -1,
      );
      if (criteria) {
        return options.filter((opt) => {
          if (opt.tag_sos_name.toLowerCase().indexOf(criteria) > -1) {
            return opt;
          }
        });
      }

      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria';
      }
      return '';
    },
  },
  data() {
    return {
      memoPublish: { memo_name: '', memo_detail: '' },
      staffs: {},
      tags: [],
      search: '',
      search_tag: '',
      options: [],
      inputSchemas: [],
      staffRecordFields: [],
      staffPriorityGroups: undefined,
    };
  },
  async beforeCreate() {
    const params = this.$route.params;
    if (params?.memo_id) {
      const response = await this.$store.dispatch(
        'memo/getMemoPublished',
        params,
      );

      if (response) {
        this.memoPublish = response.data.memo_published;
        this.tags = response.data.memo_tags;

        if (response.data.memo_view_access.length > 0) {
          this.staffPriorityGroups = [
            {
              member: response.data.memo_view_access.map((access) => ({
                options: {
                  departments: [],
                  employees: [],
                  positions: [],
                },
                memberInfo: {
                  bch_id: access?.property_id || '',
                  order_person: 0,
                  posname_en: '',
                  depname_en: '',
                  emp_full_name: '',
                  dep_id: access?.dep_id || '',
                  position: access?.pos_id || '',
                  emp_id: access?.emp_id || '',
                  memo_view_access_id: access.id,
                },
              })),
              priorityType: '',
              priority: 0,
            },
          ];
        } else {
          this.staffPriorityGroups = [
            {
              member: [
                {
                  options: {
                    departments: [],
                    employees: [],
                    positions: [],
                  },
                  memberInfo: {
                    bch_id: '',
                    order_person: 0,
                    posname_en: '',
                    depname_en: '',
                    emp_full_name: '',
                    dep_id: '',
                    position: '',
                  },
                },
              ],
              priorityType: '',
              priority: 0,
            },
          ];
        }
      }
    } else {
      await this.$router.push({
        path: 'memoList',
        name: 'จัดการ Memo',
      });
    }

    this.inputSchemas = [
      {
        key: 'branch',
        label: 'สำนักงาน',
        type: 'select',
        model: 'bch_id',
        placeholder: 'เลือกสำนักงาน',
        changeEvent: { name: 'handleBranchChange', args: ['member'] },
      },
      {
        key: 'department',
        label: 'แผนก',
        type: 'select',
        model: 'dep_id',
        placeholder: 'เลือกแผนก',
        options: 'options.departments',
        changeEvent: { name: 'handleDepartments', args: ['member'] },
        disabledCondition: 'bch_id',
      },
      {
        key: 'position',
        label: 'ตำแหน่ง',
        type: 'select',
        model: 'position',
        placeholder: 'เลือกตำแหน่ง',
        options: 'options.positions',
        changeEvent: { name: 'handlePositions', args: ['member'] },
        disabledCondition: 'bch_id',
      },
      {
        key: 'member',
        label: 'สมาชิก',
        type: 'autocomplete',
        model: 'emp_full_name',
        placeholder: 'เลือกพนักงาน',
        options: 'options.employees',
        mdChangedEvent: {
          name: 'searchStaffs',
          args: ['priority', 'member', 'schemas'],
        },
        mdSelectedEvent: {
          name: 'selectStaff',
          args: ['event', 'priority', 'priorityType', 'member'],
        },
        disabledCondition: 'bch_id',
      },
    ];
    this.staffRecordFields = [
      'bch_id:property_id',
      'emp_id',
      'dep_id',
      'position:pos_id',
      'memo_view_access_id:id',
    ];
  },
  methods: {
    getStaffs(staffs) {
      this.staffs = staffs;
    },
    async get_tag() {
      let obj = '1';
      this.options = await this.$store.dispatch('santhiyaSOS/gettagsos', obj);
    },
    onOptionClick(option) {
      if (!option.tag_sos_name) return;

      this.tags.push({
        tag_id: option.id,
        tag_sos_name: option.tag_sos_name,
      });
      this.search_tag = '';
      this.search = '';
    },

    goBack() {
      let keySearchMemo = this.$route.params.keySearch;
      let currentPageMemo = this.$route.params.currentPage;

      let params = {
        keySearchMemo,
        currentPageMemo,
      };

      this.$router.push({
        path: this.$route.params.path || 'memoList',
        name: this.$route.params.name || 'จัดการ Memo',
        params: params,
      });
    },
    async save_publish() {
      const staffs = this.$refs.prioritySettings.getAllInput();

      let params = {
        tags: this.tags.map((t) => JSON.parse(t)),
        memoPublish: this.memoPublish,
        staffs: this.staffs,
      };

      loading_start();

      try {
        const formData = new FormData();
        if (this.memoPublish.pdf_file != undefined) {
          const {
            data: { memoDetail },
          } = await this.$store.dispatch('memo/getMemoDetail', {
            id: this.memoPublish?.memo_id,
          });

          const memorandam = {
            toText: memoDetail.to_text,
            fromText: memoDetail.from_text,
            dateMemo: memoDetail.date,
            pageText: memoDetail.page,
            subjectText: memoDetail.subject,
            cc: memoDetail.cc,
          };

          const settings = JSON.parse(memoDetail.settings);

          let template = memoDetail.memo_data;
          if (settings.autoGenerate) {
            const branchLogo = (
              await this.$store.dispatch('pdfs/getBranchLogo', {
                bch_id: memoDetail.bch_id,
              })
            )[0].image;

            const baseTemplate = this.getBaseTemplate({
              branchLogo,
              memorandam,
            });

            template = this.mergeTemplates({
              template,
              pendingChanges: [
                await this.updateBaseTemplate(baseTemplate, template),
              ],
            });
          }

          const blob = await this.generatePDF({
            template,
            title: `MEMORANDAM: ${memoDetail.subject}`,
          });

          formData.append('pdfFile', blob);
        } else {
          const blob = this.base64ToBlob(this.memoPublish.pdf_file);
          formData.append('pdfFile', blob);
        }

        formData.append(
          'tags',
          JSON.stringify(this.tags.map((t) => JSON.parse(t))),
        );

        delete this.memoPublish.pdf_file;
        formData.append('memoPublish', JSON.stringify(this.memoPublish));
        formData.append('staffs', JSON.stringify(staffs));

        params = formData;

        const response = await this.$store
          .dispatch('memo/saveMemoPublish', params)
          .finally(() => loading_close());

        if (response) {
          this.memo = response.data;
          await Swal.fire('บันทึกสำเร็จ!', '', 'success');
          this.goBack();
        }
      } catch (error) {
        await Swal.fire('มีบางอย่างผิดพลาด!', '', 'error');
        throw new Error(`Something went wrong: ${error}`);
      } finally {
        loading_close();
      }
    },
  },
};
</script>
