<template>
  <div class="md-layout">
    <BoxApprovePri
      :show="private_show"
      @update-show="updateShow"
      @callback="
        get_data();
        get_data_ot_flat_rate(), (disabledBtnPrivateCode = true);
      "
    ></BoxApprovePri>
    <div class="md-layout-item md-size-100">
      <button
        type="button"
        class="md-button md-raised md-success md-theme-default"
        @click="get_data(), get_data_ot_flat_rate()"
      >
        <div class="md-ripple">
          <div class="md-button-content">
            <i class="md-icon md-icon-font md-theme-default">
              refresh
            </i>
            refresh
          </div>
        </div>
      </button>
      <button
        type="button"
        class="md-button md-raised md-primary md-theme-default"
        v-show="showBtnPrivateCode"
        :disabled="disabledBtnPrivateCode"
        @click="private_show = !private_show"
      >
        <div class="md-ripple">
          <div class="md-button-content">
            <i class="md-icon md-icon-font md-theme-default">lock</i>
            Private Code
          </div>
        </div>
      </button>
    </div>
    <div class="md-layout-item md-size-100" v-if="isParams">
      <tabs
        class="tabs-appv_all"
        :tab-name="tabList.name"
        :tab-icon="tabList.icon"
        :tab-active="tabactive"
        nav-pills-icons
        plain
        color-button="warning"
      >
        <template slot="tab-pane-1">
          <md-card>
            <Appv_leavesOT
              :permissions="permissionsList"
              @get_Notify="get_Notify"
              @get_data="get_data"
              :items="items['nm']"
              :options="options_ot"
              :totalRows="totalRows['nm']"
            />
          </md-card>
        </template>
        <template slot="tab-pane-2">
          <md-card>
            <Appv_leavesOTOverLimit
              :permissions="permissionsList"
              @get_Notify="get_Notify"
              @get_data="get_data"
              :items="items['sp']"
              :options="options_ot"
              :totalRows="totalRows['sp']"
            />
          </md-card>
        </template>
        <template slot="tab-pane-3">
          <md-card>
            <Appv_boatSpa
              :permissions="permissionsList"
              @get_Notify="get_Notify"
              @get_data_ot_flat_rate="get_data_ot_flat_rate"
              :items="items_flatreat['sp']"
              :options="options_ot"
              :totalRows="totalRows_flatreat['sp']"
            />
          </md-card>
        </template>
        <template slot="tab-pane-4">
          <md-card class="tab-memo">
            <Appv_Memo
              @get_Notify="get_Notify"
              @get_data="get_data"
              :items="approvalData.memoItems"
            />
          </md-card>
        </template>
        <template slot="tab-pane-5">
          <md-card>
            <AppvApplicant
              @get_Notify="get_Notify"
              @get_data="get_data"
              :items="approvalData.applicantItems"
            />
          </md-card>
        </template>
        <template slot="tab-pane-6">
          <md-card>
            <AppvOutInResort
              @get_Notify="get_Notify"
              @get_data="get_data"
              :items="approvalData.outInResort"
            />
          </md-card>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import $ from 'jquery';
import moment from 'moment';
import Vuex from 'vuex';
import Appv_leavesOT from '@/pages/Dashboard/Pages/Executive/Appv_leavesOT.vue';
import Appv_leavesOTOverLimit from '@/pages/Dashboard/Pages/Executive/Appv_leavesOTOverLimit.vue';
import Appv_boatSpa from '@/pages/Dashboard/Pages/Executive/Appv_boatSpa.vue';
import Appv_Memo from '@/pages/Dashboard/Pages/Executive/Appv_Memo.vue';
import AppvOutInResort from '@/pages/Dashboard/Pages/Executive/Appv_OutInResort.vue';
import AppvApplicant from '@/pages/Dashboard/Pages/Executive/Appv_Applicant.vue';
import BoxApprovePri from '@/pages/Dashboard/Components/BoxApprovePri.vue';
import md5 from 'js-md5';
import { loading_start, loading_close } from '@/utils/loading.js';
import _ from 'lodash';

export default {
  components: {
    Tabs,
    Appv_leavesOT,
    Appv_leavesOTOverLimit,
    Appv_boatSpa,
    Appv_Memo,
    AppvApplicant,
    AppvOutInResort,
    BoxApprovePri,
    // 'raw-time': RawTime,
    // 'working-time': WorkingTime,
    // 'slip-salary': SlipSalary,
  },

  data() {
    return {
      private_show: false,
      showBtnPrivateCode: false,
      disabledBtnPrivateCode: false,
      timeOutReload: null,
      statusReload: 0,
      timeWaitReload: 300000, // 5 นาที
      tabactive: 0,
      tabList: {
        name: [],
        icon: [],
      },
      notifynum: 0,
      notifynum_flatreat: 0,
      items: { nm: [], sp: [] },
      items_flatreat: { nm: [], sp: [] },
      totalRows: { nm: 0, sp: 0 },
      totalRows_flatreat: { nm: 0, sp: 0 },
      approvalData: { memoItems: [], applicantItems: [] },
      permissionsList: [],
      employee_id: null,
      months: moment()
        // .subtract(1, 'month')
        .month(),
      years: moment().year(),
      branchs: {},
      isParams: false,
      me: null,
      query: null,
      options_ot: [],
    };
  },

  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },

  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
    );
  },

  async created() {
    this.getProfile().then(() => {
      this.setPermission(this.me);
      this.setEmployeeID(this.me).then(() => {
        this.isParams = true;
        this.setstaffTab();
      });
      this.getLocalStorageEmp();
    });
    this.get_data();
    this.get_data_ot_flat_rate();
    this.get_ot_sel();
    if (this.$route.params.tabactive) {
      this.tabactive = this.$route.params.tabactive;
    }
  },

  computed: {
    // ...Vuex.mapState({
    //   me: (state) => state.profile.me,
    // }),
  },

  methods: {
    updateShow(v) {
      this.private_show = v;
    },

    handleVisibilityChange() {
      if (document.hidden) {
        if (this.timeOutReload) {
          clearTimeout(this.timeOutReload);
        }

        this.timeOutReload = setTimeout(() => {
          this.statusReload = 1;
        }, this.timeWaitReload);
      } else {
        if (this.statusReload) {
          this.get_data();
          this.statusReload = 0;
        }
        if (this.timeOutReload) {
          clearTimeout(this.timeOutReload);
          this.timeOutReload = null;
        }
      }
    },

    getLocalStorageEmp() {
      let emp_privatecode = JSON.parse(localStorage.getItem('employees'))[
        'privatecode'
      ];

      let privatecode = localStorage.getItem('privatecode');

      if (emp_privatecode) {
        this.showBtnPrivateCode = true;
      }

      if (privatecode) {
        this.disabledBtnPrivateCode = true;
      }
    },

    setTabActive() {
      if (this.$route.params.tabactive) {
        this.tabactive = this.$route.params.tabactive;
      }
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissionsList.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissionsList.push(permission.name);
      });
    },

    async setEmployeeID(me) {
      // if (me.roles[0].name === 'Super admin') {
      //   this.employee_id = null;
      //   this.branchs = {};
      // } else {
      this.branchs = me.employees.branchs;
      this.employee_id = me.employee_id;
      // }
    },

    setstaffTab() {
      this.tabList.name = [
        'อนุมัติลา และ OT',
        'อนุมัติ OT เกิน 3 ชั่วโมง',
        'อนุมัติ OT เหมา',
        'อนุมัติ Memo',
        'อนุมัติ Applicant',
        'อนุมัติ Out/In Resort',
      ];
      this.tabList.icon = [
        'library_books',
        'update',
        'directions_boat',
        'library_books',
        'person_add',
        'transfer_within_a_station',
      ];
    },

    async getProfile() {
      loading_start();
      await this.$store.dispatch('profile/me');
      this.me = await this.$store.getters['profile/me'];
      loading_close();
    },

    async get_data() {
      this.isBusy = true;
      let privatecode = localStorage.getItem('privatecode');
      var params = { privatecode: privatecode };
      loading_start();
      var data_res = await this.$store.dispatch('approvers/get_approve_all');
      var res_otlast = await this.$store.dispatch(
        'approvers/getlastapproveotlimit',
        params,
      );
      var arr = { nm: [], sp: [] };
      arr = await this.formatDetail(data_res);
      let temp = await this.formatOTOverLimit(res_otlast);
      arr['sp'] = arr['sp'].concat(temp);
      await this.getMemoApproverList();
      await this.getApplicantAppvList();
      await this.getOutInResortList();

      this.items = arr;
      this.totalRows['nm'] = this.items['nm'].length;
      this.totalRows['sp'] = this.items['sp'].length;
      this.notifynum = this.totalRows['nm'] + this.totalRows['sp'];
      this.get_Notify();
      loading_close();
      // this.perPage = 5;
    },

    async getMemoApproverList() {
      const response = await this.$store.dispatch('memo/getMemoApprovalsList');
      this.approvalData.memoItems = response.data;
    },

    async getApplicantAppvList() {
      const response = await this.$store.dispatch('applicant/appvList');
      this.approvalData.applicantItems = response.data;
    },

    async getOutInResortList() {
      const response = await this.$store.dispatch(
        'staffleaves/out_premises_get_all',
      );
      this.approvalData.outInResort = response.data;
    },

    async formatOTOverLimit(arr) {
      let temp = [];
      for (const v of arr) {
        let from_date = v['from_date'];
        let to_date = v['to_date'];
        let ot_cost = v['ot_cost'];
        var obj = {
          row_id: v['id'],
          emp_id: v['emp_id'],
          fullname_th:
            v['title_name_th'] +
            ' ' +
            v['first_name_th'] +
            ' ' +
            v['last_name_th'],
          fullname_en:
            v['title_name_en'] +
            ' ' +
            v['first_name_en'] +
            ' ' +
            v['last_name_en'],
          pos_nameth: v['pos_nameth'],
          pos_nameen: v['pos_nameen'],
          detail: {
            type: 'Employeeot',
            type_name: '',
            row_id: v['id'],
            ot_id: '',
            ot_active: '',
            ot_cost: '',
            type_ot_name: '',
            ...{
              notes: v['notes'],
            },
            ...{
              requestdate: v['requestdate'],
            },
            requestdate_format: moment(v['requestdate'], 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            ),
          },
        };

        let duration = '';
        duration = moment.duration(
          moment(to_date, 'YYYY-MM-DD HH:mm:ss').diff(
            moment(from_date, 'YYYY-MM-DD HH:mm:ss'),
          ),
        );
        obj.detail.ot_hours = duration.asHours();

        obj.detail.type_name = 'ขอทำงานล่วงเวลา';
        obj.detail.from_date = moment(from_date, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        obj.detail.to_date = moment(to_date, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );

        obj.detail.ot_cost = ot_cost;

        temp.push(obj);
      }
      return temp;
    },

    async formatDetail(arr) {
      let temp = { nm: [], sp: [] };

      for (const v of arr) {
        var sp = false;
        let mod = v['mod'];
        let type = v['type'];
        let from_date =
          'from_date' in mod
            ? mod['from_date']['modified']
            : 'start_date' in mod
            ? mod['start_date']['modified']
            : '';
        let to_date =
          'to_date' in mod
            ? mod['to_date']['modified']
            : 'finish_date' in mod
            ? mod['finish_date']['modified']
            : '';
        let file =
          'attach_filepath' in mod ? mod['attach_filepath']['modified'] : '';
        let requestdate =
          'requestdate' in mod ? mod['requestdate']['modified'] : '';

        var obj = {
          id: v['id'],
          row_id: v['id'],
          emp_id: v['emp_id'],
          fullname_th:
            v['title_name_th'] +
            ' ' +
            v['first_name_th'] +
            ' ' +
            v['last_name_th'],
          fullname_en:
            v['title_name_en'] +
            ' ' +
            v['first_name_en'] +
            ' ' +
            v['last_name_en'],
          pos_nameth: v['pos_nameth'],
          pos_nameen: v['pos_nameen'],
          br_nameen: v['br_nameen'],
          br_nameth: v['br_nameth'],
          detail: {
            type: type,
            type_name: '',
            row_id: v['id'],
            file: file,
            ot_id: '',
            ...{
              ot_active: 'ot_active' in mod ? mod['ot_active']['modified'] : '',
            },
            type_ot_name: '',
            bill_no: '',
            room_no: '',
            treatment_name: '',
            ...{
              notes: 'notes' in mod ? mod['notes']['modified'] : '',
            },
            ...{
              requestdate,
            },
            requestdate_format: moment(requestdate, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            ),
            // from_date: moment(from_date, 'YYYY-MM-DD HH:mm:ss').format(
            //   'DD/MM/YYYY HH:mm:ss',
            // ),
            // to_date: moment(to_date, 'YYYY-MM-DD HH:mm:ss').format(
            //   'DD/MM/YYYY HH:mm:ss',
            // ),
          },
        };

        if (type == 'Employeeleave') {
          obj.detail.type_name = v['leave_type_name'];
          obj.detail.from_date = moment(
            from_date,
            'YYYY-MM-DD HH:mm:ss',
          ).format('DD/MM/YYYY');
          obj.detail.to_date = moment(to_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY',
          );

          if (v['from_date_all'] != '') {
            obj.detail.group_id = v['group_id'];
            obj.detail.count_date = v['count_date'];
            obj.detail.from_date_all = moment(
              v['from_date_all'],
              'YYYY-MM-DD HH:mm:ss',
            ).format('DD/MM/YYYY');
            obj.detail.to_date_all = moment(
              v['to_date_all'],
              'YYYY-MM-DD HH:mm:ss',
            ).format('DD/MM/YYYY');
          }
          obj.detail.from_date_type_fmt =
            mod['from_date_type']['modified'] == 'hm'
              ? 'ครึ่งวันเช้า'
              : mod['from_date_type']['modified'] == 'hn'
              ? 'ครึ่งวันบ่าย'
              : 'เต็มวัน';
          obj.detail.to_date_type_fmt =
            mod['to_date_type']['modified'] == 'hm'
              ? 'ครึ่งวันเช้า'
              : mod['to_date_type']['modified'] == 'hn'
              ? 'ครึ่งวันบ่าย'
              : 'เต็มวัน';
          obj.detail.from_date_type_old = mod['from_date_type']['modified'];
          obj.detail.to_date_type_old = mod['to_date_type']['modified'];
          obj.detail.from_date_old = from_date;
          obj.detail.to_date_old = to_date;
          // obj.detail.from_date_old = mod['from_date']['modified'];
          // obj.detail.to_date_old = mod['to_date']['modified'];
        } else if (type == 'Employeehasworkingtime') {
          obj.detail.from_date = moment(
            from_date,
            'YYYY-MM-DD HH:mm:ss',
          ).format('DD/MM/YYYY');
          obj.detail.to_date = moment(to_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY',
          );
          obj.detail.type_name = 'เปลี่ยนกะทำงาน ' + v['working_type_name'];
        } else if (type == 'Employeeot') {
          let duration = '';
          duration = moment.duration(
            moment(to_date, 'YYYY-MM-DD HH:mm:ss').diff(
              moment(from_date, 'YYYY-MM-DD HH:mm:ss'),
            ),
          );
          obj.detail.ot_hours = duration.asHours();

          obj.detail.type_name = 'ขอทำงานล่วงเวลา ';
          obj.detail.from_date = moment(
            from_date,
            'YYYY-MM-DD HH:mm:ss',
          ).format('DD/MM/YYYY HH:mm:ss');
          obj.detail.to_date = moment(to_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm:ss',
          );

          if (obj.detail.ot_active == 0) {
            // OT Over Litmi
            sp = true;
          }
        } else if (type == 'Keepdays_off') {
          obj.detail.type_name = 'ขอทำงานในวันหยุด';
          obj.detail.from_date = moment(
            from_date,
            'YYYY-MM-DD HH:mm:ss',
          ).format('DD/MM/YYYY HH:mm:ss');
          obj.detail.to_date = moment(to_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm:ss',
          );
          obj.detail.keep_type_old = mod['keep_type']['modified'];
          obj.detail.keep_type_fmt =
            mod['keep_type']['modified'] == 'hm'
              ? 'ครึ่งวันเช้า'
              : mod['keep_type']['modified'] == 'hn'
              ? 'ครึ่งวันบ่าย'
              : 'เต็มวัน';
        }
        if (sp == false) {
          temp.nm.push(obj);
        } else {
          // OT Over 3 hours
          temp.sp.push(obj);
        }
      }
      return temp;
    },

    async get_data_ot_flat_rate() {
      this.isBusy = true;
      var params = {};
      loading_start();
      var data_res = await this.$store.dispatch(
        'approvers/get_approve_flat_rate',
        params,
      );

      var arr = { nm: [], sp: [] };
      $.each(data_res, function(i, v) {
        var sp = false;
        let mod = v['mod'];
        let type = v['type'];
        let type_ot = v['ot_name'];
        let treatment_name = v['treatment_name'];
        let type_ot_name = v['type_ot_name'];
        let from_date =
          'from_date' in mod
            ? mod['from_date']['modified']
            : 'start_date' in mod
            ? mod['start_date']['modified']
            : '';
        let to_date =
          'to_date' in mod
            ? mod['to_date']['modified']
            : 'finish_date' in mod
            ? mod['finish_date']['modified']
            : '';
        let requestdate =
          'requestdate' in mod ? mod['requestdate']['modified'] : '';

        let file =
          'attach_filepath' in mod ? mod['attach_filepath']['modified'] : '';
        var obj = {
          row_id: v['id'],
          emp_id: v['emp_id'],
          fullname_th:
            v['title_name_th'] +
            ' ' +
            v['first_name_th'] +
            ' ' +
            v['last_name_th'],
          fullname_en:
            v['title_name_en'] +
            ' ' +
            v['first_name_en'] +
            ' ' +
            v['last_name_en'],
          pos_nameth: v['pos_nameth'],
          pos_nameen: v['pos_nameen'],
          ot_id: mod.ot_id.modified,
          detail: {
            type: type,
            type_name: '',
            row_id: v['id'],
            file: file,
            ot_id: '',
            ot_active: '',
            type_ot_name: '',
            bill_no: '',
            room_no: '',
            treatment_name: '',
            ...{
              notes: 'notes' in mod ? mod['notes']['modified'] : '',
            },
            ...{
              requestdate: requestdate,
            },
            requestdate_format: moment(requestdate, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            ),
          },
        };

        if (type == 'Employeeotflatrate') {
          let duration = '';
          duration = moment.duration(
            moment(to_date, 'YYYY-MM-DD HH:mm:ss').diff(
              moment(from_date, 'YYYY-MM-DD HH:mm:ss'),
            ),
          );
          obj.detail.ot_hours = duration.asHours();
          obj.detail.type_ot = mod.type_ot.modified;
          obj.detail.ot_active = mod.ot_active.modified;
          obj.detail.ot_id = mod.ot_id.modified;
          obj.detail.type_ot_name = type_ot_name;
          obj.detail.bill_no = mod.bill_no.modified;
          obj.detail.room_no = mod.room_no.modified;
          obj.detail.treatment_name = treatment_name;
          if (mod.ot_id.modified == 4) {
            obj.detail.type_name = type_ot;
          } else {
            obj.detail.type_name = type_ot;
          }
          if ([4, 6].includes(obj.ot_id)) {
            arr.sp.push(obj);
          }
          sp = true;
          obj.detail.from_date = moment(
            from_date,
            'YYYY-MM-DD HH:mm:ss',
          ).format('DD/MM/YYYY HH:mm:ss');
          obj.detail.to_date = moment(to_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm:ss',
          );
        }
        if (sp == false) {
          arr.nm.push(obj);
        }
      });
      this.items_flatreat = arr;
      this.totalRows_flatreat['nm'] = this.items_flatreat['nm'].length;
      this.totalRows_flatreat['sp'] = this.items_flatreat['sp'].length;
      this.notifynum_flatreat =
        this.totalRows_flatreat['nm'] + this.totalRows_flatreat['sp'];
      this.get_Notify();
      loading_close();
      // this.perPage = 5;
    },

    async get_ot_sel() {
      var params = {};
      var arr = [{ value: null, text: 'โปรดเลือกจำนวนค่าล่วงเวลา' }];
      var item = {};
      loading_start();
      var data_res = await this.$store.dispatch('approvers/get_ot', params);
      $.each(data_res, function(i, v) {
        item = { value: v['id'], text: v['name'] };
        if ([1, 2, 3].includes(item.value)) {
          arr.push(item);
        }
      });
      this.options_ot = arr;

      loading_close();
    },

    async get_Notify() {
      this.settotal(this.notifynum + this.notifynum_flatreat);
      // this.setLeaveAmout(0);
      // this.setOTAmout(0);
      // this.setKeepdayAmout(0);
      // this.setWorkingAmount(0);
    },
    ...Vuex.mapActions({
      settotal: 'approvers/settotal',
      // setLeaveAmout: 'staffleaves/setLeaveAmout',
      // setOTAmout: 'ots/setOTAmout',
      // setKeepdayAmout: 'keepdays/setKeepdayAmout',
      // setWorkingAmount: 'staffhasworks/setWorkingAmount',
    }),
  },
};
</script>
<style lang="scss">
.md-card.tabs-appv_all {
  position: unset !important;

  .tab-pane-1,
  .tab-pane-2,
  .tab-pane-3,
  .tab-pane-4,
  .tab-pane-5,
  .tab-pane-6 {
    width: 100%;
  }

  .md-card-content {
    position: unset !important;
  }

  .md-card.tab-memo {
    position: unset !important;

    .md-card-content {
      position: unset !important;
    }
  }
}
</style>
