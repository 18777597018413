<template>
  <div class="md-layout spacing">
    <div class="md-layout-item md-size-100">
      <div class="md-theme-default">
        <md-card-content>
          <b-container fluid>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-align-sm="left"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="my-1 text-right"
              >
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col xs="12" sm="6" lg="6" class="my-1">
                <b-button variant="primary" @click="selectAllRows">
                  เลือกทั้งหมด
                </b-button>
                <b-button variant="danger" @click="clearSelected">
                  ล้าง
                </b-button>
              </b-col>
              <b-col xs="12" sm="6" lg="6" class="my-1 text-right">
                <b-button variant="success" @click="approve">
                  Approve
                </b-button>
                <b-button variant="danger" @click="disapprove">
                  Disapprove
                </b-button>
              </b-col>
            </b-row>
          </b-container>
          <div class="approve-all-tbl">
            <b-table
              id="my-table"
              :items="items"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :select-mode="selectMode"
              :busy="isBusy"
              ref="selectableTable"
              selectable
              show-empty
              small
              responsive
              hover
              @row-selected="onRowSelected"
            >
              <template #empty>
                <center>
                  <h4>ไม่มีข้อมูล</h4>
                </center>
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(emp_name)="row">
                <p>
                  {{
                    `${row.item.emp_code} ${row.item.emp_first_name} ${row.item.emp_last_name}`
                  }}
                </p>
              </template>

              <template #cell(datetime)="row">
                <p>
                  {{
                    `${convertToThaiDateTime(
                      row.item.from_datetime,
                    )} ถึง ${convertToThaiDateTime(row.item.to_datetime)}`
                  }}
                </p>
              </template>

              <template #cell(topic)="row">
                <p>
                  {{
                    `ขออนุญาตออกนอกสถานที่เพื่อ ${convertTypeToText(
                      row.item.leave_premises_type,
                    )} ที่ ${row.item.go_to_location}`
                  }}
                </p>
              </template>

              <template #cell(remark)="row">
                <p>
                  {{ row.item.emp_remark }}
                </p>
              </template>

              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span>&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span>&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </div>
        </md-card-content>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { loading_close, loading_start } from '@/utils/loading.js';
import moment from 'moment';

export default {
  computed: {},
  components: {},
  data() {
    return {
      selected: [],
      selectMode: 'multi',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      isBusy: false,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'selected',
          label: 'เลือก',
          tdClass: 'td-selected-approve',
          class: 'text-center',
        },
        {
          key: 'emp_name',
          label: 'ผู้ขออนุญาต',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'datetime',
          label: 'ช่วงเวลา',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'topic',
          label: 'คำขอ',
          sortable: true,
          class: 'text-left',
        },
        {
          key: 'remark',
          label: 'หมายเหตุ',
          sortable: true,
          class: 'text-left',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    approve() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to approve the changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        confirmButtonText: 'Approve',
      }).then(async (result) => {
        if (result.value) {
          this.approve_st = 'approve';
          let res = await this.save_approve('approve');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
            this.$emit('get_data');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', res.error_msg, 'error');
          }
        } else {
          return false;
        }
      });
    },

    disapprove() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to reject the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Reject',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          this.approve_st = 'reject';
          await loading_start();
          let res = await this.save_approve('reject', result.value);
          await loading_close();
          this.$emit('get_data');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', res.error_msg, 'error');
          }
        } else {
          return false;
        }
      });
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    convertToThaiDateTime(dateString) {
      const [date, time] = dateString.split(' ');
      const [year, month, day] = date.split('-');
      const [H, i, s] = time.split(':');
      const thaiYear = parseInt(year) + 543;
      return `${day}/${month}/${thaiYear} ${H}:${i}`;
    },

    convertTypeToText(text) {
      var txt = ``;
      switch (text) {
        case 'according_boss_orders':
          txt = `ไปปฎิบัติงานนอกรีสอร์ท ตามคำสั่งหัวหน้า`;
          break;
        case 'personal_business':
          txt = `ไปทำธุระส่วนตัว`;
          break;
        case 'work_different_branches':
          txt = `ปฎิบัติงานต่างสาขา`;
          break;
      }
      return txt;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },

    async save_approve(status, remark = '') {
      const op_apprId = this.selected.map((item) => item.id);
      const obj = { op_apprId, status, remark };

      return await this.$store.dispatch(
        'staffleaves/out_premises_save_appr',
        obj,
      );
    },

    onRowSelected(items) {
      this.selected = items;
    },
  },
};
</script>

<style>
.td-selected-approve {
  width: 12% !important;
  vertical-align: middle !important;
  text-align: center;
  font-size: 2rem;
}

.show-file {
  width: 100%;
  height: 100%;
}

.footer-btn {
  width: 100%;
  text-align: right;
  margin: 10px 0;
  padding: 0px 50px;
}
</style>
