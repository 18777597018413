<template>
  <div>
    <!-- Filter employee -->
    <filter-employee
      :headLabel="headLabel"
      @childBranches="setBranches"
      @childDepartment="setDepartment"
      @childStaffs="setStaffs"
      :permissionsList="permissions"
      v-if="permissions.includes('view select staff')"
    >
    </filter-employee>
    <md-dialog
      style="min-height: 65%;"
      :md-active.sync="showEdit_time"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem">
        แก้ไขเวลา เข้า-ออกงาน
      </md-dialog-title>
      <md-dialog-content v-if="edittime_data" style="font-size: 1.2rem">
        วันที่แก้ไข :
        {{
          moment(edittime_data.date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format(
            'DD/MM/YYYY',
          )
        }}
        <br />
        กะการทำงาน : {{ edittime_data.condition.code }}
        <div v-if="edittime_data.timein == ''">
          <md-field>
            <VueCtkDateTimePicker
              id="time_in"
              v-model="time_in"
              :only-time="true"
              :noLabel="true"
              :minute-interval="1"
              :format="'HH:mm'"
              :formatted="'HH:mm'"
              :label="'เวลาเข้างาน'"
              :input-size="'sm'"
            />
          </md-field>
        </div>
        <div v-else>
          เวลาที่เข้างาน :
          {{
            moment(edittime_data.timein, [
              'YYYY-MM-DD hh:mm:ss A',
              'DD/MM/YYYY hh:mm:ss A',
            ]).format('HH:mm:ss')
          }}
        </div>
        <hr />
        <span>
          พัก :
          {{ edittime_data.condition.breakstart_time }}
          <br />
          เข้างานบ่าย :
          {{ edittime_data.condition.breakfinish_time }}
        </span>
        <hr />
        <div v-if="edittime_data.timeout == ''">
          <md-datepicker v-model="edittime_data.date_timeout" md-immediately>
            <label>วันที่เริ่ม</label>
          </md-datepicker>
          <md-field>
            <VueCtkDateTimePicker
              id="time_out"
              v-model="time_out"
              :only-time="true"
              :noLabel="true"
              :minute-interval="1"
              :format="'HH:mm'"
              :formatted="'HH:mm'"
              :label="'เวลาออกงาน'"
              :input-size="'sm'"
            />
          </md-field>
        </div>
        <div v-else>
          เวลาที่ออกงาน :
          {{
            moment(edittime_data.timeout, [
              'YYYY-MM-DD hh:mm:ss A',
              'DD/MM/YYYY hh:mm:ss A',
            ]).format('HH:mm:ss')
          }}
        </div>
        <div v-if="edittime_data.timein != '' && edittime_data.timeout != ''">
          <md-datepicker v-model="edittime_data.date_timeedit" md-immediately>
            <label>เลือกวันที่</label>
          </md-datepicker>
          <md-field>
            <VueCtkDateTimePicker
              id="time_edit"
              v-model="time_edit"
              :only-time="true"
              :noLabel="true"
              :minute-interval="1"
              :format="'HH:mm'"
              :formatted="'HH:mm'"
              :label="'เวลาเข้า-ออกงาน'"
              :input-size="'sm'"
            />
          </md-field>
        </div>
        <md-field>
          <label>หมายเหตุ</label>
          <md-input v-model="remark"></md-input>
          <!-- <span class="md-helper-text">Helper text</span> -->
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-success"
          @click="edittime_insert(edittime_data.date)"
        >
          บันทึก
        </md-button>
        <md-button class="md-danger" @click="showEdit_time = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- Date -->
    <div class="md-layout spacing md-alignment-left">
      <div class="md-layout-item md-size-20"></div>
      <div
        class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
      >
        <md-datepicker v-model="fromdate" md-immediately>
          <label>วันที่เริ่ม</label>
        </md-datepicker>
      </div>
      <div
        class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
      >
        <md-datepicker
          v-model="todate"
          md-immediately
          :md-disabled-dates="disabledToDates"
        >
          <label>วันที่สิ้นสุด</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-size-10">
        <md-progress-spinner
          :md-diameter="50"
          :md-stroke="3"
          md-mode="indeterminate"
          :md-value="100"
          v-show="showProgressBar"
        >
        </md-progress-spinner>
      </div>
    </div>

    <!-- Button -->
    <div class="md-layout spacing md-alignment-center">
      <div
        class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
      >
        <b-button size="lg" variant="info" @click="searchTimeInout">
          Search
        </b-button>
      </div>
      <div
        class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
      >
        <b-button
          size="lg"
          variant="success"
          @click="onExport"
          :disabled="disabled_export"
        >
          Export
        </b-button>
      </div>
      <div
        class="md-layout-item spacing md-large-size-15 md-size-15 md-medium-size-20 md-small-size-20 md-xsmall-size-50"
        v-if="permissions.includes('process salary')"
      >
        <b-button
          size="lg"
          style="color:white"
          variant="warning"
          @click="processTimeWork"
          :disabled="disabled_process"
        >
          Process
        </b-button>
      </div>
    </div>

    <!-- Display table -->
    <div class="md-layout spacing">
      <div class="md-layout-item md-size-100">
        <!-- <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title"></h4>
          </md-card-header>
          <md-card-content> -->
        <md-table
          v-model="tableData"
          table-header-color="green"
          :value="tableData"
          class="paginated-table table-striped table-hover"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Name" md-sort-by="name">
              {{ item.name }}
            </md-table-cell>
            <md-table-cell md-label="Email" md-sort-by="email">
              {{ item.email }}
            </md-table-cell>
            <md-table-cell md-label="Created At" md-sort-by="created_at">
              {{ item.created_at }}
            </md-table-cell>
            <md-table-cell md-label="Actions">
              <md-button
                class="md-icon-button md-raised md-round md-info"
                @click="onProFeature"
                style="margin: .2rem;"
              >
                <md-icon>edit</md-icon>
              </md-button>
              <md-button
                class="md-icon-button md-raised md-round md-danger"
                @click="onProFeature"
                style="margin: .2rem;"
              >
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="วันที่">
              {{ item.date }}
            </md-table-cell>
            <md-table-cell md-label="กะทำงาน">
              {{ item.condition.code }}
            </md-table-cell>
            <md-table-cell md-label="เข้างาน" v-if="item.timein != ''">
              {{ item.timein }}
            </md-table-cell>
            <md-table-cell md-label="เข้างาน" v-else></md-table-cell>
            <md-table-cell md-label="พัก" v-if="item.timebreak != ''">
              {{ item.timebreak }}
            </md-table-cell>
            <md-table-cell md-label="พัก" v-else></md-table-cell>
            <md-table-cell
              md-label="เข้างานบ่าย"
              v-if="item.timefinishbreak != ''"
            >
              {{ item.timefinishbreak }}
            </md-table-cell>
            <md-table-cell md-label="เข้างานบ่าย" v-else></md-table-cell>
            <md-table-cell md-label="ออกงาน" v-if="item.timeout != ''">
              {{ item.timeout }}
            </md-table-cell>
            <md-table-cell md-label="ออกงาน" v-else></md-table-cell>

            <!-- <md-table-cell md-label="จำนวน ชม.">{{ item.condition.working_type }}</md-table-cell> -->
            <!-- workstatus -->
            <md-table-cell class="other" md-label="สถานะ" v-if="item.cw > 0">
              {{ item.newworkstatus }}
            </md-table-cell>
            <md-table-cell
              class="stop-work"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'not'"
            >
              ขาดงาน
            </md-table-cell>
            <md-table-cell
              class="stop-work"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'keepdaynot'"
            >
              ขาดงาน - ขอทำงานวันหยุด
              <span v-if="item.keep.keep_type === 'hm'">
                (ครึ่งเช้า)
              </span>
              <span v-else-if="item.keep.keep_type === 'hn'">
                (ครึ่งบ่าย)
              </span>
              <span v-else>
                (เต็มวัน)
              </span>
            </md-table-cell>
            <md-table-cell
              class="keep-day"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'keepday'"
            >
              ทำงานวันหยุด (เต็มวัน)
            </md-table-cell>
            <md-table-cell
              class="keep-day"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'keepdayhalf'"
            >
              ทำงานวันหยุด
              <span v-if="item.keep.keep_type === 'hm'">
                (ครึ่งเช้า)
              </span>
              <span v-else-if="item.keep.keep_type === 'hn'">
                (ครึ่งบ่าย)
              </span>
              <span v-else>
                (เต็มวัน)
              </span>
            </md-table-cell>
            <md-table-cell
              class="weekend-work"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'stopday'"
            >
              วันหยุด
            </md-table-cell>
            <md-table-cell
              class="change-day"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'changeday'"
            >
              เปลี่ยนวันหยุด
              <div v-if="item.leave.from_date_type == 'f'">(เต็มวัน)</div>
              <div v-else-if="item.leave.from_date_type == 'hm'">
                (ครึ่งเช้า)
              </div>
              <div v-else-if="item.leave.from_date_type == 'hn'">
                (ครึ่งบ่าย)
              </div>
            </md-table-cell>
            <md-table-cell
              class="change-day"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'phchangeday'"
            >
              เปลี่ยนวันหยุด/ประเพณี
              <div v-if="item.leave.from_date_type == 'f'">(เต็มวัน)</div>
              <div v-else-if="item.leave.from_date_type == 'hm'">
                (ครึ่งเช้า)
              </div>
              <div v-else-if="item.leave.from_date_type == 'hn'">
                (ครึ่งบ่าย)
              </div>
            </md-table-cell>
            <md-table-cell
              class="change-day"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'keepchangeday'"
            >
              เปลี่ยนวันหยุด/วันเก็บ
              <div v-if="item.leave.from_date_type == 'f'">(เต็มวัน)</div>
              <div v-else-if="item.leave.from_date_type == 'hm'">
                (ครึ่งเช้า)
              </div>
              <div v-else-if="item.leave.from_date_type == 'hn'">
                (ครึ่งบ่าย)
              </div>
            </md-table-cell>
            <md-table-cell
              md-label="สถานะ"
              v-else-if="item.workstatus === 'work'"
            >
              <span
                v-if="item.timetolatein !== 0 || item.timetolateout !== 0"
                class="late"
              >
                สาย - กลับก่อน
              </span>
              <span v-else>ทำงาน</span>
            </md-table-cell>
            <md-table-cell
              class="notscan"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'notscanin'"
            >
              ไม่ได้สแกนนิ้วเข้างาน
            </md-table-cell>
            <md-table-cell
              class="notscan"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'notscaninkeepday'"
            >
              ไม่ได้สแกนนิ้วเข้างาน - ขอทำงานวันหยุด
            </md-table-cell>
            <md-table-cell
              class="notscan"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'notscanout'"
            >
              ไม่ได้สแกนนิ้วออกงาน
            </md-table-cell>
            <md-table-cell
              class="notscan"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'notscanoutkeepday'"
            >
              ไม่ได้สแกนนิ้วออกงาน - ขอทำงานวันหยุด
            </md-table-cell>
            <md-table-cell
              class="leave"
              md-label="สถานะ"
              v-else-if="item.workstatus === 'leave'"
            >
              {{ item.leave.leave_nameth }}
              <div v-if="item.leave.from_date_type == 'f'">(เต็มวัน)</div>
              <div v-else-if="item.leave.from_date_type == 'hm'">
                (ครึ่งเช้า)
              </div>
              <div v-else-if="item.leave.from_date_type == 'hn'">
                (ครึ่งบ่าย)
              </div>
            </md-table-cell>
            <md-table-cell class="leave" md-label="สถานะ" v-else>
              <div v-if="item.cw == '0'">
                {{ item.cw }}
                {{ item.workstatus }}
              </div>
            </md-table-cell>
            <!-- workstatus end -->
            <md-table-cell md-label="มาสาย" v-if="item.timetolatein !== 0">
              {{ item.timetolatein }} นาที
            </md-table-cell>
            <md-table-cell md-label="มาสาย" v-else>-</md-table-cell>

            <md-table-cell md-label="กลับก่อน" v-if="item.timetolateout !== 0">
              {{ item.timetolateout }} นาที
            </md-table-cell>
            <md-table-cell md-label="กลับก่อน" v-else>
              -
            </md-table-cell>
            <md-table-cell md-label="หมายเหตุ" v-if="item.remark">
              {{ item.remark }}
            </md-table-cell>
            <md-table-cell md-label="หมายเหตุ" v-else>
              -
            </md-table-cell>
            <md-table-cell
              md-label="Action"
              v-if="
                (item.workstatus === 'notscanin' ||
                  item.workstatus === 'notscanin') &&
                  permissions.includes('edit timeinout')
              "
            >
              <b-button variant="warning" @click="showEditDialog(item.date)">
                แก้ไขเวลา
              </b-button>
            </md-table-cell>
            <md-table-cell
              md-label="Action"
              v-else-if="
                item.workstatus === 'notscanout' &&
                  permissions.includes('edit timeinout')
              "
            >
              <b-button variant="warning" @click="showEditDialog(item.date)">
                แก้ไขเวลา
              </b-button>
            </md-table-cell>
            <md-table-cell
              md-label="Action"
              v-else-if="
                item.workstatus === 'not' &&
                  permissions.includes('edit timeinout')
              "
            >
              <b-button variant="warning" @click="showEditDialog(item.date)">
                แก้ไขเวลา
              </b-button>
            </md-table-cell>
            <md-table-cell
              md-label="Action"
              v-else-if="
                item.workstatus === 'keepdaynot' &&
                  permissions.includes('edit timeinout')
              "
            >
              <b-button variant="warning" @click="showEditDialog(item.date)">
                แก้ไขเวลา
              </b-button>
            </md-table-cell>
            <md-table-cell
              md-label="Action"
              v-else-if="permissions.includes('edit timeinout')"
            >
              <b-button variant="warning" @click="showEditDialog(item.date)">
                แก้ไขเวลา
              </b-button>
            </md-table-cell>
            <md-table-cell md-label="Action" v-else>
              -
            </md-table-cell>
          </md-table-row>
        </md-table>
        <!-- </md-card-content>
        </md-card> -->
      </div>
    </div>
  </div>
</template>

<script>
// import ValidationError from '@/components';
// import Pagination from '@/components/Pagination';
import moment from 'moment';
import * as XLSX from 'xlsx';
import employeeMixin from '@/mixins/employee-mixin';
import FilterEmployee from '@/components/FilterEmployee';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Vuex from 'vuex';
import { loading_start, loading_close } from '@/utils/loading.js';
// import pako from 'pako';

export default {
  components: {
    'filter-employee': FilterEmployee,
    'VueCtkDateTimePicker': VueCtkDateTimePicker,
  },
  mixins: [employeeMixin],

  data() {
    return {
      employee_id: null,
      showEdit_time: false,
      edittime_data: null,
      remark: '',
      headLabel: 'เวลาเข้า-ออกพนักงาน',
      tableData: [],
      total: 1,
      moment: moment,
      time_in: null,
      time_out: null,
      time_edit: null,
      fromdate: null,
      todate: null,
      showProgressBar: false,
      isPermission: true,
      branches: [
        {
          id: null,
          type: 'branches',
        },
      ],
      departments_id: null,
      branch_id: null,
      staffs: [],
      disabled_export: true,
      disabled_process: true,
      disabledToDates: (date) => {
        let fdate = moment(date);
        let tdate = moment(this.fromdate, 'DD/MM/YYYY');
        let datediff = fdate.diff(tdate);

        return datediff <= -1;
      },
      user: null,
    };
  },

  props: {
    permissions: Array,
    employeeID: null,
  },

  created() {
    this.employee_id = this.employeeID;
    this.fromdate = moment()
      .subtract(7, 'days')
      .format('DD/MM/YYYY');
    this.todate = moment().format('DD/MM/YYYY');
  },
  watch: {
    branch_id: function() {
      this.check_process();
    },
    employee_id: function() {
      this.check_process();
    },
    departments_id: function() {
      this.check_process();
    },
    fromdate: function() {
      if (this.todate) {
        var fromdate = moment(this.fromdate, 'DD/MM/YYYY');
        var todate = moment(this.todate, 'DD/MM/YYYY');
        if (moment(todate).diff(moment(fromdate), 'days') < 0) {
          this.todate = this.fromdate;
        }
      }
      this.check_process();
    },
    todate: function() {
      this.check_process();
    },
  },
  methods: {
    check_process() {
      if (
        (this.employee_id != null ||
          (this.departments_id != null && this.branch_id != null)) &&
        this.fromdate &&
        this.todate
      ) {
        this.disabled_process = false;
      } else {
        this.disabled_process = true;
      }
    },
    async showEditDialog(date) {
      if (date) {
        let timeinout = [...this.$store.getters['stafftimeinout/timeinout']];
        let result = timeinout.filter((item) => {
          return (
            item.worktime ==
            moment(date, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('YYYY-MM-DD')
          );
        });

        this.edittime_data = result[0];
        this.edittime_data.date_timeout = this.edittime_data.date;
        this.edittime_data.date_timeedit = this.edittime_data.date;
        this.showEdit_time = true;
      } else {
        return false;
      }
    },
    async edittime_insert(date) {
      let timeinout = [...this.$store.getters['stafftimeinout/timeinout']];
      let timeinout_filter = timeinout.filter((item) => {
        return item.date == date;
      });
      if (timeinout_filter[0].timein == '' && this.time_in == null) {
        await this.$store.dispatch('alerts/error', 'กรุณาใส่เวลาเข้างาน!');
        return false;
      }
      if (timeinout_filter[0].timeout == '' && this.time_out == null) {
        await this.$store.dispatch('alerts/error', 'กรุณาใส่เวลาออกงาน!');
        return false;
      }
      if (
        timeinout_filter[0].timein !== '' &&
        timeinout_filter[0].timeout !== '' &&
        this.time_edit == null
      ) {
        await this.$store.dispatch(
          'alerts/error',
          'กรุณาใส่เวลาเข้า - ออกงาน!',
        );
        return false;
      }
      if (!this.remark || this.remark == null || this.remark == '') {
        await this.$store.dispatch('alerts/error', 'กรุณาใส่หมายเหตุ!');
        return false;
      }

      if (this.time_in) {
        var time_in = moment(date + ' ' + this.time_in, [
          'YYYY-MM-DD hh:mm:ss A',
          'DD/MM/YYYY hh:mm:ss A',
        ]).format('YYYY-MM-DD hh:mm:ss A');
        time_in = time_in != 'Invalid date' ? time_in : null;
        time_in.replace('ก่อนเที่ยง', 'AM');
        time_in.replace('หลังเที่ยง', 'PM');
      }

      if (this.time_out) {
        var time_out = moment(
          this.edittime_data.date_timeout + ' ' + this.time_out,
          ['YYYY-MM-DD hh:mm:ss A', 'DD/MM/YYYY hh:mm:ss A'],
        ).format('YYYY-MM-DD hh:mm:ss A');
        time_out = time_out != 'Invalid date' ? time_out : null;
        time_out.replace('ก่อนเที่ยง', 'AM');
        time_out.replace('หลังเที่ยง', 'PM');
      }

      if (this.time_edit) {
        var time_edit = moment(
          this.edittime_data.date_timeedit + ' ' + this.time_edit,
          ['YYYY-MM-DD hh:mm:ss A', 'DD/MM/YYYY hh:mm:ss A'],
        ).format('YYYY-MM-DD hh:mm:ss A');
        time_edit = time_edit != 'Invalid date' ? time_edit : null;
        time_edit.replace('ก่อนเที่ยง', 'AM');
        time_edit.replace('หลังเที่ยง', 'PM');
      }

      if (time_in == null && time_out == null && time_edit == null) {
        await this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
        return false;
      }

      let datatimeinout = {
        id: null,
        type: 'timeinout',
        employee_id: this.edittime_data.condition.employee_id,
        ...(time_in ? { timein: time_in } : {}),
        ...(time_out ? { timeout: time_out } : {}),
        ...(time_edit ? { timeedit: time_edit } : {}),
        remark: this.remark,
        emp_id_edit: this.$store.getters['profile/me'].employee_id,
      };
      try {
        loading_start();
        await this.$store
          .dispatch('stafftimeinout/addStafftimeinout', datatimeinout)
          .then(
            async () => {
              let params = {
                filter: {
                  ...(this.employee_id ? { emp_id: this.employee_id } : {}),
                  ...(this.fromdate
                    ? { fromdate: moment(this.fromdate).format('YYYY-MM-DD') }
                    : {}),
                  ...(this.todate
                    ? { todate: moment(this.todate).format('YYYY-MM-DD') }
                    : {}),
                },
              };
              await this.searchTimeInout();
              this.showEdit_time = false;
              this.$store.dispatch('alerts/success', 'Save Complete.');
              await this.$store.dispatch(
                'stafftimeinout/processTimeWork',
                params,
              );
              loading_close();
              this.remark = null;
              this.time_in = null;
              this.time_out = null;
              this.time_edit = null;
              this.edittime_data = null;
            },
            async (err) => {
              await this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!');
              loading_close();
            },
          );
      } catch (error) {}
    },

    /*
     * find staff time in out
     */
    async searchTimeInout() {
      if (!this.branches) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก สำนักงาน');
        return false;
      } else if (!this.employee_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      } else if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สิ้นสุด');
        return false;
      }
      let params = {
        filter: {
          ...(this.employee_id ? { emp_id: this.employee_id } : {}),
          ...(this.fromdate
            ? {
                fromdate: moment(this.fromdate, 'DD/MM/YYYY').format(
                  'YYYY-MM-DD',
                ),
              }
            : {}),
          ...(this.todate
            ? { todate: moment(this.todate, 'DD/MM/YYYY').format('YYYY-MM-DD') }
            : {}),
        },
      };
      this.showProgressBar = true;

      try {
        await this.$store
          .dispatch('stafftimeinout/timeinout', params)
          .then(async (datatime) => {
            this.tableData = [];
            this.disabled_export = true;
            if (datatime.length > 0) {
              // if (this.$store.getters['stafftimeinout/timeinout']) {
              // let data = this.$store.getters['stafftimeinout/timeinout'];
              let datatime_cal = await this.calculateLeave(datatime);
              this.tableData = datatime_cal;
              this.disabled_export = false;
            } else {
              this.$store.dispatch('alerts/error', 'No data!!!');
            }
            this.showProgressBar = false;
          });
      } catch (e) {
        this.showProgressBar = false;
        this.disabled_export = true;
        await this.$store.dispatch('alerts/error', 'No data!!!');
      }
    },

    async calculateLeave(data) {
      var leavelisttype = {
        sick: { name: 'sick', nameth: 'ลาป่วย' },
        personal: { name: 'personal', nameth: 'ลากิจ' },
        annual: { name: 'annual', nameth: 'ลาพักร้อน' },
        wop: { name: 'wop', nameth: 'ลาไม่รับค่าจ้าง' },
        ch: { name: 'ch', nameth: 'เปลี่ยนวันหยุด/วันเก็บ' },
        maternity: { name: 'maternity', nameth: 'ลาคลอด' },
        military: { name: 'military', nameth: 'ลารับราชการทหาร' },
        training: { name: 'training', nameth: 'ลาฝึกอมรม' },
        sterilization: { name: 'sterilization', nameth: 'ลาทำหมัน' },
        ph: { name: 'ph', nameth: 'ลาวันหยุดประเพณี' },
      };
      data.forEach((d) => {
        d.date = moment(d.date, ['YYYY-MM-DD', 'DD/MM/YYYY']).format(
          'DD/MM/YYYY',
        );
        d.date = d.date != 'Invalid date' ? d.date : '';
        if (d.timein) {
          d.timein.replace('ก่อนเที่ยง', 'AM');
          d.timein.replace('หลังเที่ยง', 'PM');
          d.timein = this.formatDateTo24(d.timein);
          d.timein = moment(d.timein, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm:ss',
          );
          d.timein = d.timein != 'Invalid date' ? d.timein : '-';
        }
        d.timebreak = moment(d.timebreak, 'YYYY-MM-DD HH:mm:ss A').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        d.timebreak = d.timebreak != 'Invalid date' ? d.timebreak : '-';
        d.timefinishbreak = moment(
          d.timefinishbreak,
          'YYYY-MM-DD HH:mm:ss A',
        ).format('DD/MM/YYYY HH:mm:ss');
        d.timefinishbreak =
          d.timefinishbreak != 'Invalid date' ? d.timefinishbreak : '-';
        if (d.timeout) {
          d.timeout.replace('ก่อนเที่ยง', 'AM');
          d.timeout.replace('หลังเที่ยง', 'PM');
          d.timeout = this.formatDateTo24(d.timeout);
          d.timeout = moment(d.timeout, 'YYYY-MM-DD HH:mm:ss').format(
            'DD/MM/YYYY HH:mm:ss',
          );
          d.timeout = d.timeout != 'Invalid date' ? d.timeout : '-';
        }
        d.cw = 0;
        d.newworkstatus = '';
        if (d.workstatus.split('-').length > 1) {
          d.workstatus.split('-').forEach((work) => {
            let worksplit = work.split('_');
            d.cw += 1;
            if (d.newworkstatus != '') {
              d.newworkstatus += ' - ';
            }
            d.newworkstatus += leavelisttype[worksplit[0]].nameth;
            if (worksplit[1] == 'f') {
              d.newworkstatus += ' (เต็มวัน)';
            } else if (worksplit[1] == 'hm') {
              d.newworkstatus += ' (ครึ่งเช้า)';
            } else if (worksplit[1] == 'hn') {
              d.newworkstatus += ' (ครึ่งบ่าย)';
            }
          });
        }
      });
      return data;
    },

    async calculateSalary() {
      if (!this.fromdate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่เริ่ม');
        return false;
      } else if (!this.todate) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก วันที่สุดท้าย');
        return false;
      } else if (this.radio_select === 'bystaff' && !this.employee_id) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือก พนักงาน');
        return false;
      }

      this.tableData = [];
      this.private_code = localStorage.getItem('privatecode');
      let params = {
        filter: {
          ...(this.fromdate
            ? {
                fromdate: moment(this.fromdate, 'DD/MM/YYYY').format(
                  'YYYY-MM-DD',
                ),
              }
            : {}),
          ...(this.todate
            ? { todate: moment(this.todate, 'DD/MM/YYYY').format('YYYY-MM-DD') }
            : {}),
          ...(this.select_ot ? { select_ot: this.select_ot } : null),
          ...{ emp_id: this.employee_id },
          ...(this.private_code ? { private_code: this.private_code } : {}),
        },
      };

      try {
        this.start();
        await this.$store.dispatch('salarys/salaryCalculate', params).then(
          (data) => {
            // this.tableData = this.$store.getters['salarys/salarycalculatelist'];
            this.tableData = data;
            this.is_calculate = true;
            this.finish();
          },
          () => {
            this.$Progress.fail();
          },
        );
      } catch (e) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
      }
    },

    /**
     * process staff in out to staffworks table
     */
    async processTimeWork() {
      let params = {
        filter: {
          ...(this.employee_id ? { emp_id: this.employee_id } : {}),
          ...(this.departments_id ? { dep_id: this.departments_id } : {}),
          ...(this.branch_id ? { bch_id: this.branch_id } : {}),
          ...(this.fromdate
            ? {
                fromdate: moment(this.fromdate, 'DD/MM/YYYY').format(
                  'YYYY-MM-DD',
                ),
              }
            : {}),
          ...(this.todate
            ? { todate: moment(this.todate, 'DD/MM/YYYY').format('YYYY-MM-DD') }
            : {}),
        },
      };
      this.showProgressBar = true;
      try {
        await this.$store
          .dispatch('stafftimeinout/processTimeWork', params)
          .then((data) => {
            if (data.comment) {
              this.$store.dispatch('alerts/error', data.comment);
            } else {
              this.$store.dispatch('alerts/success', 'Process Complete.');
            }
            this.showProgressBar = false;
          });
      } catch (e) {
        this.showProgressBar = false;
        this.disabled_export = true;
        await this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด!!');
      }
    },

    // Export excel
    onExport() {
      const data = this.tableData.map((d) => ({
        date: d.date,
        in: d.timein,
        out: d.timeout,
        break: d.timebreak,
        finishbreak: d.timefinishbreak,
        status: d.workstatus,
        minutetolate: d.timetolatein,
        minuteoff: d.timetolateout,
        workingtime:
          moment(d.condition.start_date + ' ' + d.condition.start_time).format(
            'HH:mm',
          ) +
          ' - ' +
          moment(d.condition.start_date + ' ' + d.condition.finish_time).format(
            'HH:mm',
          ),
      }));
      const dataWS = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      const file_name = { ...this.employee_id } + '_timeinout_export' + '.xlsx';
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, file_name);
    },

    /**
     * set branch value from child component
     */
    setBranches(v) {
      // this.branches = v;
      this.branch_id = v;
      this.departments_id = null;
      this.staffs = [];
      this.setEmployeeID(null);
      this.disabled_process = true;
    },

    /**
     * set employee id value from child component
     */
    setDepartment(v) {
      if (v) {
        this.departments_id = v;
        this.staffs = [];
        this.setEmployeeID(null);
      }
    },

    /**
     * set employee id value from child component
     */
    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.setEmployeeID(v.id);
      } else {
        this.staffs = [];
        this.setEmployeeID(null);
      }
    },
    setEmployeeID(v) {
      this.$emit('setPropEmployeeID', v);
      this.employee_id = v;
    },
    async getProfile() {
      await this.$store.dispatch('profile/me');
    },
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
};
</script>

<style scoped>
.table-transparent {
  background-color: transparent !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.other {
  color: #508dff;
}
.stop-work {
  color: red;
}
.weekend-work {
  color: #ffd000;
}
.change-day {
  color: #ff932e;
}
.leave {
  color: #508dff;
}
.notscan {
  color: #d400ff;
}
.late {
  color: #c4284a;
}
.keep-day {
  color: #00dd30;
}
</style>
