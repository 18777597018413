import qs from 'qs';
import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;
const urlserv = process.env.VUE_APP_API_SERVICE_URL;

function fontthsarabunnew(params) {
  return axios.get(`${url}/v1/fontthsarabunnew`).then((response) => {
    return response.data;
  });
}

function exporttime(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (['bch_id', 'fromdate', 'todate', 'stafftype'].includes(value[0])) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  // let geturl = `${url}/v1/exporttimebplus?t=${t}`;
  let geturl = `${urlserv}/export/exporttimebplus?t=${t}`;
  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function exportot(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (['bch_id', 'fromdate', 'todate', 'stafftype'].includes(value[0])) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  // let geturl = `${url}/v1/exportot?t=${t}`;
  let geturl = `${urlserv}/export/exportot?t=${t}`;
  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function exportotboat(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (['bch_id', 'fromdate', 'todate', 'stafftype'].includes(value[0])) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  let geturl = `${url}/v1/exportotboat?t=${t}`;
  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function exporflatrateShow(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (['bch_id', 'fromdate', 'todate', 'stafftype'].includes(value[0])) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  let geturl = `${url}/v1/exporflatrateShow?t=${t}`;
  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function exportotspa(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (['bch_id', 'fromdate', 'todate', 'stafftype'].includes(value[0])) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  let geturl = `${url}/v1/exportotspa?t=${t}`;
  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function exportleave(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (['bch_id', 'fromdate', 'todate', 'stafftype'].includes(value[0])) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  let geturl = `${url}/v1/exportleave?t=${t}`;
  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function exportapprover(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (['bch_id', 'emp_id', 'stafftype'].includes(value[0])) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  let geturl = `${url}/v1/exportapprover?t=${t}`;

  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function exportsalary(params) {
  let t = params.t;
  var extendurl = '';

  Object.entries(params).forEach((value, key) => {
    if (
      ['bch_id', 'emp_id', 'months', 'years', 'rp_id', 'stafftype'].includes(
        value[0],
      )
    ) {
      let d = typeof value[1] !== 'undefined' ? value[1] : '';
      if (d) {
        extendurl += `&${value[0]}=${value[1]}`;
      }
    }
  });
  let geturl = `${url}/v1/exportsalary?t=${t}`;
  geturl += extendurl;
  window.open(encodeURI(geturl), '_blank');
  return geturl;
}

function reportsalarylist(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v1/reportsalarylist`, options).then((response) => {
    return response.data;
  });
}

export default {
  fontthsarabunnew,
  exporttime,
  exportot,
  exportleave,
  exportapprover,
  exportsalary,
  reportsalarylist,
  exportotboat,
  exportotspa,
  exporflatrateShow,
};
