<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <!-- <li>
            <a href="#" target="_blank"> Link #1 </a>
          </li>
          <li>
            <a href="#" target="_blank"> Link #2 </a>
          </li> -->
          <li>
            <a href="#"> About Us </a>
          </li>
          <li>
            <a href="#"> Licenses </a>
          </li>
          <!-- <li>
            <a href="#" target="_blank"> Licenses </a>
          </li> -->
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}, Livepocket Ver. {{ version }}
        made by
        <a class="bold" href="https://www.bookengine.com/" target="_blank">
          BookEngine
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
<style>
.bold {
  font-weight: bold;
}
@media print {
  .footer {
    display: none;
  }
}
</style>
