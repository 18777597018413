<template>
  <md-autocomplete
    v-model="searchEmployees"
    :md-options="employeeMatOptions"
    @md-opened="searchEmp"
    @md-changed="searchEmp"
    @md-selected="onSelect"
    @md-clear="clearStaff"
    md-clearable
  >
    <label>{{ label }}</label>
    <template slot="md-autocomplete-item" slot-scope="{ item }">
      {{ item.emp_code }}
      {{ item.firstname_en }}
      {{ item.lastname_en }}
    </template>
  </md-autocomplete>
</template>

<script>
import employeeMixin from '@/mixins/employee-mixin';
// import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  mixins: [employeeMixin],
  name: 'selectEmplist',
  data() {
    return {
      profile: null,
      branches: null,
      departments: null,
      employeeMatOptions: [],
      searchEmployees: null,
      timeout_searchEmp: null,
    };
  },
  props: {
    value: { default: null },
    label: { type: String, default: '' },
    format: { type: String, default: '' },
    permissionsList: { default: () => [] },
    filterPermissions: { default: () => [] },
  },
  computed: {},
  async created() {
    this.profile = await this.getProfileData();
    this.searchEmp();
  },

  watch: {},
  methods: {
    async searchEmp() {
      let chk = await this.employeeMatOptions;

      if (this.searchEmployees == null || this.searchEmployees == '') {
        this.$emit('input', null);
      }

      if (chk.length > 0 && this.old_searchEmployees == this.searchEmployees) {
        return;
      }

      if (this.timeout_searchEmp != null) {
        clearTimeout(this.timeout_searchEmp);
      }

      this.timeout_searchEmp = setTimeout(async () => {
        let params = {
          com_id: this.profile[0].com_id,
          first_name: this.searchEmployees,
          last_name: this.searchEmployees,
          emp_code: [this.searchEmployees],
        };

        let result = await this.$store.dispatch('staffs/getlistEmp', params);

        if (result?.msg) {
          result = [];
        }

        this.employeeMatOptions = new Promise((resolve) => {
          resolve(result);
        });
      }, 700);

      this.old_searchEmployees = this.searchEmployees;
    },
    onSelect(value) {
      if (this.timeout_searchEmp != null) {
        clearTimeout(this.timeout_searchEmp);
      }

      this.searchEmployees = value.firstname_en + ' ' + value.lastname_en;
      let emit = {};
      Object.assign(emit, value);
      if (this.format) {
        emit = emit[this.format];
      }
      this.$emit('input', emit);
    },
    clearStaff() {
      this.searchEmployees = '';
      this.$emit('input', null);
    },
  },
};
</script>
