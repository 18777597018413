<template>
  <div>
    <!--ไดอะลอก add-->
    <md-dialog
      style="min-height: 60%; min-width: 50%;"
      :md-active.sync="add_setting"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Add Setting Price
      </md-dialog-title>
      <md-content align="left">
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;">เลือก: </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field class="searchbranchs">
              <label>สำนักงาน</label>
              <md-select v-model="branches" name="branch_id">
                <md-option
                  v-for="item in available_branches"
                  :key="item.bch_id"
                  :value="item.bch_id"
                >
                  {{ item.brname_th }}
                </md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;"
              >ประเภท OT:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
          >
            <ValidationProvider
              name="ot_id"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="ot">เลือกประเภท OT</label>
                <md-select v-model="ot_id">
                  <md-option
                    v-for="item in available_ots"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label
              style="margin-top:1.3rem; text-align: right;"
              v-if="ot_id == 4"
              >ตำแหน่งหน้าที่:
            </label>
            <label
              style="margin-top:1.3rem; text-align: right;"
              v-if="ot_id == 6"
              >รายการแสดง:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
          >
            <!-- <ValidationProvider
              name="setting_name"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="ot">เลือกตำแหน่งหน้าที่</label>
                <md-select v-model="setting_name">
                  <md-option
                    v-for="item in flat_rate_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.otType_name }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider> -->
            <md-field>
              <label v-if="ot_id == 4">ระบุตำแหน่งหน้าที่ </label>
              <label v-if="ot_id == 6">ระบุรายการแสดง </label>
              <md-input
                class="md-size-50 searchID"
                v-model="setting_name"
              ></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;"
              >อัตราค่าแรง:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field>
              <label>กรอกข้อมูล </label>
              <md-input
                class="md-size-50 searchID"
                v-model="price_ot"
              ></md-input>
            </md-field>
          </div>
        </div>
      </md-content>
      <md-dialog-actions>
        <div class="md-layout spacing md-alignment-center">
          <md-button class="md-success md-wd md-sm" @click="insert_data()"
            >บันทึก</md-button
          >
        </div>
      </md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก add-->
    <!--ไดอะลอก edit-->
    <md-dialog
      style="min-height: 60%; min-width: 50%;"
      :md-active.sync="edit_setting"
      md-fullscreen
    >
      <md-dialog-title style="font-size: 1.6rem" align="center">
        Edit Setting Price
      </md-dialog-title>
      <md-content align="left">
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;">เลือก: </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field class="searchbranchs">
              <label>สำนักงาน</label>
              <md-select v-model="edit_branches" name="branch_id">
                <md-option
                  v-for="item in available_branches"
                  :key="item.branch_id"
                  :value="item.branch_id"
                >
                  {{ item.br_nameth }}
                </md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;"
              >ประเภท OT:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
          >
            <ValidationProvider
              name="ot_id"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="ot">เลือกประเภท OT</label>
                <md-select v-model="edit_ot_id">
                  <md-option
                    v-for="item in available_ots"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label
              style="margin-top:1.3rem; text-align: right;"
              v-if="edit_ot_id == 4"
              >ตำแหน่งหน้าที่:
            </label>
            <label
              style="margin-top:1.3rem; text-align: right;"
              v-if="edit_ot_id == 6"
              >รายการแสดง:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
          >
            <!-- <ValidationProvider
              name="edit_setting_name"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <md-field
                :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
              >
                <label for="ot">เลือกตำแหน่งหน้าที่</label>
                <md-select v-model="edit_setting_name">
                  <md-option
                    v-for="item in flat_rate_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.otType_name }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider> -->
            <md-field>
              <label v-if="edit_ot_id == 4">ระบุตำแหน่งหน้าที่ </label>
              <label v-if="edit_ot_id == 6">ระบุรายการแสดง </label>
              <md-input
                class="md-size-50 searchID"
                v-model="edit_setting_name"
              ></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout spacing md-alignment-left">
          <div
            class="md-layout-item md-size-25 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
            style="text-align: right;"
          >
            <label style="margin-top:1.3rem; text-align: right;"
              >อัตราค่าแรง:
            </label>
          </div>
          <div
            class="md-layout-item md-size-45 md-medium-size-40 md-small-size-40 md-xsmall-size-100"
            align="center"
          >
            <md-field>
              <label>กรอกข้อมูล </label>
              <md-input
                class="md-size-50 searchID"
                v-model="edit_price_ot"
              ></md-input>
            </md-field>
          </div>
        </div>
      </md-content>
      <md-dialog-actions>
        <div class="md-layout spacing md-alignment-center">
          <md-button class="md-success md-wd md-sm" @click="edit_data()"
            >บันทึก</md-button
          >
        </div>
      </md-dialog-actions>
    </md-dialog>
    <!--ปิดไดอะลอก add-->
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card-content>
          <div class="add-memo" style="text-align: right;">
            <md-button class="md-primary md-dense" @click="add_setting = true">
              เพิ่มการตั้งค่า
            </md-button>
          </div>
          <br />
          <md-table
            :value="tableData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="สาขา">
                <span class="upfont">
                  {{ item.br_nameth }}
                </span>
              </md-table-cell>
              <md-table-cell class="backwhite" md-label="ประเภท OT">
                <span class="upfont">
                  {{ item.name }}
                </span>
              </md-table-cell>
              <md-table-cell class="backwhite" md-label="ตำแหน่งหน้าที่">
                <span class="upfont">
                  {{ item.otType_name }}
                </span>
              </md-table-cell>
              <md-table-cell class="backwhite" md-label="อัตราค่าแรง">
                <span class="upfont">
                  {{ item.price }}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Action">
                <md-button
                  class="md-warning md-just-icon md-round"
                  style="margin: 0.2rem"
                  @click="goto_edit_data(item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-rose md-just-icon md-round"
                  style="margin: 0.2rem"
                  @click="goto_delete(item)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import $ from 'jquery';
import Vuex from 'vuex';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {},
  data() {
    return {
      add_setting: false,
      edit_setting: false,
      branches: '',
      edit_branches: '',
      setting_name: '',
      edit_setting_name: '',
      ot_id: '',
      edit_ot_id: '',
      price_ot: '',
      edit_price_ot: '',
      edit_id: '',
      setting_price_name: '',
    };
  },
  async created() {},
  props: {
    tableData: {
      type: Array,
      default: () => {},
    },
    available_branches: {
      type: Array,
      default: () => {},
    },
    available_ots: {
      type: Array,
      default: () => {},
    },
    flat_rate_list: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    async insert_data(data) {
      let price_ot = this.price_ot.toString();
      let data_add = {};
      if (!this.branches) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return false;
      }
      if (!this.setting_name) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกข้อมูล');
        return false;
      }
      if (!this.price_ot) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกข้อมูลอัตราค่าแรง');
        return false;
      }
      if (!this.price_ot.match(/^([0-9])+$/i)) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกเฉพาะตัวเลขเท่านั้น');
        return false;
      }
      data_add['branches_id'] = this.branches;
      data_add['price'] = parseFloat(this.price_ot).toFixed(2);
      data_add['ot_id'] = this.ot_id;
      data_add['setting_price_name'] = this.setting_name;
      let resp = await this.$store.dispatch('otsflatrate/addPriceOT', data_add);
      if (resp == 0) {
        await this.$store.dispatch(
          'alerts/error',
          'Oops, something went wrong!',
        );
        return false;
      } else if (resp == 1) {
        await this.$store.dispatch('alerts/success', 'บันทึกสำเร็จ');
        this.add_setting = false;
        this.$emit('get_settingPrice_OT');
        this.branches = '';
        this.setting_name = '';
        this.price_ot = '';
      }
    },
    async goto_edit_data(data) {
      this.edit_branches = data.bch_id;
      this.edit_setting_name = data.otType_name;
      this.edit_setting_id = data.setting_price_name;
      this.edit_price_ot = data.price;
      this.edit_id = data.ot_price_id;
      this.edit_ot_id = data.ot_id;
      this.edit_setting = true;
    },
    async edit_data() {
      let price_ot = this.edit_price_ot.toString();
      let data_edit = {};
      if (!this.edit_branches) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return false;
      }
      if (!this.edit_setting_name) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกข้อมูล');
        return false;
      }
      // if (!this.edit_ot_id) {
      //   this.$store.dispatch('alerts/error', 'กรุณาเลือกประเภท OT');
      //   return false;
      // }
      if (!price_ot) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกข้อมูลอัตราค่าแรง');
        return false;
      }
      if (!price_ot.match(/^([0-9])+$/i)) {
        this.$store.dispatch('alerts/error', 'กรุณากรอกเฉพาะตัวเลขเท่านั้น');
        return false;
      }
      data_edit['branches_id'] = this.edit_branches;
      data_edit['price'] = parseFloat(this.edit_price_ot).toFixed(2);
      data_edit['ot_id'] = this.edit_ot_id;
      data_edit['setting_price_name'] = this.edit_setting_name;
      data_edit['id'] = this.edit_id;
      data_edit['edit_setting_id'] = this.edit_setting_id;
      const confirmation = await Swal.fire({
        title: 'ยืนยัน ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Accept!',
        cancelButtonText: 'No, Cancel',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
      });

      if (confirmation.value === true) {
        await this.$store
          .dispatch('otsflatrate/updatePriceOT', data_edit)
          .then((response) => {
            this.$store.dispatch('alerts/success', 'ตั้งค่าเรียบร้อยแล้ว.');
            this.edit_setting = false;
            this.$emit('get_settingPrice_OT');
          });
      }
    },
    async goto_delete(data) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            let params = {
              id: data.ot_price_id,
              edit_setting_id: data.setting_price_name,
            };
            let resp = await this.$store.dispatch(
              'otsflatrate/deletePriceOT',
              params,
            );
            if ((resp = 1)) {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'fingerscan has been deleted.',
                'success',
              );
              this.$emit('get_settingPrice_OT');
            } else if ((resp = 0)) {
              swalWithBootstrapButtons.fire(
                'Error!',
                'Oops, something went wrong!',
                'error',
              );
            }
          } else if (result.dismiss) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'Your imaginary SanthiyaSOS is safe :)',
              'error',
            );
          }
        });
    },
  },
};
</script>
<style scoped>
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 99;
  max-height: 225px;
}
.md-switch .md-switch-label {
  color: rgba(0, 0, 0);
}
.upfont {
  font-size: 0.95rem;
}
.backwhite {
  background-color: white;
}
/* .searchstaffs {
  display: none;
} */
</style>
