<template>
  <div class="report-outpremises">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                description
              </md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="" style="text-align: right">
              <!-- <md-button class="md-primary md-dense" @click="goToReportDis">
                สรุปผลการประเมิน
              </md-button> -->
            </div>
            <!-- filter branch -->
            <div class="">
              <md-dialog :md-active.sync="showEditDialog" md-fullscreen>
                <md-dialog-title>{{ outpremise_emp }}</md-dialog-title>
                <md-dialog-content>
                  <div v-if="outpremise.length > 0">
                    <md-table class="" v-model="outpremise">
                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="No.">
                          {{ outpremise.indexOf(item) + 1 }}
                        </md-table-cell>

                        <md-table-cell md-label="ตั้งแต่">
                          {{ `${item.from_datetime}` }}
                        </md-table-cell>

                        <md-table-cell md-label="จนถึง">
                          {{ `${item.to_datetime}` }}
                        </md-table-cell>

                        <md-table-cell md-label="คำขอ">
                          {{
                            `${convertTypeToText(
                              item.leave_premises_type,
                            )} ที่ ${item.go_to_location}`
                          }}
                        </md-table-cell>

                        <md-table-cell md-label="หมายเหตุ">
                          {{ `${item.emp_remark}` }}
                        </md-table-cell>

                        <md-table-cell md-label="สถานะ">
                          <span
                            v-html="
                              (item.wait_status || '').replace(/\,/g, '<br>')
                            "
                          ></span>
                        </md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>
                </md-dialog-content>
                <md-dialog-actions>
                  <md-button class="md-danger" @click="showEditDialog = false">
                    ปิด
                  </md-button>
                </md-dialog-actions>
              </md-dialog>
            </div>
            <div class="">
              <filter-employee
                @childBranches="setBranches"
                @childDepartment="setDepartment"
                @childStaffs="setStaffs"
                :permissionsList="permissions"
              >
              </filter-employee>
              <!-- .filter branch -->
              <!-- filtter date -->
              <div class="md-layout spacing md-alignment-left">
                <div class="md-layout-item md-size-20"></div>
                <div
                  class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
                >
                  <b-form-group label="วันที่เริ่ม" label-for="start-date">
                    <b-datepicker
                      id="start-date"
                      placeholder="วันที่เริ่ม"
                      locale="th"
                      v-model="fromdate"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      required
                    ></b-datepicker>
                  </b-form-group>
                </div>
                <div
                  class="md-layout-item md-size-30 md-medium-size-30 md-small-size-35 md-xsmall-size-100"
                >
                  <b-form-group label="วันที่สิ้นสุด" label-for="end-date">
                    <b-datepicker
                      id="end-date"
                      placeholder="วันที่สิ้นสุด"
                      locale="th"
                      v-model="todate"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      required
                    ></b-datepicker>
                  </b-form-group>
                </div>
                <div class="md-layout-item md-size-10">
                  <md-progress-spinner
                    :md-diameter="50"
                    :md-stroke="3"
                    md-mode="indeterminate"
                    :md-value="100"
                    v-show="showProgressBar"
                  >
                  </md-progress-spinner>
                </div>
              </div>
              <!-- .filtter date -->
            </div>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                </div>
              </div>
            </div>
            <div v-if="history.length > 0">
              <md-table class="" v-model="history">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="No.">
                    {{ history.indexOf(item) + 1 }}
                  </md-table-cell>
                  <md-table-cell md-label="รหัสพนักงาน">
                    {{ item.emp_code }}
                  </md-table-cell>
                  <md-table-cell md-label="ชื่อ - สกุล">
                    {{
                      item.title_nameth +
                        item.first_nameth +
                        ' ' +
                        item.last_nameth
                    }}
                    (
                    {{
                      item.title_nameen +
                        item.first_nameen +
                        ' ' +
                        item.last_nameen
                    }})
                  </md-table-cell>
                  <md-table-cell md-label="ตำแหน่ง (แผนก)">
                    {{ item.pos_nameth }} ( {{ item.dep_nameth }} )
                  </md-table-cell>
                  <md-table-cell md-label="จำนวนครั้ง">
                    {{ item.num }}
                  </md-table-cell>
                  <md-table-cell md-label="Action">
                    <md-button
                      class="md-raised md-primary pb-10"
                      @click="showDetail(item.emp_id)"
                    >
                      รายละเอียด
                    </md-button>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between"></md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import FilterEmployee from '@/components/FilterEmployee';
import { loading_start, loading_close } from '@/utils/loading.js';
// import { debounce } from 'lodash';
export default {
  components: {
    'filter-employee': FilterEmployee,
  },
  data() {
    return {
      me: null,
      permissions: [],
      employee_id: null,
      departments_id: null,
      branch_id: null,
      staffs: [],
      fromdate: moment().format('YYYY-MM-DD'),
      todate: moment().format('YYYY-MM-DD'),
      moment: moment,
      showProgressBar: false,
      timeOutLoad: null,
      history: [],
      outpremise: [],
      outpremise_emp: '',
      showEditDialog: false,
    };
  },
  async created() {
    await this.getProfile();
    this.setPermission(this.me);
  },
  watch: {
    todate(val) {
      let chk = this.validateDate();
      if (!chk) {
        return false;
      }
      if (val) {
        this.loadReport();
      }
    },
    fromdate(val) {
      let chk = this.validateDate();
      if (!chk) {
        return false;
      }
      if (val) {
        this.loadReport();
      }
    },
  },
  computed: {},
  methods: {
    setDefaultDate() {
      this.fromdate = moment().format('YYYY-MM-DD');
    },

    validateDate() {
      if (!this.fromdate) {
        return false;
      }

      if (moment(this.fromdate) > moment(this.todate)) {
        setTimeout(() => {
          this.todate = moment(this.fromdate, 'YYYY-MM-DD').format(
            'YYYY-MM-DD',
          );
        }, 100);
        return false;
      }
      return true;
    },

    async loadReport() {
      if (!this.fromdate || !this.branch_id) {
        this.history = [];
        return false;
      }
      var params = {
        ...{ branch_id: this.branch_id ? this.branch_id : {} },
        ...{ departments_id: this.departments_id ? this.departments_id : {} },
        ...{ employee_id: this.employee_id ? this.employee_id : {} },
        ...{
          from_date: this.fromdate ? this.fromdate : {},
        },
      };
      if (!this.todate) {
        params.to_date = this.fromdate;
      } else {
        params.to_date = this.todate;
      }
      loading_start();
      let data = await this.$store.dispatch(
        'staffleaves/out_premises_report',
        params,
      );
      if (Object.values(data).length > 0) {
        this.history = data;
      } else {
        this.history = [];
      }
      loading_close();
    },

    async showDetail(empId) {
      if (!this.fromdate || !this.branch_id) {
        this.history = [];
        return false;
      }
      var params = {
        ...{ branch_id: this.branch_id ? this.branch_id : {} },
        ...{ departments_id: this.departments_id ? this.departments_id : {} },
        ...{ employee_id: empId ? empId : {} },
        ...{
          from_date: this.fromdate ? this.fromdate : {},
        },
      };
      if (!this.todate) {
        params.to_date = this.fromdate;
      } else {
        params.to_date = this.todate;
      }
      loading_start();
      let data = await this.$store.dispatch(
        'staffleaves/out_premises_report_detail',
        params,
      );
      this.showEditDialog = true;
      if (Object.values(data).length > 0) {
        this.outpremise = data;
        this.outpremise_emp =
          data[0].emp_code +
          ' ' +
          data[0].title_nameth +
          data[0].first_nameth +
          ' ' +
          data[0].last_nameth;
      } else {
        this.outpremise = [];
      }
      loading_close();
    },

    setPermission(val) {
      val.permissions.map((permis) => {
        this.permissions.push(permis.name);
      });
      val.roles[0].permissions.map((permission) => {
        this.permissions.push(permission.name);
      });
    },

    async getProfile() {
      await this.$store.dispatch('profile/me');
      this.me = await this.$store.getters['profile/me'];
    },

    setBranches(v) {
      this.branch_id = v;
      this.departments_id = null;
      this.staffs = [];
      this.setEmployeeID(null);
      this.disabled_process = true;
    },

    setDepartment(v) {
      if (v) {
        this.departments_id = v;
        this.staffs = [];
        this.setEmployeeID(null);
      }
    },

    setStaffs(v) {
      if (v) {
        this.staffs = v;
        this.setEmployeeID(v.id);
      } else {
        this.staffs = [];
        this.setEmployeeID(null);
      }
    },

    setEmployeeID(v) {
      this.employee_id = v;
      this.loadReport();
    },

    convertTypeToText(text) {
      var txt = ``;
      switch (text) {
        case 'according_boss_orders':
          txt = `ไปปฎิบัติงานนอกรีสอร์ท ตามคำสั่งหัวหน้า`;
          break;
        case 'personal_business':
          txt = `ไปทำธุระส่วนตัว`;
          break;
        case 'work_different_branches':
          txt = `ปฎิบัติงานต่างสาขา`;
          break;
      }
      return txt;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell-container .md-button {
  margin: 0;
  padding: 5px;
  height: 27px;
  min-width: 27px;
  width: 90px;
  line-height: 27px;
}
.md-dialog-container {
  display: flex;
  flex-flow: column;
  flex: 1;
  min-width: 1200px;
}
</style>

<style>
.md-button.md-raised.md-primary.pb-10.md-theme-default .md-button-content {
  padding-bottom: 10px;
}
.txt-success {
  color: green;
}
.txt-danger {
  color: red;
}
</style>
